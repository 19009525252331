import { combineReducers } from "redux";
import * as actions from "../actions";
import Constants from "../helpers/Constants";

const getDeltaSaveDays = (table) => {
  switch (table) {
    case "EventHistory":
    case "BugReport":
      return 7;
    case "ItemDelta":
    case "OrderDelta":
    case "OrderTicketDelta":
    case "CouponDelta":
    case "WaitingDelta":
    case "GlobalDelta":
    default:
      return 15;
  }
};

export const getExpDate = (table) => {
  const saveDays = getDeltaSaveDays(table);
  const now = new Date();
  const expDate = parseInt(now.getTime() / 1000, 10) + 60 * 60 * 24 * saveDays;

  return expDate;
};

export const getProgressBannerTitle = (progressEvent) => {
  let title = "";
  switch (progressEvent.func) {
    case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_TRANSFER_HISTORY:
      title = "재고관리 - 지점이동";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.CANCEL_INVENTORY_TRANSFER_HISTORY:
      title = "재고관리 - 지점이동 삭제";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_COUNT_HISTORY:
      title = "재고관리 - 재고실사";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.APPLY_PURCHASE_ORDER:
      title = "재고관리 - 발주서 처리";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.CANCEL_PURCHASE_ORDER:
      title = "재고관리 - 발주서 삭제";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_ADJUSTMENTS:
      title = "재고관리 - 재고조정 처리";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.SYNC_ORDERS:
      title = "자사몰 주문 동기화 중";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.SYNC_PRODUCTS:
      title = "카페24 상품 동기화 중";
      break;
    case Constants.ASYNC_PROGRESS_FUNC.SYNC_INVENTORIES:
      title = "카페24 재고 가져오는 중";
      break;
    default:
      title = "";
      break;
  }
  return title;
};

const initialState = {
  signUp: { email: null, password: null },
  narrowWidth: false,
  pdfStatus: {
    isFetching: false,
    error: null,
  },
  dropoutStatus: {
    isFetching: false,
    error: null,
  },
  requestProgressEvents: {},
  customerInfo: false,
  authorizeModal: {
    isVisible: false,
    view: null,
  },
  modal: {
    isVisible: false,
    view: null,
    pararm: null,
  },
  localEnvironment: null,
  environment: null,
};

function signUp(state = initialState.signUp, action) {
  switch (action.type) {
    case actions.SET_SIGNUP_STATE:
      return {
        ...state,
        email: action.email,
        password: action.password,
      };

    default:
      return state;
  }
}

function narrowWidth(state = initialState.narrowWidth, action) {
  switch (action.type) {
    case actions.SET_NARROW_WIDTH:
      return {
        narrowWidth: action.isNarrow,
      };

    default:
      return state;
  }
}

function pdfStatus(state = initialState.pdfStatus, action) {
  switch (action.type) {
    case "SHARE_HISTORY_PDF":
    case "SHARE_GROUP_HISTORY_PDF":
    case "SHARE_PURCHASE_ORDER_PDF":
      return {
        isFetching: true,
        error: null,
      };
    case "SET_SHARE_PDF_SUCCESS":
      return initialState.pdfStatus;
    case "SET_SHARE_PDF_FAILURE":
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function dropoutStatus(state = initialState.dropoutStatus, action) {
  switch (action.type) {
    case "DROPOUT_ACCOUNT":
      return {
        isFetching: true,
        error: null,
      };
    case "SET_DROPOUT_SUCCESS":
      return initialState.dropoutStatus;
    case "SET_DROPOUT_FAILURE":
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function requestProgressEvents(
  state = initialState.requestProgressEvents,
  action
) {
  switch (action.type) {
    case actions.RECEIVE_REQUEST_PROGRESS_EVENTS:
      return (action.events || []).reduce((obj, evt) => {
        obj[evt.id] = evt;
        return obj;
      }, {});
    case actions.RECEIVE_REQUEST_PROGRESS_EVENT:
      return {
        ...state,
        [action.event.id]: action.event,
      };
    case actions.CLEAR_REQUEST_PROGRESS_EVENT:
      const { [action.id]: _, ...eventById } = state;
      return eventById;
    case actions.SIGN_OUT:
      return initialState.requestProgressEvents;
    default:
      return state;
  }
}

function customerInfo(state = initialState.customerInfo, action) {
  switch (action.type) {
    case actions.OPEN_CUSTOMER_INFO:
      return true;
    case actions.CLOSE_CUSTOMER_INFO:
      return false;
    default:
      return state;
  }
}

const authorizeModal = (state = initialState.authorizeModal, action) => {
  switch (action.type) {
    case actions.SHOW_AUTHORIZE_MODAL:
      return {
        ...state,
        isVisible: true,
        view: action.view,
      };
    case actions.CLOSE_AUTHORIZE_MODAL:
      return initialState.authorizeModal;

    default:
      return state;
  }
};

const modal = (state = initialState.modal, action) => {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return {
        ...state,
        isVisible: true,
        view: action.view,
        param: action.param,
      };
    case actions.CLOSE_MODAL:
      return initialState.modal;

    default:
      return state;
  }
};

function environment(state = initialState.environment, action) {
  switch (action.type) {
    case actions.SET_ENVIRONMENT:
      return action.environment;
    default:
      return state;
  }
}

function localEnvironment(state = initialState.localEnvironment, action) {
  switch (action.type) {
    case actions.UPDATE_LOCATION_ENVIRONMENT:
      return action.environment;
    default:
      return state;
  }
}

export default combineReducers({
  signUp,
  narrowWidth,
  pdfStatus,
  dropoutStatus,
  requestProgressEvents,
  customerInfo,
  authorizeModal,
  modal,
  environment,
  localEnvironment,
});
