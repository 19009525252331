import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";

export const ITEM_PER_PAGE = 10;

export const INIT_SUPPLIER = {
  name: null,
  phone: null,
  fax: null,
  email: null,
  address: null,
  note: null,
  public: null,
};

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  byId: {},
};

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case actions.RECEIVE_SUPPLIERS:
      const _suppliers = action.suppliers.reduce((obj, su) => {
        obj[su.id] = su;
        return obj;
      }, {});
      return _suppliers;
    case actions.RECEIVE_SUPPLIER:
      return {
        ...state,
        [action.supplier.id]: action.supplier,
      };
    case actions.RECEIVE_DELETED_SUPPLIER:
      const { [action.supplier.id]: _, ...suppliers } = state;
      return suppliers;
    default:
      return state;
  }
}

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.CREATE_OR_UPDATE_SUPPLIER:
    case actions.DELETE_SUPPLIER:
    case actions.GET_SUPPLIERS:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_SUPPLIER_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_SUPPLIER_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  status,
  byId,
});
