/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://awqhhw7d3fcpnfraxfnmxydjvu.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7rjyxhf6q5hxjeewojguktvjk4",
    "aws_cognito_identity_pool_id": "ap-northeast-2:ef12880e-de5b-4f39-9af0-82cc17b6981f",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_aZ2W7R0W3",
    "aws_user_pools_web_client_id": "jni45rj979k41ncpobhkmk6fu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sales-web8e1a63f45959416f9a0a9d2924ac117d-master",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
