import { combineReducers } from "redux";
import * as actions from "../actions";

export const REPORT_BASIS = [
  { label: "결제일 기준", value: "pay" },
  { label: "영업일 기준", value: "sales" },
];

const initialState = {
  salesStatus: {
    isFetching: false,
    error: null,
  },
  sales: {
    products: {},
    sideMenu: {},
    timeSale: {},
    categories: {},
    modifiers: {},
    daySale: {},
    discountSale: {},
    order: {},
    summary: {},
    summaryCompare: {},
  },
  items: {},
  itemsStatus: {
    isFetching: false,
    error: null,
  },
  dailyStatus: {
    isFetching: false,
    error: null,
  },
  daily: [],
  orderStatus: {
    isFetching: false,
    error: null,
  },
  order: {},
  customer: {},
  channelStatus: {
    isFetching: false,
    error: null,
  },
  channel: {},
  pointStatus: {
    isFetching: false,
    error: null,
  },
  point: {},
  deliveryStatus: {
    isFetching: false,
    error: null,
  },
  delivery: {},
  trs: {},
  trsItem: {},
  subscription: {},
  subscriptionStatus: {
    isFetching: false,
    error: null,
  },
  reviewStatus: {
    isFetching: false,
    error: null,
  },
  review: {},
  loyaltyStatus: {
    isFetching: false,
    error: null,
  },
  loyalty: {},
  inventoryStatus: {
    isFetching: false,
    error: null,
  },
  inventory: {},
};

function salesStatus(state = initialState.salesStatus, action) {
  switch (action.type) {
    case actions.GET_SALES_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_SALES_REPORT_SUCCESS:
      return initialState.salesStatus;
    case actions.GET_SALES_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const sales = (state = initialState.sales, action) => {
  switch (action.type) {
    case actions.RECEIVE_SALES_REPORT:
      return {
        ...state,
        [action.report.item.type]: action.report.item,
      };
    case actions.SIGN_OUT:
      return initialState.sales;
    default:
      return state;
  }
};

const items = (state = initialState.items, action) => {
  switch (action.type) {
    case actions.RECEIVE_ITEMS_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.order;
    default:
      return state;
  }
};

function itemsStatus(state = initialState.itemsStatus, action) {
  switch (action.type) {
    case actions.GET_ITEMS_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_ITEMS_REPORT_SUCCESS:
      return initialState.itemsStatus;
    case actions.SET_ITEMS_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function dailyStatus(state = initialState.dailyStatus, action) {
  switch (action.type) {
    case actions.GET_SALES_HISTORIES:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_SALES_HISTORIES_SUCCESS:
      return initialState.dailyStatus;
    case actions.GET_SALES_HISTORIES_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const daily = (state = initialState.daily, action) => {
  switch (action.type) {
    case actions.RECEIVE_SALES_HISTORIES:
      return action.histories;
    default:
      return state;
  }
};

function orderStatus(state = initialState.orderStatus, action) {
  switch (action.type) {
    case actions.GET_ORDER_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_ORDER_REPORT_SUCCESS:
      return initialState.orderStatus;
    case actions.GET_ORDER_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const order = (state = initialState.order, action) => {
  switch (action.type) {
    case actions.RECEIVE_ORDER_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.order;
    default:
      return state;
  }
};

const customer = (state = initialState.customer, action) => {
  switch (action.type) {
    case actions.RECEIVE_CUSTOMER_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.customer;
    default:
      return state;
  }
};

function pointStatus(state = initialState.pointStatus, action) {
  switch (action.type) {
    case actions.GET_POINT_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_POINT_REPORT_SUCCESS:
      return initialState.pointStatus;
    case actions.GET_POINT_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const point = (state = initialState.point, action) => {
  switch (action.type) {
    case actions.RECEIVE_POINT_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.point;
    default:
      return state;
  }
};

function deliveryStatus(state = initialState.deliveryStatus, action) {
  switch (action.type) {
    case actions.GET_DELIVERY_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_DELIVERY_REPORT_SUCCESS:
      return initialState.deliveryStatus;
    case actions.GET_DELIVERY_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const delivery = (state = initialState.delivery, action) => {
  switch (action.type) {
    case actions.RECEIVE_DELIVERY_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.delivery;
    default:
      return state;
  }
};

const trs = (state = initialState.trs, action) => {
  switch (action.type) {
    case actions.RECEIVE_TRS_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.trs;
    default:
      return state;
  }
};

const trsItem = (state = initialState.trsItem, action) => {
  switch (action.type) {
    case actions.RECEIVE_TRS_ITEM_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.trs;
    default:
      return state;
  }
};

const subscription = (state = initialState.subscription, action) => {
  switch (action.type) {
    case actions.RECEIVE_SUBSCRIPTION_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.subscription;
    default:
      return state;
  }
};

function subscriptionStatus(state = initialState.subscriptionStatus, action) {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_SUBSCRIPTION_REPORT_SUCCESS:
      return initialState.subscriptionStatus;
    case actions.SET_SUBSCRIPTION_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function reviewStatus(state = initialState.reviewStatus, action) {
  switch (action.type) {
    case actions.GET_REVIEW_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_REVIEW_REPORT_SUCCESS:
      return initialState.reviewStatus;
    case actions.GET_REVIEW_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const review = (state = initialState.review, action) => {
  switch (action.type) {
    case actions.RECEIVE_REVIEW_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.review;
    default:
      return state;
  }
};

function loyaltyStatus(state = initialState.loyaltyStatus, action) {
  switch (action.type) {
    case actions.GET_LOYALTY_REPORT1:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_LOYALTY_REPORT1_SUCCESS:
      return initialState.loyaltyStatus;
    case actions.GET_LOYALTY_REPORT1_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const loyalty = (state = initialState.loyalty, action) => {
  switch (action.type) {
    case actions.RECEIVE_LOYALTY_REPORT1:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.loyalty;
    default:
      return state;
  }
};

function inventoryStatus(state = initialState.inventoryStatus, action) {
  switch (action.type) {
    case actions.GET_INVENTORY_DATE_REPORT:
    case actions.GET_INVENTORY_ITEM_REPORT:
    case actions.GET_INVENTORY_TURNOVER_REPORT:
    case actions.GET_INVENTORY_ASSET_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_INVENTORY_REPORT_STATUS_SUCCESS:
      return initialState.inventoryStatus;
    case actions.SET_INVENTORY_REPORT_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error.errors[0]?.message,
      };
    default:
      return state;
  }
}

const inventory = (state = initialState.inventory, action) => {
  switch (action.type) {
    case actions.RECEIVE_INVENTORY_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.inventory;
    default:
      return state;
  }
};

const channel = (state = initialState.channel, action) => {
  switch (action.type) {
    case actions.RECEIVE_CHANNEL_ORDER_REPORT:
      return action.report;
    case actions.SIGN_OUT:
      return initialState.inventory;
    default:
      return state;
  }
};

function channelStatus(state = initialState.channelStatus, action) {
  switch (action.type) {
    case actions.GET_CHANNEL_ORDER_REPORT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_CHANNEL_ORDER_REPORT_SUCCESS:
      return initialState.orderStatus;
    case actions.SET_CHANNEL_ORDER_REPORT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  salesStatus,
  sales,
  dailyStatus,
  daily,
  orderStatus,
  order,
  customer,
  pointStatus,
  point,
  deliveryStatus,
  delivery,
  trs,
  trsItem,
  reviewStatus,
  review,
  loyaltyStatus,
  loyalty,
  subscriptionStatus,
  subscription,
  inventoryStatus,
  inventory,
  channel,
  channelStatus,
  items,
  itemsStatus,
});
