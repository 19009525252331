import mixpanel from "mixpanel-browser";
import Bugsnag from "@bugsnag/js";
import firebase from "firebase/app";
import "firebase/analytics";

const MIX_TOKEN = "045d20865ac581cb5530eab4e3223835";
mixpanel.init(MIX_TOKEN);

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDd1QrEqrDfofnAbBj6xym3UmWdl0np_kU",
  authDomain: "growing-sales.firebaseapp.com",
  databaseURL: "https://growing-sales.firebaseio.com",
  projectId: "growing-sales",
  storageBucket: "growing-sales.appspot.com",
  messagingSenderId: "607631011146",
  appId: "1:607631011146:web:f2a906e0559de8720d25b3",
  measurementId: "G-EH9JS8F0KR",
};

class Analytics {
  events = {
    signin: "sign_in",
    signup: "sign_up",
    signout: "sign_out",
    selectContent: "select_content",
    openLink: "open_link",
    retrieveReport: "retrieve_report",
  };

  actions = {
    sendResetPassword: "send_reset_passowrd",
    sendPhoneCode: "send_phone_code",
    changePassword: "change_password",
    downloadNewTemplate: "download_new_template",
    downloadExistTemplate: "download_exist_template",
    uploadTemplate: "upload_template",
    tryCreateProduct: "try_create_product",
    tryCreateCategory: "try_create_category",
    tryCreateModifier: "try_create_modifier",
    createProduct: "create_product",
    createCategory: "create_category",
    createModifier: "create_modifier",
    retrieveProduct: "retrieve_product",
    retrieveCategory: "retrieve_category",
    retrieveModifier: "retrieve_modifier",
    updateProduct: "update_product",
    updateCategory: "update_category",
    updateModifier: "update_modifier",
    deleteProduct: "delete_product",
    deleteCategory: "delete_category",
    deleteModifier: "delete_modifier",
    editLoyaltyProgram: "edit_loyalty_program",
    createLoyaltyProgram: "create_loyalty_program",
    launchLoyaltyProgram: "launch_loyalty_program",
    downloadCsv: "download_csv",
    tryCreateCustomer: "try_create_customer",
    createCustomer: "create_customer",
    retrieveCustomerGroup: "retrieve_customer_group",
    retrieveCustomer: "retrieve_customer",
    retrieveOrder: "retrieve_order",
    addCustomerToOrder: "add_customer_to_order",
    updateCustomer: "update_customer",
    editCustomer: "edit_customer",
    deleteCustomer: "delete_customer",
    retrieveOrderInCustomer: "retrieve_order_in_customer",
    updateStoreInfo: "update_store_info",
    enablePaymentMethod: "enable_payment_method",
    disablePaymentMethod: "disable_payment_method",
    openCardRequiredDocList: "open_card_required_doc_list",
    openCardFileUpload: "open_card_file_upload",
    uploadCardDoc: "upload_card_doc",
    submitCardDocs: "submit_card_docs",
    editReceipt: "edit_receipt",
    updateReceipt: "update_receipt",
    tryChangeBillingCard: "try_change_billing_card",
    updateBillingCard: "update_billing_card",
    updateBillingContact: "update_billing_contact",
    openLoyaltyPricing: "open_loyalty_pricing",
    activeLoyaltyProgram: "active_loyalty_program",
    inactiveLoyaltyProgram: "inactive_loyalty_program",
    openBillingHistory: "open_billing_history",
    openAccount: "open_account",
    editAccount: "edit_account",
    updateAccount: "update_account",
  };

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this._analytics = firebase.analytics();
  }

  setUserId(id) {
    this._analytics.setUserId(id);
    Bugsnag.setUser(id);
  }

  setInfo(
    storeId,
    email,
    locationId,
    storeName,
    isMultiLocation,
    storeInfo,
    joinedAt
  ) {
    this._storeId = storeId;
    this._locationId = locationId;
    mixpanel.identify(isMultiLocation ? locationId : storeId);
    mixpanel.people.set({
      $email: email,
      $name: storeName,
      $id: isMultiLocation ? locationId : storeId,
      $joinedAt: joinedAt,
    });
    mixpanel.people.unset(["name", "email", "id", "joinedAt"]);
    mixpanel.set_group("industry", storeInfo?.industryType || "");
    if (isMultiLocation) {
      mixpanel.set_group("franchise", storeInfo.name);
    }
  }

  logEvent(event, meta) {
    // console.log({ event, meta });
    this._analytics.logEvent(event, meta);
  }

  async sendErrorReport(e, name = null, args = null) {
    try {
      let error = e;
      if (e && e.errors && e.errors.length > 0) {
        error = new Error(e.errors[0].message);
      }
      Bugsnag.notify(error, function (event) {
        event.addMetadata("meta", {
          name: name,
          args: args,
          errors: e,
        });
      });
    } catch (err) {
      Bugsnag.notify(err);
    }
  }

  // event type: click, error
  async sendMixPanelEvent(event, params = null) {
    try {
      if (params !== null) {
        mixpanel.track(event, {
          screen: params?.screen || null,
          action: params?.action || null,
          detail: params?.detail ?? null,
          extra: params?.extra || null,
          platform: "sales-web",
        });
      }
    } catch (err) {
      console.log("Failed to send mixpanel event", err);
    }
  }
}

const instance = new Analytics();
export default instance;
