import React from "react";
import { withRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./store/configureStore";
import Amplify, { Auth, I18n } from "aws-amplify";
import awsconfig from "./aws-exports";
import ko from "./translations/ko.json";
import rootSaga from "./sagas";
import { DefaultLoader, NeedUpdateModal } from "./components/Elements";
import API from "./services/API";
import { compareDateString } from "./helpers/Utils";

const Review = React.lazy(() => import("./pages/Review"));
const MobileLayout = React.lazy(() => import("./components/MobileLayout"));
const WaitingLayout = React.lazy(() => import("./components/WaitingLayout"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

const getSiteType = () => {
  if (window.location.hostname.includes("dashboard")) {
    return "dashboard";
  } else if (window.location.hostname.includes("review")) {
    return "review";
  } else if (window.location.hostname.includes("market")) {
    return "market";
  } else if (window.location.hostname.includes("waiting")) {
    return "waiting";
  }
  // For test
  if (window.location.search.includes("crid")) {
    return "waiting";
  }
  // return "market";
};

Amplify.configure(awsconfig);

// I18n
I18n.putVocabularies({ ko: ko });
I18n.setLanguage("ko");

class App extends React.Component {
  state = {
    needVersionUpdate: false,
    noShowBanner: false,
    banners: [],
  };

  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    if (!this.state.needVersionUpdate) {
      this.checkVersionUpdate();
    }
  };

  handleUpdateModalClose = () => {
    this.setState({
      needVersionUpdate: false,
    });
  };

  checkVersionUpdate = async () => {
    try {
      const environment = await API.getEnvironment("current");
      if (
        compareDateString(
          environment.webVersion,
          process.env.REACT_APP_VERSION
        ) === -1 &&
        window.location.hostname.includes("dashboard")
      ) {
        this.setState({
          needVersionUpdate: true,
        });
      } else {
        this.setState({
          needVersionUpdate: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderBody(type) {
    switch (type) {
      case "market":
        return <MobileLayout />;
      case "waiting":
        return <WaitingLayout />;
      case "review":
        return <Review />;
      case "dashboard":
      default:
        return <Dashboard />;
    }
  }

  render() {
    return (
      <ReduxProvider store={store}>
        {this.state.needVersionUpdate ? (
          <NeedUpdateModal
            open={this.state.needVersionUpdate}
            onClose={this.handleUpdateModalClose}
          />
        ) : null}

        <React.Suspense fallback={<DefaultLoader active />}>
          {this.renderBody(getSiteType())}
        </React.Suspense>
      </ReduxProvider>
    );
  }
}

export default withRouter(App);
