import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";
import {
  maskingPhoneNumber,
  phoneNumberFormat,
  replaceAt,
} from "../helpers/Utils";

const initialState = {
  reportStatus: {
    isFetching: false,
    error: null,
  },
  report: {},
  customerStatus: {
    isFetching: false,
    error: null,
    action: null,
  },
  customers: {
    byId: {},
    count: 0,
  },
  customersByTag: {
    byId: {},
    count: 0,
  },
  groupStatus: {
    isFetching: false,
    error: null,
  },
  groups: [],
  customersStats: {
    byId: {},
    count: [],
  },
  tagStatus: {
    action: null,
    isFetching: false,
    error: null,
  },
  tagsById: {},
  retrieved: null,
};

function groupStatus(state = initialState.groupStatus, action) {
  switch (action.type) {
    case actions.GET_CUSTOMER_GROUPS:
    case actions.GET_CUSTOMER_GROUPS_WITH_COUNT:
    case actions.CREATE_OR_UPDATE_CUSTOMER_GROUP:
    case actions.DELETE_CUSTOMER_GROUP:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_CUSTOMER_GROUPS_SUCCESS:
      return initialState.groupStatus;
    case actions.GET_CUSTOMER_GROUPS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const groups = (state = initialState.groups, action) => {
  switch (action.type) {
    case actions.RECEIVE_CUSTOMER_GROUPS:
      return action.groups;
    case actions.SIGN_OUT:
      return initialState.groups;
    default:
      return state;
  }
};

function tagStatus(state = initialState.tagStatus, action) {
  switch (action.type) {
    case actions.GET_CUSTOMER_TAGS:
    case actions.GET_CUSTOMER_TAGS_WITH_COUNT:
    case actions.GET_CUSTOMERS_BY_TAG:
    case actions.CREATE_OR_UPDATE_CUSTOMER_TAG:
    case actions.UPDATE_CUSTOMER_TAGS:
    case actions.DELETE_CUSTOMER_TAG:
      return {
        action: action.type,
        isFetching: true,
        error: null,
      };
    case actions.SET_CUSTOMER_TAG_STATUS_SUCCESS:
      return initialState.tagStatus;
    case actions.SET_CUSTOMER_TAG_STATUS_FAILURE:
      return {
        action: action.type,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const tagsById = (state = initialState.tagsById, action) => {
  switch (action.type) {
    case actions.RECEIVE_CUSTOMER_TAGS:
      return action.tags.reduce(
        (obj, tag) => {
          obj[tag.id] = tag;
          return obj;
        },
        action.override ? {} : _.cloneDeep(state)
      );
    case actions.RECEIVE_CUSTOMER_TAG:
      if (action.tag && action.tag.id) {
        return { ..._.cloneDeep(state), [action.tag.id]: action.tag };
      }
      return state;
    case actions.RECEIVE_DELETED_CUSTOMER_TAG:
      const { [action.tagId]: _tag, ...tags } = state;
      return tags;
    case actions.SIGN_OUT:
      return initialState.tagsById;
    default:
      return state;
  }
};

function customerStatus(state = initialState.customerStatus, action) {
  switch (action.type) {
    case actions.GET_CUSTOMERS:
    case actions.GET_PROVIDER_CUSTOMER:
    case actions.GET_CORPORATE_CUSTOMERS:
    case actions.GET_CUSTOMER:
    case actions.CREATE_CUSTOMER:
    case actions.UPDATE_CUSTOMER:
    case actions.DELETE_CUSTOMER:
    case actions.GET_CUSTOMERS_STATS:
    case actions.GET_REGISTERED_CUSTOMER_COUNT:
    case actions.RETRIEVE_CUSTOMER:
    case actions.RETRIEVE_CUSTOMER_WITH_POINT:
    case actions.RETRIEVE_CUSTOMER_WITH_ORDER:
    case actions.RETRIEVE_CUSTOMER_WITH_CREDIT:
    case actions.RETRIEVE_CUSTOMER_WITH_COUPON:
      return {
        isFetching: true,
        error: null,
        action: action.type,
      };
    case actions.GET_CUSTOMERS_SUCCESS:
    case actions.GET_CUSTOMER_SUCCESS:
    case actions.CREATE_CUSTOMER_SUCCESS:
    case actions.UPDATE_CUSTOMER_SUCCESS:
    case actions.DELETE_CUSTOMER_SUCCESS:
    case actions.GET_CUSTOMERS_STATS_SUCCESS:
    case actions.GET_REGISTERED_CUSTOMER_COUNT_SUCCESS:
      return initialState.customerStatus;
    case actions.GET_CUSTOMERS_FAILURE:
    case actions.GET_CUSTOMER_FAILURE:
    case actions.CREATE_CUSTOMER_FAILURE:
    case actions.UPDATE_CUSTOMER_FAILURE:
    case actions.DELETE_CUSTOMER_FAILURE:
    case actions.GET_CUSTOMERS_STATS_FAILURE:
    case actions.GET_REGISTERED_CUSTOMER_COUNT_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        action: null,
      };
    default:
      return state;
  }
}

const customers = (state = initialState.customers, action) => {
  let _customers = _.cloneDeep(state);
  switch (action.type) {
    case actions.RECEIVE_CUSTOMERS:
      _customers.byId = action.customers.reduce(
        (obj, customer) => {
          if (customer._delta === "DELETE") {
            delete obj[_customer.id];
          } else {
            obj[customer.id] = {
              ...customer.customer,
              ...customer,
            };
            delete obj[customer.id].customer;
          }
          return obj;
        },
        action.override ? {} : _.cloneDeep(state.byId)
      );
      _customers.count = action.count;
      return _customers;
    case actions.RECEIVE_CUSTOMER:
      if (action.customer && action.customer.id) {
        _customers.byId = {
          ..._customers.byId,
          [action.customer.id]: {
            ...(action.customer.customer || {}),
            ...action.customer,
          },
        };
        delete _customers.byId[action.customer.id].customer;
        return _customers;
      }
      return _customers;
    case actions.RECEIVE_PROVIDER_CUSTOMER:
      if (action.customer && action.customer.providerCustomerId) {
        _customers.byId = {
          ..._customers.byId,
          [action.customer.providerCustomerId]: {
            ...action.customer,
          },
        };
      }
      return _customers;
    case actions.RECEIVE_PROVIDER_CUSTOMERS:
      _customers.byId = action.customers.reduce(
        (obj, cus) => {
          obj[cus.providerCustomerId] = cus;
          return obj;
        },
        action.override ? {} : _.cloneDeep(state.byId)
      );
      return _customers;
    case actions.RECEIVE_DELETED_CUSTOMER:
      const { [action.customerId]: _customer, ...customers } = state.byId;
      return {
        byId: customers,
        count: state.count - 1,
      };
    case actions.SIGN_OUT:
    case actions.CLEAR_CUSTOMERS:
      return initialState.customers;
    default:
      return state;
  }
};

const customersByTag = (state = initialState.customersByTag, action) => {
  let _customers = _.cloneDeep(state);
  switch (action.type) {
    case actions.RECEIVE_CUSTOMERS_BY_TAG:
      _customers.byId = action.customers.reduce(
        (obj, customer) => {
          if (customer._delta === "DELETE") {
            delete obj[customer.id];
          } else {
            obj[customer.id] = {
              ...customer.customer,
              ...customer,
            };
            delete obj[customer.id].customer;
          }
          return obj;
        },
        action.override ? {} : _.cloneDeep(_customers.byId)
      );
      let customerTags = [];
      action.customers.forEach((customer) => {
        customerTags = [
          ...customerTags,
          ...(customer.tags || []).map((tag) => ({
            customerId: customer.id,
            tagId: tag.tagId,
          })),
        ];
      });
      _customers.count = action.count;
      return _customers;
    case actions.SIGN_OUT:
    case actions.CLEAR_CUSTOMERS_BY_TAG:
      return initialState.customersByTag;
    default:
      return state;
  }
};

const customersStats = (state = initialState.customersStats, action) => {
  /**
   * [{id: , phone: , lastVisitDate: }]
   */
  switch (action.type) {
    case actions.RECIEVE_CUSTOMERS_STATS:
      const customersById = (action.customers || []).reduce(
        (obj, customer) => {
          obj[customer.id] = customer;
          return obj;
        },
        action.override ? {} : _.cloneDeep(state.byId)
      );
      return {
        ...state,
        byId: customersById,
        count: action.count,
      };
    default:
      return state;
  }
};

const retrieved = (state = initialState.retrieved, action) => {
  switch (action.type) {
    case actions.RECEIVE_RETRIEVED_CUSTOMER:
      return action.customer;
    case actions.CLEAR_RETRIEVED_CUSTOMER:
      return initialState.retrieved;
    default:
      return state;
  }
};

export const getCustomerPhone = (
  phone,
  isOutsourced,
  openState,
  agreements
) => {
  switch (isOutsourced) {
    case true:
      return phoneNumberFormat(phone);
    case false:
    default:
      if (openState) {
        if (agreements?.eula && agreements?.privacy && agreements?.provider) {
          return phoneNumberFormat(phone);
        } else {
          return maskingPhoneNumber(phone);
        }
      } else {
        return maskingPhoneNumber(phone);
      }
  }
};

export const getCustomerName = (
  name,
  isOutsourced,
  isAnonymous,
  openState,
  agreements
) => {
  switch (isOutsourced || isAnonymous) {
    case true:
      return name || "";
    case false:
    default:
      if (openState) {
        if (agreements?.eula && agreements?.privacy && agreements?.provider) {
          return name || "-";
        } else {
          return replaceAt(name, 1, "*") || "-";
        }
      } else {
        return replaceAt(name, 1, "*") || "-";
      }
  }
};

export const getPointLabel = (type) => {
  switch (type) {
    case "use":
      return "사용";
    case "save":
      return "적립";
    case "expired":
      return "만료";
    default:
      return "";
  }
};

export const isNumberTagCondition = (key) => {
  switch (key) {
    case "orderAmount":
    case "visitCount":
      return true;
    case "lastVisit":
    case "tier":
      return false;
    default:
      return false;
  }
};

export const getCustomerGroupLabel = (groupKey) => {
  switch (groupKey) {
    case "all":
      return "전체고객";
    case "registered":
      return "전체등록고객";
    case "anonymous":
      return "미등록고객";

    default:
      return "";
  }
};

export const getTagFilters = (tag) => {
  let filters = [];
  for (let condition of tag.conditions || []) {
    if (condition.key === "lastVisit") {
      let lastVisitValues = { start: null, end: null };
      for (let op of condition.operations || []) {
        if (op.operation === "gt") {
          lastVisitValues.start = op.value;
        } else if (op.operation === "lt") {
          lastVisitValues.end = op.value;
        }
      }
      filters = [
        ...filters,
        {
          key: "lastVisitedAt",
          operation: "between",
          value: [
            lastVisitValues.start
              ? new Date(lastVisitValues.start)
              : new Date(),
            lastVisitValues.end ? new Date(lastVisitValues.end) : new Date(),
          ],
        },
      ];
    }
    if (condition.key === "orderAmount") {
      filters = [
        ...filters,
        ...condition.operations.map((op) => ({
          key: "orderAmount",
          value: op.value,
          operation: op.operation,
        })),
      ];
    }
    if (condition.key === "visitCount") {
      filters = [
        ...filters,
        ...condition.operations.map((op) => ({
          key: "orderCount",
          value: op.value,
          operation: op.operation,
        })),
      ];
    }
    if (condition.key === "orderLocationId") {
      filters = [
        ...filters,
        ...condition.operations.map((op) => ({
          key: "locationId",
          value: op.value,
          operation: op.operation,
        })),
      ];
    }
  }
  return filters;
};

export default combineReducers({
  customerStatus,
  customers,
  groups,
  groupStatus,
  tagsById,
  tagStatus,
  customersStats,
  customersByTag,
  retrieved,
});
