import { combineReducers } from "redux";
import _ from "lodash";

import * as actions from "../actions";
import { getTaxAmount } from "../helpers/Utils";

const initialState = {
  isAuthenticated: false,
  sidebar: false,
  status: {
    isFetching: false,
    error: null,
    action: null,
  },
  user: {},
  store: {}, // StoreCustomer
  stores: {}, // StoreCureomt
  products: {},
  orderStatus: {
    isFetching: false,
    error: null,
  },
  storeStatus: {
    isFetching: false,
    error: null,
  },
  stats: {},
  cart: {
    visitorCount: 1,
    diningOption: "takeout",
    items: [],
  },
  checkout: {},
  receiptStore: {},
};

export const isMarketUser = (username) => {
  return !!username.match(/^[a-f0-9]{32}@thegrowing.co$/);
};

export const getLoyaltyConditionItemsByProductId = (loyatly) => {
  if (loyatly.conditionItems) {
    return loyatly.conditionItems.reduce((obj, item) => {
      obj[item.parentId] = item;
      return obj;
    }, {});
  }
  return {};
};

export const getLoyaltyRewardItemById = (loyalty) => {
  let items = {};
  if (loyalty) {
    for (let item of loyalty.rewardItems || []) {
      items[item.itemId] = item;
    }
    for (let item of loyalty.rewardRates || []) {
      items[item.itemId] = item;
    }
    for (let item of loyalty.rewardAmounts || []) {
      items[item.itemId] = item;
    }
    for (let item of loyalty.rewardCashs || []) {
      items[item.itemId] = item;
    }
  }
  return items;
};

export const getLoyaltyUsePointByOrder = (order, loyalty) => {
  let orderItems = order.items.filter((item) => item.type === "reward");
  let rewardItems = getLoyaltyRewardItemById(loyalty);

  if (orderItems.length > 0) {
    let items = [];
    for (let item of orderItems) {
      if (item.type === "reward") {
        if (item.parentId in rewardItems) {
          let quantity = parseInt(item.quantity, 10);
          items = [
            ...items,
            {
              itemId: rewardItems[item.parentId].itemId,
              type: item.discountMethod,
              parentId: rewardItems[item.parentId].parentId,
              name: item.name,
              amount: parseInt(item.amount, 10),
              quantity: quantity,
              point: parseInt(item.point, 10),
            },
          ];
        }
      }
    }
    return {
      quantity: _.sumBy(items, "quantity"),
      point: _.sumBy(items, "point"),
      amount: _.sumBy(items, "amount"),
      items: items,
    };
  }
  return null;
};

export const getExpiredAt = (expirationPeriod) => {
  if (expirationPeriod && expirationPeriod > 0) {
    let date = new Date();
    let expiredAt = new Date(date.setMonth(date.getMonth() + expirationPeriod));
    return expiredAt.toISOString();
  }
  return null;
};

export const getLoyaltySavePointByOrder = (order, loyalty, skuProducts) => {
  switch (loyalty.conditionType) {
    case "amount":
      // Except credit item
      let amount =
        order.amount -
        _.sum(
          (order.items || []).map((item) =>
            item.type === "credit" ? item.amount : 0
          )
        );
      switch (loyalty.conditionSubType) {
        case "section":
          const point =
            amount >= loyalty.condition.minAmount
              ? amount < loyalty.condition.perAmount
                ? 1
                : Math.floor((amount - 1) / loyalty.condition.perAmount) + 1
              : 0;
          return {
            quantity: 1,
            amount: amount,
            point: point,
            items: [
              {
                type: "amount",
                name: "포인트 적립",
                point: point,
                amount: amount,
                quantity: 1,
              },
            ],
            expiredAt: getExpiredAt(loyalty.condition?.expirationPeriod),
          };
        case "rate":
          let ratePoint = Math.round((amount / 100) * loyalty.condition.rate);
          return {
            quantity: 1,
            amount: amount,
            point: ratePoint,
            items: [
              {
                type: "rate",
                name: "포인트 적립",
                point: ratePoint,
                amount: amount,
                quantity: 1,
              },
            ],
            expiredAt: getExpiredAt(loyalty.condition?.expirationPeriod),
          };
        default:
          return {};
      }
    case "item":
      let items = {};
      let conditionItems = getLoyaltyConditionItemsByProductId(loyalty);
      let rewardItems = getLoyaltyRewardItemById(loyalty);
      let rewardOrderItems = (order.items || [])
        .filter((o) => o.type === "reward")
        .reduce((obj, o) => {
          if (o.parentId in rewardItems) {
            obj[rewardItems[o.parentId].parentId] = o;
          }
          return obj;
        }, {});

      for (let orderItem of order.items || []) {
        const prod = skuProducts[orderItem.parentId];
        let item = items[orderItem.parentId] || {
          quantity: 0,
          amount: 0,
          point: 0,
        };
        if (prod && prod.itemId in conditionItems) {
          const conditionItem = conditionItems[prod.itemId];
          if (orderItem.type === "sku") {
            item.type = "item";
            item.name = prod.name;
            item.itemId = conditionItem.itemId;
            item.parentId = orderItem.parentId;
            item.quantity += orderItem.quantity;
            item.amount += orderItem.amount;
            item.point += orderItem.quantity * conditionItem.point;

            if (prod.itemId in rewardOrderItems) {
              item.amount -=
                (item.amount / item.quantity) *
                rewardOrderItems[prod.itemId].quantity;
              item.point -=
                (item.point / item.quantity) *
                rewardOrderItems[prod.itemId].quantity;
              item.quantity -= rewardOrderItems[prod.itemId].quantity;
              delete rewardOrderItems[prod.itemId];
            }
            items[orderItem.parentId] = item;
          }
        }
      }
      items = Object.values(items).filter((item) => item.quantity > 0);
      return {
        quantity: _.sumBy(items, "quantity"),
        point: _.sumBy(items, "point"),
        amount: _.sumBy(items, "amount"),
        items: items,
        expiredAt: getExpiredAt(loyalty.condition?.expirationPeriod),
      };
    default:
      return {};
  }
};

export const getAmountRemained = (order) => {
  let amount = 0;
  if (!_.isEmpty(order)) {
    if (order.charges) {
      for (let charge of order.charges) {
        if (charge.refunds) {
          amount -= _.sumBy(charge.refunds, "amount");
        }
        amount += charge.amount;
      }
    }
    return order.amount - amount;
  }
  return 0;
};

export const getTaxRemained = (order) => {
  let tax = 0;
  if (!_.isEmpty(order)) {
    if (order.charges) {
      for (let charge of order.charges) {
        if (charge.refunds) {
          tax -= _.sumBy(charge.refunds, "tax");
        }
        tax += charge.tax;
      }
    }
    return order.tax - tax;
  }
  return 0;
};

const getDiscountAmount = (items, withRate) => {
  let discount = 0;
  for (let item of items) {
    if (item.discountMethod === "amount" || item.discountMethod === "item") {
      discount += item.amount ? Number(item.amount) : 0;
    }
    if (withRate && item.discountMethod === "rate") {
      discount += item.amount;
    }
  }
  return discount;
};

export const getExtraAmount = (order) => {
  let extraAmount = 0;
  if (!_.isEmpty(order)) {
    if (_.isArray(order.charges)) {
      let extraCharges = order.charges.filter(
        (charge) => charge.method === "extra"
      );
      extraAmount = _.sumBy(extraCharges, "amount");
    }
  }
  return extraAmount;
};

export const getCreditAmount = (order) => {
  let creditAmount = 0;
  if (!_.isEmpty(order)) {
    if (_.isArray(order.charges)) {
      let creditCharges = order.charges.filter(
        (charge) => charge.method === "credit"
      );
      creditAmount = _.sumBy(creditCharges, "amount");
    }
  }
  return creditAmount;
};

const getTotalAmount = (items, discountableOnly = false) => {
  let totalAmount = 0;
  for (let item of discountableOnly
    ? items.filter((item) => item.discountable)
    : items) {
    if (!item.discountMethod) {
      totalAmount += item.amount;
    }
  }
  return totalAmount;
};

const getTotalQuantity = (items) => {
  let totalQuantity = 0;
  for (let item of items) {
    if (item.type === "sku" || item.type === "credit") {
      totalQuantity += item.quantity;
    }
  }
  return totalQuantity;
};

const _getQuantity = (quantity) => {
  if (!_.isNaN(quantity)) {
    return quantity;
  }
  return 1;
};

export const addItem = (origin, extra) => {
  let item = _.cloneDeep(origin);
  item.quantity += extra.quantity;
  let extraMods = extra.modifiers.reduce((obj, m) => {
    obj[m.parentId] = m;
    return obj;
  }, {});
  item.modifiers = [];
  for (let mod of origin.modifiers) {
    item.modifiers = [
      ...item.modifiers,
      {
        ...mod,
        quantity: mod.quantity + (extraMods[mod.parentId]?.quantity || 0),
      },
    ];
  }
  return item;
};

export const brushupItems = (items) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].price >= 0) {
      let modifiers = items[i].modifiers || [];
      for (let j = 0; j < modifiers.length; j++) {
        modifiers[j].quantity = _getQuantity(modifiers[j].quantity);
        modifiers[j].amount = modifiers[j].quantity * modifiers[j].price;
      }
      items[i].modifiers = modifiers;
      items[i].quantity = _getQuantity(items[i].quantity);
      items[i].amount =
        items[i].price * items[i].quantity + _.sumBy(modifiers, "amount");
      items[i].tax = getTaxAmount(items[i].amount, items[i].taxRate);
    }
    if (items[i].discountMethod === "amount") {
      items[i].quantity = _getQuantity(items[i].quantity);
      items[i].amount = items[i].discountValue * items[i].quantity;
    }
    items[i].discountable = items[i].discountable === false ? false : true;
    items[i].point = items[i].point || 0;
  }

  // 1. calculate all amount
  const totalAmount = getTotalAmount(items);

  // 2. calculate all discount without rate
  let discountAmount = getDiscountAmount(items);

  // 3. calculate reward rate if has
  for (let item of items) {
    if (item.type === "reward" && item.discountMethod === "rate") {
      item.amount = ((totalAmount - discountAmount) / 100) * item.discountValue;
      item.quantity = 1;
      discountAmount += item.amount;
    }
  }

  // 4. calculate discount rate if has
  for (let item of items) {
    if (item.type === "discount" && item.discountMethod === "rate") {
      item.amount =
        ((getTotalAmount(items, true) - discountAmount) / 100) *
        item.discountValue;
      item.quantity = 1;
      discountAmount += item.amount;
    }
  }

  // 5. caculate tax
  let taxFreeAmount = _.sumBy(
    items.filter((i) => i.type === "sku" || i.type === "credit"),
    (i) => (!i.tax ? i.amount : 0)
  );
  let tax = _.sumBy(items, (o) => o.tax || 0);
  if (totalAmount - discountAmount < taxFreeAmount) {
    tax = 0;
  } else {
    tax = getTaxAmount(totalAmount - discountAmount - taxFreeAmount, 10);
  }

  // 6. return itkems
  const amount = totalAmount - discountAmount;
  taxFreeAmount = amount < taxFreeAmount ? amount : taxFreeAmount;
  const supplyAmount =
    amount < taxFreeAmount ? 0 : amount - taxFreeAmount - tax;
  return {
    items: items,
    amount: amount,
    supplyAmount: supplyAmount,
    taxFreeAmount: taxFreeAmount,
    tax: tax,
    quantity: getTotalQuantity(items),
  };
};

export const isEqualItem = (a, b) => {
  if (a.parentId === b.parentId) {
    let aMods = (a.modifiers || []).reduce((obj, mod) => {
      if (mod.quantity > 0) {
        obj[mod.parentId] = mod;
      }
      return obj;
    }, {});

    for (let bMod of b.modifiers || []) {
      let _mod = aMods[bMod.parentId];
      if (_mod && _mod.quantity === bMod.quantity) {
        delete aMods[bMod.parentId];
      } else {
        return false;
      }
    }
    return _.isEmpty(aMods) ? true : false;
  }
  return false;
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.SIGNUP_MARKET:
    case actions.SIGNIN_MARKET:
    case actions.RESET_MARKET_PASSWORD:
    case actions.DUPLICATE_ORDER_TO_CART:
    case actions.PAY_ORDER:
    case actions.UPDATE_USER:
    case actions.GET_USER:
    case actions.DELETE_CART:
    case actions.CANCEL_ORDER:
    case actions.SET_STORE_FAVORITE:
    case actions.ADD_ITEM_TO_CART:
    case actions.ADD_ITEMS_TO_CART:
    case actions.SEND_RECEIPT_LINK:
    case actions.CREATE_CUSTOMER_PAYMENT:
    case actions.DELETE_CUSTOMER_PAYMENT:
      return {
        isFetching: true,
        error: null,
        action: action.type,
      };
    case actions.SET_MARKET_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_MARKET_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        action: null,
      };
    default:
      return state;
  }
}

function storeStatus(state = initialState.storeStatus, action) {
  switch (action.type) {
    case actions.LOAD_STORE:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_MARKET_STORE_STATUS_SUCCESS:
      return initialState.storeStatus;
    case actions.SET_MARKET_STORE_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function orderStatus(state = initialState.orderStatus, action) {
  switch (action.type) {
    case actions.RECEIVE_PAID_ORDER:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_MARKET_ORDER_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_MARKET_ORDER_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
const user = (state = initialState.user, action) => {
  switch (action.type) {
    case actions.RECEIVE_USER:
      return action.user;
    case actions.SIGNOUT_MARKET:
    case actions.CLEAR_USER:
      return initialState.user;
    default:
      return state;
  }
};

const store = (state = initialState.store, action) => {
  switch (action.type) {
    case actions.RECEIVE_STORE:
      return {
        ...action.store,
        ...action.store.store,
        customer: action.store,
        points: action.store.points,
        credits: action.store.credits,
      };
    case actions.SIGNOUT_MARKET:
    case actions.CLEAR_STORE:
      return initialState.store;
    default:
      return state;
  }
};

const stores = (state = initialState.stores, action) => {
  switch (action.type) {
    case actions.RECEIVE_STORE:
      return {
        ...state,
        [action.store.store.id]: {
          ...action.store,
          ...action.store.store,
          customer: action.store,
          points: action.store.points,
          credits: action.store.credits,
        },
      };
    case actions.SIGNOUT_MARKET:
      return initialState.stores;
    default:
      return state;
  }
};

const products = (state = initialState.products, action) => {
  switch (action.type) {
    case actions.RECEIVE_PRODUCTS:
      return action.products.reduce((obj, p) => {
        obj[p.id] = {
          ...p,
          products: p.products.map((prod) => ({
            ...prod,
            itemId: prod.itemId || prod.id,
          })),
        };
        return obj;
      }, {});
    case actions.SIGNOUT_MARKET:
      return initialState.products;
    default:
      return state;
  }
};

const stats = (state = initialState.stats, action) => {
  switch (action.type) {
    case actions.RECEIVE_POINT_STATS:
      return action.stats;
    case actions.SIGNOUT_MARKET:
      return initialState.stats;
    default:
      return state;
  }
};

const cart = (state = initialState.cart, action) => {
  switch (action.type) {
    case actions.RECEIVE_CART:
      return {
        ...action.cart,
        store:
          state.store?.id === action.cart.storeId
            ? state.store
            : action.cart.store,
      };
    case actions.CLEAR_CART:
    case actions.SIGNOUT_MARKET:
      return initialState.cart;
    default:
      return state;
  }
};

const sidebar = (state = initialState.sidebar, action) => {
  switch (action.type) {
    case actions.TOGGLE_SIDEBAR:
      return !state;
    default:
      return state;
  }
};

const isAuthenticated = (state = initialState.isAuthenticated, action) => {
  switch (action.type) {
    case actions.SET_MARKET_AUTHENTICATED:
      return action.isAuthenticated;
    default:
      return state;
  }
};

const checkout = (state = initialState.checkout, action) => {
  switch (action.type) {
    case actions.RECEIVE_CHECKOUT:
      return action.checkout;
    default:
      return state;
  }
};

const receiptStore = (state = initialState.receiptStore, action) => {
  switch (action.type) {
    case actions.RECEIVE_RECEIPT_STORE:
      return {
        ...state,
        ...action.store,
        location: action.location,
      };
    case actions.CLEAR_RECEIPT_STORE:
      return initialState.receiptStore;
    default:
      return state;
  }
};

export default combineReducers({
  sidebar,
  status,
  receiptStore,
  storeStatus,
  user,
  store,
  stores,
  products,
  stats,
  cart,
  orderStatus,
  checkout,
  isAuthenticated,
});
