import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
    action: null,
  },
  plans: {},
  editing: {},
  subscriptionEditing: {},
  events: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_PLANS:
    case actions.GET_SUBSCRIPTION_PLAN:
    case actions.CREATE_SUBSCRIPTION_PLAN:
    case actions.UPDATE_SUBSCRIPTION_PLAN:
    case actions.DELETE_SUBSCRIPTION_PLAN:
    case actions.SUBSCRIBE_SUBSCRIPTION_PLAN:
    case actions.STOP_SUBSCRIPTION:
    case actions.RESTORE_SUBSCRIPTION:
    case actions.CANCEL_SUBSCRIPTION:
    case actions.GET_SUBSCRIPTION_EVENTS:
    case actions.GET_SUBSCRIPTION_PLANS_BY_IDS:
      return {
        isFetching: true,
        error: null,
        action: action.type,
      };
    case actions.SET_SUBSCRIPTION_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_SUBSCRIPTION_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        action: null,
      };
    default:
      return state;
  }
}

const plans = (state = initialState.plans, action) => {
  switch (action.type) {
    case actions.RECEIVE_SUBSCRIPTION_PLANS:
      return action.plans.reduce((obj, p) => {
        obj[p.id] = p;
        return obj;
      }, {});
    case actions.RECEIVE_SUBSCRIPTION_PLAN:
      if (action._delta === "DELETE") {
        delete state[action.plan.id];
        return state;
      }
      return {
        ...state,
        [action.plan.id]: action.plan,
      };
    case actions.SIGN_OUT:
      return initialState.plans;
    default:
      return state;
  }
};

const editing = (state = initialState.editing, action) => {
  switch(action.type) {
    case actions.SET_EDITING_SUBSCRIPTION_PLAN:
      return action.editing;
    case actions.CLEAR_EDITING_SUBSCRIPTION_PLAN:
      return initialState.editing;
    default:
      return state;
  }
}

const events = (state = initialState.events, action) => {
  switch(action.type) {
    case actions.RECEIVE_SUBSCRIPTION_EVENTS:
      return action.events;
    default:
      return state;
  }
}

const subscriptionEditing = (state = initialState.subscriptionEditing, action) => {
  switch(action.type) {
    case actions.SET_SUBSCRIPTION_EDITING:
      return action.editing;
    default:
      return state;
  }
}

export default combineReducers({
  status,
  plans,
  editing,
  events,
  subscriptionEditing,
});
