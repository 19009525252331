import _ from "lodash";

export const ROLE_PERMISSIONS = {
  owner: {
    report: [{ key: "read", value: true }],
    loyalty: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    smscampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    reviewcampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    subscription: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    customer: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    couponcampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    item: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    stock: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
    ],
    setting: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    pos: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "login", value: false },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    employee: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    location: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    menuboard: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    billing: [
      { key: "update", value: true },
      { key: "delete", value: true },
    ],
  },
  admin: {
    report: [{ key: "read", value: true }],
    loyalty: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    smscampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    reviewcampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    subscription: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    customer: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    couponcampaign: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    item: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    stock: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
    ],
    setting: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    pos: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "login", value: false },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    employee: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    location: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    menuboard: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
      { key: "delete", value: true },
    ],
    billing: [
      { key: "update", value: true },
      { key: "delete", value: true },
    ],
  },
  manager: {
    report: [{ key: "read", value: true }],
    loyalty: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    smscampaign: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    reviewcampaign: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    subscription: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    customer: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    couponcampaign: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    item: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    stock: [
      { key: "create", value: true },
      { key: "update", value: true },
      { key: "read", value: true },
    ],
    setting: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: false },
      { key: "delete", value: false },
    ],
    pos: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "login", value: true },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    employee: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    location: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    menuboard: [
      { key: "create", value: false },
      { key: "update", value: false },
      { key: "read", value: true },
      { key: "delete", value: false },
    ],
    billing: [
      { key: "update", value: false },
      { key: "delete", value: false },
    ],
  },
};

export const PERMISSIONS_KEYS = {
  pos: {
    name: "포스 관리",
    key: "pos",
  },
  loyalty: {
    name: "로열티프로그램 정책",
    key: "loyalty",
  },
  report: {
    name: "리포트",
    admin: "모든 지점 리포트",
    owner: "모든 지점 리포트",
    manager: "해당 지점 리포트",
    key: "report",
  },
  smscampaign: {
    name: "SMS 캠페인",
    key: "smscampaign",
  },
  reviewcampaign: {
    name: "리뷰 캠페인",
    key: "reviewcampaign",
  },
  customer: {
    name: "고객관리",
    key: "customer",
  },
  couponcampaign: {
    name: "쿠폰관리",
    key: "couponcampaign",
  },
  item: {
    name: "제품관리",
    key: "item",
  },
  stock: {
    name: "재고관리",
    key: "stock",
  },
  setting: {
    name: "스토어 설정",
    owner: "스토어 설정",
    admin: "스토어 설정",
    manager: "매장 설정",
    key: "setting",
  },
  employee: {
    name: "직원관리",
    key: "employee",
  },
  location: {
    name: "지점관리",
    key: "location",
  },
  menuboard: {
    name: "메뉴판관리",
    key: "menuboard",
  },
  billing: {
    name: "결제정보관리",
    key: "billing",
  },
};

export const ACTION_KEYS = {
  create: {
    name: "신규 생성",
    key: "create",
  },
  update: {
    name: "수정/업데이트",
    key: "update",
  },
  login: {
    name: "포스 로그인",
    key: "login",
  },
  read: {
    name: "조회",
    key: "read",
  },
  delete: {
    name: "삭제",
    key: "delete",
  },
};

export const ROLES = [
  {
    name: "스토어 오너",
    role: "owner",
  },
  {
    name: "전체 관리자",
    role: "admin",
  },
  {
    name: "지점장",
    role: "manager",
  },
];

// TODO: update more
export const DEFAULT_LOCATION_POLICY = {
  billing: false,
};

class Authority {
  constructor() {
    this.actions = Object.keys(ACTION_KEYS).reduce((obj, key) => {
      obj[key] = key;
      return obj;
    }, {});
    this.subjects = Object.keys(PERMISSIONS_KEYS).reduce((obj, key) => {
      obj[key] = key;
      return obj;
    }, {});
    this.subjects = [
      "item",
      "item.product",
      "item.product.actualprice",
      "apps",
      "menu",
      "store",
      "billing",
      "location",
      "coupon",
      "customer",
      "employee",
      "aroundmarket",
      "kakaochannel",
      "kiosk",
      "subscription",
      "reviewcampaign",
      "franchise",
      "cafe24",
      "smscampaign",
      "report",
      "loyalty",
      "notice",
      "account",
      "customer",
      "stock",
      "purchaseorder",
    ].reduce((obj, sub) => {
      obj[sub] = sub;
      return obj;
    }, {});

    this.permission = {};
  }

  can = (action, subject, field = null) => {
    let key = subject;
    const hasPermission = (key) => {
      return this.permission[action] && this.permission[action].includes(key);
    };
    if (!field) {
      console.debug(`${key}.${action}: ${hasPermission(key)}`);
      return hasPermission(key);
    }
    for (let i = 0; i < field.length; i++) {
      if (hasPermission(key)) {
        break;
      }
      key = `${key}.${field[i]}`;
    }
    console.debug(`${key}.${action}: ${hasPermission(key)}`);
    return hasPermission(key);
  };

  level = () => {
    return this.organization?.role === "owner" ||
      this.organization?.role === "admin"
      ? "store"
      : "location";
  };

  defineRulesFor = (organization) => {
    this.organization = organization;
    let prePermission = {};
    switch (organization.role) {
      case "owner":
      case "admin":
        this.permission = {
          create: Object.values(this.subjects),
          read: Object.values(this.subjects).filter((s) => s !== "notice"),
          delete: Object.values(this.subjects),
          update: Object.values(this.subjects),
        };
        break;
      case "parttimer":
        prePermission = {
          create: Object.values(this.subjects),
          read: Object.values(this.subjects).filter((s) => s !== "location"),
          delete: Object.values(this.subjects),
          update: Object.values(this.subjects),
        };
        this.permission = this.brushupAuthority(
          organization.permissions,
          prePermission
        );
        break;
      case "manager":
        if (
          organization.permissions &&
          _.isPlainObject(organization.permissions)
        ) {
          this.permission = this.brushupAuthority(organization.permissions, {
            create: Object.values(this.subjects),
            read: Object.values(this.subjects).filter((s) => s !== "notice"),
            delete: Object.values(this.subjects),
            update: Object.values(this.subjects),
          });
        } else {
          if (
            organization.location &&
            organization.location.type === "indirect"
          ) {
            this.permission = {
              read: [
                "item",
                "menu",
                "customer",
                "billing",
                "kiosk",
                "notice",
                "account",
                "smscampaign",
                "report",
              ],
              update: [
                "purchaseorder",
                "stock",
                "billing",
                "kiosk",
                "account",
                "item.product",
              ],
              create: ["purchaseorder", "item.product"],
              delete: ["purchaseorder", "item.product"],
            };
          } else {
            this.permission = {
              read: [
                "item",
                "menu",
                "customer",
                "kiosk",
                "notice",
                "account",
                "report",
              ],
              update: [
                "purchaseorder",
                "stock",
                "kiosk",
                "item.product.actualprice",
              ],
              create: ["purchaseorder"],
              delete: ["purchaseorder"],
            };
          }
        }
        break;
      default:
        this.permission = { read: [], update: [] };
        break;
    }
  };

  brushupAuthority = (permissions, prePermissions) => {
    let _permissions = prePermissions;
    const removeSubject = (items, removeString) => {
      return (items || []).filter((item) => item !== removeString);
    };
    if (!permissions.employeeMgmt) {
      _permissions.create = removeSubject(_permissions.create, "employee");
      _permissions.update = removeSubject(_permissions.update, "employee");
      _permissions.delete = removeSubject(_permissions.delete, "employee");
    }
    if (!permissions.item) {
      _permissions.create = removeSubject(_permissions.create, "item");
      _permissions.create = removeSubject(_permissions.create, "item.product");
      _permissions.create = removeSubject(
        _permissions.create,
        "item.product.actualprice"
      );
      _permissions.update = removeSubject(_permissions.update, "item");
      _permissions.update = removeSubject(_permissions.update, "item.product");
      _permissions.update = removeSubject(
        _permissions.update,
        "item.product.actualprice"
      );
      _permissions.delete = removeSubject(_permissions.delete, "item");
      _permissions.delete = removeSubject(_permissions.delete, "item.product");
      _permissions.delete = removeSubject(
        _permissions.delete,
        "item.product.actualprice"
      );
    }
    if (!permissions.report) {
      _permissions.read = removeSubject(_permissions.read, "report");
      _permissions.create = removeSubject(_permissions.create, "report");
      _permissions.update = removeSubject(_permissions.update, "report");
      _permissions.delete = removeSubject(_permissions.delete, "report");
    }
    if (permissions.stock === false) {
      _permissions.update = removeSubject(_permissions.update, "stock");
    }
    if (permissions.purchaseorder === false) {
      _permissions.read = removeSubject(_permissions.read, "purchaseorder");
      _permissions.create = removeSubject(_permissions.create, "purchaseorder");
      _permissions.update = removeSubject(_permissions.update, "purchaseorder");
      _permissions.delete = removeSubject(_permissions.delete, "purchaseorder");
    }
    return _permissions;
  };
}

const instance = new Authority();
instance.defineRulesFor({ role: "owner" });
export default instance;

export const Can = ({ I, a, children }) =>
  instance.can(I, a) ? children : null;
