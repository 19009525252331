import { combineReducers } from "redux";

import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  campaigns: {},
  results: {
    list: [],
    stats: {},
  },
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.CREATE_OR_UPDATE_REVIEW_CAMPAIGN:
    case actions.DELETE_REVIEW_CAMPAIGN:
    case actions.GET_REVIEW_CAMPAIGN_RESULTS:
    case actions.LAUNCH_REVIEW_CAMPAIGN:
    case actions.GET_REVIEW_CAMPAIGNS:
    case actions.DOWNLOAD_REVIEW_CAMPAIGN:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_REVIEW_CAMPAIGN_SUCCESS:
      return initialState.status;
    case actions.SET_REVIEW_CAMPAIGN_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function campaigns(state = initialState.campaigns, action) {
  switch (action.type) {
    case actions.RECEIVE_REVIEW_CAMPAIGN:
      return {
        ...state,
        [action.campaign.id]: action.campaign,
      };
    case actions.RECEIVE_REVIEW_CAMPAIGNS:
      return action.campaigns.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
    case actions.RECEIVE_DELETED_REVIEW_CAMPAIGN:
      const { [action.campaignId]: _, ...campaigns } = state;
      return campaigns;
    case actions.SIGN_OUT:
      return initialState.campaigns;
    default:
      return state;
  }
}

function results(state = initialState.results, action) {
  switch (action.type) {
    case actions.RECEIVE_REVIEW_CAMPAIGN_RESULTS:
      return action.results;
    case actions.SIGN_OUT:
      return initialState.results;
    default:
      return state;
  }
}

export default combineReducers({
  status,
  campaigns,
  results,
});
