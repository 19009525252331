import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Divider, Modal } from "semantic-ui-react";
import "moment/locale/ko";
import _ from "lodash";
import ReactSelect from "react-select";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftRight, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";

import API from "../services/API";
import Constants, { INVENTORY_ADJUST_TYPES } from "../helpers/Constants";
import {
  DefaultLoader,
  GetInventoryTransactionReason,
  ReactSelectForLabelInput,
  CustomTextField,
  CustomTable,
  MainModalHeaderWithCustomRight,
  FilledButton,
  NofilledButton,
  LimitExcessModal,
  LabelInputComp,
  SendMailModal,
} from "./Elements";
import {
  isAvailableAmount,
  isAvailableQuantity,
  isValidDate,
  numberToCurrency,
  numberWithCommas,
  removeString,
  isValidEmail,
  isLegacyInventoryHistories,
} from "../helpers/Utils";
import * as reducers from "../reducers";
import {
  getItems,
  getInventoryHistoriesByStore,
  getOrder,
  getInventoryHistoriesByGroupId,
  shareHistoryPdf,
  getPurchaseOrderById,
  getSuppliers,
  updateInventoryHistory,
  refreshInventoryHistory,
  downloadInventoryHistories,
  shareGroupHistoryPdf,
} from "../actions";
import Authority from "../services/Authority";
import Analytics from "../services/Analytics";
import { getOrderNumber } from "../reducers/order";

const ITEM_PER_PAGE = 30;

export const TABLE_HEADERS = [
  {
    label: "일시",
    width: "15%",
    key: "createdAt",
  },
  {
    label: "위치",
    width: "10%",
    key: "location",
  },
  {
    label: "제품",
    width: "15%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "8%",
    key: "skuName",
  },
  {
    label: "품목코드(SKU)",
    width: "12%",
    key: "model",
  },
  {
    label: "조정 타입",
    width: "10%",
    key: "type",
  },
  {
    label: "조정 수량",
    width: "10%",
    key: "quantity",
    isQty: true,
    func: numberWithCommas,
  },
  {
    label: "총 비용",
    width: "10%",
    key: "totalCost",
    isAmount: true,
    func: numberToCurrency,
  },
  {
    label: "노트",
    width: "10%",
    key: "note",
  },
];

export const TABLE_GROUP_HEADERS = [
  {
    label: "일시",
    width: "20%",
    key: "createdAt",
  },
  {
    label: "위치",
    width: "15%",
    key: "location",
  },
  {
    label: "조정타입",
    width: "15%",
    key: "type",
  },
  {
    label: "조정 제품수",
    width: "10%",
    key: "skuLength",
  },
  {
    label: "총 비용",
    width: "15%",
    key: "totalCost",
    isAmount: true,
    func: numberToCurrency,
  },
  {
    label: "노트",
    width: "25%",
    key: "note",
  },
];

const TABLE_HEADER_IN_HISTORY_MODAL = [
  {
    label: "제품",
    width: "23%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "22%",
    key: "skuName",
  },
  {
    label: "품목코드(SKU)",
    width: "15%",
    key: "model",
  },
  {
    label: "조정 타입",
    width: "15%",
    key: "reason",
  },
  {
    label: "조정 수량",
    width: "10%",
    key: "quantity",
    func: numberWithCommas,
  },
  {
    label: "비용",
    width: "15%",
    key: "cost",
    isAmount: true,
    func: numberToCurrency,
  },
];

const HistoryLabel = React.memo(({ label, value }) => (
  <div className="d-flex pt-pb-5">
    <div style={{ width: "30%" }} className="pl-pr-5">
      <div className="h6 bold lightPrimary-color">{label}</div>
    </div>
    <div style={{ width: "70%" }} className="pl-pr-5">
      <div className="h6 bold ">{value}</div>
    </div>
  </div>
));

const HistoryDetailSwitch = React.memo(
  ({
    history,
    ordersById,
    suppliersById,
    currentLocationId,
    purchaseOrdersById,
  }) => {
    switch (history.originalType) {
      case "sales":
      case "returns":
        const order = ordersById[history.original.referId];
        return (
          <>
            <Divider />
            <div>
              <div className="h5 bold lightPrimary-color">
                {"관련 주문내역"}
              </div>
              {order ? (
                <>
                  <HistoryLabel
                    label="주문일시"
                    value={moment(order.createdAt).format("YYYY/MM/DD, HH:mm")}
                  />
                  <HistoryLabel
                    label="주문번호"
                    value={`#${getOrderNumber(
                      order.id,
                      order.number,
                      order.numberPrefix,
                      order.originId
                    )}`}
                  />
                  <HistoryLabel
                    label="결제금액"
                    value={numberToCurrency(order.amountPaid || order.amount)}
                  />
                </>
              ) : null}
            </div>
          </>
        );
      case "newStock":
        switch (!_.isEmpty(history.original.purchaseOrderId)) {
          case true:
            const _purchaseOrder =
              purchaseOrdersById[history.original.purchaseOrderId];
            const remoteSupplier = _purchaseOrder.supplierId
              ? suppliersById[_purchaseOrder.supplierId]?.name
              : currentLocationId === _purchaseOrder.createdLocationId
              ? "(삭제된 거래처)"
              : "(타지점 거래처)";
            return (
              <>
                <Divider />
                <div>
                  <div className="h5 bold lightPrimary-color">
                    {"관련 발주서"}
                  </div>
                  <HistoryLabel
                    label="발주일시"
                    value={moment(_purchaseOrder.createdAt).format(
                      "YYYY/MM/DD, HH:mm"
                    )}
                  />
                  <HistoryLabel
                    label="발주번호"
                    value={`#${_purchaseOrder.number}`}
                  />
                  {_purchaseOrder.supplierOrigin === "supplier" && (
                    <HistoryLabel label="거래처" value={remoteSupplier} />
                  )}
                </div>
              </>
            );
          case false:
          default:
            return null;
        }

      default:
        return null;
    }
  }
);

const HistoryModal = React.memo(
  ({
    open,
    onCancel,
    history,
    isGroup,
    onPrintPress,
    histories,
    brushupDetailItems,
    detailProps,
    hasMoreItems,
    isPaging,
    loadMore,
    getEditableState,
    isLoading,
    onExpiryDateChange,
    onForceSave,
    hasExpiry,
    onCostChange,
    onQuantityChange,
    forceCost,
    forceQuantity,
    forceExpiryDate,
    isRetail,
    isPrinting,
    isHistorySavable,
  }) => {
    switch (isGroup) {
      case true:
        let items = brushupDetailItems(histories);
        return (
          <Modal open={open} className="p-0 width-70 min-width-450px">
            <MainModalHeaderWithCustomRight
              title={"조정내역"}
              onCancel={onCancel}
            />
            <Modal.Content scrolling className="p-lg">
              <FilledButton
                loading={isPrinting}
                disabled={isPrinting}
                buttonColor="blue"
                onClick={() => onPrintPress(history.id)}
                customStyle={{ minHeight: "44px" }}
                customClass="mb-line"
                textComp={<FontAwesomeIcon icon={faPrint} className="h5" />}
              />
              <div className="d-flex align-items-start">
                <div className="w-50 pr-line">
                  <HistoryLabel label="조정일시" value={history.createdAt} />
                  <HistoryLabel label="담당자" value={history.employee} />
                  <HistoryLabel label="조정지점" value={history.location} />
                  <HistoryLabel label="조정제품 수" value={history.skuLength} />
                  <HistoryLabel
                    label="총 비용"
                    value={numberToCurrency(history.totalCost)}
                  />
                </div>
                <div className="w-50">
                  <CustomTextField
                    label="조정노트"
                    rows={5}
                    multiline
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    value={history.note}
                    disabled
                  />
                </div>
              </div>
              <Divider className="mt-mb-small" />
              <CustomTable
                items={items}
                headers={TABLE_HEADER_IN_HISTORY_MODAL}
                topMarginStyle={{ marginTop: "10px" }}
              />
              {hasMoreItems || isPaging ? (
                <div className="bg-color-white">
                  <NofilledButton
                    onClick={loadMore}
                    buttonColor={"blue"}
                    customStyle={{
                      height: "55px",
                      margin: "auto",
                    }}
                    loading={isPaging}
                    textComp={
                      <div className="label font-bold blue-color">
                        {"더보기"}
                      </div>
                    }
                  />
                </div>
              ) : null}
            </Modal.Content>
          </Modal>
        );
      case false:
      default:
        let editable = getEditableState(
          history.originalType,
          history.totalCost,
          history.purchaseOrderId
        );
        return (
          <Modal open={open} className="p-0 width-50 min-width-450px">
            <MainModalHeaderWithCustomRight
              title={"조정내역"}
              onCancel={onCancel}
            />
            <Modal.Content scrolling className="p-lg">
              <HistoryLabel label="조정일시" value={history.createdAt} />
              <HistoryLabel label="제품이름" value={history.prodName} />
              <HistoryLabel label="하위제품 이름" value={history.skuName} />
              <HistoryLabel label="바코드" value={history.unit || "-"} />
              <HistoryLabel
                label="품목코드(SKU)"
                value={history.model || "-"}
              />
              {history.original.locationId ? (
                <HistoryLabel label="조정지점" value={history.location} />
              ) : null}
              <HistoryLabel label="조정사유" value={history.type} />
              <HistoryLabel label="조정수량" value={history.quantity} />
              <HistoryLabel label="담당자" value={history.employee || "-"} />
              <HistoryLabel
                label="총 비용"
                value={numberToCurrency(history.totalCost)}
              />
              <HistoryLabel
                label="소비기한(유통기한)"
                value={history.expiryDate || "-"}
              />
              <HistoryDetailSwitch history={history} {...detailProps} />
              <Divider />
              <CustomTextField
                label="조정노트"
                rows={4}
                multiline
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                className="mt-line w-100"
                value={history.note}
                disabled
              />
              {isRetail &&
              (editable.quantity || editable.cost || editable.expiryDate) ? (
                <>
                  <Divider />
                  <div className="history-modal-edit-alert h6 bold blue-color">
                    {"조정된 내역은 리포트와 이전/이후 재고현황에 반영됩니다."}
                  </div>
                  {editable.quantity ? (
                    <LabelInputComp
                      label={"수량조정"}
                      hasUpperBorder
                      rightComp={
                        <CustomTextField
                          defaultValue={history.quantity}
                          variant="standard"
                          style={{ width: "100%", height: "100%" }}
                          InputProps={{ disableUnderline: true }}
                          type="text"
                          placeholder={"수량 임의조정"}
                          value={forceQuantity?.toString()}
                          onChange={onQuantityChange}
                        />
                      }
                    />
                  ) : null}
                  {editable.cost ? (
                    <LabelInputComp
                      label={"단가"}
                      rightComp={
                        <CustomTextField
                          variant="standard"
                          style={{ width: "100%", height: "100%" }}
                          InputProps={{ disableUnderline: true }}
                          type="text"
                          placeholder={"단가 임의조정"}
                          value={forceCost}
                          onChange={onCostChange}
                        />
                      }
                    />
                  ) : null}
                  {hasExpiry && editable.expiryDate ? (
                    <LabelInputComp
                      label={"소비기한(유통기한)"}
                      rightComp={
                        <DatePicker
                          variant="standard"
                          InputProps={{ disableUnderline: true }}
                          style={{
                            height: "100%",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          format="YYYY-MM-DD"
                          type="date"
                          value={
                            forceExpiryDate || history.originExpiryDate || null
                          }
                          onChange={onExpiryDateChange}
                        />
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </Modal.Content>
            {isRetail &&
            (editable.quantity || editable.cost || editable.expiryDate) ? (
              <div className="w-100 pl-pr-base pb-base">
                <FilledButton
                  onClick={() => onForceSave(history.id)}
                  label={"저장하기"}
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    !Authority.can(
                      Authority.actions.update,
                      Authority.subjects.stock
                    ) ||
                    !isHistorySavable(editable, history)
                  }
                  buttonColor={"blue"}
                  customStyle={{ width: "100%" }}
                />
              </div>
            ) : null}
          </Modal>
        );
    }
  }
);

const Filters = React.memo(
  ({
    startDate,
    endDate,
    locationFilter,
    onLocationChange,
    getLocations,
    storeLocations,
    onStartDateChange,
    onEndDateChange,
    hasInventoryAndAccess,
    onRetrievePress,
    isGroupView,
    typeFilter,
    onTypeFilterChange,
    onGroupPress,
    onExportCsv,
    isDownloadingExcel,
  }) => {
    let locations = getLocations(storeLocations);
    let _types = INVENTORY_ADJUST_TYPES.reduce((obj, t) => {
      obj[t.value] = t;
      return obj;
    }, {});
    return (
      <div>
        <div className="flex-between">
          <div className="d-flex align-items-center">
            <input
              dateFormat="yyyy/MM/dd"
              locale="ko"
              type="date"
              name="startDate"
              value={moment(startDate).format("YYYY-MM-DD")}
              className="custom-date-range-picker"
              onChange={onStartDateChange}
            />
            &nbsp;~&nbsp;
            <input
              dateFormat="yyyy/MM/dd"
              locale="ko"
              type="date"
              name="endDate"
              value={moment(endDate).format("YYYY-MM-DD")}
              className="custom-date-range-picker"
              onChange={onEndDateChange}
            />
            {!hasInventoryAndAccess ? null : (
              <ReactSelect
                closeMenuOnSelect={false}
                name={"locationId"}
                placeholder={"지점/채널선택"}
                isMulti
                value={locations.filter((loc) =>
                  locationFilter.includes(loc.id)
                )}
                options={locations}
                onChange={onLocationChange}
                styles={{
                  ...ReactSelectForLabelInput,
                  control: (provided) => ({
                    ...provided,
                    borderWidth: 1,
                    borderColor: "#d1d4d8",
                    borderRadius: 0,
                    padding: "0 20px",
                    height: "55px",
                    minWidth: "12rem",
                    marginLeft: "1.5rem",
                  }),
                }}
              />
            )}
            <FilledButton
              onClick={onRetrievePress}
              label="조회"
              buttonColor="blue"
              customClass="ml-sm"
              customStyle={{ minWidth: "5rem", height: "55px" }}
            />
          </div>
        </div>
        <div className="mt-line d-flex align-items-end">
          {!isGroupView ? (
            <ReactSelect
              name={"adjustType"}
              placeholder={"조정 타입"}
              value={_types[typeFilter]}
              options={[
                {
                  label: "선택안함",
                  value: null,
                },
                ...INVENTORY_ADJUST_TYPES,
              ]}
              onChange={onTypeFilterChange}
              styles={{
                ...ReactSelectForLabelInput,
                container: (provided) => ({
                  ...provided,
                  width: "fit-content",
                  minWidth: "10rem",
                  height: "55px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderWidth: 2,
                  borderColor: "#337ef3",
                  borderRadius: 0,
                  padding: "0 20px",
                  height: "55px",
                }),
              }}
            />
          ) : null}
          <FilledButton
            onClick={onGroupPress}
            buttonColor="blue-outline"
            customStyle={{
              width: "fitContent",
              borderRadius: "0",
              marginLeft: isGroupView ? 0 : "1.5rem",
              height: "55px",
            }}
            textComp={
              <div className="flex-between">
                <div className="column-center">
                  <div
                    className="h7 bold text-align-center"
                    style={{ color: isGroupView ? "#d1d4d8" : "#337ef3" }}
                  >
                    {"단일 히스토리"}
                  </div>
                </div>
                <FontAwesomeIcon icon={faLeftRight} className="h4 ml-mr-base" />
                <div className="column-center">
                  <div
                    className="h7 bold text-align-center"
                    style={{ color: !isGroupView ? "#d1d4d8" : "#337ef3" }}
                  >
                    {"그룹 히스토리"}
                  </div>
                </div>
              </div>
            }
          />

          <FilledButton
            onClick={onExportCsv}
            loading={isDownloadingExcel}
            disabled={isDownloadingExcel}
            buttonColor="primary-outline"
            customClass="ml-line"
            customStyle={{
              minHeight: "55px",
              marginLeft: "auto",
              width: "130px",
            }}
            label={
              <div className="flex-center">
                <GetAppIcon style={{ fontSize: "1.75rem" }} />
                <div className="text-14 ml-xs">다운로드</div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
);

const INIT_DATES = [new Date().setDate(new Date().getDate() - 14), new Date()];

class StockHistories extends React.Component {
  constructor(props) {
    super(props);
    const _initDates = _.cloneDeep(INIT_DATES);
    this.state = {
      startDate: _initDates[0],
      endDate: _initDates[1],
      locationFilter: [],
      typeFilter: null,
      skuSearch: "",
      isSearched: false,
      page: 1,
      historyModal: false,
      selectedHistory: null,
      isHistoryLoading: false,
      isGroupView: false,
      detailPage: 1,
      isGroupHistoryLoading: false,
      isPaging: false,
      dateLimitExcessModal: false,
      isUpdating: false,
      forceCost: null,
      forceQuantity: null,
      forceExpiryDate: null,
      isRefresh: false,
      isDownloadingExcel: false,
      sendMailModal: false,
      email: "",
    };
  }

  componentDidMount() {
    if (this.props.storeId) {
      this.props.getItems(this.props.storeId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.props.getItems(this.props.storeId);
    }
    if (
      this.state.isHistoryLoading &&
      prevProps.orderStatus?.isFetching &&
      !this.props.orderStatus.isFetching
    ) {
      this.setState({
        isHistoryLoading: false,
        historyModal: true,
      });
    }
    if (
      this.state.isGroupHistoryLoading &&
      prevProps.status.isFetching &&
      !this.props.status.isFetching
    ) {
      this.setState({
        isGroupHistoryLoading: false,
        historyModal: true,
      });
    }
    if (
      this.state.isHistoryLoading &&
      prevProps.purchaseOrderStatus.isFetching &&
      !this.props.purchaseOrderStatus.isFetching
    ) {
      this.setState({
        isHistoryLoading: false,
        historyModal: true,
      });
    }
    if (
      (this.state.isPaging || this.state.isLoading) &&
      prevProps.status.isFetching &&
      !this.props.status.isFetching
    ) {
      if (this.props.status.error) {
        alert("조회에 실패하였습니다. 잠시 후 다시 시도해 주세요.");
      }
      this.setState({
        isPaging: false,
        isLoading: false,
      });
    }
    if (
      this.state.isUpdating &&
      prevProps.status.isFetching &&
      !this.props.status.isFetching
    ) {
      if (this.props.status.error) {
        alert("수정에 실패하였습니다. 잠시 후 다시 시도해 주세요.");
      } else {
        this.initHistoryModal();
        this.refreshHistory();
      }
      this.initUpdating();
    }
    if (
      this.state.isRefresh &&
      prevProps.status.isFetching &&
      !this.props.status.isFetching
    ) {
      this.initRefresh();
    }
    if (
      this.state.isDownloadingExcel &&
      prevProps.status.isFetching &&
      !this.props.status.isFetching
    ) {
      if (this.props.status.error) {
        alert("액샐 다운로드에 실패하였습니다. 잠시 후 다시 시도해 주세요.");
      }
      this.setState({ isDownloadingExcel: false });
    }
    if (prevProps.pdfStatus.isFetching && !this.props.pdfStatus.isFetching) {
      if (this.props.pdfStatus.error) {
        alert("PDF를 받는데 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
    }
  }

  initHistoryModal = () => {
    this.setState({
      isHistoryLoading: false,
      historyModal: false,
      forceCost: null,
      forceQuantity: null,
      forceExpiryDate: null,
    });
  };

  initUpdating = () => {
    this.setState({
      isUpdating: false,
    });
  };

  initRefresh = () => {
    this.setState({
      isRefresh: false,
    });
  };

  handleLocationChange = (e) => {
    let _locationFilter = [];
    for (let loc of e) {
      _locationFilter = [..._locationFilter, loc.id];
    }
    this.setState({ locationFilter: _locationFilter });
  };

  getLocations = (locations) => {
    return (locations || [])
      .filter(
        (loc) =>
          loc.id === this.props.organization.locationId ||
          this.props.hasLocationAdminAccess
      )
      .map((_loc) => {
        return { ..._loc, value: _loc.id, label: _loc.name };
      });
  };

  handleStartDateChange = (e) => {
    this.setState({
      startDate: e.target.value,
    });
  };

  handleEndDateChange = (e) => {
    this.setState({
      endDate: e.target.value,
    });
  };

  refreshHistory = () => {
    const { page, typeFilter, startDate, endDate, locationFilter } = this.state;
    const locationIds = this.isUsableLocationFilter()
      ? locationFilter
      : [this.props.currentLocationId];
    let filters = [];
    if ((locationIds || []).length > 0) {
      if (locationIds.length > 1) {
        filters.push({
          key: "locationId",
          value: locationIds,
          operation: "in",
        });
      } else {
        filters.push({
          key: "locationId",
          value: locationIds[0],
          operation: "eq",
        });
      }
    }
    if (typeFilter) {
      filters.push({
        key: "reason",
        value: [typeFilter],
        operation: "in",
      });
    }
    if (startDate && endDate) {
      let s = new Date(startDate);
      let e = new Date(endDate);
      s.setHours(0, 0, 0, 0);
      e.setHours(23, 59, 59, 999);
      filters.push({
        key: "createdAt",
        value: [s.toISOString(), e.toISOString()],
        operation: "between",
      });
    }
    this.setState({ isRefresh: true });
    this.props.refreshInventoryHistory(page, ITEM_PER_PAGE, "single", filters);
  };

  getInventoryHistories(
    page,
    isGroupView,
    dates,
    locationIds = null,
    search = null,
    reason = [],
    override = true
  ) {
    let filters = [];
    if ((locationIds || []).length > 0) {
      if (locationIds.length > 1) {
        filters.push({
          key: "locationId",
          value: locationIds,
          operation: "in",
        });
      } else {
        filters.push({
          key: "locationId",
          value: locationIds[0],
          operation: "eq",
        });
      }
    }
    if (dates[0] && dates[1]) {
      let s = new Date(dates[0]);
      let e = new Date(dates[1]);
      s.setHours(0, 0, 0, 0);
      e.setHours(23, 59, 59, 999);
      const _s = new Date(s).toISOString();
      const _e = new Date(e).toISOString();
      filters.push({
        key: "createdAt",
        value: [_s, _e],
        operation: "between",
      });
    }
    if (search) {
      filters.push({
        key: "search",
        value: search.toLowerCase(),
        operation: "in",
      });
    }
    if (reason.length > 0) {
      filters.push({
        key: "reason",
        value: reason,
        operation: "in",
      });
    }
    if (page !== this.state.page) {
      this.setState({ page });
    }
    if (override) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isPaging: true });
    }
    this.props.getInventoryHistoriesByStore(
      page,
      ITEM_PER_PAGE,
      isGroupView ? "group" : "single",
      filters,
      override
    );
  }

  getHistoryDetail(history, page, locationId = null, override = true) {
    let filters = [
      {
        key: "groupId",
        value: history.groupId,
        operation: "eq",
      },
    ];
    if (locationId) {
      filters.push({
        key: "locationId",
        value: locationId,
        operation: "eq",
      });
    }
    if (page !== this.state.detailPage) {
      this.setState({ detailPage: page });
    }
    if (!override) {
      this.setState({ isPaging: true });
    }
    this.props.getInventoryHistoriesByGroupId(
      this.props.histories[history.id],
      page,
      ITEM_PER_PAGE,
      filters
    );
  }

  loadDetailMore = (history) => {
    this.getHistoryDetail(
      history,
      this.state.detailPage + 1,
      this.isUsableLocationFilter() ? null : this.props.currentLocationId,
      false
    );
  };

  isStoreOrLocation() {
    if (Authority.level() === "location") {
      return { type: "location", id: this.props.currentLocationId };
    } else {
      return { type: "store", id: this.props.storeId };
    }
  }

  isUsableLocationFilter = () => {
    return (
      this.props.isMultiChannel && this.isStoreOrLocation().type === "store"
    );
  };

  handleRetrievePress = () => {
    let start = new Date(this.state.startDate);
    let end = new Date(this.state.endDate);
    if (isValidDate(start) && isValidDate(end)) {
      const diff = moment(end).diff(moment(start), "months");
      if (diff >= Constants.REPORT_RESTRICTION_MONTH) {
        return this.setState({ dateLimitExcessModal: true });
      }
      this.getInventoryHistories(
        1,
        this.state.isGroupView,
        [start, end],
        this.isUsableLocationFilter()
          ? this.state.locationFilter
          : [this.props.currentLocationId],
        this.state.isSearched ? this.state.skuSearch : null,
        this.state.typeFilter ? [this.state.typeFilter] : []
      );
      Analytics.sendMixPanelEvent("click", {
        screen: "StockHistories",
        action: "set_period",
        detail: moment(end).diff(moment(start), "days"),
      });
    }
  };

  resetPeriod = () => {
    this.changePeriod("", new Date(), new Date());
  };

  changePeriod = (period, start, end) => {
    this.setState({ selectedPeriod: period });
    this.handlePeriodChange([start, end]);
  };

  handleEmailModalClose = () => {
    this.setState({
      email: "",
      sendMailModal: false,
    });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.value });
  };

  handleSendEmail = () => {
    this.exportCsv(true);
  };

  handleExportCsv = () => {
    this.exportCsv();
  };

  exportCsv = (emailValidated = false) => {
    const {
      typeFilter,
      isGroupView,
      skuSearch,
      startDate,
      endDate,
      locationFilter,
    } = this.state;
    let filters = [];
    const locationIds = this.isUsableLocationFilter()
      ? locationFilter
      : [this.props.currentLocationId];
    if ((locationIds || []).length > 0) {
      if (locationIds.length > 1) {
        filters.push({
          key: "locationId",
          value: locationIds,
          operation: "in",
        });
      } else {
        filters.push({
          key: "locationId",
          value: locationIds[0],
          operation: "eq",
        });
      }
    }
    if (typeFilter) {
      filters.push({
        key: "reason",
        value: [typeFilter],
        operation: "in",
      });
    }
    if (startDate && endDate) {
      let s = new Date(startDate);
      let e = new Date(endDate);
      s.setHours(0, 0, 0, 0);
      e.setHours(23, 59, 59, 999);
      filters.push({
        key: "createdAt",
        value: [s.toISOString(), e.toISOString()],
        operation: "between",
      });
    }
    if (skuSearch) {
      filters.push({
        key: "search",
        value: skuSearch.toLowerCase(),
        operation: "in",
      });
    }
    const historyType = isGroupView ? "group" : "single";
    if (
      isLegacyInventoryHistories(new Date(startDate).toISOString(), historyType)
    ) {
      this.setState({ isDownloadingExcel: true });
      this.props.downloadInventoryHistories(historyType, filters);
    } else {
      if (emailValidated) {
        API.sendReportToEmail(
          this.props.storeId,
          `${historyType}InventoryHistories`,
          filters,
          this.state.email
        );
        this.handleEmailModalClose();
      } else {
        this.setState({ sendMailModal: true });
      }
    }
    Analytics.sendMixPanelEvent("click", {
      screen: "StockHistories",
      action: "download_excel",
    });
  };

  handleTypeFilterChange = (e) => {
    this.setState({ typeFilter: e.value });
    let start = new Date(this.state.startDate);
    let end = new Date(this.state.endDate);
    this.getInventoryHistories(
      1,
      this.state.isGroupView,
      [start, end],
      this.isUsableLocationFilter()
        ? this.state.locationFilter
        : [this.props.currentLocationId],
      this.state.isSearched ? this.state.skuSearch : null,
      e.value ? [e.value] : []
    );
    Analytics.sendMixPanelEvent("click", {
      screen: "StockHistories",
      action: "select_type",
      detail: e.value,
    });
  };

  handleSkuSearchChange = (e) => {
    this.setState({ skuSearch: e.target.value });
  };

  handleKeyDownForSearch = (e) => {
    if (e.key === "Enter") {
      this.handleHistorySearch();
    }
  };

  handleHistorySearch = () => {
    this.setState({ isSearched: true });
    let start = new Date(this.state.startDate);
    let end = new Date(this.state.endDate);
    this.getInventoryHistories(
      1,
      false,
      [start, end],
      this.isUsableLocationFilter() ? null : [this.props.currentLocationId],
      this.state.skuSearch,
      this.state.typeFilter ? [this.state.typeFilter] : []
    );
  };

  handleSearchClear = () => {
    this.setState({ skuSearch: "", isSearched: false });
    let start = new Date(this.state.startDate);
    let end = new Date(this.state.endDate);
    this.getInventoryHistories(
      1,
      false,
      [start, end],
      this.isUsableLocationFilter() ? null : [this.props.currentLocationId],
      "",
      this.state.typeFilter ? [this.state.typeFilter] : []
    );
  };

  getProdSkuName = (skuId) => {
    let sku = this.props.skusById[skuId];
    let product = this.props.productsById[sku?.parentId];
    if (!product) {
      return "삭제된 제품";
    }
    return `${product?.name} - ${sku?.name}`;
  };

  brushupDetailItems = (histories) => {
    let data = [];
    for (let history of histories || []) {
      const sku = this.props.skusById[history.skuId];
      const product = sku && this.props.productsById[sku?.parentId];
      data = [
        ...data,
        {
          prodName: product.name || "삭제된 제품",
          skuName: sku?.name || "",
          model: sku.model || "",
          unit: sku.unit || sku.barcode || "",
          reason: GetInventoryTransactionReason(history.reason),
          originalType: history.reason,
          quantity: Number(history.quantity),
          locationId: history.locationId,
          cost: history.totalCost,
        },
      ];
    }
    return data;
  };

  brushupGroupHistories = (histories) => {
    let data = [];
    for (let history of histories || []) {
      data = [
        ...data,
        {
          id: history.id,
          createdAt: moment(history.createdAt).format("YYYY/MM/DD HH:mm"),
          location: this.props.locationsById[history.locationId]?.name,
          type: GetInventoryTransactionReason(history.reason),
          skuLength: history.groupHistoryCount,
          locationId: history.locationId,
          totalCost: history.totalCost,
          note: history.note || "",
          isGroup: true,
          groupId: history.groupId,
          employee: this.getEmployeeName(history.employeeId),
        },
      ];
    }
    return data;
  };

  getEmployeeName = (_employeeId) => {
    const { employeesById } = this.props;
    return employeesById[_employeeId]
      ? employeesById[_employeeId].lastName +
          employeesById[_employeeId].firstName ||
          employeesById[_employeeId].nickname
      : "";
  };

  brushupHistories = (histories) => {
    let data = [];
    for (let history of histories || []) {
      let skuId = history.skuId;
      // NOTE: for legacy
      if (!skuId && history.isLegacy) {
        let mixedIds = history.id.split("sku_");
        skuId = "sku_" + mixedIds[1];
      }
      let sku = this.props.skusById[skuId] || {};
      const prod = (sku && this.props.productsById[sku?.parentId]) || {};
      data = [
        ...data,
        {
          createdAt: moment(history.createdAt).format("YYYY/MM/DD HH:mm"),
          prodName: !prod ? "삭제된 제품" : prod.name,
          skuName: sku.name ?? "삭제된 제품",
          model: sku.model,
          unit: sku.unit || sku.barcode,
          location: this.props.locationsById[history.locationId]?.name,
          type: GetInventoryTransactionReason(history.reason),
          id: history.id,
          originalType: history.reason,
          quantity: Number(history.quantity),
          locationId: history.locationId,
          totalCost: history.totalCost,
          cost: history.cost,
          sku: sku,
          note: history.note || "",
          employee: this.getEmployeeName(history.employeeId),
          original: history,
          expiryDate: history.expiryDate
            ? moment(new Date(history.expiryDate)).format(
                Constants.MOMENT.DATE_FORMAT
              )
            : "-",
          originExpiryDate: history.expiryDate ? history.expiryDate : null,
        },
      ];
    }
    return data;
  };

  loadMore = () => {
    if (this.props.historyCount > this.state.page * ITEM_PER_PAGE) {
      const start = new Date(this.state.startDate);
      const end = new Date(this.state.endDate);
      this.getInventoryHistories(
        this.state.page + 1,
        this.state.isGroupView,
        [start, end],
        this.isUsableLocationFilter()
          ? this.state.locationFilter
          : [this.props.currentLocationId],
        this.state.skuSearch,
        this.state.typeFilter ? [this.state.typeFilter] : [],
        false
      );
    }
  };

  handleHistoryClick = (history) => {
    if (
      (history.originalType === "sales" ||
        history.originalType === "returns") &&
      history.original.referId
    ) {
      this.props.getOrder(history.original.referId);
      this.setState({
        isHistoryLoading: true,
        selectedHistory: history,
      });
    } else if (this.state.isGroupView && history.isGroup) {
      this.getHistoryDetail(
        history,
        1,
        this.isUsableLocationFilter() ? null : this.props.currentLocationId
      );
      this.setState({
        selectedHistory: history,
        isGroupHistoryLoading: true,
      });
    } else if (
      history.originalType === "newStock" &&
      history.original.purchaseOrderId
    ) {
      this.setState({
        isHistoryLoading: true,
        selectedHistory: history,
      });
      this.props.getSuppliers(
        this.props.storeId,
        this.isUsableLocationFilter() ? null : this.props.currentLocationId
      );
      this.props.getPurchaseOrderById(history.original.purchaseOrderId);
    } else {
      this.setState({
        historyModal: true,
        selectedHistory: history,
      });
    }
  };

  handleHistoryModalCancel = () => {
    this.setState({
      historyModal: false,
      selectedHistory: null,
      forceCost: null,
      forceExpiryDate: null,
      forceQuantity: null,
    });
  };

  handleGroupPress = () => {
    const isGroupView = !this.state.isGroupView;
    this.setState({ isGroupView: isGroupView, typeFilter: null });
    let start = new Date(this.state.startDate);
    let end = new Date(this.state.endDate);
    this.getInventoryHistories(
      1,
      isGroupView,
      [start, end],
      this.isUsableLocationFilter()
        ? this.state.locationFilter
        : [this.props.currentLocationId],
      this.state.isSearched ? this.state.skuSearch : null,
      this.state.typeFilter ? [this.state.typeFilter] : []
    );
  };

  getStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return "대기중";
      case "complete":
      default:
        return "처리완료";
    }
  };

  handleCloseDateLimitExcessModal = () => {
    this.setState({ dateLimitExcessModal: false });
  };

  handlePrintPress = (historyId) => {
    let data = {};
    const _history = this.props.histories[historyId] || {};

    data = {
      id: _history.id,
      createdAt: moment(_history.createdAt).format(
        Constants.MOMENT.DATETIME_FORMAT
      ),
      skuLength: (_history.histories || []).length,
      quantity: _.sumBy(_history.histories || [], "quantity"),
      state: this.getStatusLabel(_history.status),
      note: _history.note,
      totalCost: numberToCurrency(_history.totalCost),
      location: this.props.locationsById[_history.locationId]?.name,
      employee: this.getEmployeeName(_history.employeeId),
      origin: {
        ..._history,
        items: _history.histories || [],
      },
    };
    this.props.shareGroupHistoryPdf(data, "bulk");
  };

  getEditableState = (reason, cost, purchaseOrderId) => {
    const purchaseOrder = purchaseOrderId
      ? this.props.purchaseOrdersById[purchaseOrderId]
      : null;
    if (purchaseOrder) {
      if (purchaseOrder.status === "received") {
        return { quantity: true, cost: true, expiryDate: true };
      } else {
        return { quantity: false, cost: false, expiryDate: false };
      }
    } else {
      switch (reason) {
        case "newStock":
          return { quantity: true, cost: true, expiryDate: true };
        case "force":
          if (cost > 0) {
            return { quantity: false, cost: true, expiryDate: true };
          } else if (cost <= 0) {
            return { quantity: false, cost: false, expiryDate: false };
          }
          break;
        case "loss":
        case "remove":
        case "damaged":
          return { quantity: true, cost: false, expiryDate: false };
        case "transfer":
        default:
          return { quantity: false, cost: false, expiryDate: false };
      }
    }
  };

  handleForceSave = (historyId) => {
    const _history = this.props.histories[historyId];
    let { forceCost, forceQuantity, forceExpiryDate } = this.state;
    let cost = 0;
    if (Number(_.cloneDeep(forceCost))) {
      cost = Number(_.cloneDeep(forceCost));
    } else if (Number(_.cloneDeep(_history.cost))) {
      cost = Number(_.cloneDeep(_history.cost));
    } else {
      if (_history.reason === "newStock") {
        cost = 0;
      } else if (_history.reason === "force") {
        const sku = this.props.skusById[_history.skuId];
        cost = sku.cost;
      } else {
        cost = null;
      }
    }
    const quantity = Number(forceQuantity) || Number(_history.quantity) || 0;
    let expiryDate = forceExpiryDate || _history.expiryDate || null;

    expiryDate = expiryDate
      ? new Date(expiryDate).setHours(23, 59, 59, 999)
      : null;
    this.props.updateInventoryHistory(
      historyId,
      quantity,
      cost,
      expiryDate ? new Date(expiryDate).toISOString() : null
    );
    this.setState({ isUpdating: true });
  };

  handleForceCostChange = (e) => {
    const cost = Number(removeString(e.target.value));
    if (isAvailableAmount(cost) && cost >= 0) {
      this.setState({
        forceCost: cost.toString(),
      });
    }
  };

  getStockQuantity = (reason, quantity) => {
    if (reason === "damaged" || reason === "loss" || reason === "remove") {
      return -Number(quantity);
    }
    return Number(quantity);
  };

  handleForceQuantityChange = (e) => {
    const quantity = this.getStockQuantity(
      this.state.selectedHistory?.originalType,
      removeString(e.target.value)
    );
    if (isAvailableQuantity(quantity)) {
      this.setState({
        forceQuantity: quantity.toString(),
      });
    }
  };

  handleExpiryDateChange = (date) => {
    let expiryDate = new Date(date);
    expiryDate.setHours(23, 59, 59, 999);
    this.setState({
      forceExpiryDate: expiryDate.toISOString(),
    });
  };

  isHistorySavable = (editable, _history) => {
    const { forceQuantity, forceCost, forceExpiryDate } = this.state;
    let savable = false;
    if (editable.quantity || editable.cost || editable.expiryDate) {
      if (editable.quantity) {
        if (
          forceQuantity &&
          !(_history.originalType === "remove" && forceQuantity === "0") &&
          forceQuantity !== _history.quantity?.toString()
        ) {
          savable = true;
        }
      }
      if (editable.cost) {
        if (forceCost && forceCost !== _history.cost?.toString()) {
          savable = true;
        }
      }
      if (editable.expiryDate) {
        const _forceExpiryDate = moment(new Date(forceExpiryDate)).format(
          Constants.MOMENT.DATE_FORMAT
        );
        if (forceExpiryDate && _forceExpiryDate !== _history.expiryDate) {
          savable = true;
        }
      }
    }
    return savable;
  };

  renderList = () => {
    let _histories = this.brushupHistories(Object.values(this.props.histories));
    let _groupHistories = this.brushupGroupHistories(
      Object.values(this.props.histories)
    );
    let totalPage = Math.ceil(this.props.historyCount / ITEM_PER_PAGE);
    let { isGroupView, isUpdating, forceCost, forceQuantity, forceExpiryDate } =
      this.state;
    let hasMoreItems = this.state.page !== totalPage && totalPage !== 0;
    return (
      <>
        {this.state.historyModal ? (
          <HistoryModal
            open={this.state.historyModal}
            onCancel={this.handleHistoryModalCancel}
            history={this.state.selectedHistory}
            isGroup={this.state.selectedHistory.isGroup}
            brushupDetailItems={this.brushupDetailItems}
            histories={
              this.props.histories[this.state.selectedHistory?.id]?.histories ||
              []
            }
            detailProps={{
              suppliersById: this.props.suppliersById,
              purchaseOrdersById: this.props.purchaseOrdersById,
              currentLocationId: this.props.currentLocationId,
              ordersById: this.props.ordersById,
            }}
            onPrintPress={this.handlePrintPress}
            isPrinting={this.props.pdfStatus.isFetching}
            loadMore={() =>
              this.loadDetailMore(
                this.props.histories[this.state.selectedHistory?.id]
              )
            }
            hasMoreItems={
              ITEM_PER_PAGE * this.state.detailPage <
              this.props.historyItemCount
            }
            isPaging={this.state.isPaging}
            getEditableState={this.getEditableState}
            onExpiryDateChange={this.handleExpiryDateChange}
            hasExpiry={this.props.inventoryConfig.expiryDateMgmt}
            onForceSave={this.handleForceSave}
            onQuantityChange={this.handleForceQuantityChange}
            onCostChange={this.handleForceCostChange}
            forceCost={forceCost}
            forceQuantity={forceQuantity}
            forceExpiryDate={forceExpiryDate}
            isLoading={isUpdating}
            isRetail={this.props.isRetail}
            isHistorySavable={this.isHistorySavable}
          />
        ) : null}
        <div className="mt-medium" />
        {isGroupView ? (
          <CustomTable
            items={_groupHistories}
            headers={TABLE_GROUP_HEADERS}
            onRowClick={this.handleHistoryClick}
            topMarginStyle={{ marginTop: "15px" }}
            placeholder={"제품, 품목코드(SKU), 바코드 검색 가능"}
            allowTwoLine
          />
        ) : (
          <CustomTable
            items={_histories}
            headers={TABLE_HEADERS}
            onRowClick={this.handleHistoryClick}
            hasSearch
            searchValue={this.state.skuSearch}
            onKeyDownForSearch={this.handleKeyDownForSearch}
            onSearchChange={this.handleSkuSearchChange}
            topMarginStyle={{ marginTop: "15px" }}
            placeholder={"제품, 품목코드(SKU), 바코드 검색 가능"}
            allowTwoLine
            isManualSearch
            onSearchClear={this.handleSearchClear}
            onSearchClick={this.handleHistorySearch}
            isSearched={this.state.isSearched}
          />
        )}
        {hasMoreItems || this.state.isPaging ? (
          <div className="bg-color-white">
            <NofilledButton
              onClick={this.loadMore}
              buttonColor={"blue"}
              customStyle={{
                height: "55px",
                margin: "auto",
              }}
              loading={this.state.isPaging}
              textComp={
                <div className="label font-bold blue-color">{"더보기"}</div>
              }
            />
          </div>
        ) : null}
      </>
    );
  };

  render() {
    return (
      <>
        <DefaultLoader
          active={
            (this.props.status.isFetching ||
              this.state.isHistoryLoading ||
              this.props.itemStatus.isFetching) &&
            !this.state.isPaging &&
            !this.state.isDownloadingExcel
          }
        />
        {this.state.dateLimitExcessModal ? (
          <LimitExcessModal
            open={this.state.dateLimitExcessModal}
            onClose={this.handleCloseDateLimitExcessModal}
            title={"기간 조회는 최대 90일까지 가능합니다."}
            customTitleClass={"normal mt-mb-base"}
          />
        ) : null}
        {this.state.sendMailModal ? (
          <SendMailModal
            open={this.state.sendMailModal}
            title="히스토리 다운로드"
            onClose={this.handleEmailModalClose}
            email={this.state.email}
            onEmailChange={this.handleEmailChange}
            reportName={"재고 히스토리"}
            onSendEmail={this.handleSendEmail}
            isValidEmail={isValidEmail}
          />
        ) : null}
        <Filters
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onStartDateChange={this.handleStartDateChange}
          onEndDateChange={this.handleEndDateChange}
          locationFilter={this.state.locationFilter}
          onLocationChange={this.handleLocationChange}
          getLocations={this.getLocations}
          hasInventoryAndAccess={
            this.props.hasInventory && this.props.hasLocationAdminAccess
          }
          storeLocations={this.props.locations}
          onRetrievePress={this.handleRetrievePress}
          isGroupView={this.state.isGroupView}
          typeFilter={this.state.typeFilter}
          onTypeFilterChange={this.handleTypeFilterChange}
          onGroupPress={this.handleGroupPress}
          onExportCsv={this.handleExportCsv}
          isDownloadingExcel={this.state.isDownloadingExcel}
        />
        {this.renderList()}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      storeId: reducers.getStoreId(state),
      products: reducers.getProducts(state),
      locations: reducers.getInventoryLocations(state),
      organization: reducers.getCurrentOrganization(state),
      hasInventory: reducers.hasLocationInventory(state),
      orderStatus: reducers.getOrderStatus(state),
      ordersById: reducers.getOrdersById(state),
      productsById: reducers.getProductsById(state),
      skusById: reducers.getSkusById(state),
      locationsById: reducers.getInventoryLocationsById(state),
      currentLocationId: reducers.getCurrentLocationId(state),
      hasLocationAdminAccess: reducers.hasLocationAdminAccess(state),
      histories: reducers.getInventoryHistoriesById(state),
      historyCount: reducers.getInventoryHistoryCount(state),
      historyItemCount: reducers.getInventoryHistoryItemCount(state),
      status: reducers.getInventoryStatus(state),
      purchaseOrderStatus: reducers.getPurchaseOrderStatus(state),
      isMultiChannel: reducers.isMultiChannel(state),
      purchaseOrdersById: reducers.getPurchaseOrdersById(state),
      suppliersById: reducers.getSuppliersById(state),
      itemStatus: reducers.getItemStatus(state),
      employeesById: reducers.getEmployeesById(state),
      inventoryConfig: reducers.getStoreInventoryConfig(state),
      isRetail: reducers.isRetailMode(state),
      pdfStatus: reducers.getPdfStatus(state),
    }),
    {
      getItems,
      getInventoryHistoriesByStore,
      getOrder,
      getInventoryHistoriesByGroupId,
      getPurchaseOrderById,
      getSuppliers,
      shareHistoryPdf,
      updateInventoryHistory,
      refreshInventoryHistory,
      downloadInventoryHistories,
      shareGroupHistoryPdf,
    }
  )(StockHistories)
);
