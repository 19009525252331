import { combineReducers } from "redux";
import * as actions from "../actions";

export const getModifierLimitQuantity = (mod, currentModSku) => {
  const modifierLimitQty =
    mod.orderType === "toggle"
      ? mod.limitQuantity || (mod.skus || []).length
      : currentModSku.limitQuantity || null;

  return modifierLimitQty;
};

export const getModifierRequiredQuantity = (mod) => {
  const modifierRequiredQty = mod.requiredQuantity || 0;
  return modifierRequiredQty;
};

export const getModifierIds = (productModifiers, productId) => {
  let modifierIds = [];
  if (!productId) {
    return modifierIds;
  }
  const productModifierByProdId = getProductModifierByProdId(productModifiers);
  const hasModifierOrdering = getModifierOrdering(productModifiers, productId);
  if (hasModifierOrdering) {
    modifierIds = (productModifierByProdId[productId] || [])
      .sort(sortByPosition)
      .map((pm) => pm.modifierId);
  } else {
    modifierIds = productModifiers
      .filter((pm) => pm.productId === productId)
      .map((pm) => pm.modifierId);
  }

  return modifierIds;
};

export const getMarketModifiers = (
  productModifiers,
  productId,
  modifierById
) => {
  let modifiers = [];
  if (!productId) {
    return modifiers;
  }
  const productModifierByProdId = getProductModifierByProdId(productModifiers);
  const hasModifierOrdering = getModifierOrdering(productModifiers, productId);

  if (hasModifierOrdering) {
    modifiers = (productModifierByProdId[productId] || [])
      .sort(sortByPosition)
      .map((pm) => modifierById[pm.modifierId]);
  } else {
    const modifierIds = productModifiers
      .filter((pm) => pm.productId === productId)
      .map((pm) => pm.modifierId);
    modifiers = Object.values(modifierById).filter((mod) =>
      modifierIds.includes(mod.id)
    );
  }

  return modifiers;
};

export const getProductModifierByProdId = (productModifiers) => {
  const productModifierByProdId = productModifiers.reduce((obj, pm) => {
    if (!obj[pm.productId]) {
      obj[pm.productId] = [];
    }
    obj[pm.productId] = [...obj[pm.productId], pm];
    return obj;
  }, {});

  return productModifierByProdId;
};

export const getModifierOrdering = (productModifiers, productId) => {
  const productModifierByProdId = getProductModifierByProdId(productModifiers);

  const hasModifierOrdering = (productModifierByProdId[productId] || []).filter(
    (pm) => pm.position
  ).length;

  return hasModifierOrdering;
};
const sortByPosition = (prev, cur) => {
  if (prev.position && cur.position) {
    return prev.position - cur.position;
  } else if (prev.position && !cur.position) {
    return -1;
  } else if (!prev.position && cur.position) {
    return 1;
  } else {
    return 0;
  }
};

export const SAMPLE_PRODUCT = {
  id: "prod_YzcxNmVjZTk1MmRkYmRkYzlh",
  createdAt: "2022-01-24T08:34:26.509139+00:00",
  description: "test",
  name: "(일반)제품의 이름이 들어갈 자리입니다.",
  price: 1200,
  cost: 200,
  model: "SKU-NUMBER",
  expiryDate: "2023-03-21T15:00:00.000Z",
  attributeName: "속성명: 내용",
  storeId: "st_Nzc5NTVkZDY2ZTlmNTIwMGEx",
  taxId: "tx_YWE3MjRlYmZhZTcyN2E4ZDQ4",
  type: "product",
  updatedAt: "2023-03-17T08:12:34.583749+00:00",
};

const initialState = {
  itemStatus: {
    isFetching: false,
    error: null,
    action: null,
    data: null,
  },
  items: {},
  histories: [],
};

function itemStatus(state = initialState.itemStatus, action) {
  switch (action.type) {
    case actions.GET_ITEMS:
    case actions.GET_PRODUCT:
    case actions.CREATE_OR_UPDATE_PRODUCT:
    case actions.DELETE_PRODUCT:
    case actions.CREATE_OR_UPDATE_MODIFIER:
    case actions.DELETE_MODIFIER:
    case actions.CREATE_OR_UPDATE_CATEGORY:
    case actions.UPDATE_PRODUCTS:
    case actions.DELETE_CATEGORY:
    case actions.ADJUST_INVENTORIES:
    case actions.UPDATE_SKU_LOCATIONS:
    case actions.CREATE_SKU_INVENTORY:
    case actions.UPDATE_SKU_INVENTORY:
    case actions.DELETE_SKU_INVENTORY:
    case actions.UPDATE_ACTIVE_CHANGED_PRODUCTS:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_ITEMS_SUCCESS:
    case actions.UPDATE_ITEMS_SUCCESS:
      return initialState.itemStatus;
    case actions.GET_ITEMS_FAILURE:
    case actions.UPDATE_ITEMS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    case actions.INIT_GENERATE_INVENTORY_TEMPLATE:
      return {
        ...initialState.itemStatus,
        action: action.type,
      };
    case actions.UPLOAD_ITEM_TEMPLATE:
      return {
        ...initialState.itemStatus,
        action: action.type,
      };
    case actions.PARSE_ITEM_TEMPLATE_SUCCESS:
    case actions.SYNC_ITEM_TEMPLATE_SUCCESS:
    case actions.GENERATE_ITEM_TEMPLATE_SUCCESS:
      return {
        ...initialState.itemStatus,
        action: action.type,
        ...{ data: action.data ? action.data : null },
      };
    case actions.PARSE_ITEM_TEMPLATE_FAILURE:
    case actions.SYNC_ITEM_TEMPLATE_FAILURE:
    case actions.GENERATE_ITEM_TEMPLATE_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        action: action.type,
      };
    case actions.RESET_ERROR:
      return initialState.itemStatus;
    default:
      return state;
  }
}

const items = (state = initialState.items, action) => {
  switch (action.type) {
    case actions.RECEIVE_ITEMS:
      return { ...action.items, updatedAt: new Date().getTime() };
    case actions.RECEIVE_ITEM:
      return {
        ...state,
        products: [...(state.products || []), action.item],
        updatedAt: new Date().getTime(),
      };
    case actions.CLEAR_STORE:
      return initialState.items;
    case actions.SIGN_OUT:
      return initialState.items;
    default:
      return state;
  }
};

const histories = (state = initialState.histories, action) => {
  switch (action.type) {
    case actions.RECEIVE_ITEM_INVENTORY_HISTORIES:
      return action.histories;
    case actions.SIGN_OUT:
      return initialState.histories;
    default:
      return state;
  }
};

export default combineReducers({
  itemStatus,
  items,
  histories,
});
