import logo from "../img/brand/logo.png";

const PdfStyle = `
<style type="text/css">
    body {
      font-family: "Noto Sans", sans-serif;
      font-size: x-small;
      width: 100%;
      overflow: auto;
    }
    .container {
      width: 100%;
    }
    th, tr, td {
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
    }
    tr > th {
      font-family: "Noto Sans", sans-serif;
      font-size: 1em;
    }
    th {
      background-color: #efeff4;
      padding: 5px 3px;
      font-weight: bold;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px !important;
      text-align: center;
    }
    .th {
      background: #efeff4;
      font-size: 12px !important;
      text-align: center;
      font-weight: bold;
    }
    td {
      background: #fff;
      padding: 5px 0;
      font-size: 12px;
      text-align: center;
    }
    .td {
      background: #fff;
      padding: 5px 5px;
      font-size: 12px;
      text-align: center;
    }
    hr {
      border-color: #1a283e;
    }
    .summary {
      margin: 0 0 10px 0;
      display: flex;
    }
    .summary >.label {
      font-size: 14px;
      font-weight: normal;
      width: 30%;
    }
    .summary >.value {
      font-size: 14px;
      font-weight: bold;
      width: 70%;
    }
    textarea {
      padding: 10px 10px;
    }
    .container .top-info-wrapper {
      padding: 15px 20px;
    }
    .container .table-wrapper {
      margin-top: 30px;
    }
    .cost-info {
      padding: 15px 20px;
      margin: 10px 0;
      display: flex;
      align-items: flex-start;
    }
    .cost-info > .left {
      width: 50%;
      padding: 10px;
    }
    .cost-info > .right {
      width: 50%;
      padding: 10px;
      display: flex;
      flex-direction: column;
    }
    .cost-info > .right > .label-wrapper {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #252525;
      font-size:14px;
    }
    .top-info-wrapper .title {
      text-align: center;
      margin-top: 10px;
      font-size: 20px;
    }
    .top-info-wrapper .logo {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 30px;
      right: 25px;
    }
    .top-info-wrapper .info-section {
      margin-top:30px;
      display:flex;
    }
    .top-info-wrapper .info-section .left {
      width: 50%;
      padding: 0 15px;
    }
    .top-info-wrapper .info-section .right {
      width: 50%;
      padding: 0 0 0 15px;
      display: flex;
      align-items: stretch;
    }
    .top-info-wrapper .info-section .right .text-box {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 1px solid #1a283e;
      padding: 10px;
    }
    .mt-0 {
      margin-top: 0;
    }
    .text-center {
      text-align: center;
    }
    .border {
      border-top: 0.5px solid #252525;
      border-right: 0;
      border-bottom: 0.5px solid #252525;
      border-left: 1px solid #252525;
    }
    .border-right {
      border-right: 1px solid #252525;
    }
  </style>`;

export const BasicSample = `
<head>
${PdfStyle}
</head>
<body>
  <div class="container">
    <div class="top-info-wrapper">
      {{titleSample}}
      <img class="logo" src="${logo}">
      <div class="info-section">
        <div class="left">
          {{info}}
        </div>
        <div class="right">
          {{noteSample}}
        </div>
      </div>
    </div>
    <hr />
    <div class="table-wrapper">
      <table style="width:95%;border-spacing: 0;margin:auto;">
        {{header}}
        {{skus}}
      </table>
    </div>
  </div>
</body>`;

export const TitleSample = `<h1 class="title">{{title}}</h1>`;

export const NoteSample = `<textarea class="text-box" style="font-size:14px;">{{note}}</textarea>`;

export const BulkActionInfo = `
            <div class="summary">
              <div class="label">
                거래일시
              </div>
              <div class="value">
                {{createdAt}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                지점
              </div>
              <div class="value">
                {{location}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                담당자
              </div>
              <div class="value">
                {{employee}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                상태
              </div>
              <div class="value">
                {{state}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                총 비용
              </div>
              <div class="value">
                {{totalCost}}
              </div>
            </div>
          `;

export const BulkActionHeader = `<tr style="background: #efeff4;">
<td class="th border" style="width:16%">제품이름</td>
<td class="th border" style="width:16%">하위제품</td>
<td class="th border" style="width:15%">바코드</td>
<td class="th border" style="width:14%">품목코드(SKU)</td>
<td class="th border" style="width:13%">조정사유</td>
<td class="th border" style="width:13%">수량</td>
<td class="th border border-right" style="width:13%">총 비용</td>
</tr>`;

export const BulkActionItems = `<tr style="line-height: 1.3;">
<td class="border ">{{prodName}}</td>
<td class="border ">{{skuName}}</td>
<td class="border ">{{unit}}</td>
<td class="border ">{{model}}</td>
<td class="border ">{{reason}}</td>
<td class="border ">{{quantity}}</td>
<td class="border border-right " style="font-size:14px;">{{totalCost}}</td>
</tr>`;

export const TransferInfo = `
            <div class="summary">
              <div class="label">
                거래일시
              </div>
              <div class="value">
                {{createdAt}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                담당자
              </div>
              <div class="value">
                {{employee}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                출발지점
              </div>
              <div class="value">
                {{fromLocation}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                도착지점
              </div>
              <div class="value">
                {{toLocation}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                상태
              </div>
              <div class="value">
                {{state}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                총 비용
              </div>
              <div class="value">
                {{totalCost}}
              </div>
            </div>`;

export const TransferHeader = `
<tr style="background-color: #efeff4;">
<th class="border" style="width:16%;">제품이름</th>
<th class="border" style="width:16%;">하위제품</th>
<th class="border" style="width:15%;">바코드</th>
<th class="border" style="width:14%;">품목코드(SKU)</th>
<th class="border" style="width:13%;">이동수량</th>
<th class="border" style="width:13%;">실제수량</th>
<th class="border border-right" style="width:13%;">차이</th>
</tr>
`;

export const TransferItems = `<tr style="line-height: 1.3;">
<td class="border ">{{prodName}}</td>
<td class="border ">{{skuName}}</td>
<td class="border ">{{unit}}</td>
<td class="border ">{{model}}</td>
<td class="border ">{{quantity}}</td>
<td class="border ">{{receivedQuantity}}</td>
<td class="border border-right ">{{diff}}</td>
</tr>`;

export const CountInfo = `
            <div class="summary">
              <div class="label">
                실사일시
              </div>
              <div class="value">
                {{createdAt}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                지점
              </div>
              <div class="value">
                {{location}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                담당자
              </div>
              <div class="value">
                {{employee}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                상태
              </div>
              <div class="value">
                {{state}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                총 비용
              </div>
              <div class="value">
                {{totalCost}}
              </div>
            </div>
          `;

export const CountHeader = `<tr style="background-color: #efeff4;">
<td class="th border" style="width:15%">제품이름</td>
<td class="th border" style="width:14%">하위제품</td>
<td class="th border" style="width:13%">바코드</td>
<td class="th border" style="width:13%">품목코드(SKU)</td>
<td class="th border" style="width:10%">이전수량</td>
<td class="th border" style="width:10%">실사수량</td>
<td class="th border" style="width:10%">수량차이</td>
<td class="th border border-right" style="width:13%">비용차이</td>
</tr>`;

export const CountItems = `<tr style="line-height: 1.3;">
<td class="border ">{{prodName}}</td>
<td class="border ">{{skuName}}</td>
<td class="border ">{{unit}}</td>
<td class="border ">{{model}}</td>
<td class="border ">{{prevQuantity}}</td>
<td class="border ">{{currQuantity}}</td>
<td class="border ">{{countDiff}}</td>
<td class="border border-right " style="font-size:12px;">{{totalCost}}</td>
</tr>`;

export const PurchaseOrderHeader = `<tr style="background: #efeff4;">
<td class="th border" style="width:13%">제품이름</td>
<td class="th border" style="width:13%">하위제품</td>
<td class="th border" style="width:13%">바코드</td>
<td class="th border" style="width:13%">품목코드(SKU)</td>
<td class="th border" style="width:12%">입고단가</td>
<td class="th border" style="width:8%">발주수량</td>
<td class="th border" style="width:8%">대기수량</td>
<td class="th border" style="width:8%">입고수량</td>
<td class="th border border-right" style="width:12%">총 비용</td>
</tr>`;

export const PurchaseOrderItems = `<tr style="line-height: 1.3;">
<td class="border ">{{prodName}}</td>
<td class="border ">{{skuName}}</td>
<td class="border ">{{unit}}</td>
<td class="border ">{{model}}</td>
<td class="border ">{{cost}}</td>
<td class="border ">{{quantity}}</td>
<td class="border ">{{waitingQuantity}}</td>
<td class="border ">{{receivedQuantity}}</td>
<td class="border border-right " style="font-size:12px;">{{totalCost}}</td>
</tr>`;

export const PurchaseOrderCostInfo = `
    <div class="left">
      <h6>비용상세</h6>
    </div>
    <div class="right" style="font-size:14px">
      <div class="label-wrapper">
        <div>공급가</div>
        <div>{{supplyCost}}</div>
      </div>
      <div class="label-wrapper">
        <div>부가세</div>
        <div>{{vat}}</div>
      </div>
      <div class="label-wrapper">
        <b>총 비용</b>
        <b>{{totalCost}}</b>
      </div>
    </div>`;

export const PurchaseOrderInfo = `
            <div class="summary">
              <div class="label">
                발주일시
              </div>
              <div class="value">
                {{createdAt}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                거래처
              </div>
              <div class="value">
                {{supplier}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                입고지점
              </div>
              <div class="value">
                {{location}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                입고예정일시
              </div>
              <div class="value">
                {{expectDate}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                입고완료일시
              </div>
              <div class="value">
                {{receivedAt}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                담당자
              </div>
              <div class="value">
                {{employee}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                상태
              </div>
              <div class="value">
                {{status}}
              </div>
            </div>
            <div class="summary">
              <div class="label">
                총 비용
              </div>
              <div class="value">
                {{totalCost}}
              </div>
            </div>`;

export const PurchaseOrderSample = `
<head>
${PdfStyle}
</head>
<body>
  <div class="container">
    <div class="top-info-wrapper">
      {{titleSample}}
      <img class="logo" src="${logo}">
      <div class="info-section">
        <div class="left">
          {{info}}
        </div>
        <div class="right">
          {{noteSample}}
        </div>
      </div>
    </div>
    <hr />
    <div class="cost-info">
      {{costInfo}}
    </div>
    <div class="table-wrapper">
      <table style="width:95%;border-spacing: 0;margin:auto">
        {{header}}
        {{skus}}
      </table>
    </div>
  </div>
</body>`;
