import awsconfig from "../aws-exports";

export default {
  DEFAULT_LOGO_URL: "https://react.semantic-ui.com/images/wireframe/image.png",
  NICE_RECEIPT_URL: "https://npg.nicepay.co.kr/issue/IssueLoaderMail.do",
  BUSINESS_OPERATOR_INQUIRY_URL:
    "https://support.thegrowing.co/growing-sales/policy/policy/merchants/operation-policy",
  POLICY_URL: "https://thegrowing.co/policy/privacy",
  EULA_URL: "https://thegrowing.co/policy/eula",
  AROUND_TERMSOFUSE_URL:
    "https://support.thegrowing.co/growing-sales/policy/policy/consumers/undefined-2",
  AROUND_PRIVACY_AGREEMENT_URL:
    "https://support.thegrowing.co/growing-sales/policy/policy/consumers/undefined-1",
  CONSUMER_PRIVACY_URL: "https://thegrowing.co/policy/customer-privacy",
  CONSUMER_EULA_URL: "https://thegrowing.co/policy/customer-eula",
  CONSUMER_THIRD_URL: "https://thegrowing.co/policy/customer-third-party",
  STORE: "https://thegrowing.co/store",
  HOME: "https://thegrowing.co/",
  SERVER_URL:
    awsconfig.aws_user_files_s3_bucket.slice(
      awsconfig.aws_user_files_s3_bucket.length - 6
    ) === "master"
      ? "https://2cqlvbghtd.execute-api.ap-northeast-2.amazonaws.com/master"
      : "https://cmh6kk09ge.execute-api.ap-northeast-2.amazonaws.com/dev",
  S3_URL:
    awsconfig.aws_user_files_s3_bucket.slice(
      awsconfig.aws_user_files_s3_bucket.length - 6
    ) === "master"
      ? "https://sales-web8e1a63f45959416f9a0a9d2924ac117d-master.s3.ap-northeast-2.amazonaws.com"
      : "https://sales-web8e1a63f45959416f9a0a9d2924ac117d-dev.s3.ap-northeast-2.amazonaws.com",
  MANUAL_URL: "https://support.thegrowing.co/growing-sales",
  CARD_DEVICE_LIST:
    "http://support.thegrowing.co/ko/support/solutions/articles/60000466485-그로잉세일즈에서-지원하는-카드-단말기",
  GUIDE_NEW_PRODUCT:
    "http://support.thegrowing.co/ko/support/solutions/articles/60000466616-다수의-신규-제품-생성하기",
  GUIDE_UPDATE_PRODUCT:
    "http://support.thegrowing.co/ko/support/solutions/articles/60000467242-다수의-기존-제품-업데이트-수정-하기",
  LOYALTY_SETUP:
    "https://support.thegrowing.co/growing-sales/all%20functions/undefined-7/undefined-2/undefined",
  LOYALTY_LEAD:
    "http://support.thegrowing.co/ko/support/solutions/articles/60000467813-고객의-포인트-적립-유도하기",
  LOYALTY_POINT_USE:
    "https://support.thegrowing.co/growing-sales/all%20functions/undefined-7/undefined-2/undefined-1",
  CHANGE_EMAIL:
    "http://support.thegrowing.co/ko/support/solutions/articles/60000477823-점주-계정-이메일-변경하기",
  CS_URL: "https://support.thegrowing.co/growing-sales/",
  QUERY_KEYS: {
    STORE_LOCATION: "loc",
  },
  STORAGE_KEYS: {
    STORE: "st",
  },
  APPSTORE_URL:
    "https://apps.apple.com/us/app/%EA%B7%B8%EB%A1%9C%EC%9E%89%EC%84%B8%EC%9D%BC%EC%A6%88/id1471316655",
  PLAYSTORE_URL:
    "https://play.google.com/store/apps/details?id=com.thegrowing.sales",
  REPORT_RESTRICTION_MONTH: 3,
  REGISTRATION_GUIDE:
    "https://support.thegrowing.co/growing-sales/policy/policy/merchants/operation-policy",
  LOYALTY_GUIDE:
    "https://support.thegrowing.co/growing-sales/customer/loyalty-program",
  MESSAGE_GUIDE:
    "https://support.thegrowing.co/growing-sales/customer/message-campaign",
  LABEL_PRINTER_GUIDE:
    "https://support.thegrowing.co/growing-sales/device/peripheral/label-printer",
  LABEL_TEMPLATE_GUIDE:
    "https://support.thegrowing.co/growing-sales/device/peripheral/label-printer/template",
  BARCODE_COUPON_GUIDE:
    "https://support.thegrowing.co/growing-sales/start/food/discount#3",
  BARCODE_COUPON_DOWNLOAD_GUIDE:
    "https://support.thegrowing.co/growing-sales/start/food/discount#id-3-3",
  EXCEL_UPLOAD_GUIDE:
    "https://support.thegrowing.co/growing-sales/getting-started/setting/item/excel",
  MOMENT: {
    DATE_FORMAT: "YYYY/MM/DD",
    DATETIME_FORMAT: "YYYY/MM/DD, HH:mm",
    DATETIME_ONE_DIGIT_FORMAT: "YYYY/MM/D, HH:mm",
    DATETIME_FORMAT_KO: "YYYY년 MM월 DD일, HH시 mm분",
    DATE_FORMAT_KO: "YYYY년 MM월 DD일",
  },
  NOTION_NOTICE_URL: "http://growingco.notion.site",
  CAFE24_DASHBOARD_URL: "https://dashboard.thegrowing.co/cafe24-guide",
  STOCK_HISTORY_UPDATE_DATE: new Date(
    new Date("2023-10-20").setHours(0, 0, 0, 0)
  ),
  PRICE_MAX_VALUE: 100000000,
  STOCK_QUANTITY_MAX_VALUE: 1000000,
  CAFE24_URL: "https://store.cafe24.com/kr/apps/22979",
  SORT_DIRECTION: {
    ASCEND: "ascend",
    DESCEND: "descend",
  },
  ERROR: {
    CHUNK_LOAD: "ChunkLoadError",
  },
  ASYNC_PROGRESS_FUNC: {
    APPLY_INVENTORY_TRANSFER_HISTORY: "applyInventoryTransferHistory",
    CANCEL_INVENTORY_TRANSFER_HISTORY: "cancelInventoryTransferHistory",
    APPLY_INVENTORY_COUNT_HISTORY: "applyInventoryCountHistory",
    CANCEL_PURCHASE_ORDER: "cancelPurchaseOrder",
    APPLY_PURCHASE_ORDER: "applyPurchaseOrder",
    APPLY_INVENTORY_ADJUSTMENTS: "applyInventoryAdjustments",
    SYNC_INVENTORIES: "syncInventories",
    SYNC_PRODUCTS: "syncProducts",
    SYNC_ORDERS: "syncOrders",
  },
  MANUAL: {
    barcode_coupon:
      "https://support.thegrowing.co/growing-sales/start/mgmt/discount#id-3",
    barcode_month_coupon:
      "https://support.thegrowing.co/growing-sales/start/mgmt/discount#id-3",
  },
  REWARD_FORM: {
    receipt: "https://forms.gle/oWDGPEWCZqSijTJX7",
    store: "https://store.cafe24.com/kr/apps/22979",
    review:
      "https://docs.google.com/forms/d/e/1FAIpQLSfWZbYxKvFY_5HGXKq5R0pjSiRJAgeT2QNTaN6D0d55jWEZjQ/viewform",
  },
};

export const INDUSTRY_TYPE = [
  {
    text: "외식업 - 음식점업",
    value: "food-food",
    id: "food-food",
  },
  {
    text: "외식업 - 디저트",
    value: "food-dessert",
    id: "food-dessert",
  },
  {
    text: "외식업 - 주점 및 비알코올 음료점업",
    value: "food-drink",
    id: "food-drink",
  },
  {
    text: "소매업 - 종합소매업",
    value: "retail-total",
    id: "retail-total",
  },
  {
    text: "소매업 - 음, 식료품",
    value: "retail-foodRetail",
    id: "retail-foodRetail",
  },
  {
    text: "소매업 - 면세점",
    value: "retail-duty",
    id: "retail-duty",
  },
  {
    text: "소매업 - 패션의류/잡화",
    value: "retail-fashion",
    id: "retail-fashion",
  },
  {
    text: "소매업 - 뷰티용품 판매점",
    value: "retail-beauty",
    id: "retail-beauty",
  },
  {
    text: "소매업 - 출산/유아동",
    value: "retail-babyProducts",
    id: "retail-babyProducts",
  },
  {
    text: "소매업 - 생활/주방용품",
    value: "retail-lifeProducts",
    id: "retail-lifeProducts",
  },
  {
    text: "소매업 - 가구/인테리어",
    value: "retail-furniture",
    id: "retail-furniture",
  },
  {
    text: "소매업 - 가전 디지털 판매점",
    value: "retail-digitalProducts",
    id: "retail-digitalProducts",
  },
  {
    text: "소매업 - 스포츠/레저용품 판매점",
    value: "retail-sports",
    id: "retail-sports",
  },
  {
    text: "소매업 - 자동차 용품 판매점",
    value: "retail-vehicle",
    id: "retail-vehicle",
  },
  {
    text: "소매업 - 취미/문구/악기",
    value: "retail-hobby",
    id: "retail-hobby",
  },
  {
    text: "소매업 - 도서 판매",
    value: "retail-book",
    id: "retail-book",
  },
  {
    text: "소매업 - 반려동물용품",
    value: "retail-animalProducts",
    id: "retail-animalProducts",
  },
  {
    text: "소매업 - 건강/의료용품",
    value: "retail-medical",
    id: "retail-medical",
  },
  {
    text: "소매업 - 그외 기타 소매업",
    value: "retail-extraRetail",
    id: "retail-extraRetail",
  },
  {
    text: "서비스업 - 교육서비스업",
    value: "service-education",
    id: "service-education",
  },
  {
    text: "서비스업 - 부동산업",
    value: "service-realEstate",
    id: "service-realEstate",
  },
  {
    text: "서비스업 - 스포츠 및 오락관련 서비스업",
    value: "service-sports",
    id: "service-sports",
  },
  {
    text: "서비스업 - 사진 촬영 및 스튜디오",
    value: "service-photography",
    id: "service-photography",
  },
  {
    text: "서비스업 - 숙박업",
    value: "service-accomodation",
    id: "service-accomodation",
  },
  {
    text: "서비스업 - 보건업",
    value: "service-health",
    id: "service-health",
  },
  {
    text: "서비스업- 미용업",
    value: "service-beauty",
    id: "service-beauty",
  },
  {
    text: "소매업 - 그 외 기타 서비스업",
    value: "service-extra",
    id: "service-extra",
  },
];

export const BANK_CODE = [
  { text: "산업은행", code: "002", value: "002" },
  { text: "기업은행", code: "003", value: "003" },
  { text: "국민은행", code: "004", value: "004" },
  { text: "수협은행", code: "007", value: "007" },
  { text: "수출입은행", code: "008", value: "008" },
  { text: "농협은행", code: "011", value: "011" },
  { text: "지역 농축협", code: "012", value: "012" },
  { text: "우리은행", code: "020", value: "020" },
  { text: "SC제일은행", code: "023", value: "023" },
  { text: "한국씨티은행", code: "027", value: "027" },
  { text: "대구은행", code: "031", value: "031" },
  { text: "부산은행", code: "032", value: "032" },
  { text: "광주은행", code: "034", value: "034" },
  { text: "제주은행", code: "035", value: "035" },
  { text: "전북은행", code: "037", value: "037" },
  { text: "경남은행", code: "039", value: "039" },
  { text: "새마을금고중앙회", code: "045", value: "045" },
  { text: "신협", code: "048", value: "048" },
  { text: "저축은행", code: "050", value: "050" },
  { text: "우체국", code: "071", value: "071" },
  { text: "하나은행", code: "081", value: "081" },
  { text: "신한은행", code: "088", value: "088" },
  { text: "케이뱅크", code: "089", value: "089" },
  { text: "카카오뱅크", code: "090", value: "090" },
  { text: "대신저축은행", code: "102", value: "102" },
  { text: "웰컴저축은행", code: "105", value: "105" },
];

export const GraphColor = [
  { color: "#1a283e" },
  { color: "#f6d662" },
  { color: "#009688" },
];

export const LOCATION_PAYMENT_SETTING = [
  {
    key: "autoPay",
    text: "결제 스크린 건너뛰기",
    description:
      "고객의 주문 결제 버튼 클릭시 결제수단 선택 화면을 건너뛰고, 포인트 적립 스크린 혹은 영수증 스크린으로 넘어갑니다.",
  },
];

export const LOCATION_COST_OPTIONS = [
  {
    key: "locationCost",
    text: "지점관리비용",
    description:
      "프랜차이즈 본사에서의 지점관리를 위해 기본적으로 발생되는 고정 관리비",
  },
];

export const PROD_SETTING_OPTIONS = [
  {
    key: "actualPrice",
    text: "실제 판매가격 도입",
    default: false,
    description:
      "제품의 본래가격과 실제 판매가격을 다르게 운영할 수 있습니다. 하나의 제품을 여러 지점에서 동시 판매할 경우나 한시적인 판매가격 변동 적용이 가능합니다.",
  },
  {
    key: "locationCreatable",
    text: "지점의 제품생성 가능 여부",
    default: false,
    description:
      "지점에서 신규 제품을 생성할 수 있으며, 본사에서 해당 제품의 관리가 가능합니다.",
  },
  {
    key: "skuMgmt",
    text: "품목코드(SKU) 관리",
    default: true,
    description:
      "가게의 제품 고유번호가 있을 시 SKU에 해당 번호를 입력하여 관리할 수 있습니다.",
  },
  {
    key: "barcodeMgmt",
    text: "바코드 관리",
    default: true,
    description:
      "제품의 고유 바코드 번호를 입력 시 바코드 스캐너를 통해서 제품의 바코드를 스캔 시 자동으로 제품이 장바구니에 담기게 됩니다.",
  },
  {
    key: "stockTrack",
    text: "재고 추적",
    default: true,
    description:
      "재고 추적 옵션을 활성화하면 재고자산, 재고 입출고 기록 등의 기능을 사용할 수 있습니다.",
  },
  {
    key: "variablePrice",
    text: "시가 제품 관리",
    default: false,
    description:
      "판매자가 제품의 기준 가격 외 변동 가격을 입력하여 판매가 가능합니다.",
  },
];

export const INVEN_SETTING_OPTIONS = [
  {
    type: "config",
    key: "expiryDateMgmt",
    text: "재고 소비(유통)기한",
    description:
      "재고의 유통기한 설정이 필요한 경우 사용할 수 있는 옵션으로 재고 필터 조회시 해당 유통기한 범주로 검색이 가능합니다.",
  },
];

export const PROD_FILTERS = [
  // {
  // value: "filter_sku",
  // text: "SKU",
  // type: "text"
  // }
];

export const INVENTORY_FILTERS = [
  {
    value: "filter_expiryDate",
    name: "유통기한",
    type: "range",
    id: "expiryDate",
  },
  {
    value: "filter_stockAlertQty",
    name: "재고부족알림 도달제품",
    type: "noInput",
    id: "stockAlertQty",
  },
];

export const INVENTORY_ADD_TYPES = [
  { key: "newStock", text: "신규 재고" },
  { key: "restock", text: "재입고" },
];

export const INVENTORY_REMOVE_TYPES = [
  { key: "damaged", text: "파손 재고" },
  { key: "loss", text: "손실 재고" },
  { key: "remove", text: "임의 차감" },
];

export const INVEN_INIT_SETTING = {
  attributes: [],
  expiryDateMgmt: false,
};

export const TYPE_OPTIONS = [
  { value: "number", label: "숫자만 허용" },
  { value: "string", label: "텍스트만 허용" },
];

export const RANDOM_COLORS = [
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#1A283E",
  "#00526E",
  "#00808C",
  "#00AE8F",
  "#87D87E",
  "#F9F871",
  "#1F93FA",
  "#5F3118",
  "#004946",
];

export const INT_MAX_VALUE = 2147483647;
export const DATE_MAX_VALUE = 1000000000000000;

export const DaysOfWeek = [
  {
    key: "1",
    label: "월요일",
    value: "mon",
  },
  {
    key: "2",
    label: "화요일",
    value: "tue",
  },
  {
    key: "3",
    label: "수요일",
    value: "wed",
  },
  {
    key: "4",
    label: "목요일",
    value: "thu",
  },
  {
    key: "5",
    label: "금요일",
    value: "fri",
  },
  {
    key: "6",
    label: "토요일",
    value: "sat",
  },
  {
    key: "7",
    label: "일요일",
    value: "sun",
  },
];

export const Times = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const TimesStartOne = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

export const INVENTORY_ADJUST_TYPES = [
  {
    label: "입고",
    value: "newStock",
  },
  {
    label: "판매",
    value: "sales",
  },
  {
    label: "파손 재고",
    value: "damaged",
  },
  {
    label: "손실 재고",
    value: "loss",
  },
  {
    label: "임의차감",
    value: "remove",
  },
  {
    label: "임의조정",
    value: "force",
  },
  {
    label: "반품입고",
    value: "returns",
  },
  {
    label: "지점 이동",
    value: "transfer",
  },
];

export const STOCK_ACTION_TYPE = [
  {
    label: "입고",
    value: "newStock",
  },
  {
    label: "임의차감",
    value: "remove",
  },
  {
    label: "파손 재고",
    value: "damaged",
  },
  {
    label: "손실 재고",
    value: "loss",
  },
  {
    label: "임의조정",
    value: "force",
  },
];

export const STOCK_ACTION_TYPE_FOR_MULTI = [
  {
    label: "입고",
    value: "newStock",
  },
  {
    label: "파손 재고",
    value: "damaged",
  },
  {
    label: "손실 재고",
    value: "loss",
  },
  {
    label: "임의조정",
    value: "force",
  },
  {
    label: "지점 이동",
    value: "transfer",
  },
];

export const STOCK_ACTION_FOR_DISUSE = [
  {
    label: "재입고",
    value: "restock",
  },
  {
    label: "손실 재고",
    value: "loss",
  },
];

export const CUSTOM_TYPES = {
  "{{name}}": "name",
  "{{price}}": "price",
  "{{cost}}": "cost",
  "{{model}}": "model",
  "{{attributeName}}": "attributeName",
  "{{expiryDate}}": "expiryDate",
};
