import { combineReducers } from "redux";
import * as actions from "../actions";
import { isBefore, isAfter } from "date-fns";
import moment from "moment";
import { I18n } from "aws-amplify";

import { downloadCSV, getDayName, numberToCurrency } from "../helpers/Utils";
import Authority from "../services/Authority";
import Constants from "../helpers/Constants";
import { getOrderNumber } from "./order";
import _ from "lodash";

const initialState = {
  storeStatus: {
    isFetching: false,
    error: null,
  },
  store: {},
  billingHistoriesStatus: {
    isFetching: false,
    error: null,
  },
  billingHistories: [],
  loyaltyReportStatus: {
    isFetching: false,
    error: null,
  },
  costStatus: {
    isFetching: false,
    error: null,
  },
  cost: {},
  loyaltyReport: {
    customer: {},
    sales: {},
    point: {},
    report: {},
  },
  points: {},
  pointStatus: {
    isFetching: false,
    error: null,
  },
  contract: { store: {}, location: {}, rental: [] },
  templates: [],
  templateStatus: {
    isFetching: false,
    error: null,
  },
  credit: null,
  creditHistories: {
    byId: {},
    count: 0,
  },
  menuStatus: {
    isFetching: false,
    error: null,
  },
};

export const getPointFromCustomer = (
  loyalty,
  customer,
  locationId,
  isMultiLocation
) => {
  if (isMultiLocation && Authority.level() === "location") {
    if (loyalty?.sharePolicy === "individual") {
      for (let loc of customer.locations || []) {
        if (loc.id === locationId) {
          return loc.point;
        }
      }
      return {};
    }
  }
  return customer.point || {};
};

export const getCustomerPointInfo = (
  customer,
  minRequireRate,
  loyalty,
  locationId,
  isMultiLocation
) => {
  if (customer && customer.id && customer.point) {
    let total = (customer.point?.saved || 0) - (customer.point?.used || 0);
    let subTotal = total;
    let stats = {
      saved: customer.point?.saved || 0,
      used: customer.point?.used || 0,
    };
    if (
      isMultiLocation &&
      Authority.level() === "location" &&
      minRequireRate >= 0
    ) {
      stats = getPointFromCustomer(
        loyalty,
        customer,
        locationId,
        isMultiLocation
      );
      subTotal = (stats.saved || 0) - (stats.used || 0) + (stats.extra || 0);
      subTotal = subTotal > total ? total : subTotal;
    }
    return {
      ...customer.point,
      total,
      subTotal,
      stats,
    };
  }
  return { saved: 0, used: 0, total: 0, subTotal: 0 };
};

export const getCustomerCurrentPoint = (
  customer,
  minRequireRate,
  loyalty,
  locationId,
  isMultiLocation
) => {
  const pointInfo = getCustomerPointInfo(
    customer,
    minRequireRate,
    loyalty,
    locationId,
    isMultiLocation
  );
  const isPointShare = minRequireRate > 0;
  return isPointShare ? pointInfo.total : pointInfo.subTotal;
};

export const getCustomerCurrentCredit = (
  customer,
  isMultiLocation,
  locationId
) => {
  let creditInfo = customer.credit;
  if (isMultiLocation && Authority.level() === "location") {
    const currentLocationCustomerInfo =
      (customer?.locations || []).filter(
        (location) => location.id === locationId
      )[0] || {};
    creditInfo = currentLocationCustomerInfo.credit;
  }

  return creditInfo?.balance || 0;
};

export const transactionsExportCsv = (
  type,
  transactions,
  customer,
  locationsById,
  isMultiLocation,
  callback
) => {
  let headers = [];
  let _data = [];
  let fileName;
  let customerPhone = (customer.phone || "").slice(-4);
  let isHead = Authority.level() === "store" && isMultiLocation;
  switch (type) {
    case "point":
      {
        fileName = `${customerPhone} - 포인트 내역`;
        headers = isHead
          ? ["일시", "지점", "적립/사용", "포인트", "비고", "주문번호"]
          : ["일시", "적립/사용", "포인트", "비고", "주문번호"];
        const _scope = "loyalty.customer";
        for (let point of transactions) {
          _data = [
            ..._data,
            isHead
              ? [
                  moment(point.createdAt).format(
                    Constants.MOMENT.DATETIME_FORMAT
                  ),
                  point.provider === "cafe24"
                    ? "자사몰"
                    : locationsById[point.locationId]?.name || "삭제된 지점",
                  I18n.get(
                    `${_scope}.detail.loyalty.${
                      point.type === "use" && point.note === "expired"
                        ? "expired"
                        : point.type
                    }`
                  ),
                  point.point + I18n.get(`${_scope}.symbols.point`),
                  point.note
                    ? point.provider === "cafe24"
                      ? point.note
                      : I18n.get(`${_scope}.detail.note.${point.note}`)
                    : "",
                  getOrderNumber(
                    point.orderId,
                    point.orderNumber,
                    point.orderNumberPrefix
                  )
                    ? `##${getOrderNumber(
                        point.orderId,
                        point.orderNumber,
                        point.orderNumberPrefix
                      )}`
                    : "",
                ]
              : [
                  moment(point.createdAt).format(
                    Constants.MOMENT.DATETIME_FORMAT
                  ),
                  I18n.get(
                    `${_scope}.detail.loyalty.${
                      point.type === "use" && point.note === "expired"
                        ? "expired"
                        : point.type
                    }`
                  ),
                  point.point + I18n.get(`${_scope}.symbols.point`),
                  point.note
                    ? I18n.get(`${_scope}.detail.note.${point.note}`)
                    : "",
                  getOrderNumber(
                    point.orderId,
                    point.orderNumber,
                    point.orderNumberPrefix
                  )
                    ? `##${getOrderNumber(
                        point.orderId,
                        point.orderNumber,
                        point.orderNumberPrefix
                      )}`
                    : "",
                ],
          ];
        }
      }
      break;
    case "credit":
      fileName = `${customerPhone} - 선불금 내역`;
      headers = ["일시", "사용/충전", "금액"];
      headers = isHead
        ? ["일시", "지점", "사용/충전", "금액"]
        : ["일시", "사용/충전", "금액"];
      for (let credit of transactions) {
        _data = [
          ..._data,
          isHead
            ? [
                moment(credit.createdAt).format(
                  Constants.MOMENT.DATETIME_FORMAT
                ),
                locationsById[credit.locationId]?.name || "삭제된 지점",
                getCreditType(credit.type),
                numberToCurrency(credit.amount),
              ]
            : [
                moment(credit.createdAt).format(
                  Constants.MOMENT.DATETIME_FORMAT
                ),
                getCreditType(credit.type),
                numberToCurrency(credit.amount),
              ],
        ];
      }
      break;
    default:
  }
  downloadCSV([headers, ..._data], fileName);
  callback && callback();
};

export const getCreditType = (creditTrasactionType) => {
  switch (creditTrasactionType) {
    case "withdraw":
      return "사용";
    case "deposit":
      return "충전";
  }
};

export const isMarketOpened = (market, hours, isMultiLocation) => {
  const current = new Date();
  const dayName = getDayName(current.toISOString());

  const today = (hours || {})[dayName];
  if (market?.paused || isMultiLocation) {
    return false;
  } else if (today?.open && today?.close) {
    let startDate = new Date();
    startDate.setHours(parseInt(today.open.split(":")[0], 10));
    startDate.setMinutes(parseInt(today.open.split(":")[1], 10));
    let endDate = new Date();
    endDate.setHours(parseInt(today.close.split(":")[0], 10));
    endDate.setMinutes(parseInt(today.close.split(":")[1], 10));
    const isOpened = isAfter(current, startDate) && isBefore(current, endDate);
    return isOpened;
  }
  return false;
};

function billingHistoriesStatus(
  state = initialState.billingHistoriesStatus,
  action
) {
  switch (action.type) {
    case actions.GET_BILLING_HISTORIES_REQUEST:
    case actions.PAY_FAILED_BILLINGS:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_BILLING_HISTORIES_SUCCESS:
      return initialState.billingHistoriesStatus;
    case actions.GET_BILLING_HISTORIES_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function storeStatus(state = initialState.storeStatus, action) {
  // TODO: Reducer 분리가 필요할때 분리
  switch (action.type) {
    case actions.GET_STORE_REQUEST:
    case actions.GENERATE_STORE:
    case actions.UPDATE_STORE_REQUEST:
    case actions.UPDATE_STORE_LOYALTY_REQUEST:
    case actions.REGISTER_OR_UPDATE_ACCOUNT:
    case actions.ACTIVE_REVIEW_CAMPAIGN:
    case actions.INACTIVE_REVIEW_CAMPAIGN:
    case actions.LAUNCH_MULTI_LOCATION:
    case actions.CREATE_OR_UPDATE_MENU_PLAN:
    case actions.DELETE_MENU_PLAN:
    case actions.CREATE_OR_UPDATE_ORDER_PLAN:
    case actions.DELETE_ORDER_PLAN:
    case actions.CREATE_OR_UPDATE_LOCATION:
    case actions.UPDATE_LOCATION:
    case actions.ACTIVE_KIOSK_MODE:
    case actions.INACTIVE_KIOSK_MODE:
    case actions.UPDATE_RECEIPT_CONFIG:
    case actions.UPDATE_VIEW_CONFIG:
    case actions.UPDATE_SETTINGS:
    case actions.CONVERT_POS_MODE:
    case actions.SYNC_EXTERNAL_LOYALTY:
    case actions.GET_CREDIT_STATUS:
    case actions.CHARGE_CREDIT:
    case actions.UPDATE_CREDIT_CONFIG:
    case actions.GET_CREDIT_HISTORIES:
    case actions.UPDATE_STORE_PROVIDER:
    case actions.CREATE_OR_UPDATE_STORE_ATTRIBUTES:
    case actions.UPDATE_STORE_MENUPLAN:
    case actions.UPDATE_MIRROR_CONFIG:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_STORE_SUCCESS:
    case actions.UPDATE_STORE_SUCCESS:
      return initialState.storeStatus;
    case actions.GET_STORE_FAILURE:
    case actions.UPDATE_STORE_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function billingHistories(state = initialState.billingHistories, action) {
  switch (action.type) {
    case actions.INIT_BILLING_HISTORIES:
      return action.billingHistories;
    case actions.RECEIVE_BILLING_HISTORY:
      let histories = [];
      for (let history of state) {
        if (history.createdAt === action.history.createdAt) {
          histories = [...histories, action.history];
        } else {
          histories = [...histories, history];
        }
      }
      return histories;
    case actions.SIGN_OUT:
      return initialState.billingHistories;
    default:
      return state;
  }
}

const store = (state = initialState.store, action) => {
  switch (action.type) {
    case actions.INIT_STORE:
      return action.store;
    case actions.RECEIVE_STORE:
      const _store = _.cloneDeep(action.store);
      return _store;
    case actions.RECEIVE_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    default:
      return state;
  }
};

function pointStatus(state = initialState.pointStatus, action) {
  switch (action.type) {
    case actions.GET_POINT_TRANSACTIONS:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_POINT_TRANSACTIONS_SUCCESS:
      return initialState.pointStatus;
    case actions.GET_POINT_TRANSACTIONS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const points = (state = initialState.points, action) => {
  switch (action.type) {
    case actions.RECEIVE_POINT_TRANSACTIONS:
      return action.transactions;
    case actions.SIGN_OUT:
      return initialState.points;
    default:
      return state;
  }
};

function costStatus(state = initialState.costStatus, action) {
  switch (action.type) {
    case actions.GET_BILLING_COST_REQUEST:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_BILLING_COST_SUCCESS:
      return initialState.costStatus;
    case actions.GET_BILLING_COST_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    case actions.SIGN_OUT:
      return initialState.costStatus;
    default:
      return state;
  }
}

const cost = (state = initialState.cost, action) => {
  switch (action.type) {
    case actions.RECEIVE_BILLING_COST:
      return action.cost;
    case actions.SIGN_OUT:
      return initialState.cost;
    default:
      return state;
  }
};

function contract(state = initialState.contract, action) {
  switch (action.type) {
    case actions.RECEIVE_STORE_CONTRACT:
      return { ...state, store: action.storeContract };
    case actions.SIGN_OUT:
      return initialState.contract;
    default:
      return state;
  }
}

function templates(state = initialState.templates, action) {
  let _templates = state.reduce((obj, temp) => {
    obj[temp.id] = temp;
    return obj;
  }, {});
  switch (action.type) {
    case actions.RECEIVE_LABEL_TEMPLATE:
      _templates[action.template.id] = action.template;
      return Object.values(_templates);
    case actions.RECEIVE_LABEL_TEMPLATES:
      _templates = action.templates.reduce((obj, template) => {
        obj[template.id] = template;
        return obj;
      }, _templates);
      return Object.values(_templates);
    case actions.RECEIVE_DELETED_LABEL_TEMPLATE:
      if (action.id in _templates) {
        delete _templates[action.id];
      }
      return Object.values(_templates);
    case actions.SIGN_OUT:
      return initialState.templates;
    default:
      return state;
  }
}

function templateStatus(state = initialState.templateStatus, action) {
  switch (action.type) {
    case actions.CREATE_OR_UPDATE_LABEL_TEMPLATE:
    case actions.GET_LABEL_TEMPLATES:
    case actions.DELETE_LABEL_TEMPLATE:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_TEMPLATE_STATUS_SUCCESS:
      return initialState.templateStatus;
    case actions.SET_TEMPLATE_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function menuStatus(state = initialState.menuStatus, action) {
  switch (action.type) {
    case actions.SYNC_MENU_BOARD:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SYNC_MENU_BOARD_SUCCESS:
      return initialState.menuStatus;
    case actions.SYNC_MENU_BOARD_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function credit(state = initialState.credit, action) {
  switch (action.type) {
    case actions.RECEIVE_CREDIT_STATUS:
      return action.credit;
    case actions.SIGN_OUT:
      return initialState.credit;
    default:
      return state;
  }
}

function creditHistories(state = initialState.creditHistories, action) {
  let _histories = _.cloneDeep(state.byId);
  switch (action.type) {
    case actions.RECEIVE_CREDIT_HISTORIES:
      return {
        ...state,
        byId: action.items.reduce(
          (obj, his) => {
            obj[his.id] = his;
            return obj;
          },
          action.override ? {} : _histories
        ),
        count: action.count,
      };
    case actions.CLEAR_CREDIT_HISTORIES:
      return initialState.creditHistories;
    default:
      return state;
  }
}

export default combineReducers({
  storeStatus,
  store,
  billingHistories,
  billingHistoriesStatus,
  points,
  pointStatus,
  costStatus,
  cost,
  contract,
  templates,
  templateStatus,
  credit,
  creditHistories,
  menuStatus,
});
