import { combineReducers } from "redux";
import _ from "lodash";

import * as actions from "../actions";
import moment from "moment";
import Constants from "../helpers/Constants";

const initialState = {
  status: {
    isFetching: false,
    error: null,
    action: null,
  },
  byId: {},
  nextToken: null,
  searchedTransactions: {
    byId: {},
    nextToken: null,
  },
  providerById: {},
};

export const getCheckStatus = (selectedCount) => {
  if (selectedCount === 0) {
    return "all";
  } else {
    return "partial";
  }
};

export const isCurrentLocationAvailableCoupon = (
  coupon,
  currentLocationId,
  isPublish
) => {
  if (isPublish) {
    return (
      (coupon.method === "barcode" &&
        ((coupon.publishableLocationIds || []).length === 0 ||
          (coupon.publishableLocationIds || []).includes(currentLocationId) ||
          !currentLocationId)) ||
      (coupon.method !== "barcode" &&
        ((!coupon.locationId &&
          (coupon.activeLocationIds || []).length === 0 &&
          ((coupon.inactiveLocationIds || []).length === 0 ||
            !(coupon.inactiveLocationIds || []).includes(currentLocationId))) ||
          (coupon.activeLocationIds || []).includes(currentLocationId) ||
          coupon.locationId === currentLocationId))
    );
  } else {
    return (
      (!coupon.locationId &&
        (coupon.activeLocationIds || []).length === 0 &&
        ((coupon.inactiveLocationIds || []).length === 0 ||
          !(coupon.inactiveLocationIds || []).includes(currentLocationId))) ||
      (coupon.activeLocationIds || []).includes(currentLocationId) ||
      coupon.locationId === currentLocationId
    );
  }
};

export const brushupBarcodeCouponExpireDate = (expireDate) => {
  if (!expireDate) {
    return expireDate;
  }
  let _expireDate = new Date(expireDate);
  if (
    _expireDate.getHours() === 0 &&
    _expireDate.getMinutes() === 0 &&
    _expireDate.getSeconds() === 0 &&
    _expireDate.getMilliseconds() === 0
  ) {
    _expireDate = new Date(_expireDate.getTime() - 1);
  }

  return _expireDate.toISOString();
};

export const getDiscountValueLabel = (coupon) => {
  switch (coupon.discountMethod) {
    case "rate":
      return `${coupon.discountValue}%`;
    case "amount":
      return `₩${coupon.discountValue}`;
    case "products":
      return "제품 무료 제공";
  }
};

export const getDiscountMethodLabel = (method) => {
  switch (method) {
    case "rate":
      return `%`;
    case "amount":
      return `₩`;
    case "products":
      return "제품 무료 제공";
  }
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.CREATE_COUPON:
    case actions.UPDATE_COUPON:
    case actions.DELETE_COUPON:
    case actions.DELETE_COUPONS:
    case actions.GET_COUPONS:
    case actions.GET_COUPONS_FOR_PUBLISH:
    case actions.GET_COUPON:
    case actions.GET_BARCODE_COUPON:
    case actions.GET_BARCODE_COUPONS:
    case actions.CREATE_COUPON_TRANSACTIONS:
    case actions.DOWNLOAD_CSV_FOR_TRANSACTIONS:
    case actions.GET_BARCODE_COUPON_TRANSACTION:
    case actions.GET_BARCODE_COUPOON_TRANSACTION_BY_PHONE:
    case actions.ISSUE_BARCODE_COUPON_FOR_PROVIDER_CUSTOMER:
    case actions.ISSUE_BARCODE_COUPON_WITH_STORE_CUSTOMER_ID:
    case actions.GET_PROVIDER_COUPONS:
      return {
        isFetching: true,
        error: null,
        action: action.type,
      };
    case actions.SET_COUPON_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_COUPON_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
        action: null,
      };
    default:
      return state;
  }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case actions.RECEIVE_COUPON:
      return {
        ...state,
        [action.coupon.id]: action.coupon,
      };
    case actions.RECEIVE_COUPONS:
      return action.coupons.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
    case actions.RECEIVE_BARCODE_COUPONS:
      return action.coupons.reduce(
        (obj, item) => {
          obj[item.id] = item;
          return obj;
        },
        action.override ? {} : state
      );
    case actions.RECEIVE_DELETED_COUPON:
      const { [action.couponId]: _, ...coupons } = state;
      return coupons;
    case actions.SIGN_OUT:
      return initialState.byId;
    default:
      return state;
  }
}

function searchedTransactions(
  state = initialState.searchedTransactions,
  action
) {
  switch (action.type) {
    case actions.RECEIVE_BARCODE_COUPON_TRANSACTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.transaction.id]: action.transaction,
        },
      };
    case actions.RECEIVE_BARCODE_COUPON_TRANSACTIONS:
      return {
        byId: action.transactions.reduce((obj, tran) => {
          obj[tran.id] = tran;
          return obj;
        }, {}),
        nextToken: action.nextToken,
      };
    case actions.CLEAR_BARCODE_COUPON_TRANSACTION:
      return initialState.searchedTransactions;
    default:
      return state;
  }
}

export const getExpiryDateOfCoupon = (coupon) => {
  switch (coupon.validDateType) {
    case "publish":
      const publishedAt = new Date();
      const expiryDate = new Date(
        publishedAt.getTime() + coupon.validValue * 24 * 60 * 60 * 1000
      );
      return moment(expiryDate).format(Constants.MOMENT.DATE_FORMAT);
    case "expire":
      return moment(
        brushupBarcodeCouponExpireDate(coupon.validValue) || new Date()
      ).format(Constants.MOMENT.DATE_FORMAT);
    default:
      return null;
  }
};

function providerById(state = initialState.providerById, action) {
  switch (action.type) {
    case actions.RECEIVE_PROVIDER_COUPONS:
      return action.coupons.reduce((obj, coupon) => {
        obj[coupon.providerCouponId] = coupon;
        return obj;
      }, {});
    case actions.RECEIVE_PROVIDER_COUPON:
      return {
        ...state,
        [action.coupon.providerCouponId]: action.coupon,
      };
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  status,
  searchedTransactions,
  providerById,
});
