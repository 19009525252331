import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  employee: {},
  employees: {},
  organization: {},
  organizations: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.UPDATE_EMPLOYEE:
    case actions.INVITE_EMPLOYEE:
    case actions.UPDATE_ORGANIZATION_EMPLOYEE:
    case actions.DELETE_ORGANIZATION_EMPLOYEE:
    case actions.CONFIRM_INVITE_EMPLOYEE:
    case actions.GET_ORGNIZATIONS:
    case actions.GET_EMPLOYEE_BY_PURCHASE_ORDER_ID:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_EMPLOYEE_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_EMPLOYEE_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const employee = (state = initialState.employee, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_EMPLOYEE:
      return action.employee;
    case actions.SIGN_OUT:
      return initialState.employee;
    default:
      return state;
  }
};

const employees = (state = initialState.employees, action) => {
  switch (action.type) {
    case actions.RECEIVE_EMPLOYEE:
      return {
        ...state,
        [action.employee.id]: action.employee,
      };
    case actions.RECEIVE_EMPLOYEES:
      return action.employees.reduce((obj, emp) => {
        obj[emp.id] = emp;
        return obj;
      }, state);
    case actions.SIGN_OUT:
      return initialState.employees;
    default:
      return state;
  }
};

const organization = (state = initialState.organization, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_ORGANIZATION:
      return action.organization;
    case actions.SIGN_OUT:
      return initialState.organization;
    default:
      return state;
  }
};

const organizations = (state = initialState.organizations, action) => {
  switch (action.type) {
    case actions.RECEIVE_ORGANIZATIONS:
      return action.organizations;
    case actions.SIGN_OUT:
      return initialState.organizations;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  employee,
  employees,
  organization,
  organizations,
});
