import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  searched: [],
  contract: {
    store: {},
    operator: {},
    contract: {},
    terminals: [],
    rentals: [],
  },
  locations: [],
  searchedStores: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.FIND_EMPLOYEE_BY_EMAIL:
    case actions.GET_MGMT_CONTRACT:
    case actions.FIND_STORES_BY_OPERATOR_ID:
    case actions.CREATE_OR_UPDATE_CONTRACT:
    case actions.CREATE_OR_UPDATE_RENTAL_CONTRACT:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_MGMT_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_MGMT_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const searched = (state = initialState.searched, action) => {
  switch (action.type) {
    case actions.RECEIVE_SEARCHED_MGMT_STORES:
      return action.stores;
    default:
      return state;
  }
};

const contract = (state = initialState.contract, action) => {
  switch (action.type) {
    case actions.RECEIVE_MGMT_CONTRACT:
      return action.contract;
    case actions.CLEAR_CONTRACT:
      return initialState.contract;
    case actions.CLEAR_OPERATOR:
      return { ...state, operator: {} };
    case actions.RECEIVE_UPDATED_CONTRACT:
      return { ...state, contract: action.contract };
    case actions.RECEIVE_OPERATOR:
      return { ...state, operator: action.operator };
    case actions.RECEIVE_RENTALS:
      return { ...state, rentals: action.rentalContracts };
    case actions.RECEIVE_CONTRACT_TERMINAL:
      return {
        ...state,
        terminals: Object.values(
          [...(state.terminals || []), action.terminal].reduce((obj, t) => {
            obj[t.id] = t;
            return obj;
          }, {})
        ),
      };
    case actions.RECEIVE_DELETED_CONTRACT_TERMINAL_ID:
      return {
        ...state,
        terminals: (state.terminals || []).filter(
          (t) => t.id !== action.terminalId
        ),
      };
    default:
      return state;
  }
};

const locations = (state = initialState.locations, action) => {
  switch (action.type) {
    case actions.RECEIVE_MGMT_LOCATIONS:
      return action.locations;
    case actions.CLEAR_MGMT_LOCATIONS:
      return initialState.locations;
    default:
      return state;
  }
};

const searchedStores = (state = initialState.searchedStores, action) => {
  switch (action.type) {
    case actions.RECEIVE_SEARCHED_STORES_BY_OPERATOR_ID:
      return action.stores;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  searched,
  contract,
  locations,
  searchedStores,
});
