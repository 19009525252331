import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  notices: [],
  archives: [],
  campaigns: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.GET_NOTICES:
    case actions.GET_NOTICE_CAMPAIGNS:
    case actions.CREATE_OR_UPDATE_NOTICE:
    case actions.CREATE_OR_UPDATE_NOTICE_CAMPAIGN:
    case actions.GET_ARCHIVED_NOTICES:
    case actions.DELETE_NOTICE:
    case actions.DELETE_NOTICE_CAMPAIGN:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_ARTICLE_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_ARTICLE_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const notices = (state = initialState.notices, action) => {
  switch (action.type) {
    case actions.RECEIVE_NOTICE:
      const _notices = state.reduce((obj, n) => {
        obj[n.id] = n;
        return obj;
      }, {});
      return Object.values({
        ..._notices,
        [action.notice.id]: action.notice,
      }).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
    case actions.RECEIVE_NOTICES:
      return action.notices.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
    case actions.RECEIVE_DELETE_NOTICE:
      const newNotices = state.filter((notice) => {
        return notice.id !== action.notice.id;
      });
      return newNotices;
    case actions.SIGN_OUT:
      return initialState.notices;
    default:
      return state;
  }
};

const archives = (state = initialState.archives, action) => {
  switch (action.type) {
    case actions.RECEIVE_ARCHIVED_NOTICES:
      return action.notices.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
    case actions.SIGN_OUT:
      return initialState.archives;
    default:
      return state;
  }
};

const campaigns = (state = initialState.campaigns, action) => {
  switch (action.type) {
    case actions.RECEIVE_NOTICE_CAMPAIGN:
      const _campaigns = state.reduce((obj, n) => {
        obj[n.id] = n;
        return obj;
      }, {});
      return Object.values({
        ..._campaigns,
        [action.noticeCampaign.id]: action.noticeCampaign,
      }).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
    case actions.RECEIVE_NOTICE_CAMPAIGNS:
      return action.campaigns.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
    case actions.RECEIVE_DELETED_NOTICE_CAMPAIGN:
      const newCampaigns = state.filter((campaign) => {
        return campaign.id !== action.campaign.id;
      });
      return newCampaigns;
    case actions.SIGN_OUT:
      return initialState.campaigns;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  notices,
  campaigns,
  archives,
});
