import React from "react";
import { numberToCurrency, numberWithCommas } from "./Utils";
import { FULL_COUNTRY } from "./fullCountry";

export const DISTRICTS = [
  {
    text: "강원도",
    value: "강원도",
  },
  {
    text: "경기도",
    value: "경기도",
  },
  {
    text: "경상남도",
    value: "경상남도",
  },
  {
    text: "경상북도",
    value: "경상북도",
  },
  {
    text: "광주광역시",
    value: "광주광역시",
  },
  {
    text: "대구광역시",
    value: "대구광역시",
  },
  {
    text: "대전광역시",
    value: "대전광역시",
  },
  {
    text: "부산광역시",
    value: "부산광역시",
  },
  {
    text: "서울특별시",
    value: "서울특별시",
  },
  {
    text: "세종특별자치시",
    value: "세종특별자치시",
  },
  {
    text: "울산광역시",
    value: "울산광역시",
  },
  {
    text: "인천광역시",
    value: "인천광역시",
  },
  {
    text: "전라남도",
    value: "전라남도",
  },
  {
    text: "전라북도",
    value: "전라북도",
  },
  {
    text: "제주특별자치도",
    value: "제주특별자치도",
  },
  {
    text: "충청남도",
    value: "충청남도",
  },
  {
    text: "충청북도",
    value: "충청북도",
  },
];

export const INDUSTRY_TYPE = [
  {
    text: "외식업 - 음식점업",
    value: "food-food",
  },
  {
    text: "외식업 - 디저트",
    value: "food-dessert",
  },
  {
    text: "외식업 - 주점 및 비알코올 음료점업",
    value: "food-drink",
  },
  {
    text: "소매업 - 종합소매업",
    value: "retail-total",
  },
  {
    text: "소매업 - 음, 식료품",
    value: "retail-foodRetail",
  },
  {
    text: "소매업 - 면세점",
    value: "retail-duty",
  },
  {
    text: "소매업 - 패션의류/잡화",
    value: "retail-fashion",
  },
  {
    text: "소매업 - 뷰티용품 판매점",
    value: "retail-beauty",
  },
  {
    text: "소매업 - 출산/유아동",
    value: "retail-babyProducts",
  },
  {
    text: "소매업 - 생활/주방용품",
    value: "retail-lifeProducts",
  },
  {
    text: "소매업 - 가구/인테리어",
    value: "retail-furniture",
  },
  {
    text: "소매업 - 가전 디지털 판매점",
    value: "retail-digitalProducts",
  },
  {
    text: "소매업 - 스포츠/레저용품 판매점",
    value: "retail-sports",
  },
  {
    text: "소매업 - 자동차 용품 판매점",
    value: "retail-vehicle",
  },
  {
    text: "소매업 - 취미/문구/악기",
    value: "retail-hobby",
  },
  {
    text: "소매업 - 도서 판매",
    value: "retail-book",
  },
  {
    text: "소매업 - 반려동물용품",
    value: "retail-animalProducts",
  },
  {
    text: "소매업 - 건강/의료용품",
    value: "retail-medical",
  },
  {
    text: "소매업 - 그외 기타 소매업",
    value: "retail-extraRetail",
  },
  {
    text: "서비스업 - 교육서비스업",
    value: "service-education",
  },
  {
    text: "서비스업 - 부동산업",
    value: "service-realEstate",
  },
  {
    text: "서비스업 - 스포츠 및 오락관련 서비스업",
    value: "service-sports",
  },
  {
    text: "서비스업 - 사진 촬영 및 스튜디오",
    value: "service-photography",
  },
  {
    text: "서비스업 - 숙박업",
    value: "service-accomodation",
  },
  {
    text: "서비스업 - 보건업",
    value: "service-health",
  },
  {
    text: "서비스업 - 미용업",
    value: "service-beauty",
  },
  {
    text: "소매업 - 그 외 기타 서비스업",
    value: "service-extra",
  },
];

export const ORDER_ORIGIN = [
  {
    label: "매장",
    key: "pos",
  },
  {
    label: "키오스크",
    key: "kiosk",
  },
  {
    label: "테이블오더",
    key: "menuorder",
  },
  {
    label: "어라운드마켓",
    key: "market",
  },
  {
    label: "쿠팡이츠",
    key: "coupang",
  },
  {
    label: "배달의 민족",
    key: "baemin",
  },
  {
    label: "요기요",
    key: "yogiyo",
  },
  {
    label: "카페24",
    key: "cafe24",
  },
];

export const PROVIDERORDER_ORIGIN = [
  {
    label: "매장",
    key: "pos",
  },
  {
    label: "키오스크",
    key: "kiosk",
  },
  {
    label: "카페24",
    key: "cafe24",
  },
];

export const ORDER_ORIGIN_FOR_REPORT = [
  {
    label: "매장",
    key: "pos",
  },
  {
    label: "키오스크",
    key: "kiosk",
  },
  {
    label: "테이블오더",
    key: "menuorder",
  },
  {
    label: "어라운드마켓",
    key: "market",
  },
  {
    label: "배달주문",
    key: "delivery",
  },
];

export const PAYMENT_METHOD_FOR_REPORT = [
  {
    label: "현금",
    key: "cash",
  },
  {
    label: "카드",
    key: "card",
  },
  {
    label: "간편결제",
    key: "barcode",
  },
  {
    label: "기타수단",
    key: "extra",
  },
  {
    label: "선불/외상금",
    key: "credit",
  },
];

export const ORDER_ORIGIN_FOR_REPORT_BY_KEY = ORDER_ORIGIN_FOR_REPORT.reduce(
  (obj, o) => {
    obj[o.key] = o;
    return obj;
  },
  {}
);

export const ORDER_ORIGIN_BY_KEY = ORDER_ORIGIN.reduce((obj, o) => {
  obj[o.key] = o;
  return obj;
}, {});

export const PAYMENT_METHOD_FOR_REPORT_BY_KEY =
  PAYMENT_METHOD_FOR_REPORT.reduce((obj, m) => {
    obj[m.key] = m;
    return obj;
  }, {});

export const DATA_COLOR_KEY = {
  method: {
    card: "#006AFF",
    cash: "#3b8dff",
    extra: "#7eb4ff",
    barcode: "#b1d2ff",
    credit: "#d7e8ff",
  },
  origin: {
    pos: "#191D88",
    delivery: "#48D1CC",
    baemin: "#48D1CC",
    coupang: "#7E3F21",
    yogiyo: "#F50048",
    market: "#F4A261",
    kiosk: "#1450A3",
    menuorder: "#337CCF",
    cafe24: "#FFC436",
  },
};

export const LANGUAGE_OPTIONS = [
  { text: "🇰🇷 한국어", value: "ko" },
  { text: "🇺🇸 영어", value: "en" },
  { text: "🇨🇳 중국어", value: "zh" },
  { text: "🇯🇵 일본어", value: "ja" },
];

export const INVENTORY_REASON_OPTIONS = [
  {
    value: "newStock",
    label: "입고",
    description: "재고 수량을 증가시키며, 단가는 매출원가에 반영됩니다.",
  },
  {
    value: "remove",
    label: "임의차감",
    description: "재고 수량만 차감되며, 판매로 취급되지 않습니다.",
  },
  {
    value: "force",
    label: "임의 조정",
    description: "재고 수량을 임의로 지정합니다.",
  },
  {
    value: "damaged",
    label: "파손처리",
    description: "가용 재고 수량에서 차감되며, 불용재고로 분류됩니다.",
  },
  {
    value: "loss",
    label: "손실처리",
    description: "재고 수량을 차감하며, 비용으로 처리됩니다.",
  },
];

export const TABLE_HEADER_FOR_TRANSFER_HISTORY = [
  {
    label: "제품",
    width: "18%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "18%",
    key: "skuName",
  },
  {
    label: "바코드",
    width: "17%",
    key: "unit",
  },
  {
    label: "품목코드(SKU)",
    width: "17%",
    key: "model",
  },
  {
    label: "이동수량",
    width: "15%",
    key: "quantity",
  },
  {
    label: "입고(예정)수량",
    width: "15%",
    key: "receivedQuantity",
  },
];

export const TABLE_HEADER_FOR_COUNT_HISTORY = [
  {
    label: "제품이름",
    width: "15%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "15%",
    key: "skuName",
  },
  {
    label: "바코드",
    width: "13%",
    key: "unit",
  },
  {
    label: "품목코드(SKU)",
    width: "13%",
    key: "model",
  },
  {
    label: "이전 수량",
    width: "10%",
    key: "prevQuantity",
    isQty: true,
    func: numberWithCommas,
  },
  {
    label: "실사수량",
    width: "10%",
    key: "currQuantity",
    isQty: true,
    func: numberWithCommas,
  },
  {
    label: "수량차이",
    width: "10%",
    key: "diff",
    isQty: true,
    func: numberWithCommas,
  },
  {
    label: "비용차이",
    width: "14%",
    key: "totalCost",
    isBold: true,
    isAmount: true,
    func: numberToCurrency,
  },
];

export const CREDIT_CHARGE_LIST = [
  {
    quantity: 3000,
    amount: 15000,
  },
  {
    quantity: 5000,
    amount: 25000,
  },
  {
    quantity: 10000,
    amount: 50000,
  },
  {
    quantity: 20000,
    amount: 90000,
    discount: 0.1,
  },
  {
    quantity: 50000,
    amount: 225000,
    discount: 0.1,
  },
  {
    quantity: 100000,
    amount: 435000,
    discount: 0.13,
  },
];

export const RETURN_REASON = [
  {
    key: "accidental_charge",
    label: "결제 실수",
  },
  {
    key: "product_malfunction",
    label: "제품 불량",
  },
  {
    key: "canceled_order",
    label: "주문 취소",
  },
  {
    key: "fraudulent_charge",
    label: "사기 거래",
  },
  {
    key: "extra",
    label: "기타",
  },
];

export const CAFE24_HELP_GUIDE = {
  type: (
    <>
      <div className="h5 bold">
        품목코드란,
        <div className="h6 normal">
          카페24에서 제공하는 기본 상품코드로 아래 이미지와 같습니다.
          <br />
          옵션제품이 없는 경우, <b>기본정보에 있는 상품코드</b>를 매칭하며
          <br />
          옵션제품이 있는 경우, 옵션제품별 <b>품목코드</b>를 매칭합니다.
          <br />
          <img
            style={{ width: "100%" }}
            src={require("../img/cafe24/id-guide.png")}
          />
        </div>
      </div>
      <div className="h5 bold mt-line">
        자체 품목코드란,
        <div className="h6 normal">
          사업자가 직접 설정하신 자체 상품코드를 말하며,
          <br />
          옵션제품이 없는 경우, <b>기본정보에 있는 자체 상품코드</b>를 매칭하며
          <br />
          옵션제품이 있는 경우, 옵션제품별 <b>자체 품목코드</b>를 매칭합니다.
          <br />
          <img
            style={{ width: "100%" }}
            src={require("../img/cafe24/model-guide.png")}
          />
        </div>
      </div>
    </>
  ),
  includesType: (
    <>
      <div className="h5 bold">
        상품코드란,
        <div className="h6 normal">
          카페24에서 제공하는 기본 상품코드로 아래 이미지와 같습니다.
          <br />
          <img
            style={{ width: "100%" }}
            src={require("../img/cafe24/productId-guide.png")}
          />
        </div>
      </div>
    </>
  ),
  method: (
    <>
      <div className="h5 bold">
        [자동동기화]
        <div className="h6 normal">
          - 카페24의 상품정보들과 그로잉세일즈에 저장돼 있는 상품정보들을
          비교합니다.
          <br />
          - 비교 후 그로잉세일즈에서 조회되지 않는다면 신규 생성을 진행합니다.
          <br />
          <img
            style={{ width: "100%" }}
            src={require("../img/cafe24/method1.png")}
          />
        </div>
        <div className="mt-small" />
        <div className="h6 normal">
          - 반대로 그로잉세일즈에 이미 연동돼 있는 상품이 있다면, 카페24의
          상품정보와 비교합니다.
          <br />
          - 만약 카페24에서 상품이 삭제됐다면, 자동으로 연동이 해제됩니다.
          <br />
          - 연동이 해제되더라도, 그로잉세일즈에는 상품정보는 그대로 유지됩니다.
          <br />
          <img
            style={{ width: "100%" }}
            src={require("../img/cafe24/method2.png")}
          />
        </div>
      </div>
    </>
  ),
  unlink: (
    <>
      <img
        style={{ width: "100%" }}
        src={require("../img/cafe24/unlink_on.png")}
      />

      <div className="mt-small" />

      <img
        style={{ width: "100%" }}
        src={require("../img/cafe24/unlink_off.png")}
      />
    </>
  ),
};

export const REPORT_HELP_GUIDES = {
  standard: (
    <div className="h5">
      매출 기준: <b>총 판매금액 - 총 환불금액</b>
      <br />
      <br />
      결제금액 기준: 고객의 최종 결제금액,
      <b> 실결제금액 또는 순매출에 해당</b>
    </div>
  ),
  newCount: (
    <div className="h5">
      현금으로 결제한 고객 혹은 새로운 결제수단(카드, 간편결제 등)을 사용한
      고객들이 신규고객으로 분류됩니다.
    </div>
  ),
  revisitCount: (
    <div className="h5">
      (재방문한 등록고객 + 동일카드*로 결제하신 손님) / 전체 주문 수
      <br />* 동일카드란, 이전 매장에서 동일한 카드로 결제했던 이력이 있는
      카드를 말합니다.
    </div>
  ),
  pointUse: (
    <div className="h5">
      00시 포인트 만료처리로 사용데이터가 많아 보일수 있습니다.
      <br />
      유효기간이 지난 포인트의 만료도 사용처리로 집계됩니다.
    </div>
  ),
  method: (
    <div className="h5">
      <b>카드사 조회시 유의사항</b>
      <br />
      그로잉세일즈 앱 81버전, PC 43버전부터 조회가능합니다.
      <br />
      <br />
      <b>기타수단 조회시 유의사항</b>
      <br />
      기타수단 결제시 이름이 입력된 수단만 집계됩니다.
    </div>
  ),
  cost: (
    <div className="h5">
      <b>매출원가 조회시 유의사항</b>
      <br />
      판매표 내 매출원가는 현재 제품정보에 저장된 단가를 기준으로 계산되며,
      단가를 변경할시 매출원가 정보가 변경될 수 있습니다.
      <br />
      선입선출 방식의 매출원가 리포트는 재고리포트에서 조회 가능합니다.
      <br />
    </div>
  ),
};

export const CHERRY_GUIDE = {
  cherry: (
    <div className="h6">
      <b>현재등급</b>: 마지막 등급 업데이트후 조정된 등급
      <br />
      <br />
      <b>추정등급</b>: 현재 주문(결제, 환불내역)을 기준으로 등급업데이트 반영시
      추정되는 등급산정시
      <br />
      <br />
      <b>등급산정시 결제금액</b>: 마지막 등급 업데이트시 반영된 실결제금액
      <br />
      <br />
      <b>등급산정후 환불금액</b>: 마지막 등급 업데이트시 반영된 주문 중
      환불처리된 결제금액
      <br />
      <br />
      <b>등급산정후 결제금액</b>: 마지막 등급 업데이트 이후 추가된 결제금액
      <br />
      등급 산정후 결제금액은 지난 등급 업데이트시 산정된 결제금액 중 부득이한
      사정으로 환불한 경우가 있을수 있으므로, 추가된 결제금액을 참조하여
      체리피커 여부를 판단하기 위해 제공되는 데이터임
    </div>
  ),
};

export const PROVIDER_CONDITION_OPTIONS = [
  {
    value: "lastVisit",
    label: "마지막 주문",
    field: "createdAt",
    object: "order",
  },
  {
    value: "orderAmount",
    label: "총 결제금액",
    field: "amount",
    object: "order",
  },
  {
    value: "visitCount",
    label: "총 주문횟수",
    field: "count",
    object: "order",
  },
  // TODO:
  // {
  //   value: "tier",
  //   label: "고객등급",
  //   field: "tier",
  // },
];

export const CONDITION_OPTIONS = [
  {
    value: "lastVisit",
    label: "마지막 주문",
    field: "createdAt",
    object: "order",
  },
  {
    value: "orderAmount",
    label: "총 결제금액",
    field: "amount",
    object: "order",
  },
  {
    value: "visitCount",
    label: "총 주문횟수",
    field: "count",
    object: "order",
  },
];

export const CONDITION_OPTIONS_FOR_MULTI = [
  {
    value: "orderLocationId",
    label: "방문지점",
    field: "orderLocationId",
    object: "order",
  },
];

export const getConditionLabelByKey = (key) => {
  let conditionsByKey = [
    ...PROVIDER_CONDITION_OPTIONS,
    ...CONDITION_OPTIONS_FOR_MULTI,
  ].reduce((obj, c) => {
    obj[c.value] = c.label;
    return obj;
  }, {});
  return conditionsByKey[key] ? conditionsByKey[key] : "";
};

export const OPERATION_LABEL = {
  gte: "이상",
  lte: "이하",
  gt: "초과",
  lt: "미만",
  eq: "일치",
  ne: "불일치",
};

export const OPERATION_LABEL_FOR_DATE = {
  gt: "이후",
  lt: "이전",
  gte: "이후",
  lte: "이전",
};

export const OPERATION_LABEL_FOR_LOCATION = {
  eq: "포함",
  ne: "미포함",
};

export const CONDITION_OPERATION = {
  orderAmount: [
    {
      operation: "gte",
      type: "number",
      value: null,
    },
    {
      operation: "lte",
      type: "number",
      value: null,
    },
  ],
  lastVisit: [
    {
      operation: "gt",
      type: "string",
      value: null,
    },
    {
      operation: "lt",
      type: "string",
      value: null,
    },
  ],
  visitCount: [
    {
      operation: "gte",
      type: "number",
      value: null,
    },
    {
      operation: "lte",
      type: "number",
      value: null,
    },
  ],
  tier: [
    {
      operation: "eq",
      type: "string",
      value: null,
    },
    {
      operation: "ne",
      type: "string",
      value: null,
    },
  ],
  orderLocationId: [
    {
      operation: "eq",
      type: "string",
      value: null,
    },
    // {
    //   operation: "ne",
    //   type: "string",
    //   value: null,
    // },
  ],
};

export const CAMPAIGN_METHODS = [
  {
    key: "alimtalk",
    keys: ["alimtalk"],
    icon: require("../img/icon/alimtalk.png"),
    title: "알림톡 캠페인",
    body: (
      <>
        주어진 템플릿을 활용하여 카카오 알림톡 메시지를 발송할 수 있습니다.
        <br />
        <br />
        비용 안내
        <br />- 2크레딧 / 건당
      </>
    ),
  },
  {
    key: "text",
    keys: ["text"],
    icon: require("../img/icon/sms.png"),
    title: "문자 캠페인",
    body: (
      <>
        SMS, LMS, MMS 의 일반 문자메시지를 발송할 수 있습니다.
        <br />
        <br />
        비용 안내
        <br />
        - SMS: 2크레딧 / 건당
        <br />- LMS: 6크레딧 / 건당
        <br />- MMS: 12크레딧 / 건당
      </>
    ),
  },
  {
    key: "friendtalk",
    keys: ["friendtalk"],
    icon: require("../img/icon/friendtalk.png"),
    title: "친구톡 캠페인",
    body: (
      <>
        연동하신 카카오 비즈니스채널을 친구로 추가한 고객들에게 친구톡 발송이
        가능합니다.
        <br />
        <br />
        비용 안내
        <br />
        - 일반이미지: 6크레딧 / 건당
        <br />- 와이드이미지: 7크레딧 / 건당
      </>
    ),
  },
];

export const CAMPAIGN_TEMPLATES = {
  text: [
    {
      key: "sms",
      title: "SMS",
      body: (
        <>
          - 한글 45자 내외
          <br />- 건당 2캔디
        </>
      ),
    },
    {
      key: "lms",
      title: "LMS",
      body: (
        <>
          - 한글 1000자 내외
          <br />- 건당 6캔디
        </>
      ),
    },
    {
      key: "mms",
      title: "MMS",
      body: (
        <>
          - 한글 1000자 내외
          <br />- 건당 12캔디
          <br />- 이미지 첨부 가능
        </>
      ),
    },
  ],
  friendtalk: [
    {
      key: "normal",
      title: "일반 이미지형",
      body: (
        <>
          <div className="body-image body-image-friendtalk-normal">
            <img
              src={require("../img/guide/friendtalk-normal.png")}
              className="w-100"
            />
          </div>
          <br />
          - 일반 이미지 + 텍스트 + 링크 유형
          <br />
          - 최대 400자 입력 가능
          <br />- 링크 버튼 5개 추가 가능
        </>
      ),
    },
    {
      key: "wide",
      title: "와이드 이미지형",
      body: (
        <>
          <div className="body-image body-image-friendtalk-wide">
            <img
              src={require("../img/guide/friendtalk-wide.png")}
              className="w-100"
            />
          </div>
          <br />
          - 일반 이미지 + 텍스트 + 링크 유형
          <br />
          - 최대 76자 입력 가능
          <br />- 링크 버튼 1개 추가 가능
        </>
      ),
    },
  ],
  alimtalk: [
    {
      key: "regularCustomerCoupon",
      title: "단골손님 쿠폰 발송",
      body: (
        <>
          <div className="body-image body-image-alimtalk">
            <img
              src={require("../img/campaign/regular-coupon.png")}
              className="w-100"
            />
          </div>
          <br />
          - 바코드 쿠폰 발송 캠페인
          <br />- 단골 손님을 대상으로 한 감사쿠폰 증정
          <br />- 건당 2캔디
        </>
      ),
    },
    {
      key: "monthCoupon",
      title: "월간 쿠폰 발송",
      body: (
        <>
          <div className="body-image body-image-alimtalk">
            <img
              src={require("../img/campaign/month-coupon.png")}
              className="w-100"
            />
          </div>
          <br />
          - 바코드 쿠폰 발송 캠페인
          <br />- 한 달동안 사용할 수 있는 월간 할인쿠폰 증정
          <br />- 건당 2캔디
        </>
      ),
    },
    {
      key: "expireCoupon",
      disabled: true,
      title: "쿠폰만료 알림 발송",
      body: (
        <>
          <div className="body-image body-image-alimtalk">
            <img
              src={require("../img/campaign/expire-coupon.png")}
              className="w-100"
            />
          </div>
          <br />
          - 쿠폰만료 시기에 맞춰 만료 알림 발송
          <br />- 쿠폰 사용유도를 하여 매출증진 효과
          <br />- 건당 2캔디
        </>
      ),
    },
    {
      key: "invitation",
      disabled: true,
      title: "행사 초대장 발송",
      body: (
        <>
          <div className="body-image body-image-alimtalk">
            <img
              src={require("../img/campaign/invitation.png")}
              className="w-100"
            />
          </div>
          <br />
          - 오프라인 행사 초대를 위한 초대장 발송
          <br />- 팝업, 오프라인 이벤트시 행사 참여유도
          <br />- 건당 2캔디
        </>
      ),
    },
  ],
};

export const MESSAGE_LIMIT_LENGTH = {
  sms: 90,
  lms: 2000,
  mms: 2000,
  wide: 150,
  normal: 800,
  title: 40,
};

export const MONTH_OPTIONS = [
  {
    value: "1",
    label: "1월",
  },
  {
    value: "2",
    label: "2월",
  },
  {
    value: "3",
    label: "3월",
  },
  {
    value: "4",
    label: "4월",
  },
  {
    value: "5",
    label: "5월",
  },
  {
    value: "6",
    label: "6월",
  },
  {
    value: "7",
    label: "7월",
  },
  {
    value: "8",
    label: "8월",
  },
  {
    value: "9",
    label: "9월",
  },
  {
    value: "10",
    label: "10월",
  },
  {
    value: "11",
    label: "11월",
  },
  {
    value: "12",
    label: "12월",
  },
];

export const getAlimtalkTemplateLabel = (templateCode) => {
  switch (templateCode) {
    case "regularCustomerCoupon":
      return "단골손님 쿠폰 발송";
    case "monthCoupon":
      return "월간 쿠폰 발송";
    default:
      return "";
  }
};

export const getAlimtalkImage = (templateCode) => {
  switch (templateCode) {
    case "regularCustomerCoupon":
      return require("../img/campaign/regular-coupon.png");
    case "monthCoupon":
      return require("../img/campaign/month-coupon.png");
    default:
      return "";
  }
};

export const SHIPPING_COMPANY_BY_CODE = {
  6: "CJ대한통운",
  12: "우체국택배",
  13: "우체국(등기,소포 우편)",
  18: "한진택배",
  19: "롯데택배",
  1076: "농협택배",
  1078: "CU편의점택배",
  62: "GS Postbox(CVSnet)",
  75: "TNT 익스프레스",
  4: "로젠택배",
  16: "일양택배",
  5: "삼성택배(HTH)",
  2: "건영택배",
  7: "아주택배",
  9: "오렌지택배",
  10: "오세기고구려",
  11: "용마로지스",
  14: "이젠택배",
  15: "이클라인",
  17: "트라넷",
  21: "KGB특급택배",
  22: "KGB택배",
  23: "KL-Net 택배",
  24: "LOEX 택배",
  25: "일반택배",
  26: "DHL",
  27: "FedEx",
  28: "UPS",
  29: "국제특급 EMS",
  30: "국제특급 EMS 프리미엄",
  31: "국제항공소포",
  32: "국제선편소포",
  33: "선편소형포장물",
  34: "항공소형포장물",
  35: "SAL소포",
  36: "벨익스프레스",
  37: "우리택배",
  38: "코덱스택배",
  39: "경동택배",
  40: "스피디익스프레스",
  41: "고구려택배",
  42: "양양택배",
  43: "대신택배",
  44: "천일택배",
  45: "하이브랜로지스",
  46: "호남택배",
  47: "동서택배",
  48: "한국택배",
  49: "이트랜스택배",
  50: "이패밀리",
  51: "하나로택배",
  52: "WPX택배",
  53: "메일렉스코리아",
  55: "SEDEX(신세계)",
  56: "SC로지스",
  57: "굿모닝택배",
  58: "정안CLS",
  59: "AirboyExpress",
  60: "퀵퀵닷컴",
  61: "이노지스",
  63: "위즈와",
  64: "포시즌익스프레스",
  65: "인로스",
  66: "USX 국제특송",
  67: "한진화물",
  68: "ACI Express",
  69: "바익스",
  70: "인티박스",
  71: "USPS",
  72: "First Air Express",
  73: "hddt(HDL)",
  74: "GTX 로지스",
  76: "GSM",
  77: "합동택배",
  78: "GPS lgx",
  79: "롯데글로벌로지스",
  80: "MIT EXPRESS CO., LTD",
  81: "FASTBOX",
  82: "CAINIAO",
  83: "사가와 익스프레스(SAGAWA EXPRESS)",
  84: "야마토 운송(YAMATO TRANSPORT)",
  85: "순평(SF)",
  86: "LX판토스",
  87: "RINCOS",
  88: "야마토택배",
  89: "STO Express",
  90: "한진 Fedex",
  91: "사가와택배(飛脚宅配便)",
  93: "중통택배",
  94: "OCS",
  95: "WEX24(SMART POST)",
  96: "야마토(黑貓宅急便)",
  98: "Japan Post Service (EMS)",
  99: "고쿠사이 익스프레스",
  107: "Seino Transportation",
  213: "SLX택배",
  214: "한의사랑",
  215: "한덱스",
  216: "굿투럭",
  217: "KGL네트웍스",
  218: "I-Parcel",
  219: "ACE Express",
  220: "성원글로벌",
  221: "스마트로지스",
  222: "큐익스프레스",
  223: "워펙스",
  224: "퍼레버택배",
  225: "우리동네택배",
  226: "자이언트",
  227: "씨웨이",
  228: "홈이노베이션",
  229: "브리지로지스",
  230: "시알로지텍",
  231: "두발히어로",
  232: "로지스파트너",
  233: "카카오 T 당일배송",
  234: "컬리넥스트마일",
  235: "투데이",
  237: "핑퐁",
  238: "ARGO",
  239: "JoomLogistics",
  240: "팀프레시",
  241: "우리한방택배",
  242: "용성종합물류",
  243: "프레딧(HY)",
  244: "딜리박스",
  245: "ENVYCUBE",
  246: "ENVYCUBE Light",
  247: "홈픽",
  248: "쉽터",
  249: "케이티 익스프레스",
  250: "홈픽 오늘도착",
  251: "Qxpress economy",
  252: "EFS",
  253: "델레오",
  1003: "CATS YTO express logistics",
  1070: "FASTBOX(연동)",
  1071: "Fastbox Mail",
  1072: "Fastbox Economy",
  1073: "Fastbox Standard",
  1074: "Fastbox Premium",
  1075: "세방택배",
  1077: "FamilyMart Pickup",
  1079: "도도플렉스당일도착",
  1080: "글로벌 해상 특송",
  1081: "Pantos",
  1082: "Qxpress",
  1083: "Qprime",
  1084: "GSI Express",
  1088: "로지스밸리(logisvalley)",
  9996: "오늘도착(원더스)",
  9997: "퀵배송",
  9998: "당일배송",
};

export const DAUM_SCRIPT_URL =
  "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

export const COUNTRY_FLAG_IMAGE_URL_BY_CODE = FULL_COUNTRY.reduce((obj, c) => {
  obj[c.alpha3] = c.file_url;
  return obj;
}, {});

export const COUNTRY_3_EMOJI_DICT = {
  IND: "🇮🇳",
  NGA: "🇳🇬",
  KEN: "🇰🇪",
  ITA: "🇮🇹",
  USA: "🇺🇸",
  CUB: "🇨🇺",
  GBR: "🇬🇧",
  ZAF: "🇿🇦",
  POL: "🇵🇱",
  GHA: "🇬🇭",
  MAR: "🇲🇦",
  IDN: "🇮🇩",
  ESP: "🇪🇸",
  BRA: "🇧🇷",
  DEU: "🇩🇪",
  FRA: "🇫🇷",
  ARE: "🇦🇷",
  CMR: "🇨🇲",
  MEX: "🇲🇪",
  UZB: "🇺🇿",
  IRN: "🇮🇷",
  SAU: "🇸🇦",
  TUR: "🇹🇷",
  CHL: "🇨🇱",
  TZA: "🇹🇿",
  UKR: "🇺🇦",
  NPL: "🇳🇵",
  PAK: "🇵🇰",
  PER: "🇵🇪",
  UGA: "🇺🇬",
  COL: "🇨🇴",
  AUS: "🇦🇺",
  AUT: "🇦🇹",
  ARG: "🇦🇷",
  ETH: "🇪🇹",
  CHN: "🇨🇳",
  RUS: "🇷🇺",
  PHL: "🇵🇭",
  VEN: "🇻🇪",
  MYS: "🇲🇾",
  GRC: "🇬🇷",
  CAN: "🇨🇦",
  BGD: "🇧🇩",
  ZWE: "🇿🇼",
  JPN: "🇯🇵",
  THA: "🇹🇭",
  LKA: "🇱🇰",
  ROU: "🇷🇴",
  ISR: "🇮🇱",
  VNM: "🇻🇳",
  NZL: "🇳🇿",
  NLD: "🇳🇱",
  EGY: "🇪🇬",
  ECU: "🇪🇨",
  SGP: "🇸🇬",
  DOM: "🇩🇴",
  GEO: "🇬🇪",
  DZA: "🇩🇿",
  KAZ: "🇰🇿",
  KOR: "🇰🇷",
  BEL: "🇧🇪",
  LBN: "🇱🇧",
  CZE: "🇨🇿",
  QAT: "🇶🇦",
  TJK: "🇹🇯",
  MDV: "🇲🇻",
  IRQ: "🇮🇶",
  HKG: "🇭🇰",
  SWE: "🇸🇪",
  TWN: "🇹🇼",
  AZE: "🇦🇿",
  TUN: "🇹🇳",
  ARM: "🇦🇲",
  KHM: "🇰🇭",
  KWT: "🇰🇼",
  NOR: "🇳🇴",
  HRV: "🇭🇷",
  MDA: "🇲🇩",
  FIN: "🇫🇮",
  CHE: "🇨🇭",
  ALB: "🇦🇱",
  LAO: "🇱🇦",
  PRT: "🇵🇹",
  BWA: "🇧🇼",
  BRN: "🇧🇳",
  MMR: "🇲🇲",
  DNK: "🇩🇰",
  HUN: "🇭🇺",
  IRL: "🇮🇪",
  MNG: "🇲🇳",
  SVK: "🇸🇰",
  OMN: "🇴🇲",
  LUX: "🇱🇺",
  CYP: "🇨🇾",
  BGR: "🇧🇬",
  CIV: "🇨🇮",
  BTN: "🇧🇹",
  BLR: "🇧🇾",
};

export const COUNTRIES = [
  { name: "Afghanistan", alpha2: "AF", alpha3: "AFG" },
  { name: "Albania", alpha2: "AL", alpha3: "ALB" },
  { name: "Algeria", alpha2: "DZ", alpha3: "DZA" },
  { name: "American Samoa", alpha2: "AS", alpha3: "ASM" },
  { name: "Andorra", alpha2: "AD", alpha3: "AND" },
  { name: "Angola", alpha2: "AO", alpha3: "AGO" },
  { name: "Anguilla", alpha2: "AI", alpha3: "AIA" },
  { name: "Antigua and Barbuda", alpha2: "AG", alpha3: "ATG" },
  { name: "Argentina", alpha2: "AR", alpha3: "ARG" },
  { name: "Armenia", alpha2: "AM", alpha3: "ARM" },
  { name: "Aruba", alpha2: "AW", alpha3: "ABW" },
  { name: "Australia", alpha2: "AU", alpha3: "AUS" },
  { name: "Austria", alpha2: "AT", alpha3: "AUT" },
  { name: "Azerbaijan", alpha2: "AZ", alpha3: "AZE" },
  { name: "Bahamas", alpha2: "BS", alpha3: "BHS" },
  { name: "Bahrain", alpha2: "BH", alpha3: "BHR" },
  { name: "Bangladesh", alpha2: "BD", alpha3: "BGD" },
  { name: "Barbados", alpha2: "BB", alpha3: "BRB" },
  { name: "Belarus", alpha2: "BY", alpha3: "BLR" },
  { name: "Belgium", alpha2: "BE", alpha3: "BEL" },
  { name: "Belize", alpha2: "BZ", alpha3: "BLZ" },
  { name: "Benin", alpha2: "BJ", alpha3: "BEN" },
  { name: "Bermuda", alpha2: "BM", alpha3: "BMU" },
  { name: "Bhutan", alpha2: "BT", alpha3: "BTN" },
  { name: "Bolivia", alpha2: "BO", alpha3: "BOL" },
  { name: "Bosnia and Herzegovina", alpha2: "BA", alpha3: "BIH" },
  { name: "Botswana", alpha2: "BW", alpha3: "BWA" },
  { name: "Brazil", alpha2: "BR", alpha3: "BRA" },
  { name: "British Virgin Islands", alpha2: "VG", alpha3: "VGB" },
  { name: "Brunei Darussalam", alpha2: "BN", alpha3: "BRN" },
  { name: "Bulgaria", alpha2: "BG", alpha3: "BGR" },
  { name: "Burkina Faso", alpha2: "BF", alpha3: "BFA" },
  { name: "Burundi", alpha2: "BI", alpha3: "BDI" },
  { name: "Cambodia", alpha2: "KH", alpha3: "KHM" },
  { name: "Cameroon", alpha2: "CM", alpha3: "CMR" },
  { name: "Canada", alpha2: "CA", alpha3: "CAN" },
  { name: "Cape Verde", alpha2: "CV", alpha3: "CPV" },
  { name: "Central African Republic", alpha2: "CF", alpha3: "CAF" },
  { name: "Chad", alpha2: "TD", alpha3: "TCD" },
  { name: "Chile", alpha2: "CL", alpha3: "CHL" },
  { name: "China", alpha2: "CN", alpha3: "CHN" },
  { name: "Hong Kong", alpha2: "HK", alpha3: " HKG" },
  { name: "Macao", alpha2: "MO", alpha3: "MAC" },
  { name: "Colombia", alpha2: "CO", alpha3: "COL" },
  { name: "Comoros", alpha2: "KM", alpha3: "COM" },
  { name: "Congo", alpha2: "CG", alpha3: "COG" },
  { name: "Costa Rica", alpha2: "CR", alpha3: "CRI" },
  { name: "Côte d'Ivoire", alpha2: "CI", alpha3: "CIV" },
  { name: "Croatia", alpha2: "HR", alpha3: "HRV" },
  { name: "Cuba", alpha2: "CU", alpha3: "CUB" },
  { name: "Cyprus", alpha2: "CY", alpha3: "CYP" },
  { name: "Czech Republic", alpha2: "CZ", alpha3: "CZE" },
  { name: "Denmark", alpha2: "DK", alpha3: "DNK" },
  { name: "Djibouti", alpha2: "DJ", alpha3: "DJI" },
  { name: "Dominica", alpha2: "DM", alpha3: "DMA" },
  { name: "Dominican Republic", alpha2: "DO", alpha3: "DOM" },
  { name: "Ecuador", alpha2: "EC", alpha3: "ECU" },
  { name: "Egypt", alpha2: "EG", alpha3: "EGY" },
  { name: "El Salvador", alpha2: "SV", alpha3: "SLV" },
  { name: "Equatorial Guinea", alpha2: "GQ", alpha3: "GNQ" },
  { name: "Eritrea", alpha2: "ER", alpha3: "ERI" },
  { name: "Estonia", alpha2: "EE", alpha3: "EST" },
  { name: "Ethiopia", alpha2: "ET", alpha3: "ETH" },
  { name: "European Union", alpha2: "EU", alpha3: "" },
  { name: "Faroe Islands", alpha2: "FO", alpha3: "FRO" },
  { name: "Fiji", alpha2: "FJ", alpha3: "FJI" },
  { name: "Finland", alpha2: "FI", alpha3: "FIN" },
  { name: "France", alpha2: "FR", alpha3: "FRA" },
  { name: "French Guiana", alpha2: "GF", alpha3: "GUF" },
  { name: "French Polynesia", alpha2: "PF", alpha3: "PYF" },
  { name: "Gabon", alpha2: "GA", alpha3: "GAB" },
  { name: "Gambia", alpha2: "GM", alpha3: "GMB" },
  { name: "Georgia", alpha2: "GE", alpha3: "GEO" },
  { name: "Germany", alpha2: "DE", alpha3: "DEU" },
  { name: "Ghana", alpha2: "GH", alpha3: "GHA" },
  { name: "Greece", alpha2: "GR", alpha3: "GRC" },
  { name: "Greenland", alpha2: "GL", alpha3: "GRL" },
  { name: "Grenada", alpha2: "GD", alpha3: "GRD" },
  { name: "Guadeloupe", alpha2: "GP", alpha3: "GLP" },
  { name: "Guam", alpha2: "GU", alpha3: "GUM" },
  { name: "Guatemala", alpha2: "GT", alpha3: "GTM" },
  { name: "Guinea-Bissau", alpha2: "GW", alpha3: "GNB" },
  { name: "Haiti", alpha2: "HT", alpha3: "HTI" },
  { name: "Honduras", alpha2: "HN", alpha3: "HND" },
  { name: "Hungary", alpha2: "HU", alpha3: "HUN" },
  { name: "Iceland", alpha2: "IS", alpha3: "ISL" },
  { name: "India", alpha2: "IN", alpha3: "IND" },
  { name: "Indonesia", alpha2: "ID", alpha3: "IDN" },
  { name: "Iran (Islamic Republic of)", alpha2: "IR", alpha3: "IRN" },
  { name: "Iraq", alpha2: "IQ", alpha3: "IRQ" },
  { name: "Ireland", alpha2: "IE", alpha3: "IRL" },
  { name: "Israel", alpha2: "IL", alpha3: "ISR" },
  { name: "Italy", alpha2: "IT", alpha3: "ITA" },
  { name: "Japan", alpha2: "JP", alpha3: "JPN" },
  { name: "Jordan", alpha2: "JO", alpha3: "JOR" },
  { name: "Kazakhstan", alpha2: "KZ", alpha3: "KAZ" },
  { name: "Kenya", alpha2: "KE", alpha3: "KEN" },
  { name: "Kiribati", alpha2: "KI", alpha3: "KIR" },
  { name: "Korea", alpha2: "KP", alpha3: "PRK" },
  { name: "Korea", alpha2: "KR", alpha3: "KOR" },
  { name: "Kuwait", alpha2: "KW", alpha3: "KWT" },
  { name: "Kyrgyzstan", alpha2: "KG", alpha3: "KGZ" },
  { name: "Lao PDR", alpha2: "LA", alpha3: "LAO" },
  { name: "Latvia", alpha2: "LV", alpha3: "LVA" },
  { name: "Lebanon", alpha2: "LB", alpha3: "LBN" },
  { name: "Lesotho", alpha2: "LS", alpha3: "LSO" },
  { name: "Liberia", alpha2: "LR", alpha3: "LBR" },
  { name: "Libya", alpha2: "LY", alpha3: "LBY" },
  { name: "Liechtenstein", alpha2: "LI", alpha3: "LIE" },
  { name: "Lithuania", alpha2: "LT", alpha3: "LTU" },
  { name: "Luxembourg", alpha2: "LU", alpha3: "LUX" },
  { name: "Madagascar", alpha2: "MG", alpha3: "MDG" },
  { name: "Malawi", alpha2: "MW", alpha3: "MWI" },
  { name: "Malaysia", alpha2: "MY", alpha3: "MYS" },
  { name: "Maldives", alpha2: "MV", alpha3: "MDV" },
  { name: "Mali", alpha2: "ML", alpha3: "MLI" },
  { name: "Malta", alpha2: "MT", alpha3: "MLT" },
  { name: "Marshall Islands", alpha2: "MH", alpha3: "MHL" },
  { name: "Martinique", alpha2: "MQ", alpha3: "MTQ" },
  { name: "Mauritania", alpha2: "MR", alpha3: "MRT" },
  { name: "Mauritius", alpha2: "MU", alpha3: "MUS" },
  { name: "Mexico", alpha2: "MX", alpha3: "MEX" },
  { name: "Micronesia, Federated States of", alpha2: "FM", alpha3: "FSM" },
  { name: "Moldova", alpha2: "MD", alpha3: "MDA" },
  { name: "Monaco", alpha2: "MC", alpha3: "MCO" },
  { name: "Mongolia", alpha2: "MN", alpha3: "MNG" },
  { name: "Montenegro", alpha2: "ME", alpha3: "MNE" },
  { name: "Montserrat", alpha2: "MS", alpha3: "MSR" },
  { name: "Morocco", alpha2: "MA", alpha3: "MAR" },
  { name: "Mozambique", alpha2: "MZ", alpha3: "MOZ" },
  { name: "Myanmar", alpha2: "MM", alpha3: "MMR" },
  { name: "Namibia", alpha2: "NA", alpha3: "NAM" },
  { name: "Nauru", alpha2: "NR", alpha3: "NRU" },
  { name: "Nepal", alpha2: "NP", alpha3: "NPL" },
  { name: "Netherlands", alpha2: "NL", alpha3: "NLD" },
  { name: "Netherlands Antilles", alpha2: "AN", alpha3: "ANT" },
  { name: "New Caledonia", alpha2: "NC", alpha3: "NCL" },
  { name: "New Zealand", alpha2: "NZ", alpha3: "NZL" },
  { name: "Nicaragua", alpha2: "NI", alpha3: "NIC" },
  { name: "Niger", alpha2: "NE", alpha3: "NER" },
  { name: "Nigeria", alpha2: "NG", alpha3: "NGA" },
  { name: "Northern Mariana Islands", alpha2: "MP", alpha3: "MNP" },
  { name: "Norway", alpha2: "NO", alpha3: "NOR" },
  { name: "Oman", alpha2: "OM", alpha3: "OMN" },
  { name: "Pakistan", alpha2: "PK", alpha3: "PAK" },
  { name: "Palau", alpha2: "PW", alpha3: "PLW" },
  { name: "Palestinian Territory", alpha2: "PS", alpha3: "PSE" },
  { name: "Panama", alpha2: "PA", alpha3: "PAN" },
  { name: "Papua New Guinea", alpha2: "PG", alpha3: "PNG" },
  { name: "Paraguay", alpha2: "PY", alpha3: "PRY" },
  { name: "Peru", alpha2: "PE", alpha3: "PER" },
  { name: "Philippines", alpha2: "PH", alpha3: "PHL" },
  { name: "Pitcairn", alpha2: "PN", alpha3: "PCN" },
  { name: "Poland", alpha2: "PL", alpha3: "POL" },
  { name: "Portugal", alpha2: "PT", alpha3: "PRT" },
  { name: "Puerto Rico", alpha2: "PR", alpha3: "PRI" },
  { name: "Qatar", alpha2: "QA", alpha3: "QAT" },
  { name: "Réunion", alpha2: "RE", alpha3: "REU" },
  { name: "Romania", alpha2: "RO", alpha3: "ROU" },
  { name: "Russian Federation", alpha2: "RU", alpha3: "RUS" },
  { name: "Rwanda", alpha2: "RW", alpha3: "RWA" },
  { name: "Saint Kitts and Nevis", alpha2: "KN", alpha3: "KNA" },
  { name: "Saint Lucia", alpha2: "LC", alpha3: "LCA" },
  { name: "Saint Vincent and Grenadines", alpha2: "VC", alpha3: "VCT" },
  { name: "Samoa", alpha2: "WS", alpha3: "WSM" },
  { name: "San Marino", alpha2: "SM", alpha3: "SMR" },
  { name: "Sao Tome and Principe", alpha2: "ST", alpha3: "STP" },
  { name: "Saudi Arabia", alpha2: "SA", alpha3: "SAU" },
  { name: "Senegal", alpha2: "SN", alpha3: "SEN" },
  { name: "Serbia", alpha2: "RS", alpha3: "SRB" },
  { name: "Seychelles", alpha2: "SC", alpha3: "SYC" },
  { name: "Sierra Leone", alpha2: "SL", alpha3: "SLE" },
  { name: "Singapore", alpha2: "SG", alpha3: "SGP" },
  { name: "Slovakia", alpha2: "SK", alpha3: "SVK" },
  { name: "Slovenia", alpha2: "SI", alpha3: "SVN" },
  { name: "Solomon Islands", alpha2: "SB", alpha3: "SLB" },
  { name: "Somalia", alpha2: "SO", alpha3: "SOM" },
  { name: "South Africa", alpha2: "ZA", alpha3: "ZAF" },
  { name: "Spain", alpha2: "ES", alpha3: "ESP" },
  { name: "Sri Lanka", alpha2: "LK", alpha3: "LKA" },
  { name: "Sudan", alpha2: "SD", alpha3: "SDN" },
  { name: "Suriname", alpha2: "SR", alpha3: "SUR" },
  { name: "Swaziland", alpha2: "SZ", alpha3: "SWZ" },
  { name: "Sweden", alpha2: "SE", alpha3: "SWE" },
  { name: "Switzerland", alpha2: "CH", alpha3: "CHE" },
  { name: "Syrian Arab Republic", alpha2: "SY", alpha3: "SYR" },
  { name: "Taiwan (Province of China)", alpha2: "TW", alpha3: "TWN" },
  { name: "Tajikistan", alpha2: "TJ", alpha3: "TJK" },
  { name: "Tanzania", alpha2: "TZ", alpha3: "TZA" },
  { name: "Thailand", alpha2: "TH", alpha3: "THA" },
  { name: "Timor-Leste", alpha2: "TL", alpha3: "TLS" },
  { name: "Togo", alpha2: "TG", alpha3: "TGO" },
  { name: "Tonga", alpha2: "TO", alpha3: "TON" },
  { name: "Trinidad and Tobago", alpha2: "TT", alpha3: "TTO" },
  { name: "Tunisia", alpha2: "TN", alpha3: "TUN" },
  { name: "Turkey", alpha2: "TR", alpha3: "TUR" },
  { name: "Turkmenistan", alpha2: "TM", alpha3: "TKM" },
  { name: "Tuvalu", alpha2: "TV", alpha3: "TUV" },
  { name: "Uganda", alpha2: "UG", alpha3: "UGA" },
  { name: "Ukraine", alpha2: "UA", alpha3: "UKR" },
  { name: "United Arab Emirates", alpha2: "AE", alpha3: "ARE" },
  { name: "United Kingdom", alpha2: "GB", alpha3: "GBR" },
  { name: "United States of America", alpha2: "US", alpha3: "USA" },
  { name: "Uruguay", alpha2: "UY", alpha3: "URY" },
  { name: "Uzbekistan", alpha2: "UZ", alpha3: "UZB" },
  { name: "Vanuatu", alpha2: "VU", alpha3: "VUT" },
  { name: "Venezuela", alpha2: "VE", alpha3: "VEN" },
  { name: "Viet Nam", alpha2: "VN", alpha3: "VNM" },
  { name: "Virgin Islands, US", alpha2: "VI", alpha3: "VIR" },
  { name: "Yemen", alpha2: "YE", alpha3: "YEM" },
  { name: "Zambia", alpha2: "ZM", alpha3: "ZMB" },
  { name: "Zimbabwe", alpha2: "ZW", alpha3: "ZWE" },
];
