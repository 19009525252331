import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  terminals: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.GET_TERMINALS:
    case actions.CREATE_OR_UPDATE_TERMINAL:
    case actions.DELETE_TERMINAL:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_TERMINAL_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_TERMINAL_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const terminals = (state = initialState.terminals, action) => {
  switch (action.type) {
    case actions.RECEIVE_TERMINALS:
      const _terminals = state.reduce((obj, t) => {
        obj[t.id] = t;
        return obj;
      }, {});
      for (let t of action.terminals) {
        _terminals[t.id] = t;
      }
      const a = Object.values(_terminals).sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
      );
      return a;
    case actions.RECEIVE_DELETED_TERMINAL:
      const newTerminals = _.cloneDeep(state).filter((terminal) => {
        return terminal.id !== action.terminalId;
      });
      return newTerminals;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  terminals,
});
