export const listProductsByStore = /* GraphQL */ `
  query ListProductsByStore(
    $storeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByStore(
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        variablePrice
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        locationIds
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listCategoriesByStore = /* GraphQL */ `
  query ListCategoriesByStore(
    $storeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriesByStore(
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        id
        storeId
        name
        displayName
        locationIds
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const listModifiersByStore = /* GraphQL */ `
  query ListModifiersByStore(
    $storeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModifiersByStore(
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        storeId
        name
        nameMl {
          en
          zh
          ja
        }
        origin
        coverage
        required
        requiredQuantity
        limitQuantity
        orderType
        defaultSkuId
        skus {
          type
          id
          itemId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        locationIds
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listCustomerByStore = /* GraphQL */ `
  query ListCustomerByStore(
    $storeId: ID
    $customerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoreCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerByStore(
      storeId: $storeId
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        locationId
        status
        registeredAt
        isFavorite
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            origin
            provider
            providerCustomerId
            providerSyncedAt
            pointTransactionCustomerId
            expiredAt
            salesDate
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              locationId
              status
              registeredAt
              isFavorite
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              orders {
                nextToken
              }
              _delta
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            note
            currency
            parentId
            customerId
            origin
            creditTransactionCustomerId
            description
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              locationId
              status
              registeredAt
              isFavorite
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              orders {
                nextToken
              }
              _delta
              store {
                id
                currency
                createdAt
              }
              customer {
                id
                ident
                identType
                type
                name
                addresses {
                  id
                  main
                  detail
                  storeId
                }
                agreements {
                  privacy
                  provider
                  eula
                  marketing
                  updatedAt
                  createdAt
                }
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            storeId
            orderCustomerId
            state
            amount
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountPaid
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            deviceId
            parentDeviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              type
              # category
              # categories
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              modifiers {
                type
                # category
                name
                amount
                price
                parentId
                referId
                referType
                campaignId
                quantity
                sku
                tax
                taxRate
                discountable
                discountMethod
                discountValue
                discountQuantity
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discountable
              discountMethod
              discountValue
              discountQuantity
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            returnSalesDate
            updatedAt
            visitorCount
            totalPoint
            pointSaved
            note
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                tax
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                type
                category
                # categories
                # product
                name
                amount
                price
                parentId
                referId
                referType
                requestId
                campaignId
                quantity
                sku
                tax
                taxRate
                discountable
                discountMethod
                discountValue
                discountQuantity
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
          }
          nextToken
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          phone
          email
          birth
          ageGroup
          gender
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const listCustomerByStoreWithTransactions = /* GraphQL */ `
  query ListCustomerByStore(
    $storeId: ID
    $customerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoreCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerByStore(
      storeId: $storeId
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            origin
            provider
            providerCustomerId
            providerSyncedAt
            amount
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            customerId
            creditTransactionCustomerId
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        store {
          id
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            system
            ipAddress
            brand
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            parentId
            childId
            printOptions {
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
              port
              baudRate
            }
            updatedAt
            createdAt
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            table
            diningOption
            orderAlarm
            orderConfirm
            addressInput
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
          }
          locations {
            items {
              id
              name
              address
              zip
              storeId
              state
              createdAt
              updatedAt
            }
            nextToken
          }
          market {
            status
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const listPointTransactionsByCustomer = /* GraphQL */ `
  query ListPointTransactionsByCustomer(
    $customerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPointTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointTransactionsByCustomer(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        type
        name
        amount
        origin
        provider
        providerCustomerId
        providerSyncedAt
        currency
        point
        extraPoint
        extraExpire
        items {
          type
          itemId
          name
          parentId
          point
          amount
          quantity
        }
        quantity
        note
        orderId
        referId
        orderNumberPrefix
        orderNumber
        customerId
        pointTransactionCustomerId
        expiredAt
        salesDate
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listCreditTransactionsByCustomer = /* GraphQL */ `
  query ListCreditTransactionsByCustomer(
    $customerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditTransactionsByCustomer(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        name
        type
        amount
        origin
        note
        currency
        parentId
        customerId
        creditTransactionCustomerId
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCreditTransactionsByStoreCustomer = /* GraphQL */ `
  query listCreditTransactionsByStoreCustomer(
    $creditTransactionCustomerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditTransactionsByStoreCustomer(
      creditTransactionCustomerId: $creditTransactionCustomerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        name
        type
        amount
        origin
        note
        currency
        parentId
        customerId
        creditTransactionCustomerId
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listStoreByCustomer = /* GraphQL */ `
  query ListStoreByCustomer(
    $customerId: ID
    $storeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoreCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreByCustomer(
      customerId: $customerId
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        _delta
      }
      nextToken
    }
  }
`;

export const listOrdersByLocation = /* GraphQL */ `
  query ListOrdersByLocation(
    $orderLocationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByLocation(
      orderLocationId: $orderLocationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountPaid
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          modifierId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        receiptCustomerId
        alarmCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalNumber
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
      }
      nextToken
    }
  }
`;

export const listOrdersByCustomer = /* GraphQL */ `
  query ListOrdersByCustomer(
    $customerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByCustomer(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountPaid
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        receiptCustomerId
        alarmCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
      }
      nextToken
    }
  }
`;

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountPaid
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      receiptCustomerId
      alarmCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
    }
  }
`;

export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountPaid
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        inventoryReason
        paidQuantity
        readyQuantity
        receivedQuantity
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      receiptCustomerId
      alarmCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
    }
  }
`;

export const createCreditTransaction = /* GraphQL */ `
  mutation CreateCreditTransaction($input: CreateCreditTransactionInput!) {
    createCreditTransaction(input: $input) {
      id
      locationId
      storeId
      name
      type
      amount
      origin
      note
      currency
      parentId
      customerId
      creditTransactionCustomerId
      description
      createdAt
      updatedAt
    }
  }
`;

export const createPointTransaction = /* GraphQL */ `
  mutation CreatePointTransaction($input: CreatePointTransactionInput!) {
    createPointTransaction(input: $input) {
      id
      locationId
      storeId
      type
      name
      amount
      origin
      provider
      providerCustomerId
      providerSyncedAt
      currency
      point
      extraPoint
      extraExpire
      items {
        type
        itemId
        name
        parentId
        point
        amount
        quantity
      }
      quantity
      note
      orderId
      referId
      orderNumberPrefix
      orderNumber
      customerId
      pointTransactionCustomerId
      expiredAt
      salesDate
      updatedAt
      createdAt
    }
  }
`;

export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountPaid
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      receiptCustomerId
      alarmCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          name
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
    }
  }
`;

export const getStoreCustomer = /* GraphQL */ `
  query GetStoreCustomer($id: ID!) {
    getStoreCustomer(id: $id) {
      id
      customerId
      storeId
      sources {
        id
        type
        name
        ident
        createdAt
      }
      isFavorite
      locationId

      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        isFavorite
        status
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        registeredAt
        updatedAt
        createdAt
      }
      status
      registeredAt
      campaignBlockedAt
      note
      removedAt
      updatedAt
      createdAt
      _delta
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
      }
    }
  }
`;

export const listPointTransactionsByStore = /* GraphQL */ `
  query ListPointTransactionsByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointTransactionsByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        type
        name
        amount
        origin
        provider
        providerCustomerId
        providerSyncedAt
        currency
        point
        extraPoint
        extraExpire
        items {
          type
          itemId
          name
          parentId
          point
          amount
          quantity
        }
        quantity
        note
        orderId
        referId
        orderNumberPrefix
        orderNumber
        customerId
        pointTransactionCustomerId
        expiredAt
        salesDate
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listPointTransactionsByLocation = /* GraphQL */ `
  query ListPointTransactionsByLocation(
    $locationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointTransactionsByLocation(
      locationId: $locationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        type
        name
        amount
        origin
        provider
        providerCustomerId
        providerSyncedAt
        currency
        point
        extraPoint
        extraExpire
        items {
          type
          itemId
          name
          parentId
          point
          amount
          quantity
        }
        quantity
        note
        orderId
        referId
        orderNumberPrefix
        orderNumber
        customerId
        pointTransactionCustomerId
        expiredAt
        salesDate
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listCreditTransactionsByStore = /* GraphQL */ `
  query ListCreditTransactionsByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditTransactionsByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        name
        type
        amount
        origin
        note
        currency
        parentId
        customerId
        creditTransactionCustomerId
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProductCategoryByCategory = /* GraphQL */ `
  query ListProductCategoryByCategory(
    $categoryId: ID
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategoryByCategory(
      categoryId: $categoryId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        productId
        categoryId
        updatedAt
        createdAt
        product {
          id
          type
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          variablePrice
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          locationIds
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const listProductModifierByModifier = /* GraphQL */ `
  query ListProductModifierByModifier(
    $modifierId: ID
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductModifierByModifier(
      modifierId: $modifierId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        productId
        modifierId
        position
        updatedAt
        createdAt
        product {
          id
          type
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          variablePrice
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          locationIds
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const listOrdersByStore = /* GraphQL */ `
  query ListOrdersByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountPaid
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        receiptCustomerId
        alarmCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            name
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
      }
      nextToken
    }
  }
`;

export const getStoreLoyalty = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
    }
  }
`;

export const listProductCategoryByStore = /* GraphQL */ `
  query ListProductCategoryByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategoryByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enterpriseId
        storeId
        productId
        categoryId
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listProductModifierByStore = /* GraphQL */ `
  query ListProductModifierByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductModifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductModifierByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enterpriseId
        storeId
        productId
        modifierId
        position
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          columnSize
        }
        terminalId
        printOptions {
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
          port
          baudRate
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                titleMl {
                  en
                  zh
                  ja
                }
                index
                ids
                items {
                  parentId
                  badge
                  type
                  position
                  activeOptions
                }
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      groups {
        items {
          id
          name
          type
          storeGroupsId
          locationGroupsId
          key
          conditions {
            key
            object
            field
            operations {
              operation
              type
              value
            }
            value
            operation
            type
          }
          count
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
            }
            columnSize
          }
          terminalId
          printOptions {
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
            port
            baudRate
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
    }
  }
`;

export const getStoreByApiKey = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          columnSize
        }
        terminalId
        printOptions {
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
          port
          baudRate
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                titleMl {
                  en
                  zh
                  ja
                }
                index
                ids
                items {
                  parentId
                  badge
                  type
                  position
                  activeOptions
                }
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
            }
            columnSize
          }
          terminalId
          printOptions {
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
            port
            baudRate
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
    }
  }
`;

export const listOrderHistorys = /* GraphQL */ `
  query ListOrderHistorys(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderHistorys(
      storeId: $storeId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        storeId
        createdAt
        enterpriseId
        locationId
        type
        amount
        parentId
        currency
        orderHistoryOrderId
        customerStatus
        gender
        birth
        customerId
        updatedAt
        order {
          id
          orderLocationId
          numberPrefix
          number
          beeper
          enterpriseId
          storeId
          customerId
          orderCustomerId
          state
          amount
          origin
          subOrigin
          originId
          customerProvider
          providerCustomerId
          amountChanged
          amountPaid
          amountReturned
          tax
          currency
          quantity
          shippingType
          diningOption
          diningState
          retailOption
          retailState
          name
          phone
          address
          zip
          receivers {
            id
            name
            phone
            zip
            address1
            address2
            addressState
            addressCity
            addressStreet
            addressFull
            note
            shippingCompanyCode
            trackingNumber
            privacyAgreedAt
          }
          tableId
          tableIds
          parentDeviceId
          deviceId
          reserveDate
          pickupDate
          shippingDate
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                productId
                name
                amount
                price
                parentId
                referType
                referId
                campaignId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                discountQuantity
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              discountQuantity
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            discountQuantity
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          createdEmployeeId
          createdDeviceId
          updatedDeviceId
          shippingLocationId
          confirmedEmployeeId
          confirmedAt
          createdAt
          paidAt
          salesDate
          returnedAt
          shippedAt
          returnShippedAt
          returnSalesDate
          updatedAt
          noticeCount
          visitorCount
          totalPoint
          pointSaved
          receiptId
          receiptNumber
          receiptCount
          receiptCustomerId
          alarmCustomerId
          note
          request
          charges {
            id
            method
            extraMethod
            gateway
            amount
            tax
            taxRefundAmount
            purchaser {
              name
              number
              nation
              address
            }
            supplyAmount
            taxFreeAmount
            amountRefunded
            createdEmployeeId
            createdDeviceId
            createdAt
            creditTransactionId
            balance
            splitType
            pg
            van
            origin
            originId
            note
            brand
            name
            ident
            installment
            transactionId
            approver
            issuerCode
            acquirer
            acquirerCode
            acquirerName
            refundable
            recurring
            approvalCode
            approvalDate
            approvalNumber
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            refunds {
              id
              amount
              supplyAmount
              taxFreeAmount
              tax
              taxRefundAmount
              creditTransactionId
              approvalNumber
              approvalDate
              transactionId
              origin
              originId
              currency
              reason
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              createdEmployeeId
              createdDeviceId
              createdAt
            }
          }
          returns {
            amount
            currency
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                productId
                name
                amount
                price
                parentId
                referType
                referId
                campaignId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                discountQuantity
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              discountQuantity
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            refundId
            createdAt
          }
          fee
          revision
          _delta
          orderCustomer {
            id
            enterpriseId
            storeId
            locationId
            customerId
            customerCreatedAt
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            tags {
              tagId
              createdLocationId
            }
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            status
            registeredAt
            campaignBlockedAt
            isFavorite
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              registeredAt
              updatedAt
              createdAt
            }
            note
            sources {
              id
              type
              name
              ident
              createdAt
            }
            removedAt
            updatedAt
            createdAt
            _delta
            timestamp
            expdate
          }
        }
      }
      nextToken
    }
  }
`;

export const listOrderHistorysByLocation = /* GraphQL */ `
  query ListOrderHistorysByLocation(
    $locationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderHistorysByLocation(
      locationId: $locationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        storeId
        createdAt
        enterpriseId
        locationId
        type
        amount
        parentId
        currency
        orderHistoryOrderId
        customerStatus
        gender
        birth
        customerId
        updatedAt
        order {
          id
          orderLocationId
          numberPrefix
          number
          beeper
          enterpriseId
          storeId
          customerId
          orderCustomerId
          state
          amount
          origin
          subOrigin
          originId
          customerProvider
          providerCustomerId
          amountChanged
          amountPaid
          amountReturned
          tax
          currency
          quantity
          shippingType
          diningOption
          diningState
          retailOption
          retailState
          name
          phone
          address
          zip
          receivers {
            id
            name
            phone
            zip
            address1
            address2
            addressState
            addressCity
            addressStreet
            addressFull
            note
            shippingCompanyCode
            trackingNumber
            privacyAgreedAt
          }
          tableId
          tableIds
          parentDeviceId
          deviceId
          reserveDate
          pickupDate
          shippingDate
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              discountQuantity
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            discountQuantity
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          createdEmployeeId
          createdDeviceId
          updatedDeviceId
          shippingLocationId
          confirmedEmployeeId
          confirmedAt
          createdAt
          paidAt
          salesDate
          returnedAt
          shippedAt
          returnShippedAt
          returnSalesDate
          updatedAt
          noticeCount
          visitorCount
          totalPoint
          pointSaved
          receiptId
          receiptNumber
          receiptCount
          receiptCustomerId
          alarmCustomerId
          note
          request
          charges {
            id
            method
            extraMethod
            gateway
            amount
            tax
            taxRefundAmount
            purchaser {
              name
              number
              nation
              address
            }
            supplyAmount
            taxFreeAmount
            amountRefunded
            createdEmployeeId
            createdDeviceId
            createdAt
            creditTransactionId
            balance
            splitType
            pg
            van
            origin
            originId
            note
            brand
            name
            ident
            installment
            transactionId
            approver
            issuerCode
            acquirer
            acquirerCode
            acquirerName
            refundable
            recurring
            approvalCode
            approvalDate
            approvalNumber
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            refunds {
              id
              amount
              supplyAmount
              taxFreeAmount
              tax
              taxRefundAmount
              creditTransactionId
              approvalNumber
              approvalDate
              transactionId
              origin
              originId
              currency
              reason
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              createdEmployeeId
              createdDeviceId
              createdAt
            }
          }
          returns {
            amount
            currency
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              productId
              name
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                productId
                name
                amount
                price
                parentId
                referType
                referId
                campaignId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                discountQuantity
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              discountQuantity
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            refundId
            createdAt
          }
          revision
          fee
          _delta
          orderCustomer {
            id
            enterpriseId
            storeId
            locationId
            customerId
            customerCreatedAt
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            tags {
              tagId
              createdLocationId
            }
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            status
            registeredAt
            campaignBlockedAt
            isFavorite
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              registeredAt
              updatedAt
              createdAt
            }
            note
            sources {
              id
              type
              name
              ident
              createdAt
            }
            removedAt
            updatedAt
            createdAt
            _delta
            timestamp
            expdate
          }
        }
      }
      nextToken
    }
  }
`;

export const listSubscriptionEvents = /* GraphQL */ `
  query ListSubscriptionEvents(
    $filter: ModelSubscriptionEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        locationId
        subscriptionId
        sessionId
        planId
        price
        amount
        currency
        type
        customerId
        storeCustomerId
        orderId
        orderNumberPrefix
        orderNumber
        endDate
        updatedAt
        createdAt
        location {
          id
          name
          type
          address
          zip
          phone
          createdAt
          updatedAt
        }
        plan {
          id
          storeId
          productId
          skuId
          name
          recurringType
          recurringValue
          price
          quantity
          status
          image {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          gracePeriod
          subscriberCount
          locations {
            planId
            locationId
            available
            subscriberCount
          }
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            variablePrice
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
                locations {
                  id
                  quantity
                }
                attributes {
                  name
                  type
                  value
                }
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
        }
      }
      nextToken
    }
  }
`;

export const listOrganizationsByStore = /* GraphQL */ `
  query ListOrganizationsByStore(
    $storeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByStore(
      storeId: $storeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        employeeId
        enterpriseId
        storeId
        pin
        status
        locationId
        employee {
          id
          role
          lastName
          firstName
          name
          nickname
          phone
          email
          employeeStoreId
          employeeEnterpriseId
          organizations {
            items {
              id
              role
              employeeId
              enterpriseId
              storeId
              pin
              status
              locationId
              permissions
              updatedAt
              createdAt
            }
            nextToken
          }
          survey
          updatedAt
          createdAt
        }
        permissions
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listOrganizationsByLocation = /* GraphQL */ `
  query ListOrganizationsByLocation(
    $locationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByLocation(
      locationId: $locationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        employeeId
        enterpriseId
        storeId
        pin
        status
        locationId
        employee {
          id
          role
          lastName
          firstName
          name
          nickname
          phone
          email
          employeeStoreId
          employeeEnterpriseId
          organizations {
            items {
              id
              role
              employeeId
              enterpriseId
              storeId
              pin
              status
              locationId
              permissions
              updatedAt
              createdAt
            }
            nextToken
          }
          survey
          updatedAt
          createdAt
        }
        permissions
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listOrganizationsByEmployee = /* GraphQL */ `
  query ListOrganizationsByEmployee(
    $employeeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByEmployee(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        employeeId
        enterpriseId
        storeId
        pin
        status
        locationId
        permissions
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const LS_getEmployeeByEmail = /* GraphQL */ `
  query LS_getEmployeeByEmail($email: String) {
    LS_getEmployeeByEmail(email: $email) {
      id
      role
      lastName
      firstName
      name
      nickname
      phone
      email
      employeeStoreId
      employeeEnterpriseId
      survey
      updatedAt
      createdAt
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      type
      enterpriseId
      storeId
      origin
      refer
      referId
      name
      nameMl {
        en
        zh
        ja
      }
      description
      descriptionMl {
        en
        zh
        ja
      }
      variablePrice
      attributes {
        name
        type
        value
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      locationIds
      updatedAt
      createdAt
    }
  }
`;

export const listPointTransactionsByStoreCustomer = /* GraphQL */ `
  query ListPointTransactionsByStoreCustomer(
    $pointTransactionCustomerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointTransactionsByStoreCustomer(
      pointTransactionCustomerId: $pointTransactionCustomerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        storeId
        type
        name
        amount
        origin
        provider
        providerCustomerId
        providerSyncedAt
        currency
        point
        extraPoint
        extraExpire
        items {
          type
          itemId
          name
          parentId
          point
          amount
          quantity
        }
        quantity
        note
        orderId
        referId
        orderNumberPrefix
        orderNumber
        customerId
        pointTransactionCustomerId
        expiredAt
        salesDate
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const listOrdersByStoreCustomer = /* GraphQL */ `
  query ListOrdersByStoreCustomer(
    $orderCustomerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrdersByStoreCustomer(
      orderCustomerId: $orderCustomerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
      }
      nextToken
    }
  }
`;
