import Amplify, { API as AWSAPI, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import * as custom from "../graphql/custom";
import Constant from "../helpers/Constants";
import _ from "lodash";

import { AUTH_TYPE } from "aws-appsync";
import { removeTypename } from "../helpers/Utils";
import { getExpDate } from "../reducers/local";

class API {
  constructor() {
    this._subscribers = {};
  }

  sendCode(phone) {
    const body = { phone: phone };
    return new Promise(function (resolve, reject) {
      fetch(`${Constant.SERVER_URL}/code`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            reject(new Error("Failed send code"));
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  confirmCode(phone, code) {
    return new Promise(function (resolve, reject) {
      fetch(`${Constant.SERVER_URL}/code?phone=${phone}&code=${code}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.status !== 200) {
            reject(new Error("Invalid code"));
          } else {
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getBillingCost(storeId, locationId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getBillingCost, {
          storeId,
          locationId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getBillingCost));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateItems(items) {
    delete items.legacy;
    delete items.updatedAt;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateItems, {
          input: items,
        })
      )
        .then((data) => {
          resolve(data.data.updateItems);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createInventoryHistory(history) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createInventoryHistory, {
          input: history,
        })
      )
        .then((data) => {
          resolve(data.data.createInventoryHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryHistories(storeId, page, size, type, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getInventoryHistories, {
          storeId,
          page,
          size,
          type,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getInventoryHistories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryHistoriesById(id, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listInventoryHistorys, {
          id,
          nextToken,
          sortDirection: "DESC",
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listInventoryHistorys);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryHistoriesByStore(storeId, createdAt, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listInventoryHistorysByStore, {
          storeId,
          createdAt,
          nextToken,
          sortDirection: "DESC",
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listInventoryHistorysByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryHistoriesByLocation(locationId, createdAt, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listInventoryHistorysByLocation, {
          locationId,
          createdAt,
          nextToken,
          sortDirection: "DESC",
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listInventoryHistorysByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSkuInventoryHistories(
    start,
    end,
    skuId,
    storeId,
    filters,
    nextToken,
    limit
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getSkuInventoryHistories, {
          start,
          end,
          skuId,
          storeId,
          filters: filters ? JSON.stringify(filters) : null,
          nextToken,
          sortDirection: "DESC",
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getSkuInventoryHistories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryHistorySummary(storeId, start, end, filters, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getInventoryHistorySummary, {
          storeId,
          start,
          end,
          filters: filters ? JSON.stringify(filters) : null,
          nextToken,
          sortDirection: "DESC",
          limit,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getInventoryHistorySummary));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderWithTransactions(orderId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getOrder, {
          id: orderId,
        })
      )
        .then((data) => {
          resolve(data.data.getOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrder(orderId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.getOrder, {
          id: orderId,
        })
      )
        .then((data) => {
          resolve(data.data.getOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderByApiKey(orderId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(custom.getOrder, {
          id: orderId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersByLocation(orderLocationId, createdAt, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrdersByLocation, {
          orderLocationId,
          createdAt,
          limit,
          nextToken,
          filter,
          sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersByStore(storeId, createdAt, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        // TODO: optimzing by custom
        graphqlOperation(queries.listOrdersByStore, {
          storeId,
          createdAt,
          limit,
          nextToken,
          filter,
          sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getShippingOrdersByShippingLocation(
    shippingLocationId,
    createdAt,
    nextToken,
    limit,
    filter
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listOrdersByShippingLocation, {
          shippingLocationId,
          createdAt,
          sortDirection: "DESC",
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByShippingLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getShippingDeltaOrdersByShippingLocation(
    shippingLocationId,
    createdAt,
    nextToken,
    limit,
    filter
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listOrderDeltaByShippingLocation, {
          shippingLocationId,
          createdAt,
          sortDirection: "DESC",
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrderDeltaByShippingLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersOnlyByStore(storeId, createdAt, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrdersByStore, {
          storeId,
          createdAt,
          limit,
          nextToken,
          filter,
          sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersByStoreCustomer(
    orderCustomerId,
    filter = null,
    limit = null,
    nextToken = null,
    start = null,
    end = null
  ) {
    console.log("API.getOrders");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrdersByStoreCustomer, {
          orderCustomerId: orderCustomerId,
          sortDirection: "DESC",
          createdAt: start && end ? { between: [start, end] } : null,
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersByCustomer(customerId, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrdersByCustomer, {
          customerId,
          nextToken,
          limit,
          filter,
          sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderCustomerGroups(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderCustomerGroups, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderCustomerGroups));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderOrders(storeId, page, size, filters) {
    console.log("API.getProviderOrders");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderOrders, {
          storeId,
          page,
          size,
          filters: JSON.stringify(filters),
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderOrders));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTierUpdateHistories(storeId, createdAt, nextToken, limit, filter = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomerTierUpdateHistoryByStore, {
          storeId,
          createdAt,
          sortDirection: "DESC",
          filter: filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomerTierUpdateHistoryByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrganizationsByEmployee(employeeId, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrganizationsByEmployee, {
          employeeId,
          nextToken,
          limit,
          filter,
        })
      )
        .then((data) => {
          console.log(data);
          resolve(data.data.listOrganizationsByEmployee);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrganizationsByStore(storeId, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrganizationsByStore, {
          storeId,
          nextToken,
          limit,
          filter,
          sortDirection: "ASC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrganizationsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrganizationsByLocation(locationId, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrganizationsByLocation, {
          locationId,
          nextToken,
          limit,
          filter,
          sortDirection: "ASC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrganizationsByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrders(filter, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listOrders, {
          filter,
          limit,
          nextToken,
          // sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listOrders);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  echo(message) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.echo, { message }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.echo);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resetPassword(ident, password) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.resetPassword, { ident, password }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.resetPassword);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateAuthCode(redirectUrl, customize) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.generateAuthCode, {
          redirectUrl,
          customize,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.generateAuthCode);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  decodeAuthData(encData) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.decodeAuthData, { data: encData }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.decodeAuthData));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createUser(ident, firstName, lastName, phone, storeName, resend = false) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.createUser, {
          ident,
          firstName,
          lastName,
          phone,
          storeName,
          resend,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.createUser));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteUser(ident) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.deleteUser, {
          ident,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.deleteUser));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointStatsByCustomerId(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getPointStatsByCustomerId, { id }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.getPointStatsByCustomerId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerByStore(customerId, storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(custom.listStoreByCustomer, {
          customerId,
          storeId: { eq: storeId },
          limit: 1,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          const { items } = data.data.listStoreByCustomer;
          resolve(items.length > 0 ? items[0] : null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationCustomersByOriginId(
    customerId,
    storeId = null,
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.listLocationCustomerDeltaByCustomer, {
          customerId: customerId,
          storeId: storeId,
          nextToken: nextToken,
          limit: limit,
          filter: filter,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.listLocationCustomerDeltaByCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationCustomer(id, locationId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getLocationCustomerDelta, {
          id,
          locationId,
        })
      )
        .then((data) => {
          resolve(data.data.getLocationCustomerDelta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationCustomerWithStoreCustomer(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.listLocationCustomerDeltas, {
          id,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          const { items } = data.data.listLocationCustomerDeltas;
          resolve(items.length > 0 ? items[0] : null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationCustomersByStore(
    storeId,
    timeStamp = null,
    limit,
    filter = null,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationCustomerDeltaByStore, {
          storeId,
          timeStamp,
          sortDirection: "DESC",
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationCustomerDeltaByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createInventory(inventory) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createSkuInventory, {
          inventory: JSON.stringify(inventory),
        })
      )
        .then((data) => {
          resolve(data.data.createInventory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInventory(inventory) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateSkuInventory, {
          inventory: JSON.stringify(inventory),
        })
      )
        .then((data) => {
          resolve(data.data.deleteInventory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteInventory(inventory) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteSkuInventory, {
          inventory: JSON.stringify(inventory),
        })
      )
        .then((data) => {
          resolve(data.data.deleteInventory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelCardPay(ident, transactionId, amount) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.cancelCardPay, {
          ident,
          transactionId,
          amount,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.cancelCardPay));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getKakaoCategories() {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(queries.getKakaoCategories))
        .then((data) => {
          resolve(JSON.parse(data.data.getKakaoCategories));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  requestKakaoProfileToken(channelId, phone) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_requestKakaoProfileToken, {
          channelId,
          phone,
        })
      )
        .then((data) => {
          resolve(data.data.LS_requestKakaoProfileToken);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  registerKakaoProfile(channelId, token, phone, categoryCode) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_registerKakaoProfile, {
          channelId,
          token,
          phone,
          categoryCode,
        })
      )
        .then((data) => {
          resolve(data.data.LS_registerKakaoProfile);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAlimtalkTemplates(channelId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getAlimtalkTemplates, {
          channelId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getAlimtalkTemplates));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelCashReceipt(
    ident,
    transactionId,
    amount,
    supplyAmount,
    vat,
    taxFreeAmount
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.cancelCashReceipt, {
          ident,
          transactionId,
          amount,
          supplyAmount,
          vat,
          taxFreeAmount,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.cancelCashReceipt));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  decodeToken(token) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.decodeToken, { token }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.decodeToken));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSettlements(
    locationId,
    transactionDate,
    limit,
    sortDirection,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationSettlements, {
          locationId,
          transactionDate,
          limit,
          sortDirection,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationSettlements);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSettlementsByStore(
    storeId,
    transactionDate,
    limit,
    sortDirection,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationSettlementByStore, {
          storeId,
          transactionDate,
          limit,
          sortDirection,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationSettlementByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSalesHistoriesByStore(storeId, start, end = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listSalesHistorys, {
          storeId,
          limit: 500,
          sortDirection: "DESC",
          startedAt: {
            between: [start, end || new Date().toISOString()],
          },
        })
      )
        .then((data) => {
          resolve(data.data.listSalesHistorys.items);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSalesHistoriesByLocation(locationId, start, end = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listSalesHistorysByLocation, {
          locationId,
          sortDirection: "DESC",
          limit: 500,
          filter: {
            startedAt: {
              between: [start, end || new Date().toISOString()],
            },
          },
        })
      )
        .then((data) => {
          resolve(data.data.listSalesHistorysByLocation.items);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderHistoriesByStore(storeId, start, end, filters, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrderHistorys, {
          storeId,
          createdAt: {
            between: [start, end],
          },
          filter: filters ? filters : null,
          sortDirection: "DESC",
          limit: limit,
          nextToken: nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrderHistorys);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderHistoriesByLocation(locationId, start, end, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listOrderHistorysByLocation, {
          locationId,
          createdAt: {
            between: [start, end],
          },
          sortDirection: "DESC",
          limit: limit,
          nextToken: nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrderHistorysByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSalesSummary(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getSalesSummary, {
          id: storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getSalesSummary));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactions(nextToken, limit, filter = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPointTransactions, {
          filter: filter || {},
          nextToken: nextToken,
          limit: limit,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactionsByStore(
    storeId,
    createdAt = {},
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listPointTransactionsByStore, {
          storeId,
          createdAt,
          sortDirection: "DESC",
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactionsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactionsByStoreSalesDate(
    storeId,
    salesDate = null,
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPointTransactionsByStoreSalesDate, {
          storeId,
          salesDate,
          sortDirection: "DESC",
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactionsByStoreSalesDate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactionsByLocation(
    locationId,
    createdAt = {},
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listPointTransactionsByLocation, {
          locationId,
          createdAt,
          sortDirection: "DESC",
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactionsByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactionsByLocationSalesDate(
    locationId,
    salesDate = {},
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPointTransactionsByLocationSalesDate, {
          locationId,
          salesDate,
          sortDirection: "DESC",
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactionsByLocationSalesDate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditTransactionsByStore(
    storeId,
    createdAt = {},
    nextToken = null,
    limit = null,
    filter = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listCreditTransactionsByStore, {
          storeId,
          createdAt,
          sortDirection: "DESC",
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listCreditTransactionsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPointTransactionByStoreCustomer(
    storeCustomerId,
    filter = null,
    limit = null,
    nextToken = null,
    start = null,
    end = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listPointTransactionsByStoreCustomer, {
          pointTransactionCustomerId: storeCustomerId,
          sortDirection: "DESC",
          createdAt: start && end ? { between: [start, end] } : null,
          filter: filter,
          limit: limit,
          nextToken: nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listPointTransactionsByStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderCustomerPointTransactions(storeId, customerId, start, end) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderPointTransactions, {
          storeId,
          customerId,
          start,
          end,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderPointTransactions));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditTransactionsByCustomer(customerId, nextToken, filter, limit = 300) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listCreditTransactionsByCustomer, {
          customerId,
          nextToken,
          limit,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listCreditTransactionsByCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditTransactionsByStoreCustomer(
    storeCustomerId,
    nextToken = null,
    filter = null,
    limit = null,
    start = null,
    end = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listCreditTransactionsByStoreCustomer, {
          creditTransactionCustomerId: storeCustomerId,
          sortDirection: "DESC",
          filter: !_.isEmpty(filter) ? filter : null,
          limit: limit,
          nextToken: nextToken,
          createdAt: start && end ? { between: [start, end] } : null,
        })
      )
        .then((data) => {
          resolve(data.data.listCreditTransactionsByStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditTransactions(nextToken, limit, filter = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCreditTransactions, {
          filter: filter || {},
          nextToken: nextToken,
          limit: limit,
        })
      )
        .then((data) => {
          resolve(data.data.listCreditTransactions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSalesReport(
    storeId,
    item,
    start,
    end,
    locationId,
    locationIds,
    page = null,
    totalPage = null,
    basis
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getSalesReport, {
          id: storeId,
          item,
          start,
          end,
          locationId,
          locationIds,
          basis,
          page,
          totalPage,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getSalesReport));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPeriodReport(
    storeId,
    item,
    start,
    end,
    locationId = null,
    basis = null,
    origin = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getPeriodReport, {
          storeId,
          locationId,
          item,
          start,
          end,
          basis,
          origin,
          version: "2024.7.15",
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getPeriodReport));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  validateImage(key, method, level, options) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_validateImage, {
          key,
          method,
          level,
          options: options ? JSON.stringify(options) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_validateImage);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerCount(
    storeId,
    locationId = null,
    status = null,
    filters = null,
    includes = [],
    locationIds = [],
    version = "1.0"
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCustomerCount, {
          storeId,
          locationId,
          locationIds,
          status,
          version,
          filters: filters ? JSON.stringify(filters) : null,
          includes,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getCustomerCount);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerGroups(
    storeId,
    locationId = null,
    page = null,
    size = null,
    group = "all"
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCustomerGroups, {
          storeId,
          locationId,
          page,
          size,
          group,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerGroups));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerGroupsWithStats(storeId, locationId, page, maxPage) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomerGroupWithStats, {
          id: storeId,
          locationId,
          page,
          maxPage,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.listCustomerGroupWithStats));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLoyaltySummary(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getLoyaltySummary, {
          id: storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getLoyaltySummary));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCampaignWithReport(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCampaignWithReport, {
          id: id,
        })
      )
        .then((data) => {
          let campaign = data.data.getCampaignWithReport;
          if (typeof campaign.report === "string") {
            campaign = {
              ...campaign,
              report: JSON.parse(campaign.report),
            };
          }
          resolve(campaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateStore(employee, survey, settings = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.generateStore, {
          employee: JSON.stringify(employee),
          survey: JSON.stringify(survey),
          settings: settings ? JSON.stringify(settings) : null,
        })
      )
        .then((data) => {
          resolve(data.data.generateStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCardBillingKey(storeId, cardNo, cardPw, expMonth, expYear, identify) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.generateBillingKey, {
          storeId: storeId,
          cardNo: cardNo,
          cardPw: cardPw,
          expMonth: expMonth,
          expYear: expYear,
          identify: identify,
        })
      )
        .then((data) => {
          resolve(data.data.generateBillingKey);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelCampaign(campaignId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.cancelCampaign, {
          id: campaignId,
        })
      )
        .then((data) => {
          resolve(data.data.cancelCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  launchCampaign(campaignId, recipientIds) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.launchCampaign, {
          id: campaignId,
          recipientIds: recipientIds,
        })
      )
        .then((data) => {
          resolve(data.data.launchCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendSms(
    message,
    method,
    recipient,
    title,
    sendNumber,
    trackingLink,
    imageUrl,
    options
  ) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.sendSms, {
          message: message,
          method: method,
          recipient: recipient,
          title: title,
          sendNumber: sendNumber,
          trackingLink,
          imageUrl,
          options: options ? JSON.stringify(options) : null,
        })
      )
        .then((data) => {
          resolve(data.data.sendSms);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCoupon(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCoupon, {
          id: id,
        })
      )
        .then((data) => {
          resolve(data.data.getCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponByApiKey(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getCoupon, {
          id: id,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCoupons(storeId, nextToken, limit, filter) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCouponsByStore, {
          storeId,
          nextToken,
          limit,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listCouponsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponsWithStats(storeId, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCouponsWithStats, {
          storeId,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCouponsWithStats));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCoupon(coupon) {
    delete coupon.transactions;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_createCoupon, {
          coupon,
        })
      )
        .then((data) => {
          resolve(data.data.LS_createCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCoupon(coupon) {
    delete coupon.transactions;
    delete coupon.discountAmount;
    delete coupon.locations;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateCoupon, {
          coupon,
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCoupon(couponId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deleteCoupon, {
          couponId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deleteCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionByApiKey(transactionId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getCouponTransaction, {
          id: transactionId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getCouponTransaction);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionsByStoreCoupon(
    storeId,
    couponId,
    filter,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCouponTransactionsByStoreCoupon, {
          storeId,
          filter,
          limit,
          nextToken,
          couponTransactionsId: couponId ? { eq: couponId } : null,
        })
      )
        .then((data) => {
          console.log(data);
          resolve(data.data.listCouponTransactionsByStoreCoupon);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionsByStoreCustomer(
    customerId,
    method,
    filter,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCouponTransactionsByStoreCustomerMethod, {
          couponTransactionCustomerId: customerId,
          method: method ? { eq: method } : null,
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          console.log(data);
          resolve(data.data.listCouponTransactionsByStoreCustomerMethod);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderCouponTransactions(storeId, customerId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderCouponTransactions, {
          storeId,
          customerId,
        })
      )
        .then((data) => {
          resolve(
            JSON.parse(data.data.LS_getProviderCouponTransactions).items || []
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionsByStoreCustomerByApi(
    couponId,
    customerId,
    filter,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(
          queries.listCouponTransactionsByCouponStoreCustomer,
          {
            filter,
            limit,
            nextToken,
            couponTransactionsId: couponId,
            couponTransactionCustomerId: customerId ? { eq: customerId } : null,
          }
        ),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          console.log(data);
          resolve(data.data.listCouponTransactionsByCouponStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionsByStoreBarcode(
    storeId,
    barcode,
    filter,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.listCouponTransactionsByStoreBarcode, {
          storeId,
          filter,
          limit,
          nextToken,
          barcode: barcode ? { eq: barcode } : null,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          console.log(data);
          resolve(data.data.listCouponTransactionsByStoreBarcode);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCampaign(campaignId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCampaign, {
          campaignId,
        })
      )
        .then((data) => {
          resolve(data.data.getCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCampaignsByStoreId(
    storeId,
    createdAt = null,
    filter = null,
    nextToken = null,
    limit = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCampaignsByStore, {
          storeId,
          createdAt,
          filter,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listCampaignsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCampaignsByLocationId(
    locationId,
    createdAt = null,
    filter = null,
    nextToken = null,
    limit = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCampaignsByLocation, {
          locationId,
          createdAt,
          filter,
          nextToken,
          limit,
          sortDirection: "DESC",
        })
      )
        .then((data) => {
          resolve(data.data.listCampaignsByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBillingHistory(billHistoryId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getBillHistory, {
          id: billHistoryId,
        })
      )
        .then((data) => {
          resolve(data.data.getBillHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBillingHistoriesByStore(
    storeId,
    filter = null,
    limit = null,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listBillHistoryByStore, {
          storeId,
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listBillHistoryByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBillingHistoriesByLocation(
    locationId,
    filter = null,
    limit = null,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listBillHistoryByLocation, {
          locationId,
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listBillHistoryByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createEmployee(employee) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.createEmployee, { input: employee }),
      })
        .then((data) => {
          resolve(data.data.createEmployee);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateEmployee(employee) {
    delete employee.store;
    delete employee.organizations;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateEmployee, {
          input: employee,
        })
      )
        .then((data) => {
          resolve(data.data.updateEmployee);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteEmployee(employeeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteEmployee, {
          input: {
            id: employeeId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteEmployee);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSubscriptionPlans(storeId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listSubscriptionPlanDeltaByStore, {
          storeId,
          nextToken,
          limit,
          filter: { _delta: { ne: "DELETE" }, status: { ne: "archived" } },
        })
      )
        .then((data) => {
          resolve(data.data.listSubscriptionPlanDeltaByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSubscriptionPlan(planId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getSubscriptionPlanDelta, {
          id: planId,
        })
      )
        .then((data) => {
          resolve(data.data.getSubscriptionPlanDelta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createSubscriptionPlan(plan) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_createSubscriptionPlan, {
          plan: JSON.stringify(plan),
        })
      )
        .then((data) => {
          resolve(data.data.LS_createSubscriptionPlan);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSubscriptionPlan(plan) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateSubscriptionPlan, {
          plan: JSON.stringify(plan),
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateSubscriptionPlan);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCustomerPayment(payment) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_createCustomerPayment, {
          payment: JSON.stringify(payment),
        })
      )
        .then((data) => {
          resolve(data.data.LS_createCustomerPayment);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCustomerPayment(id) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deleteCustomerPayment, {
          paymentId: id,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deleteCustomerPayment);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  subscribeSubscriptionPlan(customerId, planId, locationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_subscribeSubscriptionPlan, {
          customerId,
          planId,
          locationId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_subscribeSubscriptionPlan);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  stopSubscription(subscriptionId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_stopSubscription, {
          subscriptionId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_stopSubscription);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  restoreSubscription(subscriptionId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_restoreSubscription, {
          subscriptionId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_restoreSubscription);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelSubscription(subscriptionId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_cancelSubscription, {
          subscriptionId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_cancelSubscription);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSubscriptionEvents(customerId, limit, nextToken) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(custom.listSubscriptionEvents, {
          filter: { customerId: { eq: customerId }, type: { ne: "failed" } },
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listSubscriptionEvents);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteSubscriptionPlan(planId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deleteSubscriptionPlan, {
          planId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deleteSubscriptionPlan);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReviewCampaigns(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getReviewCampaigns, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getReviewCampaigns));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReviewCampaignResult(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getReviewCampaignResult, {
          id,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getReviewCampaignResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReviewCampaignResults(storeId, start, end) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getReviewCampaignResults, {
          storeId,
          start,
          end,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getReviewCampaignResults));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReviewCampaignResultsByCapaign(campaignId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listReviewCampaignResults, {
          filter: { campaignId: { eq: campaignId } },
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listReviewCampaignResults);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateReviewCampaignResult(result) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.updateReviewCampaignResult, {
          input: result,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.updateReviewCampaignResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getReviewCampaign(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getReviewCampaign, {
          id,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getReviewCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrdersByReciptCustomer(customerId, limit = null, nextToken = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listOrdersByReceiptCustomer, {
          receiptCustomerId: customerId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listOrdersByReceiptCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createReviewCampaign(campaign) {
    delete campaign.stats;
    if (typeof campaign.questions !== "string") {
      campaign.questions = JSON.stringify(campaign.questions);
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createReviewCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.createReviewCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateReviewCampaign(campaign) {
    delete campaign.stats;
    if (typeof campaign.questions !== "string") {
      campaign.questions = JSON.stringify(campaign.questions);
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateReviewCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.updateReviewCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteReviewCampaign(campaignId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteReviewCampaign, {
          input: { id: campaignId },
        })
      )
        .then((data) => {
          resolve(data.data.deleteReviewCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCampaign(campaign) {
    delete campaign.recipient;
    delete campaign.recipientTag;
    delete campaign.report;
    delete campaign.results;
    if (!campaign.recipientId) {
      delete campaign.recipientId;
    }
    if (!campaign.status) {
      delete campaign.status;
    }
    if (!campaign.locationId) {
      delete campaign.locationId;
    }
    if (!campaign.storeId) {
      delete campaign.storeId;
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.createCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCampaign(campaign) {
    delete campaign.recipient;
    delete campaign.recipientTag;
    delete campaign.report;
    delete campaign.results;
    if (!campaign.recipientId) {
      delete campaign.recipientId;
    }
    if (!campaign.status) {
      delete campaign.status;
    }
    if (!campaign.locationId) {
      delete campaign.locationId;
    }
    if (!campaign.storeId) {
      delete campaign.storeId;
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.updateCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCampaign(campaignId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteCampaign, {
          input: {
            id: campaignId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerList(id, filter, filterRefusal = false) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomersByGroupId, {
          id,
          filter,
          filterRefusal,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomersByGroupId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductCategories(storeId, nextToken = null, limit = null) {
    console.log("getProductCategories");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listProductCategoryByStore, {
          storeId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listProductCategoryByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductModifiers(storeId, nextToken = null, limit = null) {
    console.log("getProductModifiers");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listProductModifierByStore, {
          storeId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listProductModifierByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductsByApiKey(storeId, limit = 1000) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(custom.listProductsByStore, {
          storeId,
          limit,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.listProductsByStore?.items || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomer(customerId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getCustomer, {
          customerId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomer));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreCustomer(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.getStoreCustomer, {
          id: id,
        })
      )
        .then((data) => {
          resolve(data.data.getStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreCustomerWithTransactions(id, customerId, nextToken, limit = 300) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listCustomerByStoreWithTransactions, {
          storeId: id,
          customerId: { eq: customerId },
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomerByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createStoreProducts(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createStoreProducts, {
          input: { id: storeId },
        })
      )
        .then((data) => {
          resolve(data.data.createStoreProducts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static pickCustomer(customer) {
    // TODO: use scheme, not hard coding
    return _.pick(customer, [
      "id",
      "enterpriseId",
      "storeId",
      "locationId",
      "customerId",
      "customerCreatedAt",
      "ident",
      "identType",
      "attributes",
      "type",
      "name",
      "addresses",
      "phone",
      "email",
      "birth",
      "agreements",
      "ageGroup",
      "gender",
      "status",
      "registeredAt",
      "campaignBlockedAt",
      "isFavorite",
      "locations",
      "note",
      "tags",
      "sources",
      "removedAt",
      "updatedAt",
      "createdAt",
      "_delta",
      "timestamp",
      "expdate",
    ]);
  }

  createCustomer(customer, profileOnly) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_createCustomer, {
          customer: JSON.stringify(API.pickCustomer(customer)),
          profileOnly,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.LS_createCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCustomer(customer, profileOnly = false) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_updateCustomer, {
          customer: JSON.stringify(API.pickCustomer(customer)),
          profileOnly,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.LS_updateCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCustomer(customerId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deleteCustomer, {
          customerId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deleteCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomers(
    customerId = null,
    storeId = null,
    locationId = null,
    status = null,
    withStore = true,
    page = null,
    size = null
  ) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getCustomers, {
          customerId,
          storeId,
          locationId,
          status,
          withStore,
          page,
          size,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomers));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomersWithCount(
    storeId,
    page,
    size,
    sort,
    filters,
    search,
    locationId
  ) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getCustomersWithCount, {
          storeId,
          page,
          size,
          sort,
          search,
          filters: JSON.stringify(filters),
          extraSize: 1,
          locationId: locationId,
        }),
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomersWithCount));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerTagsWithCount(storeId, locationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getCustomerTagsWithCount, {
          storeId,
          locationId,
        }),
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerTagsWithCount));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerCountsByStatus(storeId, locationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getCustomerCountsByStatus, {
          storeId,
          locationId,
        }),
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerCountsByStatus));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * @return {obj} {id: , name:, phone:, lastVisitDate: }
   */
  getCustomersWithStats(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomersWithStats, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.listCustomersWithStats));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerWithStats(customerId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCustomerWithStats, {
          customerId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getCustomerWithStats));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendReceipt(method, recipient, order) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.sendReceipt, {
          method: method,
          recipient: recipient,
          order: JSON.stringify(order),
        })
      )
        .then((data) => {
          resolve(data.data.sendReceipt);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendReceiptLink(recipient, order) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.sendReceiptLink, {
          recipient: recipient,
          order: JSON.stringify(order),
        })
      )
        .then((data) => {
          resolve(data.data.sendReceiptLink);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // getProducts

  getItems(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getItems, { id: storeId }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getItems);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getItem(productId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getProduct, { id: productId }),
      })
        .then((data) => {
          resolve(data.data.getProduct);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getItemByApiKey(productId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(custom.getProduct, { id: productId }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getProduct);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createPointTransaction(transaction) {
    console.log("API.createPointTransaction");
    if (!transaction.salesDate) {
      delete transaction.salesDate;
    }
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(custom.createPointTransaction, {
          input: transaction,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.createPointTransaction);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCreditTransaction(transaction) {
    console.log("API.createCreditTransaction");
    if (!transaction.salesDate) {
      delete transaction.salesDate;
    }
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(custom.createCreditTransaction, {
          input: transaction,
        })
      )
        .then((data) => {
          resolve(data.data.createCreditTransaction);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createMessage(message) {
    if (message.action?.args) {
      message.action.args = JSON.stringify(message.action.args);
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.createMessage, { input: message }),
      })
        .then((data) => {
          resolve(data.data.createMessage);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInventoryHistory(historyId, quantity, cost, expiryDate) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateInventoryHistory, {
          historyId,
          quantity,
          cost,
          expiryDate,
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateInventoryHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  adjustInventories(adjustments) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_adjustInventories, {
          adjustments: adjustments,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_adjustInventories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelAdjusments(historyIds) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_cancelAdjustments, {
          historyIds,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_cancelAdjustments);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createInventoryTransferHistory(history) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_createInventoryTransferHistory, {
          history,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_createInventoryTransferHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createInventoryTransactions(adjustments) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_adjustInventories, {
          adjustments: adjustments,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_adjustInventories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateInventoryTransferHistory(history) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_updateInventoryTransferHistory, {
          history,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_updateInventoryTransferHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteInventoryTransferHistory(historyId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_deleteInventoryTransferHistory, {
          historyId,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_deleteInventoryTransferHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryTransferHistories(storeId, page, size, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.LS_getInventoryTransferHistories, {
          storeId,
          page,
          size,
          filters: filters ? JSON.stringify(filters) : null,
        }),
      })
        .then((data) => {
          resolve(data.data.LS_getInventoryTransferHistories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getInventoryCountHistories(storeId, page, size, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getInventoryCountHistories, {
          storeId,
          page,
          size,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getInventoryCountHistories);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  arrangeProductLocation(storeId, defaults = null) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.arrangeProductLocation, {
          storeId,
          defaults: defaults ? JSON.stringify(defaults) : null,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.arrangeProductLocation));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSkuLocation(location) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateSkuLocation, {
          location: JSON.stringify(location),
        })
      )
        .then((data) => {
          resolve(data.data.updateSkuLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createItem(item, options = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createItem, {
          item: JSON.stringify(item),
          options: options ? JSON.stringify(options) : null,
        })
      )
        .then((data) => {
          resolve(data.data.createItem);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateItem(item, options = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateItem, {
          item: JSON.stringify(item),
          options: options ? JSON.stringify(options) : null,
        })
      )
        .then((data) => {
          resolve(data.data.updateItem);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteItem(item) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteItem, { item: JSON.stringify(item) })
      )
        .then((data) => {
          resolve(data.data.deleteItem);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSalesOrder(orderId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getSalesOrder, {
          id: orderId,
        })
      )
        .then((data) => {
          const order = data.data.getSalesOrder;
          resolve(
            order && order.payment
              ? {
                  ...order,
                  payment: JSON.parse(order.payment),
                }
              : order
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createSalesOrder(order) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createSalesOrder, {
          input: order,
        })
      )
        .then((data) => {
          resolve(data.data.createSalesOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreStats(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.getStoreStats, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.getStoreStats));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generatePGCode(
    amount,
    ident,
    name,
    address1,
    phone,
    email,
    itemName,
    returnUrl
  ) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.generatePgCode, {
          amount,
          ident,
          name,
          address1,
          phone,
          email,
          itemName,
          returnUrl,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.generatePgCode));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreProducts(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getStoreProducts, { id: storeId }),
        // authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getStoreProducts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProducts(storeId, nextToken = null, limit = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listProductsByStore, {
          storeId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listProductsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCategories(storeId, nextToken = null, limit = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listCategoriesByStore, {
          storeId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCategoriesByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductCategoriesByCategory(
    categoryId,
    productId,
    nextToken = null,
    limit = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listProductCategoryByCategory, {
          categoryId,
          productId: productId ? { eq: productId } : {},
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listProductCategoryByCategory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductModifiersByModifier(
    modifierId,
    productId,
    nextToken = null,
    limit = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listProductModifierByModifier, {
          modifierId,
          productId: productId ? { eq: productId } : {},
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listProductModifierByModifier);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getModifiers(storeId, nextToken = null, limit = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(custom.listModifiersByStore, {
          storeId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listModifiersByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getEmployee(employeeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(queries.getEmployee, { id: employeeId }))
        .then((data) => {
          resolve(data.data.getEmployee);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getEmployeeByEmail(email) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(custom.LS_getEmployeeByEmail, { email }))
        .then((data) => {
          resolve(data.data.LS_getEmployeeByEmail);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createOrganization(organization) {
    delete organization.employee;
    delete organization.store;
    if (!organization.locationId) {
      delete organization.locationId;
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createOrganization, { input: organization })
      )
        .then((data) => {
          resolve(data.data.createOrganization);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOrganization(organization) {
    delete organization.employee;
    delete organization.store;
    if (!organization.locationId) {
      delete organization.locationId;
    }
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateOrganization, {
          input: organization,
        })
      )
        .then((data) => {
          resolve(data.data.updateOrganization);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteOrganization(organizationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteOrganization, {
          input: {
            id: organizationId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteOrganization);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // NOTE: Deprecated
  retrieveBizNumber(bizNumber) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.retrieveBizNumber, {
          bizNumber: bizNumber,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.retrieveBizNumber));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  submitCard(email, phone, storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.submitCard, {
          email,
          phone,
          storeId,
        })
      )
        .then((data) => {
          resolve(data.data.submitCard);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStore(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(custom.getStore, { id: storeId }))
        .then((data) => {
          resolve(data.data.getStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreByApiKey(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(custom.getStoreByApiKey, { id: storeId }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreContractsByBusinessOperator(businessOperatorId, nextToken, limit) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.listStoreContractByBusinessOperator, {
          businessOperatorId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listStoreContractByBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationContractsByBusinessOperator(businessOperatorId, nextToken, limit) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationContractByBusinessOperator, {
          businessOperatorId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationContractByBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreLoyaltyByApiKey(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(custom.getStoreLoyalty, {
          id: storeId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationByApiKey(locationId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getLocation, {
          id: locationId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreLocationsByApiKey(storeId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql({
        ...graphqlOperation(queries.listLocationsByStore, {
          storeId,
          nextToken,
          limit,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.listLocationsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreLocation(locationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(graphqlOperation(queries.getLocation, { id: locationId }))
        .then((data) => {
          resolve(data.data.getLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreLocations(storeId, nextToken, limit) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationsByStore, {
          storeId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCart(cartId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCustomerCart, {
          id: cartId,
        })
      )
        .then((data) => {
          resolve(data.data.getCustomerCart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  registerAccount(account) {
    const { ident, name, businessNumber, bankCode, number, holder } = account;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.registerAccount, {
          ident,
          name,
          businessNumber,
          bankCode,
          number,
          holder,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.registerAccount));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRequestProgressEventsByOrigin(
    originId,
    createdAt = null,
    filter = null,
    nextToken = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listRequestProgressEventByOrigin, {
          originId,
          createdAt,
          filter,
          nextToken,
          limit: 500,
        })
      )
        .then((data) => {
          resolve(data.data.listRequestProgressEventByOrigin);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateAccount(account) {
    const { ident, name, businessNumber, bankCode, number, holder } = account;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.updateAccount, {
          ident,
          name,
          businessNumber,
          bankCode,
          number,
          holder,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.updateAccount));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCustomerGroup(group) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createCustomerGroup, {
          input: group,
        })
      )
        .then((data) => {
          resolve(data.data.createCustomerGroup);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCustomerGroup(group) {
    delete group.list;
    delete group.count;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateCustomerGroup, {
          input: group,
        })
      )
        .then((data) => {
          resolve(data.data.updateCustomerGroup);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCustomerGroup(groupId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteCustomerGroup, {
          input: {
            id: groupId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteCustomerGroup);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCustomerTag(tag) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createCustomerTag, {
          input: tag,
        })
      )
        .then((data) => {
          resolve(data.data.createCustomerTag);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  applyCustomerTag(tag) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_applyCustomerTag, {
          tag: JSON.stringify(tag),
        })
      )
        .then((data) => {
          resolve(data.data.LS_applyCustomerTag);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerTags(storeId, locationId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomerTagsByStore, {
          storeId,
          locationId: locationId ? { eq: locationId } : null,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomerTagsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerTag(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCustomerTag, {
          id,
        })
      )
        .then((data) => {
          resolve(data.data.getCustomerTag);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerTagsByStore(storeId, nextToken, limit, filter = null) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomerTagsByStoreId, {
          storeId,
          limit,
          nextToken,
          filter,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomerTagsByStoreId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCustomerTagsByLocation(locationId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCustomerTagsByLocationId, {
          locationId,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCustomerTagsByLocationId);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCustomerTag(tag) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateCustomerTag, {
          input: tag,
        })
      )
        .then((data) => {
          resolve(data.data.updateCustomerTag);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCustomerTag(tagId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteCustomerTag, {
          input: {
            id: tagId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteCustomerTag);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createCart(cart) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createCustomerCart, {
          input: removeTypename(cart),
        })
      )
        .then((data) => {
          resolve(data.data.createCustomerCart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateCart(cart) {
    delete cart.store;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateCustomerCart, {
          input: removeTypename(cart),
        })
      )
        .then((data) => {
          resolve(data.data.updateCustomerCart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCart(cartId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteCustomerCart, {
          input: {
            id: cartId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteCustomerCart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createOrder(order, hasDelta = false) {
    console.log("API.createOrder");
    delete order.customer;
    delete order.location;
    delete order.expdate;
    delete order.timestamp;
    delete order.orderCustomer;
    // NOTE: index key롤 사용되는 값들은 null을 넣을 수 없음
    if (!order.orderCustomerId) {
      delete order.orderCustomerId;
    }
    if (!order.customerId) {
      delete order.customerId;
    }
    if (!order.receiptCustomerId) {
      delete order.receiptCustomerId;
    }
    if (!order.receiptNumber) {
      delete order.receiptNumber;
    }
    if (!order.originId) {
      delete order.originId;
    }
    if (!order.request) {
      delete order.request;
    }
    if (!order.shippingLocationId) {
      delete order.shippingLocationId;
    }
    console.log(order);
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(custom.createOrder, { input: order }))
        .then((data) => {
          let _order = {
            ..._.cloneDeep(data.data.createOrder),
            timestamp: parseInt(new Date().getTime() / 1000, 10),
            expdate: getExpDate("OrderDelta"),
          };
          delete _order.location;
          delete _order.customer;
          delete _order.orderCustomer;
          if (!_order.shippingLocationId) {
            delete _order.shippingLocationId;
          }
          AWSAPI.graphql(
            graphqlOperation(mutations.createOrderDelta, {
              input: _order,
            })
          )
            .then((delta) => {
              resolve(data.data.createOrder);
            })
            .catch((err) => {
              console.log(err);
              AWSAPI.graphql(
                graphqlOperation(mutations.updateOrderDelta, {
                  input: removeTypename(_order),
                })
              )
                .then((delta) => {
                  resolve(data.data.createOrder);
                })
                .catch((_err) => {
                  reject(_err);
                });
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOrder(order) {
    order = _.cloneDeep(order);
    order.updatedDeviceId = null;
    delete order.timestamp;
    delete order.expdate;
    delete order.customer;
    delete order.location;
    delete order.orderCustomer;
    if (!order.orderCustomerId) {
      delete order.orderCustomerId;
    }
    if (!order.customerId) {
      delete order.customerId;
    }
    if (!order.receiptCustomerId) {
      delete order.receiptCustomerId;
    }
    if (!order.receiptNumber) {
      delete order.receiptNumber;
    }
    if (!order.originId) {
      delete order.originId;
    }
    if (!order.request) {
      delete order.request;
    }
    if (!order.shippingLocationId) {
      delete order.shippingLocationId;
    }
    delete order.waitingTime;
    delete order.tableNumber;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(custom.updateOrder, {
          input: removeTypename(order),
        })
      )
        .then((data) => {
          let _order = {
            ..._.cloneDeep(data.data.updateOrder),
            timestamp: parseInt(new Date().getTime() / 1000, 10),
            expdate: getExpDate("OrderDelta"),
          };
          delete _order.location;
          delete _order.customer;
          delete _order.orderCustomer;
          if (!_order.shippingLocationId) {
            delete _order.shippingLocationId;
          }
          AWSAPI.graphql(
            graphqlOperation(mutations.updateOrderDelta, {
              input: removeTypename(_order),
            })
          )
            .then((delta) => {
              resolve(data.data.updateOrder);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteOrder(orderId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteOrder, {
          input: {
            id: orderId,
          },
        })
      )
        .then((data) => {
          if (data.data.deleteOrder) {
            let _order = {
              ..._.cloneDeep(data.data.deleteOrder),
              timestamp: parseInt(new Date().getTime() / 1000, 10),
              expdate: getExpDate("OrderDelta"),
              _delta: "DELETE",
            };
            delete _order.customer;
            delete _order.location;
            AWSAPI.graphql(
              graphqlOperation(mutations.updateOrderDelta, {
                input: removeTypename(_order),
              })
            )
              .then((delta) => {
                resolve(data.data.deleteOrder);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve({ id: orderId });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateStore(store) {
    delete store.groups;
    delete store.location;
    delete store.locations;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateStore, {
          input: removeTypename(store),
        })
      )
        .then((data) => {
          resolve(data.data.updateStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createLocation(location) {
    delete location.stats;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.createLocation, {
          input: removeTypename(location),
        })
      )
        .then((data) => {
          resolve(data.data.createLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateLocation(location) {
    delete location.stats;
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateLocation, {
          input: removeTypename(location),
        })
      )
        .then((data) => {
          resolve(data.data.updateLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNoticeCampaignResultsByLocation(
    loacationId,
    nextToken = null,
    limit = null
  ) {
    let input = {
      recipientLocationId: loacationId,
    };
    if (limit) {
      input.limit = limit;
    }
    if (nextToken) {
      input.nextToken = nextToken;
    }

    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listNoticeCampaignResultsByLocation, input)
      )
        .then((data) => {
          resolve(data.data.listNoticeCampaignResultsByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNoticeCampaigns(storeId, nextToken = null, limit = null) {
    let input = {
      storeId: storeId,
    };
    if (limit) {
      input.limit = limit;
    }
    if (nextToken) {
      input.nextToken = nextToken;
    }

    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listNoticeCampaignsByStore, input)
      )
        .then((data) => {
          resolve(data.data.listNoticeCampaignsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNotices(storeId, status = null, nextToken = null, limit = null) {
    let input = {
      storeId: storeId,
    };
    if (limit) {
      input.limit = limit;
    }
    if (nextToken) {
      input.nextToken = nextToken;
    }
    if (status) {
      input.status = { eq: status };
    }

    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(queries.listNoticesByStore, input))
        .then((data) => {
          resolve(data.data.listNoticesByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNotice(noticeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(queries.getNotice, { id: noticeId }))
        .then((data) => {
          resolve(data.data.getNotice);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createNoticeCampaign(campaign) {
    delete campaign.notice;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createNoticeCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.createNoticeCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateNoticeCampaign(campaign) {
    delete campaign.notice;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateNoticeCampaign, {
          input: campaign,
        })
      )
        .then((data) => {
          resolve(data.data.updateNoticeCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteNoticeCampaign(campaignId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteNoticeCampaign, {
          input: {
            id: campaignId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteNoticeCampaign);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createNoticeCampaignResult(result) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createNoticeCampaignResult, {
          input: result,
        })
      )
        .then((data) => {
          resolve(data.data.createNoticeCampaignResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createNotice(notice) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createNotice, {
          input: notice,
        })
      )
        .then((data) => {
          resolve(data.data.createNotice);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateNotice(notice) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateNotice, {
          input: notice,
        })
      )
        .then((data) => {
          resolve(data.data.updateNotice);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteNotice(noticeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteNotice, {
          input: {
            id: noticeId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteNotice);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateItemTemplate(storeId, stockManageable, excelType) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.generateItemTemplate, {
          storeId,
          stockManageable,
          excelType,
        })
      )
        .then((data) => {
          resolve(data.data.generateItemTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  parseItemTemplate(storeId, key, locationId, resource, excelType) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.parseItemTemplate, {
          storeId,
          key,
          locationId,
          resource,
          excelType,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.parseItemTemplate));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  syncItemTemplate(storeId, key, excelType) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.syncItemTemplate, {
          storeId,
          key,
          excelType,
        })
      )
        .then((data) => {
          resolve(data.data.syncItemTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateInventoryTemplate(storeId, locationId, reason, type) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.generateInventoryTemplate, {
          storeId,
          locationId,
          reason,
          type,
        })
      )
        .then((data) => {
          resolve(data.data.generateInventoryTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  parseInventoryTemplate(storeId, employeeId, key, reason, type) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.parseInventoryTemplate, {
          storeId,
          employeeId,
          key,
          reason,
          type,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.parseInventoryTemplate));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  syncInventoryTemplate(storeId, key, reason, type) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.syncInventoryTemplate, {
          storeId,
          key,
          reason,
          type,
        })
      )
        .then((data) => {
          resolve(data.data.syncInventoryTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  applyInventoryTransferHistory(history, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.applyInventoryTransferHistory, {
          history,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.applyInventoryTransferHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelInventoryTransferHistory(history, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.cancelInventoryTransferHistory, {
          history,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.cancelInventoryTransferHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  applyPurchaseOrder(purchaseOrder, adjustments, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.applyPurchaseOrder, {
          purchaseOrder,
          adjustments,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.applyPurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelPurchaseOrder(purchaseOrder, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.cancelPurchaseOrder, {
          purchaseOrder,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.cancelPurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  applyInventoryAdjustments(adjustments, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.applyInventoryAdjustments, {
          adjustments,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.applyInventoryAdjustments);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  applyInventoryCountHistory(history, origin, originId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.applyInventoryCountHistory, {
          history,
          origin,
          originId,
        })
      )
        .then((data) => {
          resolve(data.data.applyInventoryCountHistory);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  requestCashReceipt(data) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(graphqlOperation(queries.requestCashReceipt, data))
        .then((data) => {
          resolve(JSON.parse(data.data.requestCashReceipt));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  subscribeStoreStatusUpdated(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedStoreStatus, {
        id: storeId,
      })
    ).subscribe({
      next: (data) => {
        callback(data.value.data.onUpdatedStoreStatus);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  subscribeRequestPrgressEvent(key, originId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedRequestProgressEvent, {
        originId,
      })
    ).subscribe({
      next: (data) => {
        callback(data.value.data.onUpdatedRequestProgressEvent);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  subscribeProductUpdated(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedProduct, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onUpdatedProduct),
      error: (err) => console.log(err),
    });
  }

  subscribeProductDeleted(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onDeletedProduct, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onDeletedProduct),
      error: (err) => console.log(err),
    });
  }

  subscribeOrderUpdated(key, customerId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onOrderDeltaByCustomer, {
        // graphqlOperation(subscriptions.onOrderDelta, {
        customerId: customerId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onOrderDeltaByCustomer),
      // next: (data) => callback(data.value.data.onOrderDelta),
      error: (err) => console.log("subscribe error", err),
    });
  }

  subscribeOrderDeltaChanged(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onOrderDeltaByStore, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onOrderDeltaByStore),
      error: (err) => console.log("subscribe error", err),
    });
  }

  subscribeCategoryUpdated(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedCategory, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onUpdatedCategory),
      error: (err) => console.log(err),
    });
  }

  subscribeCategoryDeleted(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onDeletedCategory, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onDeletedCategory),
      error: (err) => console.log(err),
    });
  }

  subscribeModifierUpdated(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedModifier, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onUpdatedModifier),
      error: (err) => console.log(err),
    });
  }

  subscribeModifierDeleted(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onDeletedModifier, {
        storeId: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onDeletedModifier),
      error: (err) => console.log(err),
    });
  }

  subscribeItemsChanged(key, storeId, callback) {
    this._subscribers[key] = AWSAPI.graphql(
      graphqlOperation(subscriptions.onUpdatedItems, {
        id: storeId,
      })
    ).subscribe({
      next: (data) => callback(data.value.data.onUpdatedItems),
      error: (err) => console.log(err),
    });
  }

  unsubscribe(key) {
    if (_.has(this._subscribers, key)) {
      this._subscribers[key].unsubscribe();
      delete this._subscribers[key];
    }
  }

  unsubscribeAll() {
    for (let key of Object.keys(this._subscribers)) {
      this._subscribers[key].unsubscribe();
    }
    this._subscribers = {};
  }

  createTerminal(terminal) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createPaymentTerminal, {
          input: terminal,
        })
      )
        .then((data) => {
          resolve(data.data.createPaymentTerminal);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateTerminal(terminal) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updatePaymentTerminal, {
          input: terminal,
        })
      )
        .then((data) => {
          resolve(data.data.updatePaymentTerminal);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteTerminal(terminalId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.deletePaymentTerminal, {
          input: {
            id: terminalId,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deletePaymentTerminal);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminal(terminalId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.getPaymentTerminal, {
          id: terminalId,
        })
      )
        .then((data) => {
          resolve(data.data.getPaymentTerminal);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminals(nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPaymentTerminals, { nextToken, limit })
      )
        .then((data) => {
          resolve(data.data.listPaymentTerminals);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminalsByBusinessNumber(businessNumber, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPaymentTerminalByBusinessNumber, {
          businessNumber,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listPaymentTerminalByBusinessNumber);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminalsByBusinessOperator(businessOperatorId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPaymentTerminalByBusinessOperator, {
          businessOperatorId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listPaymentTerminalByBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminalsByStore(storeId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPaymentTerminalByStore, {
          storeId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listPaymentTerminalByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getTerminalsByLocation(locationId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listPaymentTerminalByLocation, {
          locationId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listPaymentTerminalByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRentalContractsByBusinessOperator(businessOperatorId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listRentalContractByBusinessOperator, {
          businessOperatorId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listRentalContractByBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRentalContractsByStore(storeId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listRentalContractByStore, {
          storeId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listRentalContractByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRentalContractsByByLocation(locationId, nextToken, limit) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listRentalContractByLocation, {
          locationId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listRentalContractByLocation);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPurchaseOrders(storeId, locationId, page, size) {
    console.log("API.getPurchaseOrders");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getPurchaseOrders, {
          storeId,
          locationId,
          page,
          size,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getPurchaseOrders);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPurchaseOrder(purchaseOrderId) {
    console.log("API.getPurchaseOrder");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getPurchaseOrder, {
          purchaseOrderId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getPurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createPurchaseOrder(order) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_createPurchaseOrder, {
          purchaseOrder: order,
        })
      )
        .then((data) => {
          resolve(data.data.LS_createPurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updatePurchaseOrder(order) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updatePurchaseOrder, {
          purchaseOrder: removeTypename(order),
        })
      )
        .then((data) => {
          resolve(data.data.LS_updatePurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deletePurchaseOrder(orderId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deletePurchaseOrder, {
          purchaseOrderId: orderId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deletePurchaseOrder);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  payBillings(storeId, locationId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_payBillings, {
          storeId,
          locationId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_payBillings);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOrderCountFromReport(storeId, filters) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getOrderCount, {
          storeId,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getOrderCount);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generateReport(storeId, item, start, end) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.generateReport, {
          storeId,
          item,
          start,
          end,
        })
      )
        .then((data) => {
          resolve(data.data.generateReport);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendReportToEmail(storeId, item, filters, recepient) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_sendReport, {
          storeId,
          item,
          recepient,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_sendReport);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStoreContract(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getStoreContract, {
          id: id,
        })
      )
        .then((data) => {
          resolve(data.data.getStoreContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createStoreContract(contract) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createStoreContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.createStoreContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateStoreContract(contract) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateStoreContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.updateStoreContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationContract(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getLocationContract, {
          id: id,
        })
      )
        .then((data) => {
          resolve(data.data.getLocationContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createLocationContract(contract) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createLocationContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.createLocationContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateLocationContract(contract) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateLocationContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.updateLocationContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBusinessOperator(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getBusinessOperator, {
          id: id,
        })
      )
        .then((data) => {
          resolve(data.data.getBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createBusinessOperator(operator) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createBusinessOperator, {
          input: operator,
        })
      )
        .then((data) => {
          resolve(data.data.createBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateBusinessOperator(operator) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateBusinessOperator, {
          input: operator,
        })
      )
        .then((data) => {
          resolve(data.data.updateBusinessOperator);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createRentalContract(contract) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createRentalContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.createRentalContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateRentalContract(contract) {
    delete contract.businessOperator;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateRentalContract, {
          input: contract,
        })
      )
        .then((data) => {
          resolve(data.data.updateRentalContract);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLabelTemplatesByStore(storeId) {
    console.log("API getLabelTemplatesByStore", storeId);
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listLabelTemplateByStore, {
          storeId,
        })
      )
        .then((data) => {
          resolve(data.data.listLabelTemplateByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createLabelTemplate(template) {
    if (!template.locationId) {
      delete template.locationId;
    }
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createLabelTemplate, {
          input: template,
        })
      )
        .then((data) => {
          resolve(data.data.createLabelTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateLabelTemplate(template) {
    delete template.horizontalCount;
    delete template.labelSetting;
    delete template.pageSetting;
    delete template.paperType;
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateLabelTemplate, {
          input: template,
        })
      )
        .then((data) => {
          resolve(data.data.updateLabelTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteLabelTemplate(id) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteLabelTemplate, {
          input: {
            id,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteLabelTemplate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSuppliers(storeId, locationId = null) {
    console.log("API.getSuppliers");
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getSuppliers, {
          storeId,
          locationId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getSuppliers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createSupplier(supplier) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_createSupplier, { supplier })
      )
        .then((data) => {
          resolve(data.data.LS_createSupplier);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSupplier(supplier) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateSupplier, {
          supplier,
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateSupplier);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteSupplier(supplierId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_deleteSupplier, {
          supplierId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_deleteSupplier);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getEnvironment(id) {
    console.log("API.getEnvironment");
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getEnvironment, {
          id,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getEnvironment);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getBarcodeCouponTransactions(couponId, recepient = null) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getBarcodeCouponTransactions, {
          couponId,
          recepient,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getBarcodeCouponTransactions));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCouponTransactionsByCouponStoreCustomer(
    couponTransactionsId,
    couponTransactionCustomerId = null,
    filter = null,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listCouponTransactionsByCouponStoreCustomer, {
          couponTransactionsId,
          couponTransactionCustomerId: couponTransactionCustomerId
            ? { eq: couponTransactionCustomerId }
            : null,
          sortDirection: "DESC",
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listCouponTransactionsByCouponStoreCustomer);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createBarcodeCouponTransactions(transactions) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_createBarcodeCouponTransactions, {
          transactions,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.LS_createBarcodeCouponTransactions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  registerProvider(storeId, providerId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_registerProvider, {
          storeId,
          provider: "cafe24",
          providerId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_registerProvider);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getExternalLoyaltyConfig(storeId, provider) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getLoyaltyConfig, {
          storeId,
          provider,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getLoyaltyConfig);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderCustomers(storeId, locationId, filters) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_getProviderCustomers, {
          storeId,
          locationId,
          filters: filters ? JSON.stringify(filters) : null,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderCustomers).items || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderConfig(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.getStoreCafe24Config, {
          id: storeId,
        })
      )
        .then((data) => {
          resolve(data.data.getStoreCafe24Config);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateProviderConfig(config) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateStoreCafe24Config, {
          input: config,
        })
      )
        .then((data) => {
          resolve(data.data.updateStoreCafe24Config);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProviderCoupons(storeId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderCoupons, {
          storeId,
          couponId: null,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderCoupons).items || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStores(nextToken, limit) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.listStores, {
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listStores);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationsByStore(storeId, nextToken, limit) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(queries.listLocationsByStore, {
          storeId,
          nextToken,
          limit,
        })
      )
        .then((data) => {
          resolve(data.data.listLocationsByStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getWaitingDelta(waitingId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(queries.getWaitingDelta, {
          id: waitingId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.getWaitingDelta);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getWaitingInfo(storeId, locationId, salesDate, waitingId) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_getWaitingStatus, {
          storeId: storeId,
          locationId: locationId,
          salesDate,
          waitingId,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getWaitingStatus));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cancelWaiting(waiting) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql({
        ...graphqlOperation(mutations.LS_updateWaiting, {
          waiting: waiting,
        }),
        authMode: AUTH_TYPE.API_KEY,
      })
        .then((data) => {
          resolve(data.data.LS_updateWaiting);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  chargeCredit(storeId, amount, quantity, locationId = null) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_chargeStoreCredit, {
          storeId,
          amount,
          quantity,
          locationId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_chargeStoreCredit);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditStatus(storeId, locationId = null) {
    return new Promise((resolve, reject) => {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getStoreCreditStatus, {
          storeId,
          locationId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getStoreCreditStatus));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCreditTransactionHistories(
    storeId,
    page,
    size,
    locationId = null,
    filters = null
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getStoreCreditTransactions, {
          storeId,
          page,
          size,
          locationId,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(data.data.LS_getStoreCreditTransactions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  syncProviderOrders(storeId, startDate) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_syncProviderOrders, {
          storeId,
          start: startDate,
        })
      )
        .then((data) => {
          resolve(data.data.LS_syncProviderOrders);
        })
        .catch((err) => reject(err));
    });
  }

  getProviderProducts(storeId, size, since, search, searchType, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_getProviderProducts, {
          storeId,
          searchType,
          search,
          size,
          since,
          filters: JSON.stringify(filters),
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getProviderProducts));
        })
        .catch((err) => reject(err));
    });
  }

  linkProviderProducts(
    storeId,
    options,
    pairs = [],
    excludeKeys = [],
    employeeId
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_linkProviderProducts, {
          storeId,
          options,
          pairs,
          excludeKeys,
          employeeId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_linkProviderProducts);
        })
        .catch((err) => reject(err));
    });
  }

  unlinkProviderProducts(storeId, skuIds) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_unlinkProviderProducts, {
          storeId,
          skuIds,
        })
      )
        .then((data) => {
          resolve(data.data.LS_unlinkProviderProducts);
        })
        .catch((err) => reject(err));
    });
  }

  syncProviderInventories(storeId, employeeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_syncProviderInventories, {
          storeId,
          employeeId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_syncProviderInventories);
        })
        .catch((err) => reject(err));
    });
  }

  getInventorySyncHistories(storeId, page, size, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getInventorySyncHistories, {
          storeId,
          page,
          size,
          filters: JSON.stringify(filters),
        })
      )
        .then((data) => {
          resolve(data.data.LS_getInventorySyncHistories);
        })
        .catch((err) => reject(err));
    });
  }

  getProviderProductsLinkHistories(
    storeId,
    createdAt,
    filter = null,
    limit,
    nextToken
  ) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.listItemLinkHistoryByStore, {
          storeId,
          createdAt,
          sortDirection: "DESC",
          filter,
          limit,
          nextToken,
        })
      )
        .then((data) => {
          resolve(data.data.listItemLinkHistoryByStore);
        })
        .catch((err) => reject(err));
    });
  }

  syncProviderProducts(storeId, skuIds, excludeKeys) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_syncProviderProducts, {
          storeId,
          skuIds,
          excludeKeys,
        })
      )
        .then((data) => {
          resolve(data.data.LS_syncProviderProducts);
        })
        .catch((err) => reject(err));
    });
  }

  getOnoffConfig(storeId, stage) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getOnoffConfig, {
          storeId,
          stage,
        })
      )
        .then((data) => {
          resolve(data.data.getOnoffConfig);
        })
        .catch((err) => reject(err));
    });
  }

  getCafe24Auth(ident) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.getCafe24Auth, {
          id: ident,
        })
      )
        .then((data) => {
          resolve(data.data.getCafe24Auth);
        })
        .catch((err) => reject(err));
    });
  }

  createOnoffConfig(config) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.createOnoffConfig, {
          input: config,
        })
      )
        .then((data) => {
          resolve(data.data.createOnoffConfig);
        })
        .catch((err) => reject(err));
    });
  }

  updateOnoffConfig(config) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.updateOnoffConfig, {
          input: config,
        })
      )
        .then((data) => {
          resolve(data.data.updateOnoffConfig);
        })
        .catch((err) => reject(err));
    });
  }

  deleteOnoffConfig(storeId, stage) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.deleteOnoffConfig, {
          input: {
            storeId,
            stage,
          },
        })
      )
        .then((data) => {
          resolve(data.data.deleteOnoffConfig);
        })
        .catch((err) => reject(err));
    });
  }

  getCherryPickers(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCustomerCherrypickers, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerCherrypickers));
        })
        .catch((err) => reject(err));
    });
  }

  getBlackList(storeId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCustomerBlacklists, {
          storeId,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerBlacklists));
        })
        .catch((err) => reject(err));
    });
  }

  getCustomerTierHistories(storeId, filters) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(queries.LS_getCustomerTierHistories, {
          storeId,
          filters: filters ? JSON.stringify(filters) : null,
        })
      )
        .then((data) => {
          resolve(JSON.parse(data.data.LS_getCustomerTierHistories));
        })
        .catch((err) => reject(err));
    });
  }

  updateCustomerTier(storeId, customerId, tierId) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateCustomerTier, {
          storeId,
          customerId,
          tierId,
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateCustomerTier);
        })
        .catch((err) => reject(err));
    });
  }

  updateCustomerBlackList(storeId, customerId, active) {
    return new Promise(function (resolve, reject) {
      AWSAPI.graphql(
        graphqlOperation(mutations.LS_updateCustomerBlacklist, {
          storeId,
          customerId,
          active,
        })
      )
        .then((data) => {
          resolve(data.data.LS_updateCustomerBlacklist);
        })
        .catch((err) => reject(err));
    });
  }
}

const instance = new API();
export default instance;
