import {
  // take,
  put,
  call,
  fork,
  select,
  takeEvery,
  all,
  delay,
} from "redux-saga/effects";
import _ from "lodash";
import { Auth, Storage } from "aws-amplify";
import * as dateFns from "date-fns";
import moment from "moment";
import html2pdf from "html2pdf.js";

import Constants from "./../helpers/Constants";
import * as actions from "../actions";
import * as reducers from "../reducers";
import API from "../services/API";
import {
  addressToStr,
  hashMd5,
  downloadCSV,
  generateId,
  diffSet,
  numberToCurrency,
  isLegacyInventoryHistories,
  maskingName,
  maskingPhoneNumber,
  generateBarcode,
} from "../helpers/Utils";
import Authority from "../services/Authority";
import Analytics from "../services/Analytics";
import * as fromMarket from "../reducers/market";
import {
  BasicSample,
  BulkActionInfo,
  BulkActionHeader,
  BulkActionItems,
  TransferHeader,
  TransferInfo,
  TransferItems,
  CountInfo,
  CountItems,
  CountHeader,
  NoteSample,
  TitleSample,
  PurchaseOrderHeader,
  PurchaseOrderInfo,
  PurchaseOrderItems,
  PurchaseOrderCostInfo,
  PurchaseOrderSample,
} from "../helpers/PdfMaker";
import {
  CustomSpinner,
  GetInventoryTransactionReason,
} from "../components/Elements";
import {
  TABLE_GROUP_HEADERS,
  TABLE_HEADERS,
} from "../components/StockHistoryList";
import {
  getAssetHistoryReason,
  getHistoryFileNameByType,
  getOriginReason,
} from "../reducers/inventory";
import { ASSET_TABLE_HEADER } from "../pages/StockReport";
import { deleteCookie, getCookie } from "../helpers/Cookie";
import { getOrderNumber } from "../reducers/order";
import { isCurrentLocationAvailableCoupon } from "../reducers/coupon";

export function* generateStore({ employee, survey, settings }) {
  try {
    const _settings = {
      ...(settings || {}),
      generateItems: true,
    };
    const store = yield call(API.generateStore, employee, survey, _settings);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "generateStore", {
      employee,
      survey,
      settings,
    });
  }
}

export function* watchGenerateStore() {
  yield takeEvery(actions.GENERATE_STORE, generateStore);
}

function* _setMixPanelInfo(store) {
  const employee = yield select(reducers.getEmployee);
  const _store = store.info;
  const _location = yield select(reducers.getCurrentLocation);
  const isMultiLocation = yield select(reducers.isMultiLocation);
  if (isMultiLocation) {
    Analytics.setInfo(
      store.id,
      employee.email,
      _location.id,
      `${_store.name}-${_location.name}`,
      isMultiLocation,
      _store,
      _location.createdAt
    );
  } else {
    Analytics.setInfo(
      store.id,
      employee.email,
      employee.locationId || store.location.id,
      _store.name,
      false,
      _store,
      _location.createdAt
    );
  }
}

function* _getStoreContract(storeId) {
  const storeContract = yield call(API.getStoreContract, storeId);
  yield put(actions.receiveStoreContract(storeContract));
}

function* _syncDiscountsByTier(storeId, providerCustomerGroups) {
  const newTierCoupons = [];
  providerCustomerGroups.forEach((group) => {
    if (
      group.discount &&
      group.discount.discountMethod &&
      group.discount.discountValue
    ) {
      newTierCoupons.push({
        tier: group.name,
        discountMethod: group.discount.discountMethod,
        discountValue: group.discount.discountValue,
      });
    }
  });

  const newTierCouponsByTier = newTierCoupons.reduce((obj, coupon) => {
    obj[coupon.tier] = coupon;
    return obj;
  }, {});

  let oldTierCoupons = [];
  let data = {};
  do {
    data = yield call(API.getCoupons, storeId, data.nextToken, 500, {
      conditionType: { eq: "tier" },
    });
    oldTierCoupons = [...oldTierCoupons, ...data.items];
  } while (data.nextToken);

  const oldTierCouponsByTier = oldTierCoupons.reduce((obj, coupon) => {
    obj[coupon.conditionTier] = coupon;
    return obj;
  }, {});

  const addCoupons = newTierCoupons.filter(
    (newCoupon) => !(newCoupon.tier in oldTierCouponsByTier)
  );
  yield all(
    addCoupons.map((coupon) =>
      call(API.createCoupon, {
        id: generateId("cpn"),
        storeId: storeId,
        name: `${coupon.tier} 등급 할인`,
        type: "auto",
        conditionType: "tier",
        conditionTier: coupon.tier,
        discountMethod: coupon.discountMethod,
        discountValue: coupon.discountValue,
        applyScope: "all",
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      })
    )
  );

  let deleteCoupons = [];
  let updateCoupons = [];

  for (const oldCoupon of oldTierCoupons) {
    if (!(oldCoupon.conditionTier in newTierCouponsByTier)) {
      deleteCoupons.push(oldCoupon);
    } else if (oldCoupon.conditionTier in newTierCouponsByTier) {
      const _newCoupon = newTierCouponsByTier[oldCoupon.conditionTier];
      const _oldCoupon = oldTierCouponsByTier[oldCoupon.conditionTier];
      if (
        _oldCoupon.discountMethod !== _newCoupon.discountMethod ||
        _oldCoupon.discountValue !== _newCoupon.discountValue
      ) {
        updateCoupons.push(_newCoupon);
      }
    }
  }

  yield all(deleteCoupons.map((coupon) => call(API.deleteCoupon, coupon.id)));
  yield all(
    updateCoupons.map((coupon) =>
      call(API.updateCoupon, {
        ...oldTierCouponsByTier[coupon.tier],
        discountValue: coupon.discountValue,
        discountMethod: coupon.discountMethod,
        updatedAt: new Date().toISOString(),
      })
    )
  );
}

export function* getStore({ storeId, withStats }) {
  try {
    let store = yield call(_getStoreWithLocations, storeId);
    const mid = getCookie("mid");
    console.log("mid", mid);
    if (mid) {
      const providerKey = "cafe24";
      yield call(API.registerProvider, store.id, mid);
      const loyalty = yield call(
        API.getExternalLoyaltyConfig,
        store.id,
        providerKey
      );
      const locationId = generateId("loc", [storeId, mid]);
      const loc = yield call(API.getStoreLocation, locationId);
      console.log("locationId", locationId);
      if (!loc) {
        yield call(API.createLocation, {
          id: locationId,
          storeId,
          type: "external",
          name: "카페24",
          provider: providerKey,
        });
      }
      yield call(API.updateStore, {
        ...store,
        policy: {
          ...(store.policy || {}),
          provider: {
            ...(store.policy?.provider || {}),
            key: providerKey,
            ident: mid,
            customer: true,
            coupon: true,
            locationId,
          },
        },
        loyalty,
      });

      const data = yield call(API.getProviderCustomerGroups, storeId);
      const providerCustomerGroups = data.items || [];
      yield call(_syncDiscountsByTier, store.id, providerCustomerGroups);

      deleteCookie("mid");
      store = yield call(_getStoreWithLocations, storeId);
    }
    if (withStats) {
      const start = new Date();
      const end = new Date();
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 0);
      const report = yield call(
        API.getPeriodReport,
        storeId,
        "location",
        start.toISOString(),
        end.toISOString()
      );
      const stats = (report.locations || []).reduce((obj, l) => {
        obj[l.id] = l;
        return obj;
      }, {});
      store.locations.items = (store.locations.items || []).map((l) => ({
        ...l,
        stats: stats[l.id] ?? { avg: 0, customer: 0, total: 0 },
      }));
    }

    yield put(actions.initStore(store));
    yield call(_setMixPanelInfo, store);
    yield call(_getStoreContract, storeId);
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.log("getStores", error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "getStore", { storeId });
  }
}

export function* watchGetStore() {
  /*
    takeEvery will fork a new `getAllProducts` task on each GET_ALL_PRODUCTS actions
    i.e. concurrent GET_ALL_PRODUCTS actions are allowed
  */
  yield takeEvery(actions.GET_STORE_REQUEST, getStore);
}

export function* getBillingCost({ storeId, locationId }) {
  try {
    const cost = yield call(API.getBillingCost, storeId, locationId);
    yield put(actions.receiveBillingCost(cost));
    yield put(actions.setGetBillingCostSucess());
  } catch (error) {
    yield put(actions.setGetBillingCostFailure(error));
    Analytics.sendErrorReport(error, "getBillingCost", { storeId, locationId });
  }
}

export function* watchGetBillingCost() {
  yield takeEvery(actions.GET_BILLING_COST_REQUEST, getBillingCost);
}

export function* updateStore({ updater }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    const locationId = yield select(reducers.getCurrentLocationId);
    const location = (store.locations.items || []).filter(
      (location) => location.id === locationId
    )[0];
    let _store = {
      ..._.cloneDeep(store),
      ..._.cloneDeep(updater),
    };
    if (!store.policy?.multiLocation) {
      if (
        _store.info.name !== location.name ||
        _store.info.phone !== location.phone ||
        _store.info.address !== location.address ||
        _store.info.zip !== location.zip
      ) {
        yield call(API.updateLocation, {
          ..._.cloneDeep(location),
          name: _store.info.name,
          phone: _store.info.phone,
          address: _store.info.address,
          zip: _store.info.zip,
        });
      }
    }
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateStore", { updater });
  }
}

export function* watchUpdateStore() {
  yield takeEvery(actions.UPDATE_STORE_REQUEST, updateStore);
}

export function* updateMirrorConfig({ config }) {
  try {
    let store = yield select(reducers.getStore);
    const oldConfig = yield select(reducers.getMirrorConfig);
    // delete 로 간주
    if (!config?.video && !_.isEmpty(oldConfig?.video)) {
      yield Storage.remove(oldConfig.video.key, { level: "public" });
    }
    store = yield call(_getStoreWithLocations, store.id);
    let _store = {
      ..._.cloneDeep(store),
      mirror: config,
    };
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateMirrorConfig", config);
  }
}

export function* watchUpdateMirrorConfig() {
  yield takeEvery(actions.UPDATE_MIRROR_CONFIG, updateMirrorConfig);
}

export function* inviteEmployee({ employee, resend }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const info = yield select(reducers.getStoreInfo);

    const user = yield call(
      API.createUser,
      employee.email,
      employee.firstName,
      employee.lastName,
      employee.phone,
      info.name,
      resend
    );

    let _employee = _.cloneDeep(employee);
    if (!resend) {
      _employee = yield call(API.createEmployee, {
        id: user.username,
        role: "owner",
        firstName: _employee.firstName,
        lastName: _employee.lastName,
        phone: _employee.phone,
        email: _employee.email,
      });
    } else {
      _employee = yield call(API.updateEmployee, _employee);
    }
    if (resend) {
      const employees = yield select(reducers.getOrganizationsByEmployee);
      for (let emp of employees) {
        if (emp.id === employee.id) {
          yield all(
            (emp.organizations || []).map((org) =>
              call(API.deleteOrganization, org.id)
            )
          );
        }
      }
    }
    yield all(
      employee.organizations.map((org) =>
        call(API.createOrganization, {
          ...org,
          employeeId: _employee.id,
          storeId: org.storeId || storeId,
          status: "invited",
        })
      )
    );
    const organizations = yield call(_getAllOrganizations, storeId);
    yield put(actions.receiveOrganizations(organizations));
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    if (
      error.errors &&
      error.errors[0].message.includes("UsernameExistsException")
    ) {
      yield put(
        actions.setEmployeeStatusFailure(new Error("UsernameExistsException"))
      );
    } else {
      yield put(actions.setEmployeeStatusFailure(error));
    }

    Analytics.sendErrorReport(error, "inviteEmployee", { employee });
  }
}

export function* watchInviteEmployee() {
  yield takeEvery(actions.INVITE_EMPLOYEE, inviteEmployee);
}

export function* confirmInviteEmployee({ user }) {
  try {
    const employee = yield call(API.getEmployee, user.username);
    console.log(employee);
    for (let org of employee.organizations?.items || []) {
      yield call(API.updateOrganization, {
        ...org,
        status: "confirmed",
      });
    }
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "confirmInviteEmployee", { user });
  }
}

export function* watchConfirmInviteEmployee() {
  yield takeEvery(actions.CONFIRM_INVITE_EMPLOYEE, confirmInviteEmployee);
}

function* _getLocations(storeId) {
  let locations = [];
  let data = {};

  do {
    data = yield call(
      API.getStoreLocations,
      storeId,
      data.nextToken,
      //limit 100 or more
      200
    );
    locations = [...locations, ...data.items];
  } while (data.nextToken);
  return locations;
}

function* _getCustomerTags(storeId, locationId, isOutsourced) {
  let tags = [];
  let data = {};
  if (locationId && !isOutsourced) {
    do {
      data = yield call(
        API.getCustomerTagsByLocation,
        locationId,
        data.nextToken,
        500
      );
      tags = [...tags, ...data.items];
    } while (data.nextToken);
  } else {
    do {
      data = yield call(
        API.getCustomerTagsByStore,
        storeId,
        data.nextToken,
        500
      );
      tags = [...tags, ...data.items];
    } while (data.nextToken);
  }
  return tags;
}

function* _updateStore(store) {
  yield call(API.updateStore, store);
  const updatedStore = yield call(_getStoreWithLocations, store.id);
  return updatedStore;
}

function* _getStoreWithLocations(storeId) {
  const [store, locations] = yield all([
    API.getStore(storeId),
    _getLocations(storeId),
  ]);
  return {
    ...store,
    locations: {
      items: locations,
    },
  };
}

function* _getAllTerminals({
  storeId = null,
  locationId = null,
  businessOperatorId = null,
  businessNumber = null,
}) {
  let terminals = [];
  let data = {};
  do {
    if (storeId) {
      data = yield call(
        API.getTerminalsByStore,
        storeId,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else if (locationId) {
      data = yield call(
        API.getTerminalsByLocation,
        locationId,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else if (businessOperatorId) {
      data = yield call(
        API.getTerminalsByBusinessOperator,
        businessOperatorId,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else if (businessNumber) {
      data = yield call(
        API.getTerminalsByBusinessNumber,
        businessNumber,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else {
      data = yield call(API.getTerminals, data.nextToken, 500);
    }
    terminals = [...terminals, ...(data.items || [])];
  } while (data.nextToken);
  return terminals;
}

function* _getAllRentalContracts({
  businessOperatorId = null,
  storeId = null,
  locationId = null,
}) {
  let rentals = [];
  let data = {};
  do {
    if (businessOperatorId) {
      data = yield call(
        API.getRentalContractsByBusinessOperator,
        businessOperatorId,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else if (storeId) {
      data = yield call(
        API.getRentalContractsByStore,
        storeId,
        data.nextToken,
        //limit 100 or more
        500
      );
    } else if (locationId) {
      data = yield call(
        API.getRentalContractsByByLocation,
        locationId,
        data.nextToken,
        //limit 100 or more
        500
      );
    }
    rentals = [...rentals, ...(data.items || [])];
  } while (data.nextToken);
  return rentals;
}

function* _updateBusinessNumberForOperation(
  businessOperator,
  terminals,
  rentals
) {
  const newOperatorId = hashMd5(businessOperator.businessNumber);
  let newOperator = yield call(API.createBusinessOperator, {
    id: newOperatorId,
    businessNumber: businessOperator.businessNumber,
    name: businessOperator.name,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
  for (let terminal of terminals) {
    yield call(API.updateTerminal, {
      ...terminal,
      businessOperatorId: newOperatorId,
      updatedAt: new Date().toISOString(),
    });
  }
  // TODO: rental이 한개일때만 처리해둠
  for (let rental of rentals) {
    yield call(API.updateRentalContract, {
      ...rental,
      businessOperatorId: newOperatorId,
      updatedAt: new Date().toISOString(),
    });
  }
  return newOperator;
}

function* _getAllOrganizations(storeId) {
  console.log(storeId);
  let organizations = [];
  let data = {};
  do {
    data = yield call(
      API.getOrganizationsByStore,
      storeId,
      data.nextToken,
      //limit 100 or more
      500
    );
    organizations = [...organizations, ...data.items];
  } while (data.nextToken);
  return organizations;
}

function* _getAllOrganizationsByEmployee(employeeId) {
  let organizations = [];
  let data = {};
  do {
    data = yield call(
      API.getOrganizationsByEmployee,
      employeeId,
      data.nextToken,
      //limit 100 or more
      500
    );
    organizations = [...organizations, ...data.items];
  } while (data.nextToken);
  return organizations;
}

function* getRegisterdCustomer(storeCustomers) {
  let stores = [];
  (storeCustomers || []).forEach((customer) => {
    if (customer.status === "registered") {
      stores = [
        ...stores,
        {
          ..._.cloneDeep(customer),
          store: { ..._.cloneDeep(customer.store), location: null },
        },
      ];
      const locations = (customer.store.locations?.items || []).reduce(
        (obj, l) => {
          obj[l.id] = l;
          return obj;
        },
        {}
      );
      (customer.visitedLocationIds || []).forEach((locationId) => {
        if (locationId in locations) {
          stores = [
            ...stores,
            {
              ..._.cloneDeep(customer),
              location: _.cloneDeep(locations[locationId]),
              store: {
                ..._.cloneDeep(customer.store),
                locations: null,
              },
            },
          ];
        }
      });
    }
  });
  return stores;
}

export function* setCurrentOrganization({ organization }) {
  try {
    if (!_.isEmpty(organization)) {
      if (organization.locationId) {
        const location = yield call(
          API.getStoreLocation,
          organization.locationId
        );
        organization = {
          ...organization,
          location,
        };
      }
      Authority.defineRulesFor(organization);
      yield put(
        actions.getRequestProgressEvents(
          ["admin", "owner"].includes(organization.role)
            ? organization.storeId
            : organization.locationId
        )
      );
    }
  } catch (error) {
    console.log(error);
    Analytics.sendErrorReport(error, "setCurrentOrganization", {
      organization,
    });
  }
}

export function* watchSetCurrentOrganization() {
  yield takeEvery(actions.SET_CURRENT_ORGANIZATION, setCurrentOrganization);
}

export function* getOrganizations({ storeId }) {
  try {
    const organizations = yield _getAllOrganizations(storeId);
    yield put(actions.receiveOrganizations(organizations));
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "getOrganizations", { storeId });
  }
}

export function* watchGetOrganizations() {
  yield takeEvery(actions.GET_ORGNIZATIONS, getOrganizations);
}

export function* createOrUpdateLocation({ updater }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const locationId = updater.id;
    let location = {};
    if (locationId) {
      location = yield call(API.getStoreLocation, locationId);
    }
    let _location = {
      ..._.cloneDeep(location),
      ..._.cloneDeep(updater),
    };
    if (_location.orderPlanId) {
      let orders = yield select(reducers.getOrderPlans);
      orders = orders.reduce((obj, o) => {
        obj[o.id] = o;
        return obj;
      }, {});
      _location = {
        ..._location,
        settings: {
          ...(_location.settings ?? {}),
          ...(orders[_location.orderPlanId]?.settings ?? {}),
        },
      };
    }
    if (_location.menuPlanId) {
      let menus = yield select(reducers.getMenuPlans);
      console.log(menus);
      menus = menus.reduce((obj, m) => {
        obj[m.id] = m;
        return obj;
      }, {});
      if (_location.menuPlanId in menus) {
        if (menus[_location.menuPlanId].mode !== "category") {
          _location = {
            ..._location,
            menus: menus[_location.menuPlanId].menus,
            settings: {
              ...(_location.settings ?? {}),
              menuGridSize: menus[_location.menuPlanId]?.size,
              menuMode: "multi",
            },
          };
        } else {
          _location = {
            ..._location,
            settings: {
              ...(_location.settings ?? {}),
              menuMode: "category",
            },
          };
        }
      }
    }
    console.log(_location);

    if (!_location.id) {
      _location = yield call(API.createLocation, {
        ..._location,
        storeId,
        id: generateId("loc"),
      });
      //TODO: support location settings or product settings for defaults
      yield call(API.arrangeProductLocation, storeId, [
        {
          id: _location.id,
          available: false,
        },
      ]);
    } else {
      let _store = yield call(_getStoreWithLocations, storeId);
      yield call(API.updateLocation, {
        ..._location,
        // NOTE: reduce dependency
        businessNumber:
          _location.type === "direct" &&
          _location.businessNumber === _store.info.businessNumber
            ? null
            : _location.businessNumber,
        representative:
          _location.type === "direct" &&
          _location.representative === _store.info.representative
            ? null
            : _location.representative,
        updatedAt: new Date().toISOString(),
      });
    }
    let store = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateLocation", { updater });
  }
}

export function* watchCreateOrUpdateLocation() {
  yield takeEvery(actions.CREATE_OR_UPDATE_LOCATION, createOrUpdateLocation);
}

export function* updateLocation({ location }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const _location = _.cloneDeep(location);
    yield call(API.updateLocation, _location);
    const store = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
  }
}

export function* watchUpdateLocation() {
  yield takeEvery(actions.UPDATE_LOCATION, updateLocation);
}

function* _createLocationContract(storeId, locationId) {
  const storeContract = yield call(API.getStoreContract, storeId);
  if (!_.isEmpty(storeContract)) {
    delete storeContract.locationFee;
    delete storeContract.locationFeeExemptionIds;
    yield call(API.createLocationContract, {
      ...storeContract,
      id: locationId,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    });
  }
}

function* _createOrUpdateOrganizations(employee) {
  const _organizations = yield _getAllOrganizations(employee.employeeStoreId);
  if (_.isEmpty(_organizations)) {
    yield call(API.createOrganization, {
      id: generateId("org"),
      role: "owner",
      employeeId: employee.id,
      status: "confirmed",
      enterpriseId: employee.employeeEnterpriseId,
      storeId: employee.employeeStoreId,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    });
  } else {
    for (let org of _organizations) {
      if (org.role === "owner") {
        delete org.locationId;
        yield call(API.deleteOrganization, org.id);
        yield call(API.createOrganization, {
          ...org,
          enterpriseId: employee.employeeEnterpriseId,
          updatedAt: new Date().toISOString(),
        });
      } else if (org.role === "manager") {
        yield call(API.updateOrganization, {
          ...org,
          role: "parttimer",
          permissions: org.permissions
            ? [
                JSON.stringify({
                  ...JSON.parse(org.permissions[0]),
                  item: false,
                }),
              ]
            : null,
          updatedAt: new Date().toISOString(),
        });
      }
    }
  }
}

export function* lanunchMultiLocation() {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    const locationId = yield select(reducers.getCurrentLocationId);
    const location = yield call(API.getStoreLocation, locationId);
    const employee = yield select(reducers.getEmployee);

    yield _createOrUpdateOrganizations(employee);

    const updatedEmployee = yield call(API.getEmployee, employee.id);
    yield put(actions.setCurrentEmployee(updatedEmployee));
    const menus = _.cloneDeep(location.menus);
    const settings = _.cloneDeep(store.settings);
    const menuPlan = {
      id: generateId("mp"),
      name: `${store.info.name} 메뉴판`,
      menus: menus,
      size: settings.menuGridSize || "expanded",
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    };
    yield call(API.updateLocation, {
      ..._.cloneDeep(location),
      type: "direct",
      menus: null,
      menuPlanId: menuPlan.id,
      payments: {
        ...location.payments,
        refund: store.payments?.refund ?? false,
      },
      storeId: store.id,
      settings,
    });
    const updatedStore = yield call(API.updateStore, {
      ..._.cloneDeep(store),
      policy: {
        ...store.policy,
        multiLocation: true,
      },
      plans: {
        menus: [menuPlan],
      },
      settings: null,
    });
    yield _createLocationContract(store.id, location.id);
    yield call(API.arrangeProductLocation, store.id);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "lanunchMultiLocation", {});
  }
}

export function* watchLanunchMultiLocation() {
  yield takeEvery(actions.LAUNCH_MULTI_LOCATION, lanunchMultiLocation);
}

export function* createOrUpdateMenuPlan({ plan, isApply }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);
    const _plan = {
      ..._.cloneDeep(plan),
      id: plan.id || generateId("mp"),
    };
    const updatedStore = yield call(API.updateStore, {
      ..._store,
      plans: {
        ...(_store.plans || {}),
        menus: [
          ...(_store.plans?.menus || []).filter((m) => m.id !== _plan.id),
          _plan,
        ],
      },
    });

    if (_plan.mode === "category") {
      yield all(
        _store.locations.items
          .filter((loc) => loc.menuPlanId === _plan.id)
          .map((loc) =>
            call(API.updateLocation, {
              ...loc,
              menuPlanId: _plan.id,
              settings: {
                ...(loc.settings ?? {}),
                menuMode: "category",
              },
            })
          )
      );
    } else {
      if (isApply) {
        yield all(
          _store.locations.items
            .filter((loc) => loc.menuPlanId === _plan.id)
            .map((loc) =>
              call(API.updateLocation, {
                ...loc,
                menuPlanId: _plan.id,
                menus: _plan.menus,
                settings: {
                  ...(loc.settings ?? {}),
                  menuGridSize: _plan.size,
                },
              })
            )
        );
      }
    }
    store = yield call(_getStoreWithLocations, store.id);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateMenuPlan", plan);
  }
}

export function* watchCreateOrUpdateMenuPlan() {
  yield takeEvery(actions.CREATE_OR_UPDATE_MENU_PLAN, createOrUpdateMenuPlan);
}

export function* deleteMenuPlan({ plan }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);
    const updatedStore = yield call(API.updateStore, {
      ..._store,
      plans: {
        ...(_store.plans || {}),
        menus: (_store.plans?.menus || []).filter((m) => m.id !== plan.id),
      },
    });
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "deleteMenuPlan", plan);
  }
}

export function* watchDeleteMenuPlan() {
  yield takeEvery(actions.DELETE_MENU_PLAN, deleteMenuPlan);
}

export function* createOrUpdateOrderPlan({ plan, isApply }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);
    const _plan = {
      ..._.cloneDeep(plan),
      id: plan.id || generateId("op"),
    };
    const updatedStore = yield call(API.updateStore, {
      ..._store,
      plans: {
        ...(_store.plans || {}),
        orders: [
          ...(_store.plans?.orders || []).filter((o) => o.id !== _plan.id),
          _plan,
        ],
      },
    });
    if (isApply) {
      yield all(
        _store.locations.items
          .filter((loc) => loc.orderPlanId === _plan.id)
          .map((loc) =>
            call(API.updateLocation, {
              ...loc,
              orderPlanId: _plan.id,
              settings: {
                ...(loc.settings ?? {}),
                ...(plan?.settings ?? {}),
              },
            })
          )
      );
    }
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateOrderPlan", plan);
  }
}

export function* watchCreateOrUpdateOrderPlan() {
  yield takeEvery(actions.CREATE_OR_UPDATE_ORDER_PLAN, createOrUpdateOrderPlan);
}

export function* createOrUpdateNotice({ notice, file }) {
  try {
    let _notice = _.cloneDeep(notice);
    if (!_notice.storeId) {
      _notice.storeId = yield select(reducers.getStoreId);
    }
    if (file) {
      const storeId = yield select(reducers.getStoreId);
      const imgKey = `${storeId}/attachments/${generateId("")}_${file.name}`;
      const result = yield Storage.put(imgKey, file, {
        level: "public",
        contentType: file.type,
      });
      _notice.attachment = { key: result.key, level: "public" };
    }
    if (!_notice.id) {
      _notice = yield call(API.createNotice, {
        id: _notice.id || generateId("nt"),
        ..._notice,
      });
    } else {
      _notice = yield call(API.updateNotice, {
        ..._notice,
        updatedAt: new Date().toISOString(),
      });
    }
    yield put(actions.receiveNotice(_notice));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateNotice", { notice });
  }
}

export function* watchCreateOrUpdateNotice() {
  yield takeEvery(actions.CREATE_OR_UPDATE_NOTICE, createOrUpdateNotice);
}

export function* createOrUpdateNoticeCampaign({ noticeCampaign }) {
  try {
    let _noticeCampaign = _.cloneDeep(noticeCampaign);
    if (!_noticeCampaign.storeId) {
      _noticeCampaign.storeId = yield select(reducers.getStoreId);
    }

    if (!_noticeCampaign.id) {
      _noticeCampaign = yield call(API.createNoticeCampaign, {
        id: _noticeCampaign.id || generateId("cmp"),
        status: _noticeCampaign.status || "reserved",
        ..._noticeCampaign,
      });
    } else {
      _noticeCampaign = yield call(API.updateNoticeCampaign, {
        ..._noticeCampaign,
        updatedAt: new Date().toISOString(),
      });
    }
    if (_noticeCampaign.sendNow) {
      let notice = yield call(API.getNotice, noticeCampaign.noticeId);

      let archived = yield call(API.createNotice, {
        ...notice,
        id: generateId("nt"),
        status: "archived",
        parentId: notice.id,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
      });
      yield all(
        _noticeCampaign.locationIds.map((id) =>
          call(API.createNoticeCampaignResult, {
            id: generateId("ncr"),
            recipientStoreId: archived.storeId,
            recipientLocationId: id,
            campaignId: _noticeCampaign.id,
            noticeId: archived.id,
            read: false,
          })
        )
      );
      _noticeCampaign = yield call(API.updateNoticeCampaign, {
        ..._noticeCampaign,
        status: "done",
        noticeId: archived.id,
        updatedAt: new Date().toISOString(),
      });
    }
    yield put(actions.receiveNoticeCampaign(_noticeCampaign));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateNoticeCampaign", {
      noticeCampaign,
    });
  }
}

export function* watchCreateOrUpdateNoticeCampaign() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_NOTICE_CAMPAIGN,
    createOrUpdateNoticeCampaign
  );
}

export function* deleteNotice({ notice }) {
  try {
    yield call(API.deleteNotice, notice.id);
    yield put(actions.receiveDeleteNotice(notice));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteNotice", { notice });
  }
}

export function* watchDeleteNotice() {
  yield takeEvery(actions.DELETE_NOTICE, deleteNotice);
}

export function* deleteNoticeCampaign({ campaign }) {
  try {
    yield call(API.deleteNoticeCampaign, campaign.id);
    yield put(actions.receiveDeletedNoticeCampaign(campaign));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteNoticeCampaign", { campaign });
  }
}

export function* watchDeleteNoticeCampaign() {
  yield takeEvery(actions.DELETE_NOTICE_CAMPAIGN, deleteNoticeCampaign);
}

export function* deleteOrderPlan({ plan }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);
    const updatedStore = yield call(API.updateStore, {
      ..._store,
      plans: {
        ...(_store.plans || {}),
        orders: (_store.plans?.orders || []).filter((o) => o.id !== plan.id),
      },
    });
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "deleteOrderPlan", plan);
  }
}

export function* watchDeleteOrderPlan() {
  yield takeEvery(actions.DELETE_ORDER_PLAN, deleteOrderPlan);
}

export function* activeReviewCampaign() {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    const locationId = yield select(reducers.getCurrentLocationId);
    let _store = _.cloneDeep(store);

    if (!_store.review || !_store.review.campaignId) {
      const template = yield call(API.createReviewCampaign, {
        id: generateId("cmp"),
        storeId: _store.id,
        locationId: locationId,
        basic: true,
        trial: 10,
        limit: 0,
        delay: 60,
        rewardPoint: 0,
        questions: JSON.stringify({}),
        templateName: "기본 템플릿",
        status: "draft",
      });
      const campaign = yield call(API.createReviewCampaign, {
        ...template,
        id: generateId("cmp"),
        name: "기본 리뷰 캠페인",
        startedAt: new Date().toISOString(),
        templateId: template.id,
        templateName: template.templateName,
        status: "active",
      });
      _store.review = {
        campaignId: campaign.id,
      };
    }
    _store.review.status = "active";
    _store.review.updatedAt = new Date().toISOString();
    if (!_store.review.createdAt) {
      _store.review.createdAt = _store.review.updatedAt;
    }

    // TODO: loyalty status 를 active로 둬야 한다. draft도 가능???
    _store = {
      ..._store,
      loyalty: {
        ..._store.loyalty,
        status: _store.loyalty.conditionType ? "active" : "draft",
      },
    };
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "activeReviewCampaign", {});
  }
}

export function* watchActiveReviewCampaign() {
  yield takeEvery(actions.ACTIVE_REVIEW_CAMPAIGN, activeReviewCampaign);
}

export function* inactiveReviewCampaign() {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);

    if (_store.review && _store.review.campaignId) {
      let campaign = yield call(
        API.getReviewCampaign,
        _store.review.campaignId
      );
      yield call(API.updateReviewCampaign, {
        ...campaign,
        status: "inactive",
        updatedAt: new Date().toISOString(),
      });
    }
    _store.review.status = "inactive";
    _store.review.updatedAt = new Date().toISOString();
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "inactiveReviewCampaign", {});
  }
}

export function* watchInactiveReviewCampaign() {
  yield takeEvery(actions.INACTIVE_REVIEW_CAMPAIGN, inactiveReviewCampaign);
}

export function* activeKioskMode() {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);

    _store = {
      ..._store,
      kiosk: {
        ...(_store.kiosk || {}),
        status: "active",
        updatedAt: new Date().toISOString(),
        createdAt: _store.kiosk?.createdAt || new Date().toISOString(),
      },
    };
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "activeKioskMode", {});
  }
}

export function* watchActiveKioskMode() {
  yield takeEvery(actions.ACTIVE_KIOSK_MODE, activeKioskMode);
}

export function* inactiveKioskMode() {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _store = _.cloneDeep(store);

    _store = {
      ..._store,
      devices: (_store.devices || []).map((d) => ({
        ...d,
        type: d.type === "kiosk" ? "POS" : d.type,
        parentId: d.type === "kiosk" ? null : d.parentId,
        kioskOptions: d.kioskOptions
          ? { ...d.kioskOptions, isActive: false }
          : null,
      })),
      kiosk: {
        ...(_store.kiosk || {}),
        status: "inactive",
        updatedAt: new Date().toISOString(),
      },
    };
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "inactiveKioskMode", {});
  }
}

export function* watchInactiveKioskMode() {
  yield takeEvery(actions.INACTIVE_KIOSK_MODE, inactiveKioskMode);
}

export function* updateStoreLoyalty({ loyalty }) {
  try {
    let store = yield select(reducers.getStore);
    store = yield call(_getStoreWithLocations, store.id);
    let _loyalty = _.cloneDeep(loyalty);

    _loyalty.conditionItems = (_loyalty.conditionItems || []).filter(
      (item) => item.itemId && item.point && item.parentId
    );
    _loyalty.rewardItems = (_loyalty.rewardItems || []).filter(
      (item) => item.itemId && item.point && item.parentId
    );
    _loyalty.rewardRates = (_loyalty.rewardRates || []).filter(
      (item) => item.itemId && item.point && item.rate
    );
    _loyalty.rewardAmounts = (_loyalty.rewardAmounts || []).filter(
      (item) => item.itemId && item.point && item.amount
    );

    console.log(_loyalty);
    if (!_.isEmpty(_loyalty.incentives)) {
      if (
        _loyalty.incentives.fulfill &&
        !_loyalty.incentives.fulfillCampaignId
      ) {
        const fCamp = yield call(API.createCampaign, {
          id: generateId("cmp"),
          status: "ready",
          storeId: store.id,
          type: "auto",
          name: "추가 정보 기입시 적립",
          trigger: "fulfill",
          triggerTiming: 0,
          // title:
          // message:
          // method: sms,
        });
        _loyalty.incentives.fulfillCampaignId = fCamp.id;
      }
      if (_loyalty.incentives.birth && !_loyalty.incentives.birthCampaignId) {
        const bCamp = yield call(API.createCampaign, {
          id: generateId("cmp"),
          status: "ready",
          storeId: store.id,
          type: "auto",
          name: "생일 포인트 적립",
          trigger: "birth",
          triggerTiming: 0,
          // title:
          // message:
          // method: sms,
        });
        _loyalty.incentives.birthCampaignId = bCamp.id;
        // TODO: remove (For backward compatibility)
        _loyalty.incentives.birthItem = {
          itemId: "tmp",
          name: `${_loyalty.incentives.birhtPoint}P`,
        };
      }
    }

    let _store = {
      ..._.cloneDeep(store),
      loyalty: _loyalty,
    };
    const updatedStore = yield call(API.updateStore, _store);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateStoreLoyalty", { loyalty });
  }
}

export function* watchUpdateStoreLoyalty() {
  yield takeEvery(actions.UPDATE_STORE_LOYALTY_REQUEST, updateStoreLoyalty);
}

function* _getBillingHistories(storeId, locationId = null) {
  let billingHistories = [];
  let data = [];
  const isMultiLocation = yield select(reducers.isMultiLocation);
  if (!isMultiLocation) {
    do {
      data = yield call(
        API.getBillingHistoriesByStore,
        storeId,
        null,
        500,
        data.nextToken
      );
      billingHistories = [...billingHistories, ...data.items];
    } while (data.nextToken);
  } else {
    if (!locationId) {
      do {
        data = yield call(
          API.getBillingHistoriesByStore,
          storeId,
          null,
          500,
          data.nextToken
        );
        billingHistories = [...billingHistories, ...data.items];
      } while (data.nextToken);
      billingHistories = billingHistories.filter((his) => !his.locationId);
    } else {
      do {
        data = yield call(
          API.getBillingHistoriesByLocation,
          locationId,
          null,
          500,
          data.nextToken
        );
        billingHistories = [...billingHistories, ...data.items];
      } while (data.nextToken);
    }
  }
  return billingHistories;
}

export function* getBillingHistories({ storeId, locationId = null }) {
  try {
    const billingHistories = yield call(
      _getBillingHistories,
      storeId,
      locationId
    );
    yield put(actions.initBillingHistories(billingHistories));
    yield put(actions.setGetBillingHistoriesSuccess());
  } catch (error) {
    yield put(actions.setGetBillingHistoriesFailure(error));
    Analytics.sendErrorReport(error, "getBillingHistories", {
      storeId,
      locationId,
    });
  }
}

export function* watchGetBillingHistories() {
  yield takeEvery(actions.GET_BILLING_HISTORIES_REQUEST, getBillingHistories);
}

export function* payFailedBillings({ storeId, locationId = null }) {
  try {
    yield call(API.payBillings, storeId, locationId);
    const billingHistories = yield call(
      _getBillingHistories,
      storeId,
      locationId
    );
    yield put(actions.initBillingHistories(billingHistories));
    yield put(actions.setGetBillingHistoriesSuccess());
  } catch (error) {
    yield put(actions.setGetBillingHistoriesFailure(error));
    Analytics.sendErrorReport(error, "payFailedBillings", {
      storeId,
      locationId,
    });
  }
}

export function* watchPayFailedBillings() {
  yield takeEvery(actions.PAY_FAILED_BILLINGS, payFailedBillings);
}

export function* getCampaigns({
  storeId,
  locationId,
  startDate,
  endDate,
  filters,
}) {
  try {
    let campaigns = [];
    let data = {};
    let filter = {
      type: { eq: "normal" },
      ...filters,
    };
    let createdAt = null;
    if (startDate && endDate) {
      createdAt = { between: [startDate, endDate] };
    }

    if (locationId) {
      do {
        data = yield call(
          API.getCampaignsByLocationId,
          locationId,
          createdAt,
          filter,
          data.nextToken,
          1000
        );
        campaigns = [...campaigns, ...data.items];
      } while (data.nextToken);
    } else {
      do {
        data = yield call(
          API.getCampaignsByStoreId,
          storeId,
          createdAt,
          filter,
          data.nextToken,
          1000
        );
        campaigns = [...campaigns, ...data.items];
      } while (data.nextToken);
    }

    for (let idx in campaigns) {
      if (
        campaigns[idx].status === "requested" ||
        campaigns[idx].status === "reserved"
      ) {
        try {
          campaigns[idx] = yield call(
            API.getCampaignWithReport,
            campaigns[idx].id
          );
        } catch (err) {
          console.log("Failed to get campainWithReport", err);
        }
      }
    }

    //TODO: parrel
    yield put(actions.receiveCampaigns(campaigns));
    yield put(actions.setGetCampaignsSuccess());
  } catch (error) {
    yield put(actions.setGetCampaignsFailure(error));
    Analytics.sendErrorReport(error, "getCampaigns", { storeId });
  }
}

export function* getCampaign({ campaignId }) {
  try {
    const campaign = yield call(API.getCampaign, campaignId);
    yield put(actions.receiveCampaign(campaign));
    yield put(actions.setGetCampaignSuccess());
  } catch (error) {
    yield put(actions.setGetCampaignFailure(error));
    Analytics.sendErrorReport(error, "getCampaign", {
      campaignId,
    });
  }
}

export function* getCampaignWithReport({ campaignId, start, end }) {
  try {
    const campaign = yield call(
      API.getCampaignWithReport,
      campaignId,
      start,
      end
    );
    yield put(actions.receiveCampaign(campaign));
    yield put(actions.setGetCampaignSuccess());
  } catch (error) {
    yield put(actions.setGetCampaignFailure(error));
    Analytics.sendErrorReport(error, "getCampaignWithReport", {
      campaignId,
      start,
      end,
    });
  }
}

export function* getCustomersStats({
  storeId,
  page,
  size,
  filters,
  search,
  override,
}) {
  try {
    let { items, count } = yield call(
      API.getCustomersWithCount,
      storeId,
      page,
      size,
      null,
      filters,
      search
    );
    let list = items.map((customer) => ({
      id: customer.id,
      name: customer.name,
      phone: customer.phone,
      registeredAt: customer.registeredAt,
      ...customer.stats,
    }));
    yield put(actions.receiveCustomers(list, count, override));
    yield put(actions.setGetCustomerStatsSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerStatsFailure(error));
    Analytics.sendErrorReport(error, "getCustomersStats", {
      storeId,
      page,
      size,
      filters,
      search,
      override,
    });
  }
}

export function* watchGetCustomersStats() {
  yield takeEvery(actions.GET_CUSTOMERS_STATS, getCustomersStats);
}

export function* createCampaign({ campaign }) {
  try {
    let _campaign = _.cloneDeep(campaign);
    if (!_campaign.id) {
      _campaign.id = generateId("cmp");
    }
    if (!_campaign.status) {
      _campaign.status = "draft";
    }
    if (_campaign.recipient && _campaign.recipient.id) {
      _campaign.campaignRecipientId = _campaign.recipient.id;
    }
    const createdCampaign = yield call(API.createCampaign, _campaign);
    yield put(actions.receiveCampaign(createdCampaign));
    yield put(actions.setCreateOrUpdateCampaignSuccess());
  } catch (error) {
    yield put(actions.setCreateOrUpdateCampaignFailure(error));
    Analytics.sendErrorReport(error, "createCampaign", { campaign });
  }
}

export function* watchCreateCampaign() {
  yield takeEvery(actions.CREATE_CAMPAIGN, createCampaign);
}

export function* updateCampaign({ campaign }) {
  try {
    let _campaign = _.cloneDeep(campaign);
    if (!_campaign.status) {
      _campaign.status = "draft";
    }
    if (_campaign.recipient && _campaign.recipient.id) {
      _campaign.campaignRecipientId = _campaign.recipient.id;
    }
    const updatedCampaign = yield call(API.updateCampaign, _campaign);
    yield put(actions.receiveCampaign(updatedCampaign));
    yield put(actions.setCreateOrUpdateCampaignSuccess());
  } catch (error) {
    yield put(actions.setCreateOrUpdateCampaignFailure(error));
    Analytics.sendErrorReport(error, "updateCampaign", { campaign });
  }
}

export function* watchUpdateCampaign() {
  yield takeEvery(actions.UPDATE_CAMPAIGN, updateCampaign);
}

export function* launchCampaign({ campaign }) {
  try {
    if (campaign.id) {
      yield call(API.deleteCampaign, campaign.id);
      yield call(API.createCampaign, campaign);
    } else {
      campaign = { ...campaign, id: generateId("cmp") };
      yield call(API.createCampaign, campaign);
    }
    const updatedCampaign = yield call(API.launchCampaign, campaign.id);
    yield put(actions.receiveCampaign(updatedCampaign));
    yield put(actions.setLaunchCampaignSuccess());
  } catch (error) {
    campaign.status = "draft";
    yield call(API.updateCampaign, campaign);
    yield put(actions.setLaunchCampaignFailure(error));
    Analytics.sendErrorReport(error, "launchCampaign", { campaign });
  }
}

export function* watchLanuchCampaign() {
  yield takeEvery(actions.LAUNCH_CAMPAIGN, launchCampaign);
}

export function* deleteCampaign({ campaignId }) {
  try {
    yield call(API.deleteCampaign, campaignId);
    yield put(actions.setDeleteCampaignSuccess());
    yield put(actions.receiveDeletedCampaign(campaignId));
  } catch (error) {
    yield put(actions.setDeleteCampaignFailure(error));
    Analytics.sendErrorReport(error, "deleteCampaign", { campaignId });
  }
}

export function* cancelCampaign({ campaignId }) {
  try {
    const updatedCampaign = yield call(API.cancelCampaign, campaignId);
    yield put(actions.setCancelCampaignSuccess());
    yield put(actions.receiveCampaign(updatedCampaign));
  } catch (error) {
    console.log(error);
    yield put(actions.setCancelCampaignFailure(error));
    Analytics.sendErrorReport(error, "cancelCampaign", { campaignId });
  }
}

export function* sendBarcodeCouponCampaign({ campaign }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const organization = yield select(reducers.getCurrentOrganization);
    const isOutsourced = yield select(reducers.isOutsourcedCustomer);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    let _coupon = _.cloneDeep(campaign.coupon);
    let _campaign = {
      ...campaign,
      storeId,
    };
    delete _campaign.coupon;
    if (isMultiLocation && !isOutsourced && organization.locationId) {
      _campaign.locationId = organization.locationId;
    }
    if (!_coupon?.id) {
      _coupon.id = generateId("cpn");
      _coupon.storeId = storeId;
      if (isMultiLocation && !isOutsourced && organization.locationId) {
        _coupon.locationId = organization.locationId;
        _coupon.publishableLocationIds = [organization.locationId];
      }
      _coupon = yield call(API.createCoupon, _coupon);
    }
    _campaign.couponId = _coupon.id;
    if (_campaign.id) {
      _campaign = {
        ..._campaign,
        updatedAt: new Date().toISOString(),
      };
      let updatedCampaign = yield call(API.updateCampaign, _campaign);
      updatedCampaign = yield call(API.launchCampaign, updatedCampaign.id);
      yield put(actions.receiveCampaign(updatedCampaign));
    } else {
      _campaign = {
        ..._campaign,
        id: generateId("cmp"),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      let createdCampaign = yield call(API.createCampaign, _campaign);
      try {
        if (createdCampaign.id) {
          createdCampaign = yield call(API.launchCampaign, createdCampaign.id);
        }
      } catch (error) {
        yield call(API.deleteCampaign, _campaign.id);
        yield put(actions.setLaunchCampaignFailure(error));
        throw error;
      }
      yield put(actions.receiveCampaign(createdCampaign));
    }
    yield put(actions.setLaunchCampaignSuccess());
  } catch (error) {
    console.log("Failed to sendBarcodeCouponCampaign", error);
    yield put(actions.setLaunchCampaignFailure(error));
    Analytics.sendErrorReport(error, "sendBarcodeCouponCampaign", {
      campaign,
    });
  }
}
export function* watchSendBarcodeCouponCampaign() {
  yield takeEvery(
    actions.SEND_BARCODE_COUPON_CAMPAIGN,
    sendBarcodeCouponCampaign
  );
}

export function* watchGetCampaigns() {
  yield takeEvery(actions.GET_CAMPAIGNS, getCampaigns);
}

export function* watchDeleteCampaign() {
  yield takeEvery(actions.DELETE_CAMPAIGN, deleteCampaign);
}

export function* watchCancelCampaign() {
  yield takeEvery(actions.CANCEL_CAMPAIGN, cancelCampaign);
}

export function* watchGetCampaignWithReport() {
  yield takeEvery(actions.GET_CAMPAIGN_WITH_REPORT, getCampaignWithReport);
}

export function* watchGetCampaign() {
  yield takeEvery(actions.GET_CAMPAIGN, getCampaign);
}

export function* getReviewCampaigns({ storeId }) {
  try {
    let campaigns = yield call(API.getReviewCampaigns, storeId);
    yield put(actions.receiveReviewCampaigns(campaigns));
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "getReviewCampaigns", { storeId });
  }
}

export function* watchGetReviewCampaigns() {
  yield takeEvery(actions.GET_REVIEW_CAMPAIGNS, getReviewCampaigns);
}

export function* getReviewCampaignResults({ storeId, locationId, start, end }) {
  try {
    let results = yield call(
      API.getReviewCampaignResults,
      storeId,
      start,
      end,
      locationId
    );
    yield put(actions.receiveReviewCampaignResults(results));
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "getReviewCampaignResults", {
      storeId,
      start,
      end,
    });
  }
}

export function* watchGetReviewCampaignResults() {
  yield takeEvery(
    actions.GET_REVIEW_CAMPAIGN_RESULTS,
    getReviewCampaignResults
  );
}

export function* downloadReviewCampaign({ campaign }) {
  try {
    let results = [];
    let data = {};
    do {
      data = yield call(
        API.getReviewCampaignResultsByCapaign,
        campaign.id,
        data.nextToken,
        500
      );
      results = [...results, ...data.items.filter((r) => !!r.submittedAt)];
    } while (data.nextToken);

    console.log(campaign.questions);
    console.log(results);
    let body = [];
    let keys = [];
    let titles = ["제출일시", "별점", "의견"];
    if (campaign.questions?.pages?.length > 0) {
      for (let header of campaign.questions.pages[0].elements || []) {
        titles = [...titles, header.title];
        keys = [...keys, header.name];
      }
    }
    body = [...body, titles];
    for (let r of results) {
      let row = [
        moment(r.submittedAt).format("YYYY/MM/DD HH:mm"),
        r.rate,
        r.comment,
      ];
      const answers = JSON.parse(r.answers);
      for (let key of keys) {
        if (key in answers) {
          row = [
            ...row,
            Array.isArray(answers[key])
              ? answers[key].join(", ")
              : answers[key],
          ];
        } else {
          row = [...row, ""];
        }
      }
      body = [...body, row];
    }
    downloadCSV(body);
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "downloadReviewCampaign", {
      campaign,
    });
  }
}

export function* watchDownloadReviewCampaign() {
  yield takeEvery(actions.DOWNLOAD_REVIEW_CAMPAIGN, downloadReviewCampaign);
}

export function* createOrUpdateReviewCampaign({ campaign }) {
  try {
    console.log(campaign);
    const storeId = yield select(reducers.getStoreId);
    if (!campaign.id) {
      const createdCampaign = yield call(API.createReviewCampaign, {
        ...campaign,
        storeId,
        id: generateId("cmp"),
        questions: JSON.stringify(campaign.questions),
      });
      yield put(
        actions.receiveReviewCampaign({
          ...createdCampaign,
          questions: JSON.parse(createdCampaign.questions),
        })
      );
    } else {
      const updatedCampaign = yield call(API.updateReviewCampaign, {
        ...campaign,
        storeId,
        questions: JSON.stringify(campaign.questions),
      });
      yield put(
        actions.receiveReviewCampaign({
          ...updatedCampaign,
          questions: JSON.parse(updatedCampaign.questions),
        })
      );
    }
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateReviewCampaign", {
      campaign,
    });
  }
}

export function* watchCreateOrUpdateReviewCampaign() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_REVIEW_CAMPAIGN,
    createOrUpdateReviewCampaign
  );
}

export function* launchRewviewCampagin({ campaign }) {
  try {
    const campaigns = yield select(reducers.getReviewCampaignsById);

    console.log(campaign);
    if (campaign.templateId !== campaigns[campaign.id].templateId) {
      const compltedCampagin = yield call(API.updateReviewCampaign, {
        ...campaigns[campaign.id],
        endedAt: new Date().toISOString(),
        status: "completed",
      });
      yield put(actions.receiveReviewCampaign(compltedCampagin));
      const template = campaigns[campaign.templateId];
      console.log(template);
      const locationId = yield select(reducers.getCurrentLocationId);
      const createdCampaign = yield call(API.createReviewCampaign, {
        ...campaign,
        id: generateId("cmp"),
        startedAt: new Date().toISOString(),
        endedAt: null,
        templateId: template.id,
        locationId: locationId,
        templateName: template.templateName,
        basic: template.basic,
        questions: template.questions,
        status: "active",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
      yield put(actions.receiveReviewCampaign(createdCampaign));
      let store = yield select(reducers.getStore);
      store = yield call(_getStoreWithLocations, store.id);
      const updatedStore = yield call(API.updateStore, {
        ...store,
        review: {
          ...store.review,
          campaignId: createdCampaign.id,
        },
      });
      yield put(actions.initStore(updatedStore));
    } else {
      const updatedCampaign = yield call(API.updateReviewCampaign, campaign);
      yield put(actions.receiveReviewCampaign(updatedCampaign));
    }
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "launchRewviewCampagin", {
      campaign,
    });
  }
}

export function* watchLaunchReviewCampaign() {
  yield takeEvery(actions.LAUNCH_REVIEW_CAMPAIGN, launchRewviewCampagin);
}

export function* deleteReviewCampaign({ campaign }) {
  try {
    yield call(API.deleteReviewCampaign, campaign.id);
    yield put(actions.receiveDeletedReviewCampaign(campaign.id));
    yield put(actions.setReviewCampaignSuccess());
  } catch (error) {
    yield put(actions.setReviewCampaignFailure(error));
    Analytics.sendErrorReport(error, "deleteReviewCampaign", {
      campaign,
    });
  }
}

export function* watchDeleteReviewCampaign() {
  yield takeEvery(actions.DELETE_REVIEW_CAMPAIGN, deleteReviewCampaign);
}

export function* updateEmployee({ employee }) {
  try {
    let _employee = _.cloneDeep(employee);
    const updatedEmployee = yield call(API.updateEmployee, _employee);
    yield put(actions.setCurrentEmployee(updatedEmployee));
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "updateEmployee", { employee });
  }
}

export function* watchUpdateEmployee() {
  yield takeEvery(actions.UPDATE_EMPLOYEE, updateEmployee);
}

export function* updateOrganizationEmployee({ employee }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    yield call(API.updateEmployee, _.cloneDeep(employee));
    // TODO: yield call(API.updateUserAttribute, _.cloneDeep(employee));

    const employees = yield select(reducers.getOrganizationsByEmployee);
    for (let emp of employees) {
      if (emp.id === employee.id) {
        yield all(
          emp.organizations.map((org) => call(API.deleteOrganization, org.id))
        );
      }
    }
    yield all(
      employee.organizations.map((org) =>
        call(API.createOrganization, {
          ...org,
          employeeId: employee.id,
          storeId: org.storeId || storeId,
          status: "confirmed",
        })
      )
    );
    const organizations = yield call(_getAllOrganizations, storeId);
    yield put(actions.receiveOrganizations(organizations));
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "updateOrganizationEmployee", {
      employee,
    });
  }
}

export function* watchUpdateOrganizationEmployee() {
  yield takeEvery(
    actions.UPDATE_ORGANIZATION_EMPLOYEE,
    updateOrganizationEmployee
  );
}

export function* deleteOrganizationEmployee({ employee }) {
  try {
    yield all(
      (employee.organizations || []).map((org) =>
        call(API.deleteOrganization, org.id)
      )
    );
    yield call(API.deleteEmployee, employee.id);
    yield call(API.deleteUser, employee.email);
    const storeId = yield select(reducers.getStoreId);
    const organizations = yield call(_getAllOrganizations, storeId);
    yield put(actions.receiveOrganizations(organizations));
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteOrganizationEmployee", {
      employee,
    });
  }
}

export function* watchDeleteOrganizationEmployee() {
  yield takeEvery(
    actions.DELETE_ORGANIZATION_EMPLOYEE,
    deleteOrganizationEmployee
  );
}

function* _getAllProductsByStore(storeId) {
  let products = [];
  let data = {};
  do {
    data = yield call(
      API.getProducts,
      storeId,
      data.nextToken,
      //limit 100 or more
      2000
    );
    products = [...products, ...data.items];
  } while (data.nextToken);
  return products;
}

function* _getAllCategoriesByStore(storeId) {
  let categories = [];
  let data = {};
  do {
    data = yield call(
      API.getCategories,
      storeId,
      data.nextToken,
      //limit 100 or more
      500
    );
    categories = [...categories, ...data.items];
  } while (data.nextToken);
  return categories;
}

function* _getAllModifiersByStroe(storeId) {
  let modifiers = [];
  let data = {};
  do {
    data = yield call(
      API.getModifiers,
      storeId,
      data.nextToken,
      //limit 100 or more
      500
    );
    modifiers = [...modifiers, ...data.items];
  } while (data.nextToken);
  return modifiers;
}

function* _getAllProductCategoriesByStore(storeId) {
  let cc = new Date();
  let productCategories = [];
  let data = {};
  do {
    data = yield call(
      API.getProductCategories,
      storeId,
      data.nextToken,
      //limit 100 or more
      2000
    );
    productCategories = [...productCategories, ...data.items];
  } while (data.nextToken);
  console.log(
    "PRODUCT_CATEGORIES",
    (new Date().getTime() - cc.getTime()) / 1000
  );
  return productCategories;
}

function* _getAllProductModifiersByStore(storeId) {
  let cc = new Date();
  let productModifiers = [];
  let data = {};
  do {
    data = yield call(
      API.getProductModifiers,
      storeId,
      data.nextToken,
      //limit 100 or more
      2000
    );
    productModifiers = [...productModifiers, ...data.items];
  } while (data.nextToken);
  console.log(
    "PRODUCT_MODIFIERS",
    (new Date().getTime() - cc.getTime()) / 1000
  );
  return productModifiers;
}

function* _getItems(storeId) {
  let a = new Date().getTime();
  let items = {};
  let [products, categories, modifiers, productCategories, productModifiers] =
    yield all([
      _getAllProductsByStore(storeId),
      _getAllCategoriesByStore(storeId),
      _getAllModifiersByStroe(storeId),
      _getAllProductCategoriesByStore(storeId),
      _getAllProductModifiersByStore(storeId),
    ]);

  items = {
    products: [],
    categories: [],
    modifiers: [],
    categoryProducts: productCategories,
    productModifiers: productModifiers,
  };
  items.products = (products || []).map((prod) => ({
    ...prod,
    itemId: prod.id,
  }));
  items.categories = (categories || []).map((c) => ({
    ...c,
    itemId: c.id,
  }));
  items.modifiers = (modifiers || []).map((m) => ({
    ...m,
    itemId: m.id,
  }));
  console.log("TIME(getItems) TOTAL: " + (new Date().getTime() - a) / 1000);
  return items;
}

export function* getItems({ storeId }) {
  try {
    const items = yield _getItems(storeId);
    yield put(actions.receiveItems(items));
    yield put(actions.setGetItemsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetItemsFailure(error));
    Analytics.sendErrorReport(error, "getItems", { storeId });
  }
}

export function* watchGetItems() {
  yield takeEvery(actions.GET_ITEMS, getItems);
}

export function* getProduct({ productId }) {
  try {
    const product = yield call(API.getItem, productId);
    yield put(actions.receiveItem(product));
    yield put(actions.setGetItemsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetItemsFailure(error));
    Analytics.sendErrorReport(error, "getProduct", { productId });
  }
}

export function* watchGetProduct() {
  yield takeEvery(actions.GET_PRODUCT, getProduct);
}

export function* getLoyaltyReport1({ storeId, locationId, start, end, basis }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "loyalty",
      start,
      end,
      locationId,
      basis
    );
    let transactions = [];
    if (locationId) {
      transactions = yield call(
        _getPointTransactionsByLocation,
        locationId,
        null,
        start,
        end,
        basis
      );
    } else {
      transactions = yield call(
        _getPointTransactionsByStore,
        storeId,
        null,
        start,
        end,
        null,
        basis
      );
    }
    console.log(report);
    yield put(
      actions.receiveLoyaltyReport1({
        ...report,
        transactions,
      })
    );
    yield put(actions.setLoyaltyReport1Success());
  } catch (error) {
    yield put(actions.setLoyaltyReport1Failure(error));
    Analytics.sendErrorReport(error, "getLoyaltyReport1", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetLoyaltyReport1() {
  yield takeEvery(actions.GET_LOYALTY_REPORT1, getLoyaltyReport1);
}

export function* getLoyaltySummary({ storeId }) {
  try {
    const summary = yield call(API.getLoyaltySummary, storeId);
    yield put(actions.setGetLoyaltySummarySuccess());
    yield put(actions.receiveLoyaltySummary(summary));
  } catch (error) {
    yield put(actions.setGetLoyaltySummaryFailure(error));
    Analytics.sendErrorReport(error, "getLoyaltySummary", { storeId });
  }
}

export function* watchGetLoyaltySummary() {
  yield takeEvery(actions.GET_LOYALTY_SUMMARY, getLoyaltySummary);
}

export function* getSalesSummary({ storeId }) {
  try {
    const summary = yield call(API.getSalesSummary, storeId);
    yield put(actions.setGetSalesSummarySuccess());
    yield put(actions.receiveSalesSummary(summary));
  } catch (error) {
    yield put(actions.setGetSalesSummaryFailure(error));
    Analytics.sendErrorReport(error, "getSalesSummary", { storeId });
  }
}

export function* watchGetSalesSummary() {
  yield takeEvery(actions.GET_SALES_SUMMARY, getSalesSummary);
}

export function* getSalesReport({
  storeId,
  item,
  start,
  end,
  locationId,
  locationIds,
  basis,
}) {
  try {
    const report = yield call(
      API.getSalesReport,
      storeId,
      item,
      start,
      end,
      locationId,
      locationIds,
      null,
      null,
      basis
    );
    // TODO 이미 불러왔으면 안부르게 고치기
    if (item === "products" || item === "categories" || item === "modifiers") {
      const items = yield _getItems(storeId);
      yield put(actions.receiveItems(items));
    }
    yield put(actions.setGetSalesReportSuccess());
    yield put(actions.receiveSalesReport(report));
  } catch (error) {
    yield put(actions.setGetSalesReportFailure(error));
    if (item !== "discount") {
      Analytics.sendMixPanelEvent("error", {
        screen: "ReportItem",
        action: "failed_load",
        detail: error.errors && error.errors[0]?.message,
      });
    }
    Analytics.sendErrorReport(error, "getSalesReport", {
      storeId,
      item,
      start,
      end,
    });
  }
}

export function* watchGetSalesReport() {
  yield takeEvery(actions.GET_SALES_REPORT, getSalesReport);
}

export function* getItemsReport({
  storeId,
  start,
  end,
  locationId,
  locationIds,
  basis,
}) {
  try {
    const report = yield call(
      API.getSalesReport,
      storeId,
      "items",
      start,
      end,
      locationId,
      locationIds,
      null,
      null,
      basis
    );
    const items = yield _getItems(storeId);
    yield put(actions.receiveItems(items));
    yield put(actions.setItemsReportSuccess());
    yield put(actions.receiveItemsReport(report?.item));
  } catch (error) {
    yield put(actions.setItemsReportFailure(error));

    Analytics.sendErrorReport(error, "getItemsReport", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetItemsReport() {
  yield takeEvery(actions.GET_ITEMS_REPORT, getItemsReport);
}

export function* getOrderReport({
  storeId,
  locationId,
  start,
  end,
  item,
  basis,
}) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      item,
      start,
      end,
      locationId,
      basis
    );
    const organization = yield select(reducers.getCurrentOrganization);
    if (locationId && organization?.locationId) {
      if (item === "customer") {
        yield put(
          actions.receiveCustomerReport(report.locations[locationId] || {})
        );
      } else if (item === "taxRefund") {
        const itemReport = yield call(
          API.getPeriodReport,
          storeId,
          "taxRefundItems",
          start,
          end,
          locationId
        );
        yield put(actions.receiveTrsReport(report.locations[locationId] || {}));
        yield put(
          actions.receiveTrsItemReport(itemReport.locations[locationId] || {})
        );
      } else {
        yield put(
          actions.receiveOrderReport(report.locations[locationId] || {})
        );
      }
    } else {
      if (item === "customer") {
        yield put(actions.receiveCustomerReport(report));
      } else if (item === "taxRefund") {
        const itemReport = yield call(
          API.getPeriodReport,
          storeId,
          "taxRefundItems",
          start,
          end,
          locationId
        );
        yield put(actions.receiveTrsReport(report));
        yield put(actions.receiveTrsItemReport(itemReport));
      } else {
        yield put(actions.receiveOrderReport(report));
      }
    }
    yield put(actions.setGetOrderReportSuccess());
  } catch (error) {
    yield put(actions.setGetOrderReportFailure(error));
    if (item === "salesSummary") {
      Analytics.sendMixPanelEvent("error", {
        screen: "ReportHome",
        action: "failed_load",
        detail: error.errors && error.errors[0]?.message,
      });
    }
    Analytics.sendErrorReport(error, "getOrderReport", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetOrderReport() {
  yield takeEvery(actions.GET_ORDER_REPORT, getOrderReport);
}

export function* getChannelOrderReport({
  storeId,
  locationId,
  start,
  end,
  item,
  basis,
  origin,
}) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      item,
      start,
      end,
      null,
      basis,
      origin
    );
    yield put(actions.receiveChannelOrderReport(report));
    yield put(actions.setChannelOrderReportSuccess());
  } catch (error) {
    yield put(actions.setChannelOrderReportFailure(error));
    Analytics.sendErrorReport(error, "getChannelOrderReport", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchChannelOrderReport() {
  yield takeEvery(actions.GET_CHANNEL_ORDER_REPORT, getChannelOrderReport);
}

export function* getDeliveryReport({
  storeId,
  locationId,
  start,
  end,
  item,
  basis,
}) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      item,
      start,
      end,
      locationId,
      basis
    );
    const organization = yield select(reducers.getCurrentOrganization);
    if (locationId && organization?.locationId) {
      yield put(
        actions.receiveDeliveryReport(report.locations[locationId] || {})
      );
    } else {
      yield put(actions.receiveDeliveryReport(report));
    }
    yield put(actions.setGetDeliveryReportSuccess());
  } catch (error) {
    yield put(actions.setGetDeliveryReportFailure(error));
    Analytics.sendErrorReport(error, "getDeliveryReport", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetDeliveryReport() {
  yield takeEvery(actions.GET_DELIVERY_REPORT, getDeliveryReport);
}

export function* getPointReport({ storeId, locationId, start, end, basis }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "point",
      start,
      end,
      locationId,
      basis
    );
    const organization = yield select(reducers.getCurrentOrganization);
    if (locationId && organization?.locationId) {
      yield put(actions.receivePointReport(report.locations[locationId] || {}));
    } else {
      yield put(actions.receivePointReport(report));
    }
    yield put(actions.setGetPointReportSuccess());
  } catch (error) {
    yield put(actions.setGetPointReportFailure(error));
    Analytics.sendErrorReport(error, "getPointReport", {
      storeId,
      start,
      end,
    });
  }
}

export function* watchGetPointReport() {
  yield takeEvery(actions.GET_POINT_REPORT, getPointReport);
}

export function* getSubscriptionReport({ storeId, locationId, start, end }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "subscription",
      start,
      end,
      locationId
    );
    yield put(actions.receiveSubscriptionReport(report));
    yield put(actions.setGetSubscriptionReportSuccess());
  } catch (error) {
    yield put(actions.setGetSubscriptionReportFailure(error));
    Analytics.sendErrorReport(error, "getSubscriptionReport", {
      storeId,
      start,
      end,
    });
    Analytics.sendMixPanelEvent("error", {
      screen: "Subscription",
      action: "failed_load_report",
      detail: error.errors && error.errors[0]?.message,
    });
  }
}

export function* watchGetSubscriptionReport() {
  yield takeEvery(actions.GET_SUBSCRIPTION_REPORT, getSubscriptionReport);
}

export function* getReviewReport({ storeId, locationId, start, end }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "review",
      start,
      end
    );
    const organization = yield select(reducers.getCurrentOrganization);
    if (locationId && organization?.locationId) {
      yield put(
        actions.receiveReviewReport(report.locations[locationId] || {})
      );
    } else {
      yield put(actions.receiveReviewReport(report));
    }
    yield put(actions.setGetReviewReportSuccess());
  } catch (error) {
    yield put(actions.setGetReviewReportFailure(error));
    Analytics.sendErrorReport(error, "getReviewReport", {
      storeId,
      start,
      end,
    });
  }
}

export function* watchGetReviewReport() {
  yield takeEvery(actions.GET_REVIEW_REPORT, getReviewReport);
}

export function* getInventoryItemReport({ storeId, locationId, start, end }) {
  try {
    if (storeId) {
      const items = yield _getItems(storeId);
      yield put(actions.receiveItems(items));
    }
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "inventoryItems",
      start,
      end,
      locationId,
      "pay"
    );
    yield put(actions.receiveInventoryReport(report));
    yield put(actions.setInventoryReportStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryReportStatusFailure(error));
  }
}

export function* watchGetInventoryItemReport() {
  yield takeEvery(actions.GET_INVENTORY_ITEM_REPORT, getInventoryItemReport);
}

export function* getInventoryDateReport({ storeId, locationId, start, end }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "inventoryDates",
      start,
      end,
      locationId,
      "pay"
    );
    yield put(actions.receiveInventoryReport(report));
    yield put(actions.setInventoryReportStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryReportStatusFailure(error));
  }
}

export function* watchGetInventoryDateReport() {
  yield takeEvery(actions.GET_INVENTORY_DATE_REPORT, getInventoryDateReport);
}

export function* getInventoryTurnoverReport({
  storeId,
  locationId,
  start,
  end,
}) {
  try {
    if (storeId) {
      const items = yield _getItems(storeId);
      yield put(actions.receiveItems(items));
    }
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "inventoryTurnover",
      start,
      end,
      locationId,
      "pay"
    );
    yield put(actions.receiveInventoryReport(report));
    yield put(actions.setInventoryReportStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryReportStatusFailure(error));
  }
}

export function* watchGetInventoryTurnoverReport() {
  yield takeEvery(
    actions.GET_INVENTORY_TURNOVER_REPORT,
    getInventoryTurnoverReport
  );
}

export function* getInventoryAssetReport({ storeId, locationId, start, end }) {
  try {
    const report = yield call(
      API.getPeriodReport,
      storeId,
      "inventoryAsset",
      start,
      end,
      locationId,
      "pay"
    );
    yield put(actions.receiveInventoryReport(report));
    yield put(actions.setInventoryReportStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryReportStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryAssetReport", {
      storeId,
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetInventoryAssetReport() {
  yield takeEvery(actions.GET_INVENTORY_ASSET_REPORT, getInventoryAssetReport);
}

function* _getAllSettlements(storeId, locationId, start, end) {
  let _settlements = [];
  let data = {};
  if (locationId) {
    data = yield call(
      API.getSettlements,
      locationId,
      {
        between: [start, end],
      },
      500,
      "DESC",
      data.nextToken
    );
    _settlements = [..._settlements, ...data.items];
  } else {
    data = yield call(
      API.getSettlementsByStore,
      storeId,
      {
        between: [start, end],
      },
      500,
      "DESC",
      data.nextToken
    );
    _settlements = [..._settlements, ...data.items];
  }
  return _settlements;
}

export function* getSettlements({ storeId, locationId, start, end }) {
  try {
    const settlements = yield _getAllSettlements(
      storeId,
      locationId,
      start,
      end
    );
    yield put(actions.receiveSettlements(settlements));
    yield put(actions.setSettlementsStatusSuccess());
  } catch (error) {
    yield put(actions.setSettlementsStatusFailure(error));
    Analytics.sendErrorReport(error, "getSettlements", { storeId, start, end });
  }
}

export function* watchGetSettlements() {
  yield takeEvery(actions.GET_SETTLEMENTS, getSettlements);
}

export function* getPurchaseOrders({ storeId, locationId }) {
  try {
    let orders = [];
    let data = {};
    let count = 1;
    do {
      data = yield call(
        API.getPurchaseOrders,
        storeId,
        locationId,
        count++,
        300
      );
      orders = [...orders, ...data.items];
    } while (data.count > orders.length);
    yield put(actions.receivePurchaseOrders(orders));
    yield put(actions.setPurchaseOrderStatusSuccess());
  } catch (error) {
    yield put(actions.setPurchaseOrderStatusFailure(error));
    Analytics.sendErrorReport(error, "getPurchaseOrders");
  }
}

export function* watchGetPurchaseOrders() {
  yield takeEvery(actions.GET_PURCHASE_ORDERS, getPurchaseOrders);
}

export function* getEmployeeByPurchaseOrderId({ purchaseOrderId }) {
  try {
    let purchaseOrdersById = yield select(reducers.getPurchaseOrdersById);
    const purchaseOrder = purchaseOrdersById[purchaseOrderId];
    if (purchaseOrder) {
      if (purchaseOrder.createdByEmployeeId) {
        const employeesById = yield select(reducers.getEmployeesById);
        if (!(purchaseOrder.createdByEmployeeId in employeesById)) {
          let employee = yield call(
            API.getEmployee,
            purchaseOrder.createdByEmployeeId
          );
          yield put(actions.receiveEmployee(employee));
        } else {
          yield delay(100);
        }
      }
    }
    yield put(actions.setEmployeeStatusSuccess());
  } catch (error) {
    yield put(actions.setEmployeeStatusFailure(error));
    Analytics.sendErrorReport(error, "getEmployeeByPurchaseOrderId");
  }
}

export function* watchGetEmployeeByPurchaseOrderId() {
  yield takeEvery(
    actions.GET_EMPLOYEE_BY_PURCHASE_ORDER_ID,
    getEmployeeByPurchaseOrderId
  );
}

export function* getPurchaseOrderById({ purchaseOrderId }) {
  try {
    let purchaseOrder = {};
    purchaseOrder = yield call(API.getPurchaseOrder, purchaseOrderId);
    yield put(actions.receivePurchaseOrder(purchaseOrder));
    yield put(actions.setPurchaseOrderStatusSuccess());
  } catch (error) {
    yield put(actions.setPurchaseOrderStatusFailure(error));
    Analytics.sendErrorReport(error, "getPurchaseOrderById");
  }
}

export function* watchGetPurchaseOrderById() {
  yield takeEvery(actions.GET_PURCHASE_ORDER_BY_ID, getPurchaseOrderById);
}

export function* createOrUpdatePurchaseOrder({ purchaseOrder, adjustments }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const locationId = yield select(reducers.getCurrentLocationId);
    const organization = yield select(reducers.getCurrentOrganization);
    let employee = yield select(reducers.getEmployee);
    let _purchaseOrder = _.cloneDeep(purchaseOrder);
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    if (!_purchaseOrder.id) {
      _purchaseOrder.inventories = (_purchaseOrder.inventories || []).map(
        (inv) => ({
          ...inv,
          waitingQuantity: inv.quantity,
        })
      );
      API.applyPurchaseOrder(
        {
          ..._purchaseOrder,
          locationId: purchaseOrder.locationId || locationId,
          createdLocationId: organization.locationId || null,
          storeId: storeId,
          status: "pending",
          createdByEmployeeId: employee.id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          expectDate: purchaseOrder.expectDate
            ? new Date(purchaseOrder.expectDate).toISOString()
            : null,
        },
        adjustments,
        origin.name,
        origin.id
      );
    } else {
      API.applyPurchaseOrder(
        {
          ..._purchaseOrder,
          updatedAt: new Date().toISOString(),
        },
        adjustments,
        origin.name,
        origin.id
      );
    }
  } catch (error) {
    yield put(actions.setPurchaseOrderStatusFailure(error));
  }
}

export function* watchCreateOrUpdatePurchaseOrder() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_PURCHASE_ORDER,
    createOrUpdatePurchaseOrder
  );
}

export function* deletePurchaseOrder({ purchaseOrder }) {
  try {
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    API.cancelPurchaseOrder(purchaseOrder, origin.name, origin.id);
  } catch (error) {
    yield put(actions.setPurchaseOrderStatusFailure(error));
  }
}

export function* watchDeletePurchaseOrder() {
  yield takeEvery(actions.DELETE_PURCHASE_ORDER, deletePurchaseOrder);
}

export function* getSuppliers({ storeId, locationId }) {
  try {
    const data = yield call(API.getSuppliers, storeId, locationId);
    const suppliers = data.items;
    yield put(actions.receiveSuppliers(suppliers));
    yield put(actions.setSupplierStatusSuccess());
  } catch (error) {
    yield put(actions.setSupplierStatusFailure(error));
    Analytics.sendErrorReport(error, "getSuppliers");
  }
}

export function* watchGetSuppliers() {
  yield takeEvery(actions.GET_SUPPLIERS, getSuppliers);
}

export function* createOrUpdateSupplier({ supplier }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const locationId = yield select(reducers.getCurrentLocationId);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const isLocation = Authority.level() === "location";
    let _supplier = _.cloneDeep(supplier);

    if (!_supplier.id) {
      _supplier = {
        ..._supplier,
        id: generateId("spl"),
        storeId: storeId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        public: supplier.public || false,
      };
      if (isLocation) {
        _supplier = yield call(API.createSupplier, {
          ..._supplier,
          locationId,
        });
      } else {
        if (isMultiLocation) {
          _supplier = yield call(API.createSupplier, {
            ..._supplier,
          });
        } else {
          _supplier = yield call(API.createSupplier, _supplier);
        }
      }
    } else {
      _supplier = yield call(API.updateSupplier, {
        ...supplier,
        updatedAt: new Date().toISOString(),
      });
    }

    yield put(actions.receiveSupplier(_supplier));
    yield put(actions.setSupplierStatusSuccess());
  } catch (error) {
    yield put(actions.setSupplierStatusFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateSupplier", {
      supplier,
    });
  }
}

export function* watchCreateOrUpdateSupplier() {
  yield takeEvery(actions.CREATE_OR_UPDATE_SUPPLIER, createOrUpdateSupplier);
}

export function* deleteSupplier({ supplier }) {
  try {
    yield call(API.deleteSupplier, supplier.id);
    yield put(actions.receiveDeletedSupplier(supplier));
    yield put(actions.setSupplierStatusSuccess());
  } catch (error) {
    yield put(actions.setSupplierStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteSupplier", {
      supplier,
    });
  }
}

export function* watchDeleteSupplier() {
  yield takeEvery(actions.DELETE_SUPPLIER, deleteSupplier);
}

export function* getSalesHistories({ storeId, locationId, start, end }) {
  try {
    let histories = [];
    if (locationId) {
      histories = yield call(
        API.getSalesHistoriesByLocation,
        locationId,
        start,
        end
      );
    } else {
      histories = yield call(API.getSalesHistoriesByStore, storeId, start, end);
    }
    yield put(actions.setGetSalesHistoriesSuccess());
    yield put(actions.receiveSalesHistories(histories));
  } catch (error) {
    yield put(actions.setGetSalesHistoriesFailure(error));
    Analytics.sendErrorReport(error, "getSalesHistories", {
      storeId,
      start,
      end,
    });
  }
}

export function* watchGetSalesHistories() {
  yield takeEvery(actions.GET_SALES_HISTORIES, getSalesHistories);
}

export function* updateProducts({ products }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const locationId = yield select(reducers.getCurrentLocationId);
    const level = "public";
    yield call(
      Storage.put,
      `${storeId}/excel/products.json`,
      JSON.stringify(products),
      {
        level: level,
        contentType: "application/json",
      }
    );
    yield call(
      API.parseItemTemplate,
      storeId,
      `${level}/${storeId}/excel/products.json`,
      locationId,
      "json",
      "general"
    );
    yield call(
      API.syncItemTemplate,
      storeId,
      `${level}/${storeId}/excel/products.json`,
      "general"
    );
    const updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    console.log(error);
    if (
      !error?.errors ||
      error.errors[0].errorType !== "Lambda:ExecutionTimeoutException"
    ) {
      yield put(actions.syncItemTemplateFailure(error));
    }
    Analytics.sendErrorReport(error, "updateProducts", { products });
    Analytics.sendMixPanelEvent("error", {
      screen: "Product",
      action: "failed_save_in_excel_mode",
      detail: error.errors && error.errors[0]?.message,
    });
  }
}

export function* watchUpdateProducts() {
  yield takeEvery(actions.UPDATE_PRODUCTS, updateProducts);
}

export function* updateSkuLocations({ locations }) {
  try {
    for (let loc of locations) {
      yield call(API.updateSkuLocation, loc);
    }
    const storeId = yield select(reducers.getStoreId);
    const updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems, true));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "updateSkuLocations", { locations });
  }
}

export function* watchUpdateSkuLocations() {
  yield takeEvery(actions.UPDATE_SKU_LOCATIONS, updateSkuLocations);
}

function* _createOrUpdateProduct(product, transactions, modifierOrdering) {
  const storeId = yield select(reducers.getStoreId);
  let _product = _.cloneDeep({ ...product, type: "product" });
  let _skus = [];
  for (let sku of _product.skus || []) {
    _skus.push({
      ...sku,
      cost: _.isNil(sku.cost) || sku.cost === "" ? null : Number(sku.cost) || 0,
      stockAlertQty:
        _.isNil(sku.stockAlertQty) || sku.stockAlertQty === ""
          ? null
          : Number(sku.stockAlertQty) || 0,
    });
  }
  _product.skus = _skus;
  const options = modifierOrdering ? { modifierOrdering } : null;
  console.log(transactions);
  _product.storeId = storeId;

  if (!product.itemId) {
    _product = {
      ..._product,
      id: generateId("prod"),
    };
    _product = yield call(API.createItem, _product, options);
  } else {
    _product = yield call(API.updateItem, _product, options);
  }
  if (product.attributes) {
    let store = yield select(reducers.getStore);
    const attributesByName = (store.item?.attributes || []).reduce(
      (obj, attr) => {
        obj[attr.name] = attr;
        return obj;
      },
      {}
    );
    let isNeedSave = false;
    for (let attr of product.attributes) {
      if (!(attr.name in attributesByName)) {
        if (_.isEmpty(store.item)) {
          store.item = {};
        }
        if (_.isEmpty(store.item.attributes)) {
          store.item.attributes = [];
        }
        store.item.attributes = [
          ...store.item.attributes,
          { name: attr.name, type: attr.type },
        ];
        isNeedSave = true;
      }
    }
    if (isNeedSave) {
      const updatedStore = yield call(API.updateStore, store);
      yield put(actions.initStore(updatedStore));
    }
  }
  return _product;
}

function* _updateCatMenus(location, prodCategoryIds, prodId) {
  const _menusByCateId = (location.catMenus || []).reduce((obj, m) => {
    obj[m.parentId] = _.cloneDeep(m);
    return obj;
  }, {});

  (prodCategoryIds || []).forEach((cateId) => {
    if (cateId in _menusByCateId) {
      if (!(_menusByCateId[cateId].ids || []).includes(prodId)) {
        _menusByCateId[cateId].ids = [..._menusByCateId[cateId].ids, prodId];
      }
    }
  });

  Object.values(_menusByCateId).forEach((catMenu) => {
    if ((catMenu.ids || []).includes(prodId)) {
      if (!(prodCategoryIds || []).includes(catMenu.parentId)) {
        _menusByCateId[catMenu.parentId].ids = (
          _menusByCateId[catMenu.parentId].ids || []
        ).filter((id) => id !== prodId);
      }
    }
  });
  const catMenus = Object.values(_menusByCateId);
  const _location = { ...location, catMenus: catMenus };
  if (!_.isEqual(location, _location)) {
    yield call(API.updateLocation, _location);
  }
}

function* _updateMenuBoardConfigFromProduct(location, prodCategoryIds, prodId) {
  let menuBoard = _.cloneDeep(location.menu?.board);
  if ((menuBoard?.pages || []).length > 0) {
    let pagesByParentId = menuBoard.pages.reduce((obj, p) => {
      obj[p.parentId] = p;
      return obj;
    }, {});
    (prodCategoryIds || []).forEach((cateId) => {
      if (cateId in pagesByParentId) {
        let prodIdsInPage = (pagesByParentId[cateId].items || [])
          .filter((item) => item.type === "product")
          .map((item) => item.parentId);
        if (!(prodIdsInPage || []).includes(prodId)) {
          let itemsByPosition = (pagesByParentId[cateId]?.items || []).reduce(
            (obj, i) => {
              obj[i.position] = i;
              return obj;
            },
            {}
          );
          let nextPosition = 0;
          while (nextPosition in itemsByPosition) {
            nextPosition += 1;
          }
          let updatedMenuItem = {
            parentId: prodId,
            type: "product",
            position: nextPosition,
          };
          itemsByPosition[nextPosition] = updatedMenuItem;
          pagesByParentId[cateId] = {
            ...pagesByParentId[cateId],
            items: [...(pagesByParentId[cateId].items || []), updatedMenuItem],
          };
        }
      }
    });
    menuBoard.pages.forEach((page) => {
      let itemsByParentId = (page.items || [])
        .filter((item) => item.type === "product")
        .reduce((obj, i) => {
          obj[i.parentId] = i;
          return obj;
        }, {});
      if (prodId in itemsByParentId) {
        if (!(prodCategoryIds || []).includes(page.parentId)) {
          pagesByParentId[page.parentId] = {
            ...pagesByParentId[page.parentId],
            items: (pagesByParentId[page.parentId].items || []).filter(
              (item) => item.parentId !== prodId
            ),
          };
          // pagesByParentId[page.parentId].items = (
          //   pagesByParentId[page.parentId].items || []
          // ).filter((item) => item.parentId !== prodId);
        }
      }
    });
    // prod 삭제시 favorite에서 제외
    if (!prodCategoryIds && prodId) {
      let favoriteItemsByParentId = (menuBoard.favorite || []).reduce(
        (obj, f) => {
          obj[f.parentId] = f;
          return obj;
        },
        {}
      );
      if (prodId in favoriteItemsByParentId) {
        menuBoard = {
          ...menuBoard,
          favorite: menuBoard.favorite.filter((fav) => fav.parentId !== prodId),
        };
      }
    }
    menuBoard = { ...menuBoard, pages: Object.values(pagesByParentId) };
    const _location = {
      ...location,
      menu: { ...(location.menu || {}), board: menuBoard || {} },
    };
    if (!_.isEqual(location, _location)) {
      yield call(API.updateLocation, _location);
    }
  }
}

export function* updateStoreMenuPlan({ menuPlanId }) {
  try {
    let store = yield select(reducers.getStore);
    const categoriesById = yield select(reducers.getCategoriesById);
    const productsById = yield select(reducers.getProductsById);
    const items = yield select(reducers.getItems);
    let menusByPlanId = (store.plans?.menus || []).reduce((obj, m) => {
      obj[m.id] = m;
      return obj;
    }, {});
    for (let x = 0; x < (menusByPlanId[menuPlanId]?.pages || []).length; x++) {
      // 카테고리가 삭제된 경우
      if (!(menusByPlanId[menuPlanId].pages[x].parentId in categoriesById)) {
        menusByPlanId[menuPlanId].pages = menusByPlanId[
          menuPlanId
        ].pages.filter(
          (page) =>
            page.parentId !== menusByPlanId[menuPlanId].pages[x].parentId
        );
      } else {
        const category =
          categoriesById[menusByPlanId[menuPlanId].pages[x].parentId];
        const categoryProductIds = (items.categoryProducts || [])
          .filter((cp) => cp.categoryId === category.id)
          .map((item) => item.productId);
        const itemsByProductId = (
          menusByPlanId[menuPlanId].pages[x].items || []
        ).reduce((obj, i) => {
          obj[i.parentId] = i;
          return obj;
        }, {});
        // 카테고리 내에서 제품이 추가된 경우
        for (let prodId of categoryProductIds || []) {
          if (!(prodId in itemsByProductId)) {
            const itemsByPosition = (
              menusByPlanId[menuPlanId].pages[x].items || []
            ).reduce((obj, i) => {
              obj[i.position] = i;
              return obj;
            }, {});
            let nextPosition = 0;
            while (nextPosition in itemsByPosition) {
              nextPosition += 1;
            }
            let updatedMenuItem = {
              parentId: prodId,
              type: "product",
              position: nextPosition,
            };
            menusByPlanId[menuPlanId].pages[x] = {
              ...menusByPlanId[menuPlanId].pages[x],
              items: [
                ...(menusByPlanId[menuPlanId].pages[x].items || []),
                updatedMenuItem,
              ],
            };
          }
        }
        for (
          let y = 0;
          y < (menusByPlanId[menuPlanId].pages[x].items || []).length;
          y++
        ) {
          if (menusByPlanId[menuPlanId].pages[x].items[y].type === "product") {
            // 카테고리 내에서 제품이 삭제된 경우
            // 제품이 아예 삭제된 경우
            if (
              !(categoryProductIds || []).includes(
                menusByPlanId[menuPlanId].pages[x].items[y].parentId
              ) ||
              !(
                menusByPlanId[menuPlanId].pages[x].items[y].parentId in
                productsById
              )
            ) {
              menusByPlanId[menuPlanId].pages[x] = {
                ...menusByPlanId[menuPlanId].pages[x],
                items: menusByPlanId[menuPlanId].pages[x].items
                  .filter(
                    (item) =>
                      item.parentId !==
                      menusByPlanId[menuPlanId].pages[x].items[y].parentId
                  )
                  .map((i, index) => ({ ...i, position: index })),
              };
            }
          }
        }
      }
    }
    const updatedStore = yield call(API.updateStore, {
      ...store,
      plans: {
        ...(store.plans || {}),
        menus: Object.values(menusByPlanId),
      },
    });
    store = yield call(_getStoreWithLocations, store.id);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateStoreMenuPlan", menuPlanId);
  }
}

export function* watchUpdateStoreMenuPlan() {
  yield takeEvery(actions.UPDATE_STORE_MENUPLAN, updateStoreMenuPlan);
}

export function* createOrUpdateProduct({
  product,
  histories,
  modifierOrdering,
}) {
  try {
    const isLegacy = yield select(reducers.isLegacyItems);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    const locations = yield call(_getLocations, storeId);
    if (!isLegacy) {
      let _product = _.cloneDeep(product);
      _product = yield _createOrUpdateProduct(
        product,
        histories,
        modifierOrdering
      );
      if (isMultiLocation) {
        for (let i = 0; i < locations.length; i++) {
          const _location = _.cloneDeep(locations[i]);
          yield _updateCatMenus(_location, _product.categoryIds, _product.id);
          yield _updateMenuBoardConfigFromProduct(
            _location,
            _product.categoryIds,
            _product.id
          );
        }
        const updatedStore = yield call(_getStoreWithLocations, storeId);
        yield put(actions.initStore(updatedStore));
      } else {
        for (let i = 0; i < locations.length; i++) {
          const _location = _.cloneDeep(locations[i]);
          yield _updateCatMenus(_location, _product.categoryIds, _product.id);
          yield _updateMenuBoardConfigFromProduct(
            _location,
            _product.categoryIds,
            _product.id
          );
        }
        const updatedStore = yield call(_getStoreWithLocations, storeId);
        yield put(actions.initStore(updatedStore));
      }
      let updatedItems = yield _getItems(storeId);
      yield put(actions.receiveItems(updatedItems));
      yield put(actions.setUpdateItemsSuccess());
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);
      let _product = _.cloneDeep(product);

      let products = yield select(reducers.getProductsById);

      if (!_product.itemId) {
        _product.itemId = generateId("prod");
      }
      for (let idx in _product.skus) {
        if (!_product.skus[idx].itemId) {
          _product.skus[idx].itemId = generateId("sku");
        }
        if ((histories || []).length > 0) {
          for (let i = 0; i < histories.length; i++) {
            if (histories[i] && histories[i].id === _product.skus[idx].itemId) {
              if (
                products[_product.itemId] &&
                products[_product.itemId].skusById[histories[i].id]
              ) {
                let stock =
                  products[_product.itemId].skusById[histories[i].id].stock ||
                  0;
                _product.skus[idx].stock = stock + histories[i].quantity;
              } else {
                _product.skus[idx].stock = histories[i].quantity;
              }
            }
          }
        }
      }

      _items.productModifiers = (items.productModifiers || []).filter(
        (item) => item.productId !== _product.itemId
      );
      _items.categoryProducts = (items.categoryProducts || []).filter(
        (item) => item.productId !== _product.itemId
      );

      for (let categoryId of _product.categoryIds || []) {
        _items.categoryProducts = [
          ..._items.categoryProducts,
          { categoryId: categoryId, productId: _product.itemId },
        ];
      }
      delete _product.categoryIds;
      for (let modifierId of _product.modifierIds || []) {
        _items.productModifiers = [
          ..._items.productModifiers,
          { modifierId: modifierId, productId: _product.itemId },
        ];
      }
      delete _product.modifierIds;

      let _products = {};
      for (let prod of _items.products || []) {
        _products[prod.itemId] = prod;
      }
      _products[_product.itemId] = _product;
      _items.products = Object.values(_products);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      if ((histories || []).length > 0) {
        console.log(histories);
        yield all(
          histories.map((hs) =>
            call(API.createInventoryHistory, {
              ...hs,
              createdAt: hs.createdAt || new Date().toISOString(),
            })
          )
        );
      }
      yield put(actions.receiveItems(updatedItems));
      yield put(actions.setUpdateItemsSuccess());
    }
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateProduct", {
      product,
      histories,
    });
  }
}

export function* watchCreateOrUpdateProduct() {
  yield takeEvery(actions.CREATE_OR_UPDATE_PRODUCT, createOrUpdateProduct);
}

export function* deleteProduct({ id }) {
  try {
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    const locations = yield call(_getLocations, storeId);
    const isLegacy = yield select(reducers.isLegacyItems);
    if (!isLegacy) {
      yield call(API.deleteItem, { id, type: "product" });

      if (isMultiLocation) {
        for (let i = 0; i < locations.length; i++) {
          const _location = _.cloneDeep(locations[i]);
          yield _updateCatMenus(_location, null, id);
          yield _updateMenuBoardConfigFromProduct(_location, null, id);
        }
        const updatedStore = yield call(_getStoreWithLocations, storeId);
        yield put(actions.initStore(updatedStore));
      } else {
        for (let i = 0; i < locations.length; i++) {
          let _location = _.cloneDeep(locations[i]);
          yield _updateCatMenus(_location, null, id);
          yield _updateMenuBoardConfigFromProduct(_location, null, id);
        }
        const updatedStore = yield call(_getStoreWithLocations, storeId);
        yield put(actions.initStore(updatedStore));
      }
      let updatedItems = yield _getItems(storeId);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);

      _items.productModifiers = (_items.productModifiers || []).filter(
        (item) => item.productId !== id
      );
      _items.categoryProducts = (_items.categoryProducts || []).filter(
        (item) => item.productId !== id
      );

      let _products = {};
      for (let prod of _items.products || []) {
        _products[prod.itemId] = prod;
      }
      delete _products[id];
      _items.products = Object.values(_products);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    }
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "deleteProduct", { id });
  }
}

export function* watchDeleteProduct() {
  yield takeEvery(actions.DELETE_PRODUCT, deleteProduct);
}

export function* adjustInventories({ adjustments }) {
  try {
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    API.applyInventoryAdjustments(adjustments, origin.name, origin.id);
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
  }
}

export function* watchAdjustInventories() {
  yield takeEvery(actions.ADJUST_INVENTORIES, adjustInventories);
}

export function* createSkuInventory({ productId, skuId, inventory }) {
  try {
    const storeId = yield select(reducers.getStoreId);

    console.log(inventory);

    yield call(API.createInventory, {
      storeId,
      productId,
      skuId,
      inventory,
    });
    let updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "createSkuInventory", {
      productId,
      skuId,
      inventory,
    });
  }
}

export function* watchCreateSkuInventory() {
  yield takeEvery(actions.CREATE_SKU_INVENTORY, createSkuInventory);
}

export function* updateSkuInventory({ inventory }) {
  try {
    console.log(inventory);
    yield call(API.updateInventory, inventory);
    const storeId = yield select(reducers.getStoreId);
    let updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "updateSkuInventory", {
      inventory,
    });
  }
}

export function* watchUpdateSkuInventory() {
  yield takeEvery(actions.UPDATE_SKU_INVENTORY, updateSkuInventory);
}

export function* deleteSkuInventory({ inventory }) {
  try {
    console.log(inventory);
    yield call(API.deleteInventory, inventory);
    const storeId = yield select(reducers.getStoreId);
    let updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "deleteSkuInventory", {
      inventory,
    });
  }
}

export function* watchDeleteSkuInventory() {
  yield takeEvery(actions.DELETE_SKU_INVENTORY, deleteSkuInventory);
}

export function* updateSyncMenuBoard({ menu, locationInfo }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const categories = yield select(reducers.getCategoriesById);
    const products = yield select(reducers.getProductsById);
    const _locationInfo = yield call(API.getStoreLocation, locationInfo.id);
    const availableProductIds = new Set();
    for (const productInfo of Object.values(products)) {
      for (const sku of productInfo.skus) {
        for (const location of sku.locations) {
          if (location.id === locationInfo.id && location.available) {
            availableProductIds.add(productInfo.id);
            break;
          }
        }
      }
    }
    let _pages = [];

    menu.pages.forEach((page, idx) => {
      if (categories[page.parentId]) {
        _pages.push({
          ...page,
          index: _pages.length,
          title: categories[page.parentId].name,
        });
      }
    });
    const availableMenu = _pages.map((page) => ({
      ...page,
      items: page.items
        .filter((item) => availableProductIds.has(item.parentId))
        .map((item, idx) => ({
          ...item,
          position: idx,
        })),
    }));
    const _location = {
      ..._locationInfo,
      menuPlanId: menu.id,
      menu: {
        ..._locationInfo.menu,
        board: {
          ...(_locationInfo.menu?.board || {}),
          ...(_locationInfo.menu?.board?.favorite
            ? {
                favorite: _locationInfo.menu.board.favorite.filter((item) => {
                  if (item.type === "product") {
                    return availableProductIds.has(item.parentId);
                  }
                  return true;
                }),
              }
            : {}),
          pages: availableMenu,
        },
      },
      settings: {
        ..._locationInfo.settings,
        menuMode: "category",
      },
    };
    yield call(API.updateLocation, _location);
    const updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
    yield put(actions.syncMenuBoardSuccess());
  } catch (error) {
    yield put(actions.syncMenuBoardFailure(error));
    Analytics.sendErrorReport(error, "updateSyncMenuBoard", {
      menu,
      locationInfo,
    });
  }
}

export function* watchUpdateSyncMenuBoard() {
  yield takeEvery(actions.SYNC_MENU_BOARD, updateSyncMenuBoard);
}

function* _updateMenuPlanFromCategory(category, categoryId, isDelete) {
  const store = yield select(reducers.getStore);
  const _store = _.cloneDeep(store);
  const menus = yield select(reducers.getMenuPlans);
  let _menus = _.cloneDeep(menus);
  const _categoryId = categoryId || category.id;
  for (let x in _menus || []) {
    if (_menus[x].mode === "category") {
      for (let y in _menus[x].pages || []) {
        // 카테고리 삭제할때, menuPlan 삭제해주기
        if (isDelete) {
          _menus[x].pages = _menus[x].pages.filter(
            (page) => page.parentId !== _categoryId
          );
        }
      }
    }
  }
  const updatedStore = yield call(API.updateStore, {
    ..._store,
    plans: {
      ...(_store.plans || {}),
      menus: _menus,
    },
  });
  yield put(actions.initStore(updatedStore));
}

function* _updateCatMenuPageFromCategory(category, forwardedLocation) {
  const location = yield select(reducers.getCurrentLocation);
  let _location = _.cloneDeep(forwardedLocation || location);
  let _cateMenus = (_location.catMenus || []).reduce((obj, m) => {
    obj[m.parentId] = m;
    return obj;
  }, {});
  if (category.id in _cateMenus) {
    // 카테고리의 product에서 추가된게 있는지 체크
    for (let prodId of category.productIds) {
      if (!(_cateMenus[category.id].ids || []).includes(prodId)) {
        _cateMenus[category.id].ids = [..._cateMenus[category.id].ids, prodId];
      }
    }
    // 카테고리에서 product 삭제된게 있는지 체크
    for (let id of _cateMenus[category.id].ids || []) {
      if (!category.productIds.includes(id)) {
        _cateMenus[category.id].ids = _cateMenus[category.id].ids.filter(
          (originalId) => id && originalId !== id
        );
      }
    }
    _cateMenus[category.id] = {
      ..._cateMenus[category.id],
      title: category.name,
    };
    _location.catMenus = Object.values(_cateMenus);

    const updatedLocation = yield call(API.updateLocation, _location);
    const storeId = yield select(reducers.getStoreId);
    const updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
  }
}

function* _updateMenuBoardConfigFromCategory(category, forwardedLocation) {
  const location = yield select(reducers.getCurrentLocation);
  let _location = _.cloneDeep(forwardedLocation || location);
  let menuBoard = _.cloneDeep(_location.menu?.board);

  if ((menuBoard?.pages || []).length > 0) {
    let pagesByParentId = menuBoard.pages.reduce((obj, p) => {
      obj[p.parentId] = p;
      return obj;
    }, {});
    if (category.id in pagesByParentId) {
      for (let prodId of category.productIds) {
        let itemsByProductId = (pagesByParentId[category.id]?.items || [])
          .filter((item) => item.type === "product")
          .reduce((obj, i) => {
            obj[i.parentId] = i;
            return obj;
          }, {});
        let itemsByPosition = (
          pagesByParentId[category.id]?.items || []
        ).reduce((obj, i) => {
          obj[i.position] = i;
          return obj;
        }, {});
        if (!(prodId in itemsByProductId)) {
          let nextPosition = 0;
          while (nextPosition in itemsByPosition) {
            nextPosition += 1;
          }
          let updatedMenuItem = {
            parentId: prodId,
            type: "product",
            position: nextPosition,
          };
          itemsByPosition[nextPosition] = updatedMenuItem;
          pagesByParentId[category.id] = {
            ...pagesByParentId[category.id],
            items: [
              ...(pagesByParentId[category.id].items || []),
              updatedMenuItem,
            ],
          };
        }
      }
      for (let prodItem of (pagesByParentId[category.id]?.items || []).filter(
        (item) => item.type === "product"
      )) {
        if (!category.productIds.includes(prodItem.parentId)) {
          pagesByParentId[category.id].items = pagesByParentId[
            category.id
          ]?.items.filter((_item) => _item.parentId !== prodItem.parentId);
        }
      }
      pagesByParentId[category.id] = {
        ...pagesByParentId[category.id],
        title: category.name,
      };
    }
    menuBoard = { ...menuBoard, pages: Object.values(pagesByParentId) };
    _location = {
      ..._location,
      menu: { ...(_location.menu || {}), board: menuBoard || {} },
    };
    const updatedLocation = yield call(API.updateLocation, _location);
    const storeId = yield select(reducers.getStoreId);
    const updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
  }
}

export function* createOrUpdateCategory({ category }) {
  try {
    const isLegacy = yield select(reducers.isLegacyItems);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    const locations = yield call(_getLocations, storeId);
    if (!isLegacy) {
      const storeId = yield select(reducers.getStoreId);
      let _category = _.cloneDeep({
        ...category,
        type: "category",
        storeId,
        itemId: category.itemId || generateId("ct"),
      });
      if (!_category.id) {
        _category.id = _category.itemId;
      }
      if (!category.itemId) {
        yield call(API.createItem, _category);
      } else {
        yield call(API.updateItem, _category);
      }
      if (isMultiLocation) {
        for (let i = 0; i < locations.length; i++) {
          const _location = _.cloneDeep(locations[i]);
          yield _updateCatMenuPageFromCategory(category, _location);
          yield _updateMenuBoardConfigFromCategory(category, _location);
        }
        yield _updateMenuPlanFromCategory(category);
      } else {
        for (let i = 0; i < locations.length; i++) {
          const _location = _.cloneDeep(locations[i]);
          yield _updateCatMenuPageFromCategory(category, _location);
          yield _updateMenuBoardConfigFromCategory(category, _location);
        }
      }

      const updatedItems = yield _getItems(storeId);
      yield put(actions.receiveItems(updatedItems));
      yield put(actions.setUpdateItemsSuccess());
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);
      let _category = _.cloneDeep(category);

      if (!_category.itemId) {
        _category.itemId = generateId("ct");
      }

      _items.categoryProducts = (_items.categoryProducts || []).filter(
        (item) => item.categoryId !== _category.itemId
      );

      for (let productId of _category.productIds || []) {
        _items.categoryProducts = [
          ..._items.categoryProducts,
          { categoryId: _category.itemId, productId: productId },
        ];
      }
      delete _category.productIds;

      let _categories = {};
      for (let cate of _items.categories || []) {
        _categories[cate.itemId] = _.cloneDeep(cate);
      }
      _categories[_category.itemId] = _category;
      _items.categories = Object.values(_categories);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      yield put(actions.receiveItems(updatedItems));
      yield put(actions.setUpdateItemsSuccess());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateCategory", { category });
    if (!category.itemId) {
      Analytics.sendMixPanelEvent("error", {
        screen: "Category",
        action: "failed_create_category",
        detail: error.errors && error.errors[0]?.message,
      });
    } else {
      Analytics.sendMixPanelEvent("error", {
        screen: "Category",
        action: "failed_update_category",
        detail: error.errors && error.errors[0]?.message,
      });
    }
  }
}

export function* watchCreateOrUpdateCategory() {
  yield takeEvery(actions.CREATE_OR_UPDATE_CATEGORY, createOrUpdateCategory);
}

function* _deleteCatMenuPageFromCategory(catId, forwardedLocation) {
  const location = yield select(reducers.getCurrentLocation);
  let _location = _.cloneDeep(forwardedLocation || location);
  let _cateMenus = (_location.catMenus || []).reduce((obj, m) => {
    obj[m.parentId] = m;
    return obj;
  }, {});
  if (catId in _cateMenus) {
    _location.catMenus = _location.catMenus.filter(
      (page) => page.parentId !== catId
    );
    _location.catMenus = _location.catMenus.map((menu, index) => ({
      ...menu,
      index,
    }));
    const updatedLocation = yield call(API.updateLocation, _location);
    const storeId = yield select(reducers.getStoreId);
    const updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
  }
}

function* _deleteMenuBoardConfigFromCategory(cateId, forwardedLocation) {
  const location = yield select(reducers.getCurrentLocation);
  let _location = _.cloneDeep(forwardedLocation || location);
  let menuBoard = _.cloneDeep(_location.menu?.board);

  if ((menuBoard?.pages || []).length > 0) {
    let pagesByParentId = menuBoard.pages.reduce((obj, p) => {
      obj[p.parentId] = p;
      return obj;
    }, {});
    if (cateId in pagesByParentId) {
      menuBoard.pages = menuBoard.pages.filter(
        (page) => page.parentId !== cateId
      );
      menuBoard.pages = menuBoard.pages.map((page, _index) => ({
        ...page,
        index: _index,
      }));
      _location = {
        ..._location,
        menu: { ...(_location.menu || {}), board: menuBoard || {} },
      };
      const updatedLocation = yield call(API.updateLocation, _location);
      const storeId = yield select(reducers.getStoreId);
      const updatedStore = yield call(_getStoreWithLocations, storeId);
      yield put(actions.initStore(updatedStore));
    }
  }
}

export function* deleteCategory({ id }) {
  try {
    const isLegacy = yield select(reducers.isLegacyItems);
    const storeId = yield select(reducers.getStoreId);
    if (!isLegacy) {
      const isMultiLocation = yield select(reducers.isMultiLocation);
      const locations = yield call(_getLocations, storeId);

      yield call(API.deleteItem, { id, type: "category" });

      if (isMultiLocation) {
        for (let i = 0; i < locations.length; i++) {
          yield _deleteCatMenuPageFromCategory(id, locations[i]);
          yield _deleteMenuBoardConfigFromCategory(id, locations[i]);
        }
        let checkUpdateCatMenuPlan = yield _updateMenuPlanFromCategory(
          null,
          id,
          true
        );
      } else {
        for (let i = 0; i < locations.length; i++) {
          yield _deleteCatMenuPageFromCategory(id, locations[i]);
          yield _deleteMenuBoardConfigFromCategory(id, locations[i]);
        }
      }
      const updatedItems = yield _getItems(storeId);
      yield put(actions.receiveItems(updatedItems));
      yield put(actions.setUpdateItemsSuccess());
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);

      _items.categoryProducts = (_items.categoryProducts || []).filter(
        (item) => item.categoryId !== id
      );

      let _categories = {};
      for (let cate of _items.categories || []) {
        _categories[cate.itemId] = cate;
      }
      delete _categories[id];
      _items.categories = Object.values(_categories);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    }
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "deleteCategory", { id });
  }
}

export function* watchDeleteCategory() {
  yield takeEvery(actions.DELETE_CATEGORY, deleteCategory);
}

function* _deleteModifierSoldoutIds(id) {
  const modifiersById = yield select(reducers.getModifiersById);
  const currentModifierSkuIds = modifiersById[id].skus.map((sku) => sku.id);
  const currentLocationId = yield select(reducers.getCurrentLocationId);
  const currentLocation = yield call(API.getStoreLocation, currentLocationId);
  const prevSoldoutIds = currentLocation.modifierSoldoutIds || [];
  const shouldUpdateLocation = currentModifierSkuIds.filter((skuId) =>
    prevSoldoutIds.includes(skuId)
  ).length;

  if (shouldUpdateLocation) {
    const updatedModifierSoldoutIds = diffSet(
      prevSoldoutIds,
      currentModifierSkuIds
    );
    currentLocation.modifierSoldoutIds = updatedModifierSoldoutIds;
    yield put(actions.updateLocation(currentLocation));
  }
}

function* _updateModifierSoldoutIds(id, currentSoldoutIds) {
  const currentLocationId = yield select(reducers.getCurrentLocationId);
  const currentLocation = yield call(API.getStoreLocation, currentLocationId);
  const totalSoldoutIds = currentLocation.modifierSoldoutIds || [];
  const modifiersById = yield select(reducers.getModifiersById);
  const prevSkuIds = modifiersById[id]?.skus.map((sku) => sku.id);
  const prevSoldoutIds = prevSkuIds.filter((skuId) =>
    totalSoldoutIds.includes(skuId)
  );
  const shouldUpdateLocation = !_.isEqual(prevSoldoutIds, currentSoldoutIds);
  if (shouldUpdateLocation) {
    const prevSoldoutIdsWithoutPrevSku = diffSet(
      totalSoldoutIds,
      prevSoldoutIds
    );
    const updatedModifierSoldoutIds = [
      ...prevSoldoutIdsWithoutPrevSku,
      ...currentSoldoutIds,
    ];
    currentLocation.modifierSoldoutIds = updatedModifierSoldoutIds;
    yield put(actions.updateLocation(currentLocation));
  }
}

export function* createOrUpdateModifier({ modifier, soldoutIds }) {
  try {
    const isLegacy = yield select(reducers.isLegacyItems);
    if (!isLegacy) {
      const storeId = yield select(reducers.getStoreId);
      let _modifier = _.cloneDeep({
        ...modifier,
        type: "modifier",
        storeId: storeId,
        itemId: modifier.itemId || generateId("mod"),
      });
      if (!_modifier.id) {
        _modifier.id = _modifier.itemId;
      }
      if (!modifier.itemId) {
        yield call(API.createItem, _modifier);
      } else {
        yield call(API.updateItem, _modifier);
        yield call(_updateModifierSoldoutIds, modifier.id, soldoutIds);
      }
      const updatedItems = yield _getItems(storeId);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);
      let _modifier = _.cloneDeep(modifier);

      if (!_modifier.itemId) {
        _modifier.itemId = generateId("mod");
      }
      for (let idx in _modifier.skus) {
        if (!_modifier.skus[idx].itemId) {
          _modifier.skus[idx].itemId = generateId("sku");
        }
      }

      _items.productModifiers = (_items.productModifiers || []).filter(
        (item) => item.modifierId !== _modifier.itemId
      );

      for (let productId of _modifier.productIds || []) {
        _items.productModifiers = [
          ..._items.productModifiers,
          { modifierId: _modifier.itemId, productId: productId },
        ];
      }
      delete _modifier.productIds;

      let _modifiers = {};
      for (let mod of _items.modifiers || []) {
        _modifiers[mod.itemId] = mod;
      }
      _modifiers[_modifier.itemId] = _modifier;
      _items.modifiers = Object.values(_modifiers);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    }
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateModifier", { modifier });
    if (!modifier.itemId) {
      Analytics.sendMixPanelEvent("error", {
        screen: "Modifier",
        action: "failed_create_modifier",
        detail: error.errors && error.errors[0]?.message,
      });
    } else {
      Analytics.sendMixPanelEvent("error", {
        screen: "Modifier",
        action: "failed_update_modifier",
        detail: error.errors && error.errors[0]?.message,
      });
    }
  }
}

export function* watchCreateOrUpdateModifier() {
  yield takeEvery(actions.CREATE_OR_UPDATE_MODIFIER, createOrUpdateModifier);
}

export function* deleteModifier({ id }) {
  try {
    const isLegacy = yield select(reducers.isLegacyItems);
    if (!isLegacy) {
      yield call(API.deleteItem, { id, type: "modifier" });
      yield call(_deleteModifierSoldoutIds, id);
      const storeId = yield select(reducers.getStoreId);
      const updatedItems = yield _getItems(storeId);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    } else {
      const items = yield select(reducers.getItems);
      let _items = _.cloneDeep(items);

      _items.productModifiers = (_items.productModifiers || []).filter(
        (item) => item.productId !== id
      );

      let _modifiers = {};
      for (let mod of _items.modifiers || []) {
        _modifiers[mod.itemId] = mod;
      }
      delete _modifiers[id];
      _items.modifiers = Object.values(_modifiers);
      _items.updatedAt = new Date().toISOString();
      const updatedItems = yield call(API.updateItems, _items);
      yield put(actions.setUpdateItemsSuccess());
      yield put(actions.receiveItems(updatedItems));
    }
  } catch (error) {
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendErrorReport(error, "deleteModifier", { id });
  }
}

export function* watchDeleteModifier() {
  yield takeEvery(actions.DELETE_MODIFIER, deleteModifier);
}

export function* getOrderWithTransactions({ orderId }) {
  try {
    const order = yield call(API.getOrder, orderId);
    yield put(actions.receiveOrder(order));
    yield put(actions.setGetOrderSuccess());
  } catch (error) {
    yield put(actions.setGetOrderFailure(error));
    Analytics.sendErrorReport(error, "getOrderWithTransactions", { orderId });
  }
}

export function* watchGetOrderWithTransactions() {
  yield takeEvery(
    actions.GET_ORDER_WITH_TRANSACTIONS,
    getOrderWithTransactions
  );
}

export function* getOrder({ orderId, withStore }) {
  try {
    let a = new Date().getTime();
    const order = yield call(API.getOrder, orderId);
    yield put(actions.receiveOrder(order));
    if (withStore) {
      const customerId = order.customerId || order.receiptCustomerId;
      if (customerId) {
        let [items, storeCustomer] = yield all([
          _getItems(order.storeId),
          _getStoreCustomerWithTransaction(order.storeId, customerId),
        ]);
        yield put(actions.receiveItems(items));
        yield put(actions.receiveStore(storeCustomer));
      }
    }
    console.log("TIME(getOrder) TOTAL: " + (new Date().getTime() - a) / 1000);
    yield put(actions.setGetOrderSuccess());
  } catch (error) {
    yield put(actions.setGetOrderFailure(error));
    Analytics.sendErrorReport(error, "getOrder", { orderId, withStore });
  }
}

export function* watchGetOrder() {
  yield takeEvery(actions.GET_ORDER, getOrder);
}

export function* getOrdersByStore({ storeId, start, end, nextToken }) {
  try {
    // TODO: Change from locationId to StoreId
    // query(with GSI) is can be pagination, scan(without GSI) is cannot
    const locationId = yield select(reducers.getCurrentLocationId);
    let data = yield call(
      API.getOrdersByLocation,
      locationId,
      { between: [start, end] },
      nextToken,
      15
    );

    if (nextToken) {
      const orders = yield select(reducers.getOrdersById);
      data = {
        ...data,
        items: [...data.items, ...Object.values(orders)],
      };
    }
    yield put(actions.receiveOrders(data));
    yield put(actions.setGetOrdersSuccess);
  } catch (error) {
    yield put(actions.setGetOrderFailure(error));
    Analytics.sendErrorReport(error, "getOrdersByStore", {
      storeId,
      start,
      end,
      nextToken,
    });
  }
}

export function* watchGetOrdersByStore() {
  yield takeEvery(actions.GET_ORDERS_BY_STORE, getOrdersByStore);
}

export function* getOrdersByCustomer({ customerId, limit, nextToken }) {
  try {
    let byCustomer = yield call(
      API.getOrdersByCustomer,
      customerId,
      nextToken,
      limit
    );
    let byReceiptCustomer = {};
    let receiptCustomerData = [];
    do {
      byReceiptCustomer = yield call(
        API.getOrdersByReciptCustomer,
        customerId,
        limit,
        byReceiptCustomer.nextToken
      );
      receiptCustomerData = [
        ...receiptCustomerData,
        ...byReceiptCustomer.items,
      ];
    } while (byReceiptCustomer.nextToken);
    let data = {
      items: [...byCustomer.items, ...receiptCustomerData],
      nextToken: byCustomer.nextToken,
    };

    if (nextToken) {
      const orders = yield select(reducers.getOrdersById);
      data = {
        ...data,
        items: [...data.items, ...Object.values(orders)],
      };
    }
    yield put(actions.receiveOrders(data));
    yield put(actions.setGetOrdersSuccess());
  } catch (error) {
    yield put(actions.setGetOrderFailure(error));
    Analytics.sendErrorReport(error, "getOrdersByCustomer", {
      customerId,
      limit,
      nextToken,
    });
  }
}

export function* watchGetOrdersByCustomer() {
  yield takeEvery(actions.GET_ORDERS_BY_CUSTOMER, getOrdersByCustomer);
}

export function* getShippingOrders({ locationId, start, end, filters }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    let orders = [];
    let data = {};
    let shippingData = {};
    if (locationId) {
      do {
        data = yield call(
          API.getOrdersByLocation,
          locationId,
          { between: [start, end] },
          data.nextToken,
          100,
          { retailOption: { eq: "shipping" } }
        );
        orders = [...orders, ...(data.items || [])];
      } while (data.nextToken);
      do {
        shippingData = yield call(
          API.getShippingOrdersByShippingLocation,
          locationId,
          { between: [start, end] },
          shippingData.nextToken,
          100
        );
        orders = [...orders, ...(shippingData.items || [])];
      } while (shippingData.nextToken);
    } else {
      do {
        data = yield call(
          API.getOrdersByStore,
          storeId,
          { between: [start, end] },
          data.nextToken,
          100,
          { retailOption: { eq: "shipping" } }
        );
        orders = [...orders, ...(data.items || [])];
      } while (data.nextToken);
    }
    yield put(actions.receiveOrders({ items: orders }));
    yield put(actions.setGetOrdersSuccess);
  } catch (error) {
    yield put(actions.setGetOrderFailure(error));
    Analytics.sendErrorReport(error, "getShippingOrders", {
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetShippingOrders() {
  yield takeEvery(actions.GET_SHIPPING_ORDERS, getShippingOrders);
}

export function* getAllOrderHistories({
  storeId,
  locationId,
  start,
  end,
  filters,
}) {
  try {
    let histories = [];
    let data = {};
    do {
      // TODELETE: bymono
      if (storeId === "st_MWI3NmM2OThiNzFlZWI1YzA1") {
        data = yield call(
          API.getOrderHistoriesByLocation,
          "loc_Njk5ZTI1N2E4MmFlNGY5Mjhm",
          start,
          end,
          data.nextToken,
          500
        );
      } else {
        if (locationId) {
          data = yield call(
            API.getOrderHistoriesByLocation,
            locationId,
            start,
            end,
            data.nextToken,
            500
          );
        } else {
          data = yield call(
            API.getOrderHistoriesByStore,
            storeId,
            start,
            end,
            null,
            data.nextToken,
            500
          );
        }
      }
      histories = [
        ...histories,
        ...data.items
          .filter((item) => item?.order)
          .map((item) => ({
            ...item,
            order: {
              ...item.order,
              customer: item.order?.orderCustomer,
            },
          })),
      ];
    } while (data.nextToken);
    yield put(actions.receiveOrderHistories({ items: histories }));
    yield put(actions.setGetOrderHistoriesSuccess());
  } catch (error) {
    yield put(actions.setGetOrderHistoriesFailure(error));
    Analytics.sendErrorReport(error, "getAllOrderHistories", {
      storeId,
      start,
      end,
    });
  }
}

export function* watchGetAllOrderHistories() {
  yield takeEvery(actions.GET_ALL_ORDER_HISTORIES, getAllOrderHistories);
}

export function* getOrderHistories({ storeId, start, end, nextToken }) {
  try {
    let data = yield call(
      API.getOrderHistories,
      storeId,
      start,
      end,
      nextToken,
      1000
    );

    if (nextToken) {
      const hists = yield select(reducers.getOrderHistories);
      data = {
        ...data,
        items: [...data.items, ...hists.items],
      };
    }
    yield put(actions.receiveOrderHistories(data));
    yield put(actions.setGetOrderHistoriesSuccess());
  } catch (error) {
    yield put(actions.setGetOrderHistoriesFailure(error));
    Analytics.sendErrorReport(error, "getOrderHistories", {
      storeId,
      start,
      end,
      nextToken,
    });
  }
}

export function* watchGetOrderHistories() {
  yield takeEvery(actions.GET_ORDER_HISTORIES, getOrderHistories);
}

export function* _getLegacyInventoryHistories(filtersByKey) {
  const start = filtersByKey["createdAt"].value[0];
  const end = filtersByKey["createdAt"].value[1];
  const _search = filtersByKey["search"]?.value;
  const reason = filtersByKey["reason"]?.value;
  let locationFilter = filtersByKey["locationId"]?.value;
  if ((locationFilter || []).length === 1) {
    locationFilter = [locationFilter];
  }

  const limitEnd = Constants.STOCK_HISTORY_UPDATE_DATE;
  const _end = moment(limitEnd).isAfter(moment(end)) ? end : limitEnd;

  let data = {};
  let histories = [];

  const isMulti = yield select(reducers.isMultiLocation);
  const storeId = yield select(reducers.getStoreId);
  const locationId = yield select(reducers.getCurrentLocationId);
  const skusById = yield select(reducers.getSkusById);
  const prodsById = yield select(reducers.getProductsById);
  if (isMulti && Authority.level() === "location") {
    do {
      data = yield call(
        API.getInventoryHistoriesByLocation,
        locationId,
        { between: [start, _end] },
        data.nextToken,
        300
      );
      histories = [...histories, ...data.items];
    } while (data.nextToken);
  } else {
    do {
      data = yield call(
        API.getInventoryHistoriesByStore,
        storeId,
        { between: [start, _end] },
        data.nextToken,
        300
      );
      histories = [...histories, ...data.items];
    } while (data.nextToken);

    if ((locationFilter || []).length > 0) {
      histories = histories.filter((his) =>
        locationFilter.includes(his.locationId)
      );
    }
  }
  if (_search) {
    histories = histories.filter((his) => {
      let mixedIds = his.id.split("sku_");
      const skuId = "sku_" + mixedIds[1];
      const sku = skusById[skuId];
      const prodName = prodsById[sku?.parentId]?.name || "삭제된 제품";

      return (
        (prodName || "").includes(_search) ||
        (sku?.unit || "").includes(_search) ||
        (sku?.model || "").includes(_search)
      );
    });
  }
  if (reason) {
    histories = histories.filter((his) => reason.includes(his.reason));
  }

  return histories;
}

export function* getInventoryHistoriesByStore({
  page,
  size,
  historyType,
  filters,
  override,
}) {
  try {
    const storeId = yield select(reducers.getStoreId);

    const filtersByKey = filters.reduce((obj, filter) => {
      obj[filter.key] = filter;
      return obj;
    }, {});
    const start = filtersByKey["createdAt"].value[0];
    const isLegacy = isLegacyInventoryHistories(start, historyType);
    let legacyHistories = [];
    let totalItems = [];
    let totalCount = 0;
    if (isLegacy) {
      legacyHistories = yield call(_getLegacyInventoryHistories, filtersByKey);
    }

    let { items, count } = yield call(
      API.getInventoryHistories,
      storeId,
      page,
      size,
      historyType === "group" ? "group" : "single",
      filters
    );

    const employeesById = yield select(reducers.getEmployeesById);
    let employeeIds = new Set();
    for (let item of items) {
      if (item.employeeId) {
        employeeIds.add(item.employeeId);
      }
    }
    employeeIds = Array.from(employeeIds).filter(
      (id) => !(id in employeesById)
    );
    let employees = yield all(
      employeeIds.map((id) => call(API.getEmployee, id))
    );
    employees = employees.filter((emp) => emp);
    if (employees.length > 0) {
      yield put(actions.receiveEmployees(employees));
    }

    totalItems = [
      ...items,
      ...legacyHistories
        .slice(0, page * size - count)
        .map((his) => ({ ...his, isLegacy: true })),
    ];
    totalCount = count + legacyHistories.length;
    yield put(
      actions.receiveInventoryHistories(totalItems, totalCount, override)
    );
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryHistoriesByStore", {
      page,
      size,
      historyType,
      filters,
      override,
    });
  }
}

export function* watchGetInventoryHistoriesByStore() {
  yield takeEvery(
    actions.GET_INVENTORY_HISTORIES_BY_STORE,
    getInventoryHistoriesByStore
  );
}

export function* getInventoryHistoriesByGroupId({
  history,
  page,
  size,
  filters,
}) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const { items, count } = yield call(
      API.getInventoryHistories,
      storeId,
      page,
      size,
      "single",
      filters
    );
    let _history = {
      ...history,
      histories: items || [],
    };
    yield put(actions.receiveInventoryHistory(_history, count));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryHistoriesByGroupId", {
      history,
      page,
      size,
      filters,
    });
  }
}

export function* watchGetInventoryHistoriesByGroupId() {
  yield takeEvery(
    actions.GET_INVENTORY_HISTORIES_BY_GROUP_ID,
    getInventoryHistoriesByGroupId
  );
}

export function* getInventoryHistoriesByLocation({ locationId, start, end }) {
  try {
    let data = {};
    let histories = [];
    do {
      data = yield call(
        API.getInventoryHistoriesByLocation,
        locationId,
        { between: [start, end] },
        data.nextToken,
        300
      );
      histories = [...histories, ...data.items];
    } while (data.nextToken);
    yield put(actions.receiveInventoryHistories(histories));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryHistoriesByLocation", {
      locationId,
      start,
      end,
    });
  }
}

export function* watchGetInventoryHistoriesByLocation() {
  yield takeEvery(
    actions.GET_INVENTORY_HISTORIES_BY_LOCATION,
    getInventoryHistoriesByLocation
  );
}

export function* getInventoryTransferHistories({
  page,
  size,
  filters,
  override,
}) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const { items, count } = yield call(
      API.getInventoryTransferHistories,
      storeId,
      page,
      size,
      filters
    );
    const employeesById = yield select(reducers.getEmployeesById);
    let employeeIds = new Set();
    for (let item of items) {
      if (item.employeeId) {
        employeeIds.add(item.employeeId);
      }
    }
    employeeIds = Array.from(employeeIds).filter(
      (id) => !(id in employeesById)
    );
    let employees = yield all(
      employeeIds.map((id) => call(API.getEmployee, id))
    );
    employees = employees.filter((emp) => emp);
    if (employees.length > 0) {
      yield put(actions.receiveEmployees(employees));
    }
    yield put(
      actions.receiveInventoryTransferHistories(items, count, override)
    );
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryTransferHistories", {
      page,
      size,
      filters,
      override,
    });
  }
}

export function* watchGetInventoryTransferHistories() {
  yield takeEvery(
    actions.GET_INVENTORY_TRANSFER_HISTORIES,
    getInventoryTransferHistories
  );
}

export function* getSkuInventoryHistories({
  startDate,
  endDate,
  skuId,
  filters,
}) {
  try {
    const storeId = yield select(reducers.getStoreId);
    let { items, prevQuantity } = yield call(
      API.getSkuInventoryHistories,
      startDate,
      endDate,
      skuId,
      storeId,
      filters
    );

    let list = items;
    let prev = prevQuantity;
    yield put(actions.receiveSkuInventoryHistories(list, prev));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getSkuInventoryHistories", {
      startDate,
      endDate,
      skuId,
      filters,
    });
  }
}

export function* watchGetSkuInventoryHistories() {
  yield takeEvery(
    actions.GET_SKU_INVENTORY_HISTORIES,
    getSkuInventoryHistories
  );
}

export function* getInventoryHistorySummary({ startDate, endDate, filters }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    let list = yield call(
      API.getInventoryHistorySummary,
      storeId,
      startDate,
      endDate,
      filters
    );
    yield put(actions.receiveInventoryHistorySummary(list));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventoryHistorySummary", {
      startDate,
      endDate,
      filters,
    });
  }
}

export function* watchGetInventoryHistorySummary() {
  yield takeEvery(
    actions.GET_INVENTORY_HISTORY_SUMMARY,
    getInventoryHistorySummary
  );
}

export function* completeInventoryTransferHistory({ history }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const employee = yield select(reducers.getEmployee);
    let _history = _.cloneDeep(history);

    _history = {
      ..._history,
      storeId,
      employeeId: _history.employeeId || employee.id,
    };
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    API.applyInventoryTransferHistory(_history, origin.name, origin.id);
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
  }
}

export function* watchCompleteInventoryTransferHistory() {
  yield takeEvery(
    actions.COMPLETE_INVENTORY_TRANSFER_HISTORY,
    completeInventoryTransferHistory
  );
}

export function* deleteInventoryTransferHistory({ historyId }) {
  try {
    const inventoryTransferHistoriesById = yield select(
      reducers.getInventoryTransferHistoriesById
    );
    const transferHistory = inventoryTransferHistoriesById[historyId];
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    API.cancelInventoryTransferHistory(transferHistory, origin.name, origin.id);
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
  }
}

export function* watchDeleteInventoryTransferHistory() {
  yield takeEvery(
    actions.DELETE_INVENTORY_TRANSFER_HISTORY,
    deleteInventoryTransferHistory
  );
}

export function* getCountHistories({ page, size, filters, override }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const { items, count } = yield call(
      API.getInventoryCountHistories,
      storeId,
      page,
      size,
      filters
    );
    const employeesById = yield select(reducers.getEmployeesById);
    let employeeIds = new Set();
    for (let item of items) {
      if (item.employeeId) {
        employeeIds.add(item.employeeId);
      }
    }
    employeeIds = Array.from(employeeIds).filter(
      (id) => !(id in employeesById)
    );
    let employees = yield all(
      employeeIds.map((id) => call(API.getEmployee, id))
    );
    employees = employees.filter((emp) => emp);
    if (employees.length > 0) {
      yield put(actions.receiveEmployees(employees));
    }
    yield put(actions.receiveCountHistories(items, count, override));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "getCountHistories", {
      page,
      size,
      filters,
    });
  }
}

export function* watchGetCountHistories() {
  yield takeEvery(actions.GET_INVENTORY_COUNT_HISTORIES, getCountHistories);
}

export function* getPointTransactions({ storeId, start, end, nextToken }) {
  try {
    let data = yield call(API.getPointTransactions, nextToken, 1000, {
      storeId: { eq: storeId },
      createdAt: { between: [start, end] },
    });
    if (nextToken) {
      const trans = yield select(reducers.getPointTransactions);
      data = {
        ...data,
        items: [...data.items, ...trans.items],
      };
    }
    yield put(actions.receivePointTransactions(data));
    yield put(actions.setGetPointTransactionsSuccess());
  } catch (error) {
    yield put(actions.setGetPointTransactionsFailure(error));
    Analytics.sendErrorReport(error, "getPointTransactions", {
      storeId,
      start,
      end,
      nextToken,
    });
  }
}

export function* watchGetPointTransactions() {
  yield takeEvery(actions.GET_POINT_TRANSACTIONS, getPointTransactions);
}

function* _getCorporateCustomerByStore(storeId, page, size, filters, search) {
  const { items, count } = yield call(
    API.getCustomersWithCount,
    storeId,
    page,
    size,
    null,
    filters,
    search
  );
  let customers = [];
  for (let customer of items) {
    customers = [
      ...customers,
      {
        ...customer,
        ...customer.stats,
      },
    ];
  }
  for (let idx in customers) {
    let [orders, points, credits] = yield all([
      _getOrdersByStore(storeId, customers[idx].id),
      _getPointTransactionsByStore(storeId, customers[idx].id),
      _getCreditTransactionsByStore(storeId, customers[idx].id),
    ]);
    customers[idx] = {
      ...customers[idx],
      credits: { items: credits },
      points: { items: points },
      orders: { items: orders },
    };
  }
  return {
    list: customers,
    count: count,
  };
}

export function* getProviderCustomer({
  storeId,
  locationId,
  filter,
  withOrders,
}) {
  try {
    let _list = [];
    const customers = yield call(API.getProviderCustomers, storeId, null, [
      filter,
    ]);
    _list = customers;
    if (_list.length > 0) {
      const registerdCustomer = yield call(
        API.getCustomers,
        _list[0].customerId,
        storeId
      );
      if ((registerdCustomer || []).length > 0) {
        _list[0] = {
          ..._list[0],
          registeredCustomer: registerdCustomer[0],
        };
      }
    }
    if (_list.length > 0 && withOrders) {
      let orders = [];
      let data = {};
      do {
        data = yield call(
          API.getOrdersByStoreCustomer,
          _list[0].id,
          {
            providerCustomerId: {
              eq: _list[0].providerCustomerId,
            },
            customerProvider: {
              eq: "cafe24",
            },
            origin: { ne: "cafe24" },
          },
          50,
          data.nextToken
        );
        orders = [...orders, ...data.items];
      } while (data.nextToken);
      orders = orders.filter(
        (order) => !locationId || order.orderLocationId === locationId
      );
      _list[0] = {
        ..._list[0],
        orders: orders,
      };
    }
    yield put(actions.receiveRetrievedCustomer(_list[0]));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetCustomersFailure(error));
    Analytics.sendErrorReport(error, "getCustomers", { storeId });
  }
}

export function* watchGetProviderCustomer() {
  yield takeEvery(actions.GET_PROVIDER_CUSTOMER, getProviderCustomer);
}

export function* getCustomers({
  page,
  size,
  sort,
  filters,
  search,
  filterLocationId,
  override,
}) {
  try {
    const storeId = yield select(reducers.getStoreId);
    let { items, count } = yield call(
      API.getCustomersWithCount,
      storeId,
      page,
      size,
      sort,
      filters,
      search,
      filterLocationId
    );
    items = items.filter((i) => i.id);
    let list = items;
    yield put(actions.receiveCustomers(list, count, override));

    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetCustomersFailure(error));
    Analytics.sendErrorReport(error, "getCustomers", {
      page,
      size,
      filters,
      search,
      override,
    });
  }
}

export function* watchGetCustomers() {
  yield takeEvery(actions.GET_CUSTOMERS, getCustomers);
}

export function* getCustomersByTag({
  storeId,
  page,
  size,
  sort,
  filters,
  search,
  override,
}) {
  try {
    const { items, count } = yield call(
      API.getCustomersWithCount,
      storeId,
      page,
      size,
      sort,
      filters,
      search
    );
    yield put(actions.receiveCustomersByTag(items, count, override));
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "getCustomersByTag", { storeId });
  }
}

export function* watchGetCustomersByTag() {
  yield takeEvery(actions.GET_CUSTOMERS_BY_TAG, getCustomersByTag);
}

export function* getCorporateCustomers({
  storeId,
  page,
  size,
  filters,
  search,
}) {
  try {
    const { list, count } = yield _getCorporateCustomerByStore(
      storeId,
      page,
      size,
      filters,
      search
    );
    yield put(actions.receiveCustomers(list, count));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetCustomersFailure(error));
    Analytics.sendErrorReport(error, "getCorporateCustomers", { storeId });
  }
}

export function* watchGetCorporateCustomers() {
  yield takeEvery(actions.GET_CORPORATE_CUSTOMERS, getCorporateCustomers);
}

function* _getCustomer(id, locationId, withStat) {
  let customer = yield call(API.getCustomer, id);
  let customerWithPoint = null;
  let stats = {};
  let pointData = {};
  let pointTransactions = [];
  let orderData = {};
  let orders = [];
  let creditData = {};
  let creditTransactions = [];
  if (!customer.provider) {
    customerWithPoint = yield call(
      API.getLocationCustomer,
      id,
      customer.locationId
    );
    const customerWithPointByLocationId = (
      customerWithPoint.locations || []
    ).reduce((obj, loc) => {
      obj[loc.id] = loc;
      return obj;
    }, {});
    customer.locations = customer.locations.map((loc) => ({
      ...loc,
      point: customerWithPointByLocationId[loc.id]?.point || {},
      credit: customerWithPointByLocationId[loc.id]?.credit || {},
    }));
  } else {
    customerWithPoint = {
      point: customer.point,
      credit: null,
    };
  }
  if (withStat) {
    do {
      orderData = yield call(
        API.getOrdersByStoreCustomer,
        id,
        null,
        50,
        orderData.nextToken
      );
      orders = [...orders, ...orderData.items];
    } while (orderData.nextToken);

    do {
      pointData = yield call(
        API.getPointTransactionByStoreCustomer,
        id,
        locationId
          ? { locationId: { eq: locationId }, point: { gt: 0 } }
          : null,
        50,
        pointData.nextToken
      );
      pointTransactions = [...pointTransactions, ...pointData.items];
    } while (pointData.nextToken);

    do {
      creditData = yield call(
        API.getCreditTransactionsByStoreCustomer,
        id,
        creditData.nextToken,
        {
          ...(locationId ? { locationId: { eq: locationId } } : {}),
        },
        500
      );
      creditTransactions = [...creditTransactions, ...creditData.items];
    } while (creditData.nextToken);

    let customerLocation = (customer.locations || []).filter(
      (loc) => loc.id === locationId
    )[0];
    stats = locationId ? customerLocation.stats : customer.stats;
  }
  const _customer = {
    ...customer,
    ...stats,
    credits: _.reverse(_.sortBy(creditTransactions, "createdAt")).filter(
      (p) => !locationId || p.locationId === locationId
    ),
    points: _.reverse(_.sortBy(pointTransactions, "createdAt")).filter(
      (p) => !locationId || p.locationId === locationId
    ),

    orders: _.reverse(_.sortBy(orders, "createdAt")).filter(
      (o) => !locationId || o.orderLocationId === locationId
    ),
    point: customerWithPoint?.point,
    credit: customerWithPoint?.credit,
  };
  return _customer;
}

export function* getCustomer({ id, locationId }) {
  try {
    const customer = yield _getCustomer(id, locationId);
    yield put(actions.receiveCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "getCustomer", { id });
  }
}

export function* watchGetCustomer() {
  yield takeEvery(actions.GET_CUSTOMER, getCustomer);
}

export function* retrieveCustomer({ id, withStat }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    let customer = null;
    customer = yield _getCustomer(id, organization.locationId, withStat);
    yield put(actions.receiveRetrievedCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "retrieveCustomer", { id });
  }
}

export function* watchRetrieveCustomer() {
  yield takeEvery(actions.RETRIEVE_CUSTOMER, retrieveCustomer);
}

export function* retrieveCustomerWithPoint({ start, end }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    let customer = yield select(reducers.getRetrievedCustomer);
    const storeId = yield select(reducers.getStoreId);
    const isOutsourced = yield select(reducers.isOutsourcedCustomer);
    let pointData = {};
    let pointTransactions = [];
    if (isOutsourced) {
      pointData = yield call(
        API.getProviderCustomerPointTransactions,
        storeId,
        customer.providerCustomerId,
        start,
        end
      );
      pointTransactions = [...pointTransactions, ...pointData.items];
    } else {
      do {
        pointData = yield call(
          API.getPointTransactionByStoreCustomer,
          customer.id,
          organization.locationId
            ? {
                locationId: { eq: organization.locationId },
                point: { gt: 0 },
              }
            : null,
          50,
          pointData.nextToken,
          start,
          end
        );
        pointTransactions = [...pointTransactions, ...pointData.items];
      } while (pointData.nextToken);
    }
    customer = {
      ...customer,
      points: _.reverse(_.sortBy(pointTransactions, "createdAt")).filter(
        (p) =>
          !organization.locationId || p.locationId === organization.locationId
      ),
    };
    yield put(actions.receiveRetrievedCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "retrieveCustomerWithPoint");
  }
}

export function* watchRetrieveCustomerWithPoint() {
  yield takeEvery(
    actions.RETRIEVE_CUSTOMER_WITH_POINT,
    retrieveCustomerWithPoint
  );
}

export function* retrieveCustomerWithOrder({ start, end }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    let customer = yield select(reducers.getRetrievedCustomer);
    let orderData = {};
    let orders = [];
    do {
      orderData = yield call(
        API.getOrdersByStoreCustomer,
        customer.id,
        null,
        50,
        orderData.nextToken,
        start,
        end
      );
      orders = [...orders, ...orderData.items];
    } while (orderData.nextToken);
    customer = {
      ...customer,
      orders: _.reverse(_.sortBy(orders, "createdAt")).filter(
        (o) =>
          !organization.locationId ||
          o.orderLocationId === organization.locationId
      ),
    };
    yield put(actions.receiveRetrievedCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "retrieveCustomerWithOrder");
  }
}

export function* watchRetrieveCustomerWithOrder() {
  yield takeEvery(
    actions.RETRIEVE_CUSTOMER_WITH_ORDER,
    retrieveCustomerWithOrder
  );
}

export function* retrieveCustomerWithCredit({ start, end }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    let customer = yield select(reducers.getRetrievedCustomer);
    let creditData = {};
    let creditTransactions = [];
    if (customer.customerId) {
      do {
        creditData = yield call(
          API.getCreditTransactionsByStoreCustomer,
          customer.id,
          creditData.nextToken,
          {
            ...(organization.locationId
              ? { locationId: { eq: organization.locationId } }
              : {}),
          },
          500,
          start,
          end
        );
        creditTransactions = [...creditTransactions, ...creditData.items];
      } while (creditData.nextToken);
    }
    customer = {
      ...customer,
      credits: _.reverse(_.sortBy(creditTransactions, "createdAt")).filter(
        (p) =>
          !organization.locationId || p.locationId === organization.locationId
      ),
    };
    yield put(actions.receiveRetrievedCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "retrieveCustomerWithCredit");
  }
}

export function* watchRetrieveCustomerWithCredit() {
  yield takeEvery(
    actions.RETRIEVE_CUSTOMER_WITH_CREDIT,
    retrieveCustomerWithCredit
  );
}

export function* retrieveCustomerWithCoupon({}) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    let customer = yield select(reducers.getRetrievedCustomer);
    const isOutsourced = yield select(reducers.isOutsourcedCustomer);
    const providerCustomerId = customer.providerCustomerId;
    let externalTransactions = [];
    let locationCouponTransactions = [];
    let locationCouponData = {};
    if (isOutsourced) {
      const globalCustomerId = customer.customerId;
      let storeCustomer = null;
      externalTransactions = yield call(
        API.getProviderCouponTransactions,
        organization.storeId,
        providerCustomerId
      );
      externalTransactions = externalTransactions.filter(
        (tran) => !dateFns.isBefore(new Date(tran.expiredDate), new Date())
      );
      if (globalCustomerId) {
        // NOTE: storeCustomer의 바코드쿠폰 transaction 조회
        const data = yield call(
          API.getLocationCustomersByOriginId,
          globalCustomerId,
          { eq: organization.storeId },
          null,
          null,
          {
            _delta: {
              ne: "DELETE",
            },
            status: {
              eq: "registered",
            },
          }
        );
        if (data.items.length > 0) {
          storeCustomer = data.items[0];
        }
        if (!_.isEmpty(storeCustomer)) {
          do {
            locationCouponData = yield call(
              API.getCouponTransactionsByStoreCustomer,
              storeCustomer.id,
              "barcode",
              {
                state: {
                  ne: "used",
                },
              },
              100,
              locationCouponData.nextToken
            );
            locationCouponTransactions = [
              ...locationCouponTransactions,
              ...locationCouponData.items,
            ];
          } while (locationCouponData.nextToken);
          locationCouponTransactions = locationCouponTransactions.filter(
            (tran) =>
              ((tran.publishableLocationIds || []).length === 0 ||
                tran.publishableLocationIds.includes(
                  organization.locationId
                )) &&
              !dateFns.isBefore(new Date(tran.expiredDate), new Date())
          );
        }
      }
    } else {
      do {
        locationCouponData = yield call(
          API.getCouponTransactionsByStoreCustomer,
          customer.id,
          "barcode",
          {
            state: {
              ne: "used",
            },
          },
          100,
          locationCouponData.nextToken
        );
        locationCouponTransactions = [
          ...locationCouponTransactions,
          ...locationCouponData.items,
        ];
      } while (locationCouponData.nextToken);
      locationCouponTransactions = locationCouponTransactions.filter(
        (tran) =>
          ((tran.publishableLocationIds || []).length === 0 ||
            tran.publishableLocationIds.includes(organization.locationId)) &&
          !dateFns.isBefore(new Date(tran.expiredDate), new Date())
      );
    }
    customer = {
      ...customer,
      couponTransactions: [
        ...(locationCouponTransactions || []),
        ...(externalTransactions || []),
      ],
    };
    yield put(actions.receiveRetrievedCustomer(customer));
    yield put(actions.setGetCustomerSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerFailure(error));
    Analytics.sendErrorReport(error, "retrieveCustomerWithCoupon");
  }
}

export function* watchRetrieveCustomerWithCoupon() {
  yield takeEvery(
    actions.RETRIEVE_CUSTOMER_WITH_COUPON,
    retrieveCustomerWithCoupon
  );
}

export function* updateOrder({ order }) {
  try {
    let _order = {
      ..._.cloneDeep(order),
      orderCustomerId: order.customer?.id || null,
    };
    if (_order.hasOwnProperty("_note")) {
      if (_order.note !== _order._note) {
        _order.note = _order._note;
      }
      delete _order._note;
      if (!_order.note) {
        _order.note = null;
      }
    }
    for (let i in _order.charges || []) {
      for (let j in _order.charges[i].refunds || []) {
        delete _order.charges[i].refunds[j].returnItems;
      }
    }
    delete _order.customer;
    const updatedOrder = yield call(API.updateOrder, _order);
    yield put(actions.receiveOrder(updatedOrder));
    yield put(actions.setUpdateOrderSuccess());
  } catch (error) {
    yield put(actions.setUpdateOrderFailure(error));
    Analytics.sendErrorReport(error, "updateOrder", { order });
  }
}

export function* watchUpdateOrder() {
  yield takeEvery(actions.UPDATE_ORDER, updateOrder);
}

export function* updateOrders({ orders }) {
  try {
    let _orders = (orders || []).map((o) => ({
      ...o,
      orderCustomerId: o.customer?.id || null,
    }));

    let updatedOrders = yield all(
      _orders.map((order) => call(API.updateOrder, order))
    );
    let existOrdersById = yield select(reducers.getOrdersById);
    let itemsById = (updatedOrders || []).reduce((obj, o) => {
      obj[o.id] = o;
      return obj;
    }, existOrdersById);

    yield put(actions.receiveOrders({ items: Object.values(itemsById) }));
    yield put(actions.setUpdateOrderSuccess());
  } catch (error) {
    yield put(actions.setUpdateOrderFailure(error));
    Analytics.sendErrorReport(error, "updateOrders");
  }
}

export function* watchUpdateOrders() {
  yield takeEvery(actions.UPDATE_ORDERS, updateOrders);
}

export function* createOrUpdateCustomerGroup({ group }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    if (!group.id) {
      yield call(API.createCustomerGroup, {
        ...group,
        id: generateId("cg"),
        storeGroupsId: storeId,
      });
    } else {
      yield call(API.updateCustomerGroup, {
        ...group,
        updatedAt: new Date().toISOString(),
      });
    }
    const groups = yield call(
      _getCustomerGroup,
      storeId,
      group.locationGroupsId
    );
    yield put(actions.receiveCustomerGroups(groups));
    yield put(actions.setGetCustomerGroupsSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setGetCustomerGroupsFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateCustomerGroup", { group });
  }
}

export function* watchCreateOrUpdateCustomerGroup() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_CUSTOMER_GROUP,
    createOrUpdateCustomerGroup
  );
}

export function* deleteCustomerGroup({ groupId }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    yield call(API.deleteCustomerGroup, groupId);
    const storeId = yield select(reducers.getStoreId);
    const groups = yield call(
      _getCustomerGroup,
      storeId,
      organization.locationId
    );
    yield put(actions.receiveCustomerGroups(groups));
    yield put(actions.setGetCustomerGroupsSuccess());
  } catch (error) {
    yield put(actions.setGetCustomerGroupsFailure(error));
    Analytics.sendErrorReport(error, "deleteCustomerGroup", { groupId });
  }
}

export function* watchDeleteCustomerGroup() {
  yield takeEvery(actions.DELETE_CUSTOMER_GROUP, deleteCustomerGroup);
}

export function* getCustomerTags({ storeId, locationId, override }) {
  try {
    const isOutsourced = yield select(reducers.isOutsourcedCustomer);
    const tags = yield call(
      _getCustomerTags,
      storeId,
      locationId,
      isOutsourced
    );
    yield put(actions.receiveCustomerTags(tags, override));
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "getCustomerTags", {
      storeId,
      locationId,
    });
  }
}

export function* watchGetCustomerTags() {
  yield takeEvery(actions.GET_CUSTOMER_TAGS, getCustomerTags);
}

export function* getCustomerTagsWithCount({ storeId, locationId }) {
  try {
    const tags = yield call(API.getCustomerTagsWithCount, storeId, locationId);
    yield put(actions.receiveCustomerTags(tags, true));
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "getCustomerTagsWithCount", {
      storeId,
      locationId,
    });
  }
}

export function* watchGetCustomerTagsCount() {
  yield takeEvery(
    actions.GET_CUSTOMER_TAGS_WITH_COUNT,
    getCustomerTagsWithCount
  );
}

export function* createOrUpdateCustomerTag({ tag }) {
  try {
    const current = new Date().toISOString();
    if (!tag.id) {
      const organization = yield select(reducers.getCurrentOrganization);
      const isMultiLocation = yield select(reducers.isMultiLocation);
      const isOutsourced = yield select(reducers.isOutsourcedCustomer);
      let _tag = {
        ...tag,
        id: generateId("ctg"),
        type: tag.type || "custom",
        storeId: yield select(reducers.getStoreId),
        updatedAt: current,
        createdAt: current,
      };
      if (!isOutsourced && isMultiLocation && organization.locationId) {
        _tag = {
          ..._tag,
          locationId: yield select(reducers.getCurrentLocationId),
        };
      }
      let createdTag = yield call(API.createCustomerTag, _tag);
      if (
        createdTag.type !== "auto" &&
        (createdTag.conditions || []).length > 0
      ) {
        yield delay(200);
        yield call(API.applyCustomerTag, createdTag);
      }
      yield put(actions.receiveCustomerTag(createdTag));
    } else {
      let updatedTag = yield call(API.updateCustomerTag, {
        ...tag,
        updatedAt: current,
      });
      if (updatedTag.type !== "auto") {
        updatedTag = yield call(API.applyCustomerTag, updatedTag);
      }
      yield put(actions.receiveCustomerTag(updatedTag));
    }
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateCustomerTag", { tag });
  }
}

export function* watchCreateOrUpdateCustomerTag() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_CUSTOMER_TAG,
    createOrUpdateCustomerTag
  );
}

export function* updateCustomerTags({ tags }) {
  try {
    const updatedTags = yield all(
      tags.map((tag) =>
        call(API.updateCustomerTag, {
          ...tag,
          updatedAt: new Date().toISOString(),
        })
      )
    );
    yield put(actions.receiveCustomerTags(updatedTags));
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "updateCustomerTags", { tags });
  }
}

export function* watchUpdateCustomerTags() {
  yield takeEvery(actions.UPDATE_CUSTOMER_TAGS, updateCustomerTags);
}

export function* deleteCustomerTag({ tagId }) {
  try {
    yield call(API.deleteCustomerTag, tagId);
    yield put(actions.receiveDeletedCustomerTag(tagId));
    yield put(actions.setCustomerTagStatusSuccess());
  } catch (error) {
    yield put(actions.setCustomerTagStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteCustomerTag", { tagId });
  }
}

export function* watchDeleteCustomerTag() {
  yield takeEvery(actions.DELETE_CUSTOMER_TAG, deleteCustomerTag);
}

export function* createOrUpdateStoreAttributes({ attributes }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const store = yield call(API.getStore, storeId);
    const updatedStore = yield call(_updateStore, {
      ...store,
      customer: { ...store.customer, attributes: attributes },
    });
    // yield put(actions.receiveStore(updatedStore));
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateStoreAttributes", {
      attributes,
    });
  }
}

export function* watchCreateOrUpdateStoreAtrributes() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_STORE_ATTRIBUTES,
    createOrUpdateStoreAttributes
  );
}

function* _buildBaseCustomer(customer, meta = null) {
  /**
   * meta = {
   *    ident: string,
   *    method(=identType): string,
   * }
   */
  const ident = meta?.ident ? meta.ident : customer.phone;
  const customerId = hashMd5(ident);
  const isMulti = yield select(reducers.isMultiLocation);
  const isHeadOffice = isMulti && Authority.level() === "store";
  const base = {
    customerId: customerId,
    ident: ident,
    identType: meta?.method || "phone",
    name: meta?.method === "card" ? "미등록 고객" : customer.name,
    phone: customer.phone,
    type: customer.type,
    ...(isHeadOffice
      ? {}
      : { locationId: yield select(reducers.getCurrentLocationId) }),
    storeId: yield select(reducers.getStoreId),
    email: customer.email,
    ageGroup: customer.ageGroup,
    birth: customer.birth,
    gender: customer.gender,
  };
  return base;
}

function* _buildBaseOutsourcedCustomer(customer, storeProvider) {
  const ident = `${storeProvider.key}|${storeProvider.ident}|${customer.providerCustomerId}`;
  const customerId = hashMd5(ident);
  const isMulti = yield select(reducers.isMultiLocation);
  const isHeadOffice = isMulti && Authority.level() === "store";
  const base = {
    customerId: customerId,
    ident: ident,
    identType: "user",
    name: customer.name || "",
    phone: customer.phone,
    type: "individual",
    ...(isHeadOffice
      ? {}
      : { locationId: yield select(reducers.getCurrentLocationId) }),
    storeId: yield select(reducers.getStoreId),
    email: customer.email,
    birth: customer.birth,
    gender: customer.gender,
    tier: customer.tier,
    provider: storeProvider.key,
    providerCustomerId: customer.providerCustomerId,
  };
  return base;
}

export function* createCustomer({ customer }) {
  try {
    const _customer = _.cloneDeep(customer);
    let createdCustomer = yield call(API.createCustomer, {
      ...(yield _buildBaseCustomer(_customer)),
      status: "registered",
      id: generateId("cst"),
      registeredAt: new Date().toISOString(),
      note: _customer.note,
    });
    yield put(actions.receiveCustomer(createdCustomer));
    yield put(actions.setCreateCustomerSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setCreateCustomerFailure(error));
    Analytics.sendErrorReport(error, "createCustomer", { customer });
  }
}

export function* watchCreateCustomer() {
  yield takeEvery(actions.CREATE_CUSTOMER, createCustomer);
}

function* _getCustomerGroup(storeId, locationId) {
  const count = yield call(API.getCustomerCount, storeId, locationId);
  const size = 5000;
  let pages = _.range(Math.ceil(count / size));
  let data = [];
  const chunkSize = 8;
  for (let i = 0; i < pages.length; i += chunkSize) {
    const chunk = pages.slice(i, i + chunkSize);
    const _data = yield all(
      chunk.map((i) =>
        call(API.getCustomerGroups, storeId, locationId, i + 1, size)
      )
    );
    data = [...data, ..._data];
  }
  let groups = {};
  let list = [];
  for (let d of data) {
    console.log(d);
    for (let g of d.items) {
      if (g.key in groups) {
        groups[g.key].count += g.count;
        groups[g.key].list = [...groups[g.key].list, ...g.list];
      } else {
        groups[g.key] = g;
      }
    }
    list = [...list, ...d.list];
  }
  return {
    items: Object.values(groups),
    list,
  };
}

export function* getCustomerGroups({ storeId, locationId }) {
  try {
    const groups = yield call(_getCustomerGroup, storeId, locationId);
    yield put(actions.receiveCustomerGroups(groups));
    yield put(actions.setGetCustomerGroupsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetCustomerGroupsFailure(error));
    Analytics.sendErrorReport(error, "getCustomerGroups", { storeId });
  }
}

export function* watchGetCustomerGorups() {
  yield takeEvery(actions.GET_CUSTOMER_GROUPS, getCustomerGroups);
}

export function* getCustomerGroupsWithCount({ storeId, locationId }) {
  try {
    const countByStatus = yield call(
      API.getCustomerCountsByStatus,
      storeId,
      locationId
    );
    yield put(
      actions.receiveCustomerGroups({
        items: countByStatus,
        list: [],
      })
    );
    yield put(actions.setGetCustomerGroupsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetCustomerGroupsFailure(error));
    Analytics.sendErrorReport(error, "getCustomerGroupsWithCount", { storeId });
  }
}

export function* watchGetCustomerGroupsWithCount() {
  yield takeEvery(
    actions.GET_CUSTOMER_GROUPS_WITH_COUNT,
    getCustomerGroupsWithCount
  );
}

export function* deleteCustomer({ customer }) {
  try {
    let tags = [];
    if ((customer.tags || []).length > 0) {
      const tagIds = customer.tags.map((tag) => tag.tagId);
      tags = yield all(tagIds.map((tagId) => call(API.getCustomerTag, tagId)));
    } else {
      let data = {};
      data = yield call(
        API.getCustomerTagsByStore,
        customer.storeId,
        data.nextToken,
        500
      );
      tags = [...tags, ...data.items];
    }
    yield all(
      tags.map((tag) => {
        let _tag = {};
        if (tag.customerIds && tag.customerIds.includes(customer.id)) {
          let _customerIds = tag.customerIds.filter((id) => id !== customer.id);
          _tag = {
            ...tag,
            customerIds: _customerIds,
            updatedAt: new Date().toISOString(),
          };
          return call(API.updateCustomerTag, _tag);
        }
      })
    );

    yield call(API.deleteCustomer, customer.id);
    yield put(actions.receiveDeletedCustomer(customer.id));
    yield put(actions.setDeleteCustomerSuccess());
  } catch (error) {
    yield put(actions.setDeleteCustomerFailure(error));
    Analytics.sendErrorReport(error, "deleteCustomer", { customer });
  }
}

export function* watchDeleteCustomer() {
  yield takeEvery(actions.DELETE_CUSTOMER, deleteCustomer);
}

export function* updateCustomer({ customer }) {
  try {
    let _customer = _.cloneDeep(customer);
    const organization = yield select(reducers.getCurrentOrganization);
    // TODO: if changed origin, just change customerId of pointtransaction
    let updatedCustomer = yield call(API.updateCustomer, {
      ..._customer,
      ...(customer.identType !== "user"
        ? yield _buildBaseCustomer(_customer)
        : {}),
    });
    let _updatedCustomerWithStats = yield _getCustomer(
      customer.id,
      organization.locationId,
      true,
      updatedCustomer
    );
    let retrieved = yield select(reducers.getRetrievedCustomer);
    if (!_.isEmpty(retrieved)) {
      yield put(actions.receiveRetrievedCustomer(_updatedCustomerWithStats));
    }
    yield put(actions.receiveCustomer(_updatedCustomerWithStats));
    yield put(actions.setUpdateCustomerSuccess());
  } catch (error) {
    yield put(actions.setUpdateCustomerFailure(error));
    Analytics.sendErrorReport(error, "updateCustomer", { customer });
  }
}

export function* watchUpdateCustomer() {
  yield takeEvery(actions.UPDATE_CUSTOMER, updateCustomer);
}

function* _getMarketCustomer(id) {
  let _customer = { stores: {} };
  let _stats = {};
  let _payments = {};
  let _subscriptions = {};
  let _stores = {};
  const customers = yield call(
    API.getCustomers,
    id,
    null,
    null,
    "registered",
    true
  );
  for (let c of customers) {
    _customer = { ...c, id: c.customerId, stores: {} };
    (c.payments || []).forEach((payment) => (_payments[payment.id] = payment));
    (c.subscriptions || []).forEach(
      (subscription) =>
        (_subscriptions[subscription.id] = {
          ...subscription,
          locationId: c.locationId,
          storeId: c.storeId,
        })
    );
    if (c.storeId !== "unknown" && c.storeId) {
      _stats[c.storeId] = {
        use: c.point?.used || 0,
        save: c.point?.saved || 0,
        lastVisitedAt: c.stats?.lastVisitedAt,
      };
      _stores[c.storeId] = {
        ...c,
        store: {
          ...c.store,
          location: c.location,
          locations: [
            ...(_stores[c.storeId]?.store?.locations || []),
            c.location,
          ],
        },
      };
    }
  }
  _customer.stores.items = Object.values(_stores);
  _customer.payments = Object.values(_payments);
  _customer.subscriptions = Object.values(_subscriptions);
  return { customer: _customer, stats: _stats };
}

export function* getUser({ id }) {
  try {
    // TODO: test when case of not signed in
    console.log("get user id: ", id);
    let b = new Date().getTime();
    try {
      const cognito = yield Auth.currentAuthenticatedUser();
      if (cognito) {
        let [_data, cart, ordersByCustomerId] = yield all([
          _getMarketCustomer(id),
          API.getCart(id),
          API.getOrdersByCustomer(id, null, 10),
        ]);
        let ordersByReceiptCustomer = {};
        let data = [];
        do {
          ordersByReceiptCustomer = yield call(
            API.getOrdersByReciptCustomer,
            id,
            10,
            ordersByReceiptCustomer.nextToken
          );
          data = [...data, ...ordersByReceiptCustomer.items];
        } while (ordersByReceiptCustomer.nextToken);
        let orders = {
          items: [...ordersByCustomerId.items, ...data],
          nextToken: ordersByCustomerId.nextToken,
        };
        yield put(actions.receivePointStats(_data.stats));
        yield put(actions.receiveOrders(orders));
        yield put(actions.receiveUser(_data.customer));
        // NOTE: duplicate with getCart
        if (cart) {
          if (cart.storeId) {
            cart.store = yield call(_getStoreWithLocations, cart.storeId);
            cart.store.location = cart.store.locations.items.filter(
              (l) => l.id === cart.orderLocationId
            )[0];
          }
          yield put(actions.receiveCart(cart));
        }
      } else {
        throw new Error("no authenticated user");
      }
    } catch (err) {
      let { customer, stats } = yield call(_getMarketCustomer, id);
      yield put(actions.receivePointStats(stats));
      yield put(actions.receiveUser(customer));
    }
    console.log("TIME TOTAL: " + (new Date().getTime() - b) / 1000);
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "getUser", { id });
  }
}

export function* watchGetUser() {
  yield takeEvery(actions.GET_USER, getUser);
}

export function* updateUser({ user, incentiveCustomerId }) {
  try {
    yield call(
      API.updateCustomer,
      {
        ...user,
        customerId: user.id,
      },
      true
    );
    if (incentiveCustomerId) {
      for (let customer of user.stores.items) {
        if (customer.id === incentiveCustomerId) {
          yield call(API.createPointTransaction, {
            id: generateId("pt"),
            locationId: customer.locationId,
            storeId: customer.storeId,
            point: customer.store.loyalty.incentives.fulfillPoint,
            name: "정보 기입 감사 포인트",
            type: "save",
            note: "fulfill",
            customerId: customer.customerId,
            pointTransactionCustomerId: customer.id,
            expiredAt: fromMarket.getExpiredAt(
              customer.store.loyalty.condition?.expirationPeriod
            ),
          });
          let stats = yield select(reducers.getPointStatsByStoreId);
          stats[customer.storeId].save +=
            customer.store.loyalty.incentives.fulfillPoint;
          yield put(actions.receivePointStats(stats));
        }
      }
    }
    const { customer } = yield call(_getMarketCustomer, user.id);
    yield put(actions.receiveUser(customer));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "updateUser", { user });
  }
}

export function* watchUpdateUser() {
  yield takeEvery(actions.UPDATE_USER, updateUser);
}

export function* getNotices({ storeId }) {
  try {
    let notices = [];
    let data = {};
    do {
      data = yield call(
        API.getNotices,
        storeId,
        "draft",
        data.nextToken,
        //limit 100 or more
        500
      );
      notices = [...notices, ...data.items];
    } while (data.nextToken);

    yield put(actions.receiveNotices(notices));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "getNotices", { storeId });
  }
}

export function* watchGetNotices() {
  yield takeEvery(actions.GET_NOTICES, getNotices);
}

export function* getArchivedNotices({ locationId }) {
  try {
    let notices = [];
    let data = {};
    do {
      data = yield call(
        API.getNoticeCampaignResultsByLocation,
        locationId,
        data.nextToken,
        //limit 100 or more
        500
      );
      notices = [...notices, ...data.items];
    } while (data.nextToken);

    yield put(
      actions.receiveArchivedNotices(
        notices.map((n) => ({ ...n.notice, sentAt: n.createdAt }))
      )
    );
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "getArchivedNotices", { locationId });
  }
}

export function* watchGetArchivedNotices() {
  yield takeEvery(actions.GET_ARCHIVED_NOTICES, getArchivedNotices);
}

export function* getNoticeCampaigns({ storeId }) {
  try {
    let campaigns = [];
    let data = {};
    do {
      data = yield call(
        API.getNoticeCampaigns,
        storeId,
        data.nextToken,
        //limit 100 or more
        500
      );
      campaigns = [...campaigns, ...data.items];
    } while (data.nextToken);

    yield put(actions.receiveNoticeCampaigns(campaigns));
    yield put(actions.setArticleStatusSuccess());
  } catch (error) {
    yield put(actions.setArticleStatusFailure(error));
    Analytics.sendErrorReport(error, "getNoticeCampaigns", { storeId });
  }
}

export function* watchGetNoticeCampaigns() {
  yield takeEvery(actions.GET_NOTICE_CAMPAIGNS, getNoticeCampaigns);
}

// export function* watchCheckout() {
//   while (true) {
//     yield take(actions.CHECKOUT_REQUEST)
//     /*
//       ***THIS IS A BLOCKING CALL***
//       It means that watchCheckout will ignore any CHECKOUT_REQUEST event until
//       the current checkout completes, either by success or by Error.
//       i.e. concurrent CHECKOUT_REQUEST are not allowed
//       TODO: This needs to be enforced by the UI (disable checkout button)
//     */
//     yield call(checkout)
//   }
// }

export function* signUpMarket({ user, orderId }) {
  try {
    yield Auth.signOut();
    yield put(actions.setMarketAuthenticated(false));
    yield put(actions.clearUser());
    yield Auth.signUp({
      username: hashMd5(user.phone) + "@thegrowing.co",
      password: user.password,
      attributes: {
        name: user.name,
        birthdate: user.birth,
        gender: user.gender,
        "custom:phone_number": user.phone,
        "custom:marketing_agree": user.agreements.marketing ? "1" : "0",
        "custom:skip_verification": "1",
      },
    });
    yield Auth.signIn(hashMd5(user.phone) + "@thegrowing.co", user.password);
    const origin = yield _getMarketCustomer(hashMd5(user.phone));

    if (!origin.customer.id) {
      const createGlobalCustomer = {
        id: "unknown",
        storeId: "unknown",
        locationId: "unknown",
        customerId: hashMd5(user.phone),
        ident: user.phone,
        identType: "phone",
        name: user.name,
        phone: user.phone,
        birth: user.birth,
        gender: user.gender,
        agreements: {
          privacy: user.agreements.privacy,
          provider: null,
          //todo 마케팅 정보 메일
        },
      };
      yield call(API.createCustomer, createGlobalCustomer, true);
    } else {
      const updateStoreCustomer = {
        ...origin.customer,
        name: user.name,
        phone: user.phone,
        birth: user.birth,
        gender: user.gender,
        agreements: {
          ...(origin.customer.agreements ?? {}),
          privacy: user.agreements.privacy,
          //todo 마케팅 정보 메일
        },
      };
      yield call(API.updateCustomer, updateStoreCustomer, true);
    }

    yield call(
      _updateOrderFromReceipt,
      orderId,
      hashMd5(user.phone),
      origin.customer
    );
    yield put(actions.getUser(hashMd5(user.phone)));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "signUpMarket", { user });
  }
}

export function* watchSignUpMarket() {
  yield takeEvery(actions.SIGNUP_MARKET, signUpMarket);
}

export function* signInMarket({ id, password, orderId }) {
  try {
    yield Auth.signIn(hashMd5(id) + "@thegrowing.co", password);
    let { customer } = yield call(_getMarketCustomer, hashMd5(id));
    yield put(actions.getCart(customer.id));
    yield put(actions.receiveUser(customer));
    yield call(_updateOrderFromReceipt, orderId, hashMd5(id), customer);
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "signInMarket", { id, password });
  }
}

export function* watchSignInMarket() {
  yield takeEvery(actions.SIGNIN_MARKET, signInMarket);
}

export function* setStoreFavorite({ storeCustomer }) {
  try {
    // TODO: remove for optimizing, take a long time
    yield call(API.updateCustomer, storeCustomer);
    let { customer } = yield call(_getMarketCustomer, storeCustomer.customerId);
    yield put(
      actions.receiveUser({ ...customer, updatedAt: new Date().toISOString() })
    );
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "setStoreFavorite", { storeCustomer });
  }
}

export function* watchSetStoreFavorite() {
  yield takeEvery(actions.SET_STORE_FAVORITE, setStoreFavorite);
}

function* _getStoreCustomerWithTransaction(storeId, customerId) {
  // TODO: stores is changed properties, cannot use stores[storeId] directly
  console.log(storeId);
  let storeCustomer = {};
  let storeCustomers = yield call(
    API.getCustomers,
    customerId,
    null,
    null,
    "registered",
    true,
    null,
    100
  );
  storeCustomers = (storeCustomers || []).filter(
    (customer) => customer.storeId === storeId
  );

  if (storeCustomers.length > 0) {
    storeCustomer = storeCustomers[0];
    if (!storeCustomer.points) {
      storeCustomer.points = {};
    }
    let pointTransactions = [];
    let pointData = {};
    do {
      pointData = yield call(
        API.getPointTransactionByStoreCustomer,
        storeCustomer.id,
        null,
        500,
        pointData.nextToken
      );
      pointTransactions = [...pointTransactions, ...pointData.items];
    } while (pointData.nextToken);
    storeCustomer.points.items = pointTransactions;

    if (!storeCustomer.credits) {
      storeCustomer.credits = {};
    }
    let creditTransactions = [];
    let creditData = {};
    do {
      creditData = yield call(
        API.getCreditTransactionsByStoreCustomer,
        storeCustomer.id,
        creditData.nextToken,
        null,
        500
      );
      creditTransactions = [...creditTransactions, ...creditData.items];
    } while (creditData.nextToken);
    storeCustomer.credits.items = creditTransactions;
  }
  return storeCustomer;
}

function* _handleCreateStoreCustomer(storeId, locationId, user) {
  const data = yield call(
    API.getLocationCustomersByOriginId,
    user.id,
    { eq: storeId },
    null,
    null,
    {
      _delta: {
        ne: "DELETE",
      },
      status: {
        eq: "registered",
      },
    }
  );
  const customers = data.items;
  if (customers.length === 0) {
    const location = yield call(API.getStoreLocation, locationId);
    const salesDate = location.state === "opened" && location.sales?.startedAt;
    yield call(API.createCustomer, {
      id: generateId("cst"),
      customerId: user.id,
      storeId: storeId,
      locationId: locationId,
      status: "registered",
      registeredAt: new Date().toISOString(),
      salesDate: salesDate ?? null,
    });
  }
}

export function* loadStore({ storeId, locationId }) {
  try {
    const user = yield select(reducers.getUser);
    const keys = localStorage.getItem(Constants.STORAGE_KEYS.STORE);
    if (keys && storeId === keys.split("|")[0]) {
      const [storeId, locationId] = keys.split("|");
      yield _handleCreateStoreCustomer(storeId, locationId, user);
      localStorage.removeItem(Constants.QUERY_KEYS.STORE_LOCATION);
      localStorage.removeItem(Constants.STORAGE_KEYS.STORE);
    }
    let b = new Date().getTime();
    let [storeCustomer, stats, items] = yield all([
      _getStoreCustomerWithTransaction(storeId, user.id),
      API.getStoreStats(storeId),
      _getItems(storeId),
    ]);
    storeCustomer = {
      ...storeCustomer,
      store: {
        ...storeCustomer.store,
        stats,
      },
      location: storeCustomer.locations.filter((l) => l.id === locationId)[0],
    };
    console.log(storeCustomer);
    yield put(actions.receiveStore(storeCustomer));
    yield put(actions.receiveItems(items));
    console.log("TIME(loadStore) TOTAL: " + (new Date().getTime() - b) / 1000);
    yield put(actions.setMarketStoreStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStoreStatusFailure(error));
    Analytics.sendErrorReport(error, "loadStore", { storeId });
  }
}

export function* watchLoadStore() {
  yield takeEvery(actions.LOAD_STORE, loadStore);
}

function* _addItemToCart(item) {
  // TODO: storeId, customerId to ID, ...
  let cart = yield select(reducers.getCart);
  const user = yield select(reducers.getUser);

  console.log(cart);
  console.log(item);
  let items = _.cloneDeep(cart.items);
  let newItem = item;
  if (newItem.type === "discount") {
    items = items.filter((i) => i.type !== "discount");
  }
  for (var i = 0; i < items.length; i++) {
    // Update quantity of Item
    if (
      newItem.type !== "custom" &&
      fromMarket.isEqualItem(items[i], newItem)
    ) {
      if (items[i].discountMethod !== "rate") {
        items[i] = fromMarket.addItem(items[i], newItem);
        items[i].referId = newItem.referId;
        items[i].point += newItem.point || 0;
      }
      cart = {
        ...cart,
        ...fromMarket.brushupItems(items),
      };

      if (!cart.id) {
        cart.id = user.id;
        cart = yield call(API.createCart, cart);
      } else {
        cart = yield call(API.updateCart, cart);
      }
      return cart;
    }
  }
  // Add Item
  const storeId = yield select(reducers.getMarketStoreId);
  const stores = yield select(reducers.getMarketStores);
  console.log(stores);
  items = [
    ...items,
    { ...newItem, index: (_.maxBy(items, "index")?.index || 0) + 1 },
  ];
  cart = {
    ...cart,
    ...fromMarket.brushupItems(items),
    storeId: cart.storeId || storeId,
    // TODO: 등록고객이 아닌 경우 생성
    orderCustomerId: stores[storeId]?.customer.id,
    orderLocationId: stores[storeId]?.location.id,
    createdAt: cart.createdAt ? cart.createdAt : new Date().toISOString(),
  };
  console.log(cart);
  if (!cart.id) {
    cart.id = user.id;
    cart = yield call(API.createCart, cart);
  } else {
    cart = yield call(API.updateCart, cart);
  }
  return cart;
}

export function* addItemsToCart({ items }) {
  try {
    let cart = null;
    for (let item of items) {
      cart = yield _addItemToCart(item);
      console.log(cart);
      yield put(actions.receiveCart(cart));
    }
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "addItemsToCart", { items });
  }
}

export function* watchAddItemsToCart() {
  yield takeEvery(actions.ADD_ITEMS_TO_CART, addItemsToCart);
}

export function* addItemToCart({ item }) {
  try {
    const cart = yield _addItemToCart(item);
    yield put(actions.receiveCart({ ...cart }));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "addItemToCart", { item });
  }
}

export function* watchAddItemToCart() {
  yield takeEvery(actions.ADD_ITEM_TO_CART, addItemToCart);
}

const getDeliveryFee = (orderAmount, market) => {
  let fees = market?.deliveryFee || [];
  let deliveryPrice = 0;
  if (fees.length > 0) {
    for (let i = 0; i < fees.length; i++) {
      if (orderAmount >= fees[i].start && orderAmount <= fees[i].end) {
        deliveryPrice = fees[i].fee;
      }
    }
  }
  console.log("delivery amount", deliveryPrice);
  return deliveryPrice;
};

export function* setOrderType({ orderType }) {
  try {
    const market = yield select(reducers.getCartStoreMarketConfig);
    console.log(market);
    let cart = _.cloneDeep(yield select(reducers.getCart));

    if (market.deliveryFee) {
      const deliveries = cart.items.filter((item) => item.type === "delivery");
      if (orderType === "delivery") {
        let amount = getDeliveryFee(cart.amount, market);
        if (deliveries.length === 0 && amount > 0) {
          yield call(addItemToCart, {
            item: {
              type: "delivery",
              name: "배송비",
              price: amount,
              amount: amount,
              quantity: 1,
              modifiers: [],
            },
          });
        }
      } else {
        if (deliveries.length > 0) {
          console.log("aaa");
          yield call(removeItemFromCart, { index: deliveries[0].index });
        }
      }
    }

    cart = _.cloneDeep(yield select(reducers.getCart));
    if (market.takeoutDiscount) {
      const discounts = cart.items.filter((item) => item.referId === "takeout");
      if (orderType === "takeout") {
        if (discounts.length === 0) {
          yield call(addItemToCart, {
            item: {
              type: "discount",
              name: `테이크아웃 할인(${market.takeoutDiscount}%)`,
              quantity: 1,
              referId: "takeout",
              discountValue: market.takeoutDiscount,
              discountMethod: "rate",
              modifiers: [],
            },
          });
        }
      } else {
        if (discounts.length > 0) {
          console.log("bbb");
          yield call(removeItemFromCart, { index: discounts[0].index });
        }
      }
    }
    // yield put(actions.receiveCart(cart));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "setOrderType", { orderType });
  }
}

export function* watchSetOrderType() {
  yield takeEvery(actions.SET_ORDER_TYPE, setOrderType);
}

export function* duplicateOrderToCart({ order }) {
  try {
    let cart = {
      ...fromMarket.brushupItems(
        order.items.filter((item) => item.type === "sku")
      ),
      id: order.customerId,
      storeId: order.storeId,
      orderLocationId: order.orderLocationId,
      orderCustomerId: order.orderCustomerId,
      createdAt: new Date().toISOString(),
    };
    cart = yield call(API.createCart, cart);
    yield put(actions.receiveCart(cart));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "duplicateOrderToCart", { order });
  }
}

export function* watchDuplicateOrderToCart() {
  yield takeEvery(actions.DUPLICATE_ORDER_TO_CART, duplicateOrderToCart);
}

export function* removeItemFromCart({ index }) {
  try {
    let cart = yield select(reducers.getCart);
    const removeItems = cart.items.filter((item) => item.index === index);
    if (
      removeItems.length > 0 &&
      removeItems[0].referId &&
      (removeItems[0].type === "sku" ||
        removeItems[0].type === "discount" ||
        removeItems[0].type === "credit")
    ) {
      cart.items = cart.items.filter(
        (item) => item.referId !== removeItems[0].referId
      );
    } else {
      cart.items = cart.items.filter((item) => item.index !== index);
    }
    cart = {
      ...cart,
      ...fromMarket.brushupItems(cart.items),
    };
    console.log(cart);
    if (cart.items.length === 0) {
      yield call(API.deleteCart, cart.id);
      yield put(actions.clearCart());
    } else {
      cart = yield call(API.updateCart, cart);
      yield put(actions.receiveCart(cart));
    }

    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "removeItemFromCart", { index });
  }
}

export function* watchRemoveItemFromCart() {
  yield takeEvery(actions.REMOVE_ITEM_FROM_CART, removeItemFromCart);
}

export function* deleteCart({ cart }) {
  try {
    yield call(API.deleteCart, cart.id);
    yield put(actions.clearCart());
    yield put(actions.clearStore());
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteCart", { cart });
  }
}

export function* watchDeleteCart() {
  yield takeEvery(actions.DELETE_CART, deleteCart);
}

export function* getCart({ cartId, withStore }) {
  try {
    console.log("getCart.", cartId);
    let cart = yield call(API.getCart, cartId);
    console.log("getCart.", cart);
    if (cart) {
      if (cart.storeId) {
        cart.store = yield call(_getStoreWithLocations, cart.storeId);
        cart.store.location = cart.store.locations.items.filter(
          (l) => l.id === cart.orderLocationId
        )[0];
      }
      yield put(actions.receiveCart(cart));
    }
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "getCart", { cartId, withStore });
  }
}

export function* watchGetCart() {
  yield takeEvery(actions.GET_CART, getCart);
}

export function* _handlePaidOrder(order) {
  const transaction = yield select(reducers.getCartUsePoint);
  console.log(transaction);
  if (transaction) {
    let _transaction = {
      ...transaction,
      id: generateId("pt"),
      storeId: order.storeId,
      locationId: order.orderLocationId,
      type: "use",
      origin: "market",
      currency: order.currency,
      orderId: order.id,
      orderNumber: order.number,
      customerId: order.customerId,
      pointTransactionCustomerId: order.orderCustomerId,
      expiredAt: null,
      salesDate: order.salesDate,
    };
    const createdTransaction = yield call(
      API.createPointTransaction,
      _transaction
    );
    console.log("createdUsePoint", createdTransaction);
  }

  // Credit
  for (let idx in order.items) {
    if (order.items[idx].type === "credit") {
      let item = order.items[idx];
      let _deposit = yield call(API.createCreditTransaction, {
        id: generateId("ct"),
        locationId: order.orderLocationId,
        storeId: order.storeId,
        name: item.name || null,
        origin: "market",
        type: "deposit",
        amount: item.amount * (item.quantity || 1),
        currency: "KRW",
        customerId: order.customerId,
        creditTransactionCustomerId: order.orderCustomerId,
        salesDate: order.salesDate,
      });
      order.items[idx].parentId = _deposit.id;
      console.log("createdCredit", _deposit);
    }
  }

  return order;
}

export function* payOrder({ cart, method }) {
  try {
    if (method === "credit") {
      const customer = yield _getStoreCustomerWithTransaction(
        cart.storeId,
        cart.id
      );
      yield put(actions.receiveStore(customer));
      const salesDate = yield select(reducers.getSalesStartedAtForMarket);
      const point = yield select(reducers.getMarketStoreCredit);
      if (point < cart.amount) {
        throw new Error("balanceInvalid");
      }
      const items = yield _getItems(cart.storeId);
      yield put(actions.receiveItems(items));

      const date = new Date().toISOString();
      let order = {
        id: generateId("ord"),
        origin: "market",
        storeId: cart.storeId,
        amount: cart.amount,
        orderLocationId: cart.orderLocationId,
        orderCustomerId: cart.orderCustomerId,
        quantity: cart.quantity,
        diningOption: cart.diningOption,
        address: addressToStr(cart.address),
        reserveDate: cart.reserveDate,
        pickupDate: cart.pickupDate,
        shippingDate: cart.shippingDate,
        request: cart.request,
        tax: 0,
        customerId: cart.id,
        state: "paid",
        diningState: "created",
        receiptId: cart.receiptId,
        items: cart.items,
        charges: [],
        paidAt: date,
        updatedAt: date,
        createdAt: date,
        salesDate,
      };

      if (order.amount > 0) {
        let charge = {
          id: generateId("ch"),
          amount: order.amount,
          method: method,
          tax: 0,
          createdAt: date,
        };

        const withdrawTransaction = yield call(API.createCreditTransaction, {
          id: generateId("ct"),
          locationId: order.orderLocationId,
          storeId: order.storeId,
          type: "withdraw",
          amount: order.amount,
          currency: "KRW",
          customerId: order.customerId,
          creditTransactionCustomerId: order.orderCustomerId,
          salesDate,
        });
        charge = {
          ...charge,
          creditTransactionId: withdrawTransaction.id,
        };

        order.charges = [charge];
      }

      order = yield _handlePaidOrder(order);
      console.log(order);
      const createdOrder = yield call(API.createOrder, order);
      yield put(actions.receiveOrder(createdOrder));
      yield call(API.deleteCart, cart.id);

      yield put(actions.clearCart());
      yield put(actions.clearStore());
      yield put(actions.receiveCheckout(order));
      yield put(actions.setMarketStatusSuccess());
    } else {
      yield put(
        actions.setMarketStatusFailure(new Error("Invalid payment method"))
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "payOrder", { cart, method });
  }
}

export function* watchPayOrder() {
  yield takeEvery(actions.PAY_ORDER, payOrder);
}

export function* receivePaidOrder({ ident }) {
  try {
    // TEST: http://localhost:3000/order-completed?id=20201004141408830
    const orders = yield select(reducers.getOrdersByReceiptId);
    if (ident in orders) {
      yield put(actions.receiveCheckout(orders[ident]));
      yield put(actions.setMarketOrderStatusSuccess());
      return;
    }

    const charge = yield call(API.getSalesOrder, ident);
    const order = yield call(API.getOrder, charge.orderId);
    if (charge.state === "paid" && order.state === "paid") {
      let [storeCustomer, stats] = yield all([
        _getStoreCustomerWithTransaction(order.storeId, order.customerId),
        API.getStoreStats(order.storeId),
      ]);
      storeCustomer = {
        ...storeCustomer,
        store: {
          ...storeCustomer.store,
          stats,
        },
        location: storeCustomer.locations.filter(
          (location) => location.id === order.orderLocationId
        )[0],
      };
      yield put(actions.receiveStore(storeCustomer));
      yield put(actions.receiveOrder(order));
      yield put(actions.clearCart());
      yield put(actions.receiveCheckout(order));
      yield put(actions.setMarketOrderStatusSuccess());
    } else {
      yield put(
        actions.setMarketOrderStatusFailure(new Error("Failed to pay"))
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketOrderStatusFailure(error));
    Analytics.sendErrorReport(error, "receivePaidOrder", { ident });
  }
}

export function* watchRecievePaidOrder() {
  yield takeEvery(actions.RECEIVE_PAID_ORDER, receivePaidOrder);
}

export function* registerOrUpdateAccount({ account }) {
  try {
    let updatedStore = {};
    let removeAccount = {};
    let store = yield select(reducers.getStore);
    const locationId = yield select(reducers.getCurrentLocationId);
    const location = yield call(API.getStoreLocation, locationId);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    store = yield call(_getStoreWithLocations, store.id);

    const { ident, holder, bankCode, number, businessNumber } = yield call(
      API.registerAccount,
      {
        ...account,
        ident: generateId(null),
        name: store.info.name || "",
      }
    );
    if (isMultiLocation && location.type === "indirect") {
      removeAccount = location.market?.account || {};
      let _location = {
        ..._.cloneDeep(location),
        market: {
          ..._.cloneDeep(location.market),
          account: {
            ident,
            holder,
            bankCode,
            bank: account.bank,
            number,
            businessNumber,
          },
        },
        subscription: {
          ..._.cloneDeep(location.subscription),
          account: {
            ident,
            holder,
            bankCode,
            bank: account.bank,
            number,
            businessNumber,
          },
        },
      };
      yield call(API.updateLocation, _location);
    } else {
      removeAccount = store.market?.account || {};
      let _store = {
        ..._.cloneDeep(store),
        market: {
          ..._.cloneDeep(store.market),
          account: {
            ident,
            holder,
            bankCode,
            bank: account.bank,
            number,
            businessNumber,
          },
        },
        subscription: {
          ..._.cloneDeep(store.subscription),
          account: {
            ident,
            holder,
            bankCode,
            bank: account.bank,
            number,
            businessNumber,
          },
        },
      };
      yield call(API.updateStore, _store);
    }
    try {
      if (removeAccount?.ident) {
        //remove
        yield call(API.updateAccount, {
          ...removeAccount,
          name: `removed(${store.id})`,
        });
      }
    } catch (err) {
      console.debug("Failed to update old account");
    }
    updatedStore = yield call(_getStoreWithLocations, store.id);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "registerOrUpdateAccount", { account });
  }
}

export function* watchRegisterOrUpdateAccount() {
  yield takeEvery(actions.REGISTER_OR_UPDATE_ACCOUNT, registerOrUpdateAccount);
}

export function* sendReceiptLink({ recipient, order }) {
  try {
    yield call(API.sendReceiptLink, recipient, order);
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "sendReceiptLink", { recipient, order });
  }
}

export function* watchSendReceiptLink() {
  yield takeEvery(actions.SEND_RECEIPT_LINK, sendReceiptLink);
}

export function* cancelOrder({ order }) {
  try {
    let a = new Date().getTime();
    for (let idx in order.charges || []) {
      if (
        order.charges[idx].pg === "nicepay" &&
        order.charges[idx].method === "card"
      ) {
        const refundId = generateId("rf");
        const result = yield call(
          API.cancelCardPay,
          refundId,
          order.charges[idx].transactionId,
          order.charges[idx].amount
        );
        order.charges[idx].refunds = [
          {
            id: refundId,
            amount: order.charges[idx].amount,
            supplyAmount: order.charges[idx].supplyAmount,
            taxFreeAmount: order.charges[idx].taxFreeAmount,
            tax: order.charges[idx].tax,
            approvalNumber: result.approvalNumber,
            approvalDate: result.approvalDate,
            transactionId: result.transactionId,
            createdAt: new Date().toISOString(),
          },
        ];
      }
      if (order.charges[idx].method === "credit") {
        const refundTransaction = yield call(API.createCreditTransaction, {
          id: generateId("ct"),
          locationId: order.orderLocationId,
          storeId: order.storeId,
          type: "deposit",
          amount: order.amount,
          currency: "KRW",
          customerId: order.customerId,
          description: "returned",
          creditTransactionCustomerId: order.orderCustomerId,
        });

        let refund = {
          id: generateId("rf"),
          amount: order.charges[idx].amount,
          supplyAmount: order.charges[idx].supplyAmount,
          taxFreeAmount: order.charges[idx].taxFreeAmount,
          tax: order.charges[idx].tax,
          creditTransactionId: refundTransaction.id,
          createdAt: refundTransaction.createdAt,
        };
        if (
          order.charges[idx].pg === "nicepay" &&
          order.charges[idx].transactionId
        ) {
          const refundId = generateId("rf");
          const result = yield call(
            API.cancelCashReceipt,
            refundId,
            order.charges[idx].transactionId,
            order.charges[idx].amount,
            order.charges[idx].supplyAmount,
            order.charges[idx].tax,
            order.charges[idx].taxFreeAmount
          );
          refund = {
            ...refund,
            transactionId: result.transactionId,
            approvalNumber: result.approvalNumber,
            approvalDate: result.approvalDate,
          };
        }
        order.charges[idx].refunds = [refund];
      }
    }

    let creditItems = order.items.filter((item) => item.type === "credit");
    console.log("creditAmount", creditItems);
    const creditAmount = _.sumBy(creditItems, "amount");
    if (creditAmount > 0) {
      yield call(API.createCreditTransaction, {
        id: generateId("ct"),
        locationId: order.orderLocationId,
        storeId: order.storeId,
        type: "withdraw",
        origin: "market",
        amount: creditAmount,
        currency: "KRW",
        customerId: order.customerId,
        description: "returned",
        creditTransactionCustomerId: order.orderCustomerId,
      });
    }

    const loyalty = yield select(reducers.getMarketStoreLoyalty);
    let _usePoint = fromMarket.getLoyaltyUsePointByOrder(order, loyalty);
    if (_usePoint && _usePoint.point > 0) {
      let transaction = {
        ..._usePoint,
        id: generateId("pt"),
        storeId: order.storeId,
        locationId: order.orderLocationId,
        type: "save",
        currency: order.currency,
        orderId: order.id,
        orderNumber: order.number,
        origin: "market",
        note: "returned",
        customerId: order.customerId,
        pointTransactionCustomerId: order.orderCustomerId,
        expiredAt: fromMarket.getExpiredAt(loyalty.condition.expirationPeriod),
      };
      yield call(API.createPointTransaction, transaction);
    }

    order.amountReturned = order.amount;
    order.state = "returned";
    order.diningState = "cancelled";
    order.returnedAt = new Date().toISOString();
    order.returns = [
      {
        amount: order.amount,
        items: order.items.filter((item) => item.type === "sku"),
        refundId: order.charges[0]?.refunds[0].id,
        createdAt: new Date().toISOString(),
      },
    ];
    order = yield call(API.updateOrder, order);
    console.log("TIME(cancel) TOTAL: " + (new Date().getTime() - a) / 1000);
    yield put(actions.receiveOrder(order));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "cancelOrder", { order });
  }
}

export function* watchCancelOrder() {
  yield takeEvery(actions.CANCEL_ORDER, cancelOrder);
}

export function* resetMarketPassword({ ident, password }) {
  try {
    yield call(API.resetPassword, ident, password);
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
    Analytics.sendErrorReport(error, "resetMarketPassword", {
      ident,
      password,
    });
  }
}

export function* watchResetMarketPassword() {
  yield takeEvery(actions.RESET_MARKET_PASSWORD, resetMarketPassword);
}

// Coupon
function* _getAllCoupons(storeId, locationId, isPublish) {
  let coupons = [];
  let data = {};
  do {
    data = yield call(
      API.getCoupons,
      storeId,
      data.nextToken,
      //limit 100 or more
      500,
      {
        conditionType: {
          ne: "tier",
        },
      }
    );
    coupons = [...coupons, ...data.items];
  } while (data.nextToken);
  coupons = coupons.filter((coupon) =>
    isCurrentLocationAvailableCoupon(coupon, locationId, isPublish)
  );
  return coupons;
}

function* _getPointTransactionsByStore(
  storeId,
  customerId = null,
  start = null,
  end = null,
  locationId = null,
  basis = null
) {
  let b = new Date();
  let transactions = [];
  let data = {};
  let filter = {};
  if (customerId) {
    filter.pointTransactionCustomerId = { eq: customerId };
  }
  if (locationId) {
    filter.locationId = { eq: locationId };
  }

  if (basis === "sales") {
    do {
      data = yield call(
        API.getPointTransactionsByStoreSalesDate,
        storeId,
        start && end ? { between: [start, end] } : null,
        data.nextToken,
        //limit 100 or more
        500,
        !_.isEmpty(filter) ? filter : null
      );
      transactions = [...transactions, ...data.items];
    } while (data.nextToken);
    transactions = transactions.sort((a, b) => {
      if (moment(b.createdAt).isAfter(moment(a.createdAt))) {
        return 1;
      } else if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
        return -1;
      }
    });
  } else {
    do {
      data = yield call(
        API.getPointTransactionsByStore,
        storeId,
        start && end ? { between: [start, end] } : null,
        data.nextToken,
        //limit 100 or more
        500,
        !_.isEmpty(filter) ? filter : null
      );
      transactions = [...transactions, ...data.items];
    } while (data.nextToken);
  }
  console.log("POINT", (new Date().getTime() - b.getTime()) / 1000);
  return transactions;
}

function* _getPointTransactionsByLocation(
  locationId,
  customerId = null,
  start = null,
  end = null,
  basis = null
) {
  let b = new Date();
  let transactions = [];
  let data = {};
  if (basis === "sales") {
    do {
      data = yield call(
        API.getPointTransactionsByLocationSalesDate,
        locationId,
        start && end ? { between: [start, end] } : null,
        data.nextToken,
        500,
        customerId ? { pointTransactionCustomerId: { eq: customerId } } : null
      );
      transactions = [...transactions, ...data.items];
    } while (data.nextToken);
    transactions = transactions.sort((a, b) => {
      if (moment(b.createdAt).isAfter(moment(a.createdAt))) {
        return 1;
      } else if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
        return -1;
      }
    });
  } else {
    do {
      data = yield call(
        API.getPointTransactionsByLocation,
        locationId,
        start && end ? { between: [start, end] } : null,
        data.nextToken,
        500,
        customerId ? { pointTransactionCustomerId: { eq: customerId } } : null
      );
      transactions = [...transactions, ...data.items];
    } while (data.nextToken);
  }
  console.log("POINT", (new Date().getTime() - b.getTime()) / 1000);
  return transactions;
}

function* _getCreditTransactionsByStore(
  storeId,
  customerId = null,
  locationId = null
) {
  let b = new Date();

  let transactions = [];
  let data = {};
  let filter = {};

  if (customerId) {
    filter.creditTransactionCustomerId = { eq: customerId };
  }
  if (locationId) {
    filter.locationId = { eq: locationId };
  }
  do {
    data = yield call(
      API.getCreditTransactionsByStore,
      storeId,
      null,
      data.nextToken,
      //limit 100 or more
      500,
      !_.isEmpty(filter) ? filter : null
    );
    transactions = [...transactions, ...data.items];
  } while (data.nextToken);
  console.log("CREDIT", (new Date().getTime() - b.getTime()) / 1000);
  return transactions;
}

function* _getOrdersByStore(storeId, customerId = null, locationId = null) {
  let b = new Date();
  let orders = [];
  let data = {};
  let filter = {};
  if (customerId) {
    filter.orderCustomerId = { eq: customerId };
  }
  if (locationId) {
    filter.orderLocationId = { eq: locationId };
  }
  do {
    data = yield call(
      API.getOrdersOnlyByStore,
      storeId,
      null,
      data.nextToken,
      500,
      !_.isEmpty(filter) ? filter : null
    );
    orders = [...orders, ...data.items];
  } while (data.nextToken);
  console.log("ORDERS", (new Date().getTime() - b.getTime()) / 1000);
  return orders;
}

export function* getProviderOrders({ filters, page, size }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    let data = yield call(API.getProviderOrders, storeId, page, size, filters);
    yield put(
      actions.receiveProviderOrders({ items: data.items, count: data.count })
    );
    yield put(actions.setGetOrderHistoriesSuccess());
  } catch (error) {
    yield put(actions.setGetOrderHistoriesFailure(error));
    Analytics.sendErrorReport(error, "getProviderOrders", {
      storeId,
    });
  }
}

export function* watchGetProviderOrders() {
  yield takeEvery(actions.GET_PROVIDER_ORDERS, getProviderOrders);
}

export function* getCoupon({ couponId }) {
  try {
    const coupon = yield call(API.getCoupon, couponId);
    yield put(actions.receiveCoupon(coupon));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getCoupon", { couponId });
  }
}

export function* watchGetCoupon() {
  yield takeEvery(actions.GET_COUPON, getCoupon);
}

export function* getCoupons({ storeId }) {
  try {
    const org = yield select(reducers.getCurrentOrganization);
    const coupons = yield call(_getAllCoupons, storeId, org?.locationId);
    yield put(actions.receiveCoupons(coupons));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getCoupons", { storeId });
  }
}

export function* watchGetCoupons() {
  yield takeEvery(actions.GET_COUPONS, getCoupons);
}

export function* getCouponsForPublish({ storeId }) {
  try {
    const org = yield select(reducers.getCurrentOrganization);
    const coupons = yield call(_getAllCoupons, storeId, org?.locationId, true);
    yield put(actions.receiveCoupons(coupons));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getCouponsForPublish", { storeId });
  }
}

export function* watchGetCouponsForPublish() {
  yield takeEvery(actions.GET_COUPONS_FOR_PUBLISH, getCouponsForPublish);
}

export function* createCoupon({ coupon }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const organization = yield select(reducers.getCurrentOrganization);
    const isMultiLocation = yield select(reducers.isMultiLocation);
    let _coupon = {
      ...coupon,
      id: generateId("cpn"),
      storeId,
    };
    if (isMultiLocation && organization.locationId) {
      _coupon.locationId = organization.locationId;
    }
    const createdCoupon = yield call(API.createCoupon, _coupon);
    yield put(actions.receiveCoupon(createdCoupon));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "createCoupon", { coupon });
  }
}

export function* watchCreateCoupon() {
  yield takeEvery(actions.CREATE_COUPON, createCoupon);
}

export function* updateCoupon({ coupon }) {
  try {
    const updatedCoupon = yield call(API.updateCoupon, coupon);
    yield put(actions.receiveCoupon(updatedCoupon));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "updateCoupon", { coupon });
  }
}

export function* watchUpdateCoupon() {
  yield takeEvery(actions.UPDATE_COUPON, updateCoupon);
}

function* _updateMenuBoardConfigFromDiscountDelete(couponId) {
  const storeId = yield select(reducers.getStoreId);
  const locations = yield call(_getLocations, storeId);
  let needUpdates = [];
  for (let location of locations) {
    let _location = _.cloneDeep(location);
    let menuBoard = _.cloneDeep(_location.menu?.board);
    if ((menuBoard?.pages || []).length > 0) {
      let pagesByParentId = menuBoard.pages.reduce((obj, p) => {
        obj[p.parentId] = p;
        return obj;
      }, {});
      for (let page of menuBoard.pages) {
        let itemsByParentId = (page.items || []).reduce((obj, i) => {
          obj[i.parentId] = i;
          return obj;
        }, {});
        if (couponId in itemsByParentId) {
          pagesByParentId[page.parentId] = {
            ...pagesByParentId[page.parentId],
            items: pagesByParentId[page.parentId].items.filter(
              (item) => item.parentId !== couponId
            ),
          };
        }
      }
      let favoriteItemsByParentId = (menuBoard.favorite || []).reduce(
        (obj, f) => {
          obj[f.parentId] = f;
          return obj;
        },
        {}
      );
      if (couponId in favoriteItemsByParentId) {
        menuBoard = {
          ...menuBoard,
          favorite: menuBoard.favorite.filter(
            (fav) => fav.parentId !== couponId
          ),
        };
      }
      menuBoard = { ...menuBoard, pages: Object.values(pagesByParentId) };
      _location = {
        ..._location,
        menu: { ...(_location.menu || {}), board: menuBoard || {} },
      };
      if (!_.isEqual(location, _location)) {
        needUpdates.push(_location);
      }
    }
  }
  yield all(needUpdates.map((loc) => call(API.updateLocation, loc)));
}

export function* deleteCoupon({ coupon }) {
  try {
    yield call(API.deleteCoupon, coupon.id);
    yield put(actions.receiveDeletedCoupon(coupon.id));
    yield _updateMenuBoardConfigFromDiscountDelete(coupon.id);
    yield call(_deleteKioskDiscounts, [coupon]);
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteCoupon", { coupon });
  }
}

export function* watchDeleteCoupon() {
  yield takeEvery(actions.DELETE_COUPON, deleteCoupon);
}

export function* deleteCoupons({ coupons }) {
  try {
    for (let coupon of coupons) {
      yield call(API.deleteCoupon, coupon.id);
      yield put(actions.receiveDeletedCoupon(coupon.id));
      yield _updateMenuBoardConfigFromDiscountDelete(coupon.id);
    }
    yield call(_deleteKioskDiscounts, coupons);
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteCoupons", { coupons });
  }
}

export function* watchDeleteCoupons() {
  yield takeEvery(actions.DELETE_COUPONS, deleteCoupons);
}

function* _deleteKioskDiscounts(coupons) {
  const isMultiLocation = yield select(reducers.isMultiLocation);
  const storeId = yield select(reducers.getStoreId);
  let couponIds = [];
  for (let coupon of coupons) {
    couponIds.push(coupon.id);
  }
  if (isMultiLocation) {
    let locations = yield call(_getLocations, storeId);
    for (let location of locations) {
      if ((location.kiosk?.discounts || []).length > 0) {
        if (
          location.kiosk.discounts.length !==
          location.kiosk.discounts.filter(
            (discount) => !couponIds.includes(discount.couponId).length
          )
        ) {
          yield call(API.updateLocation, {
            ...location,
            kiosk: {
              ...location.kiosk,
              discounts: [
                ...location.kiosk.discounts.filter(
                  (discount) => !couponIds.includes(discount.couponId)
                ),
              ],
            },
          });
        }
      }
    }
  } else {
    let store = yield call(API.getStore, storeId);
    if ((store.kiosk?.discounts || []).length > 0) {
      if (
        store.kiosk.discounts.length !==
        store.kiosk.discounts.filter(
          (discount) => !couponIds.includes(discount.couponId).length
        )
      ) {
        yield call(API.updateStore, {
          ...store,
          kiosk: {
            ...store.kiosk,
            discounts: [
              ...store.kiosk.discounts.filter(
                (discount) => !couponIds.includes(discount.couponId)
              ),
            ],
          },
        });
      }
    }
  }
  const updatedStore = yield call(_getStoreWithLocations, storeId);
  yield put(actions.initStore(updatedStore));
}

export function* uploadItemTemplate({ storeId, key, locationId, excelType }) {
  // 클라이언트에서 업로드 해준 파일의 키를 가지고 서버에서 다운로드 - product부분에서 해주자
  // 파싱을 다하고 나서 넘겨주는 값을 가지고 다시 api.syncdb 호출
  try {
    const items = yield call(
      API.parseItemTemplate,
      storeId,
      key,
      locationId,
      "excel",
      excelType
    );
    if (items["errors"].length > 0) {
      yield put(actions.parseItemTemplateFailure(items["errors"]));
    } else {
      yield put(actions.parseItemTemplateSuccess(items["data"]));
    }
  } catch (error) {
    yield put(actions.parseItemTemplateFailure(error));
  }
}

export function* watchUploadItemTemplate() {
  yield takeEvery(actions.UPLOAD_ITEM_TEMPLATE, uploadItemTemplate);
}

export function* syncItemTemplate({ storeId, key, excelType }) {
  // 클라이언트에서 업로드 해준 파일의 키를 가지고 서버에서 다운로드 - product부분에서 해주자
  // 파싱을 다하고 나서 넘겨주는 값을 가지고 다시 api.syncdb 호출
  try {
    yield call(API.syncItemTemplate, storeId, key, excelType);
    let updateStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updateStore));
    // NOTE: handle syncItemTemplateSuccess in receiveStoreStatus
  } catch (error) {
    console.log(error);
    if (
      !error?.errors ||
      error.errors[0].errorType !== "Lambda:ExecutionTimeoutException"
    ) {
      yield put(actions.syncItemTemplateFailure(error));
    }
  }
}

export function* watchSyncItemTemplate() {
  yield takeEvery(actions.SYNC_ITEM_TEMPLATE, syncItemTemplate);
}

export function* uploadInventoryTemplate({
  storeId,
  employeeId,
  key,
  standardType,
  reason,
}) {
  try {
    const items = yield call(
      API.parseInventoryTemplate,
      storeId,
      employeeId,
      key,
      reason,
      standardType
    );
    if (items["errors"].length > 0) {
      yield put(actions.parseItemTemplateFailure(items["errors"]));
    } else {
      yield put(actions.parseItemTemplateSuccess(items["data"]));
    }
  } catch (error) {
    yield put(actions.parseItemTemplateFailure(error));
  }
}

export function* watchUploadInventoryTemplate() {
  yield takeEvery(actions.UPLOAD_INVENTORY_TEMPLATE, uploadInventoryTemplate);
}

export function* generateInventoryTemplate({
  storeId,
  locationId,
  standardType,
  reason,
}) {
  try {
    yield call(
      API.generateInventoryTemplate,
      storeId,
      locationId,
      reason,
      standardType
    );
  } catch (error) {
    if (
      !error?.errors ||
      error.errors[0].errorType !== "Lambda:ExecutionTimeoutException"
    ) {
      yield put(actions.generateItemTemplateFailure(error));
    }
  }
}

export function* watchGenerateInventoryTemplate() {
  yield takeEvery(
    actions.GENERATE_INVENTORY_TEMPLATE,
    generateInventoryTemplate
  );
}

export function* syncInventoryTemplate({ storeId, key, standardType, reason }) {
  try {
    yield call(API.syncInventoryTemplate, storeId, key, reason, standardType);
    // NOTE: handle syncItemTemplateSuccess in receiveStoreStatus
  } catch (error) {
    console.log(error);
    if (
      !error?.errors ||
      error.errors[0].errorType !== "Lambda:ExecutionTimeoutException"
    ) {
      yield put(actions.syncItemTemplateFailure(error));
    }
  }
}

export function* watchSyncInventoryTemplate() {
  yield takeEvery(actions.SYNC_INVENTORY_TEMPLATE, syncInventoryTemplate);
}

export function* receiveStoreStatus({ status }) {
  try {
    if (status.item) {
      switch (status.item.func) {
        case "syncItemTemplate":
          if (status.item.errorMessage) {
            yield put(
              actions.syncItemTemplateFailure(
                new Error(status.item.errorMessage)
              )
            );
          } else if (status.item.progress === 100) {
            yield put(actions.syncItemTemplateSuccess());
          }
          break;
        case "launchCampaign":
          const campaigns = yield select(reducers.getCampaigns);
          for (let c of campaigns) {
            if (c.status === "requesting") {
              const campaign = yield call(API.getCampaignWithReport, c.id);
              yield put(actions.receiveCampaign(campaign));
            }
          }
          break;
        case "syncInventoryTemplate":
          if (status.item.errorMessage) {
            yield put(
              actions.syncItemTemplateFailure(
                new Error(status.item.errorMessage)
              )
            );
          } else if (status.item.progress === 100) {
            yield put(actions.syncItemTemplateSuccess());
          }
          break;
        case "generateInventoryTemplate":
          if (status.item.errorMessage) {
            yield put(
              actions.generateItemTemplateFailure(
                new Error(status.item.errorMessage)
              )
            );
          } else if (status.item.progress === 100) {
            yield put(actions.generateItemTemplateSuccess());
          } else if (status.item.progress === 0) {
            yield put(actions.initGenerateInventoryTemplate());
          }
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log(error);
    Analytics.sendErrorReport(error, "receiveStoreStatus", { status });
  }
}

export function* watchReceiveStoreStatus() {
  yield takeEvery(actions.RECEIVE_STORE_STATUS, receiveStoreStatus);
}

export function* updateActiveChangedProducts({ products }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    yield all(products.map((product) => call(API.updateItem, product)));
    const updatedItems = yield _getItems(storeId);
    yield put(actions.receiveItems(updatedItems));
    yield put(actions.setUpdateItemsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setUpdateItemsFailure(error));
    Analytics.sendMixPanelEvent("error", {
      screen: "Product",
      action: "save_soldout",
      detail: error.errors && error.errors[0]?.message,
    });
  }
}

export function* watchUpdateActiveChangedProducts() {
  yield takeEvery(
    actions.UPDATE_ACTIVE_CHANGED_PRODUCTS,
    updateActiveChangedProducts
  );
}

export function* getOrderByApiKey({ orderId, withStore }) {
  try {
    const order = yield call(API.getOrderByApiKey, orderId);
    yield put(actions.receiveOrder(order));
    if (withStore) {
      const [store, location] = yield all([
        API.getStoreLoyaltyByApiKey(order.storeId),
        API.getLocationByApiKey(order.orderLocationId),
      ]);
      yield put(actions.receiveReceiptStore(store, location));
    }
    yield put(actions.setGetOrderSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setGetOrderFailure(error));
  }
}

export function* watchGetOrderByApiKey() {
  yield takeEvery(actions.GET_ORDER_BY_APIKEY, getOrderByApiKey);
}

function* _updateOrderFromReceipt(orderId, phone, origin) {
  if (orderId) {
    const order = yield call(API.getOrderByApiKey, orderId);
    if (order.receiptCustomerId === phone) {
      let _order = _.cloneDeep(order);
      if (!order.orderCustomerId) {
        let storeCustomers = origin.stores.items;
        storeCustomers = yield getRegisterdCustomer(storeCustomers);
        storeCustomers = (storeCustomers || []).reduce((obj, customer) => {
          obj[customer.storeId] = customer;
          return obj;
        }, {});
        let storeCustomer = storeCustomers[order.storeId] || {};
        if (_.isEmpty(storeCustomer)) {
          const location = yield call(
            API.getStoreLocation,
            order.orderLocationId
          );
          const salesDate =
            location.state === "opened" && location.sales?.startedAt;
          storeCustomer = yield call(API.createCustomer, {
            id: generateId("cst"),
            customerId: origin.id,
            storeId: order.storeId,
            locationId: order.orderLocationId,
            status: "registered",
            registeredAt: new Date().toISOString(),
            salesDate,
          });
        }
        _order = {
          ..._order,
          customerId: origin.id,
          orderCustomerId: storeCustomer.id,
          receiptCustomerId: null,
        };
        yield call(API.updateOrder, _order);
      } else {
        let _storeCustomer = yield call(
          API.getLocationCustomer,
          order.orderCustomerId,
          order.orderLocationId
        );
        if (_storeCustomer.status === "anonymous") {
          let updatedCustomers = yield getRegisterdCustomer(
            origin.stores.items
          );
          updatedCustomers = (updatedCustomers || []).reduce(
            (obj, customer) => {
              obj[customer.storeId] = customer;
              return obj;
            },
            {}
          );
          if (!_.isEmpty(updatedCustomers[order.storeId] || {})) {
            const orders = yield API.getOrdersByLocation(order.orderLocationId);
            const ordersByStoreCustomerId = orders.items.reduce(
              (obj, order) => {
                obj[order.orderCustomerId] = [
                  ...(obj[order.orderCustomerId] || []),
                  order,
                ];
                return obj;
              },
              {}
            );
            yield all(
              ordersByStoreCustomerId[_storeCustomer.id].map((ord) =>
                call(API.updateOrder, {
                  ...ord,
                  customerId: origin.id,
                  orderCustomerId: updatedCustomers[order.storeId].id,
                  updatedAt: new Date().toISOString(),
                  receiptCustomerId: null,
                })
              )
            );
            yield call(API.deleteCustomer, _storeCustomer.id);
            _storeCustomer = updatedCustomers[order.storeId];
          } else {
            const location = yield call(
              API.getStoreLocation,
              order.orderLocationId
            );
            const salesDate =
              location.state === "opened" && location.sales?.startedAt;
            const currentTime = new Date().toISOString();
            _storeCustomer = yield call(API.updateCustomer, {
              ..._storeCustomer,
              customerId: origin.id,
              status: "registered",
              registeredAt: currentTime,
              updatedAt: currentTime,
              salesDate,
            });
            _order = {
              ..._order,
              customerId: origin.id,
              orderCustomerId: _storeCustomer.id,
              receiptCustomerId: null,
            };
            yield call(API.updateOrder, _order);
          }
        }
      }
    }
  }
}

export function* updateOrderFromReceipt({ orderId, user }) {
  try {
    let origin = yield call(
      _getMarketCustomer,
      user.attributes.email.split("@")[0]
    );
    yield call(
      _updateOrderFromReceipt,
      orderId,
      user.attributes.email.split("@")[0],
      origin
    );
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketOrderStatusFailure(error));
  }
}

export function* watchUpdateOrderFromReceipt() {
  yield takeEvery(actions.UPDATE_ORDER_FROM_RECEIPT, updateOrderFromReceipt);
}

export function* getSubscriptionPlans({ storeId }) {
  try {
    const items = yield _getItems(storeId);
    yield put(actions.receiveItems(items));
    let plans = [];
    let data = {};
    do {
      data = yield call(
        API.getSubscriptionPlans,
        storeId,
        data.nextToken,
        //limit 100 or more
        500
      );
      plans = [...plans, ...data.items];
    } while (data.nextToken);
    yield put(actions.receiveSubscriptionPlans(plans));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
    Analytics.sendErrorReport(error, "getSubscriptionPlans", { storeId });
  }
}

export function* watchGetSubscriptionPlans() {
  yield takeEvery(actions.GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
}

export function* getSubscriptionPlan({ planId, locationId }) {
  try {
    let plan = yield call(API.getSubscriptionPlan, planId);
    const store = yield call(API.getStore, plan.storeId);
    plan = {
      ...plan,
      storeInfo: {
        ...store.info,
        policy: store.policy,
      },
    };
    if (locationId) {
      const location = yield call(API.getStoreLocation, locationId);
      plan = {
        ...plan,
        location: location,
      };
    }
    yield put(actions.receiveSubscriptionPlan(plan));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    yield put(actions.setSubscriptionStatusFailure(error));
    Analytics.sendErrorReport(error, "getSubscriptionPlan", { planId });
  }
}

export function* watchGetSubscriptionPlan() {
  yield takeEvery(actions.GET_SUBSCRIPTION_PLAN, getSubscriptionPlan);
}

export function* createSubscriptionPlan({ plan }) {
  try {
    let _plan = _.cloneDeep(plan);
    if (!_plan.id) {
      _plan.id = generateId("sp");
    }
    _plan = {
      ..._plan,
      storeId: yield select(reducers.getStoreId),
    };
    const createdPlan = yield call(API.createSubscriptionPlan, _plan);
    yield put(actions.receiveSubscriptionPlan(createdPlan));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    yield put(actions.setSubscriptionStatusFailure(error));
    Analytics.sendErrorReport(error, "createSubscriptionPlan", { plan });
  }
}

export function* watchCreateSubscriptionPlan() {
  yield takeEvery(actions.CREATE_SUBSCRIPTION_PLAN, createSubscriptionPlan);
}

export function* updateSubscriptionPlan({ plan }) {
  try {
    let _plan = _.cloneDeep(plan);
    const updatedPlan = yield call(API.updateSubscriptionPlan, _plan);
    yield put(actions.receiveSubscriptionPlan(updatedPlan));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    yield put(actions.setSubscriptionStatusFailure(error));
    Analytics.sendErrorReport(error, "updateSubscriptionPlan", { plan });
  }
}

export function* watchUpdateSubscriptionPlan() {
  yield takeEvery(actions.UPDATE_SUBSCRIPTION_PLAN, updateSubscriptionPlan);
}

export function* deleteSubscriptionPlan({ plan }) {
  try {
    yield call(API.deleteSubscriptionPlan, plan.id);
    yield put(
      actions.receiveSubscriptionPlan({
        ...plan,
        _delta: "DELETE",
      })
    );
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    yield put(actions.setSubscriptionStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteSubscriptionPlan", { plan });
  }
}

export function* watchDeleteSubscriptionPlan() {
  yield takeEvery(actions.DELETE_SUBSCRIPTION_PLAN, deleteSubscriptionPlan);
}

export function* createCustomerPayment({ customer, payment }) {
  try {
    const updatedPayment = yield call(API.createCustomerPayment, {
      ...payment,
      id: generateId("cp"),
      method: "card",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      customerId: customer.id,
    });
    const updatedCustomer = yield call(_getMarketCustomer, customer.id);
    yield put(actions.receiveUser(updatedCustomer.customer));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    const errorMessage = error.errors
      ? error.errors[0].message
        ? error.errors[0].message
        : "카드 추가에 실패했습니다."
      : error;
    yield put(actions.setMarketStatusFailure(errorMessage));
  }
}

export function* watchCreateCustomerPayment() {
  yield takeEvery(actions.CREATE_CUSTOMER_PAYMENT, createCustomerPayment);
}

export function* deleteCustomerPayment({ id }) {
  try {
    const customer = yield select(reducers.getUser);
    const deletedPayment = yield call(API.deleteCustomerPayment, id);
    const updatedCustomer = yield call(_getMarketCustomer, customer.id);
    yield put(actions.receiveUser(updatedCustomer.customer));
    yield put(actions.setMarketStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setMarketStatusFailure(error));
  }
}

export function* watchDeleteCustomerPayment() {
  yield takeEvery(actions.DELETE_CUSTOMER_PAYMENT, deleteCustomerPayment);
}

export function* subscribeSubscriptionPlan({
  customerId,
  planId,
  locationId,
  storeId,
}) {
  try {
    const data = yield call(
      API.getLocationCustomersByOriginId,
      customerId,
      {
        eq: storeId,
      },
      null,
      null,
      {
        _delta: {
          ne: "DELETE",
        },
        status: {
          eq: "registered",
        },
      }
    );
    const locationCustomers = data.items;
    const location = yield call(API.getStoreLocation, locationId);
    const salesDate = location.state === "opened" && location.sales?.startedAt;
    if (locationCustomers.length > 0) {
      const locationsById = (locationCustomers[0].locations || []).reduce(
        (obj, loc) => {
          obj[loc.id] = loc;
          return obj;
        },
        {}
      );
      if (!(locationId in locationsById)) {
        locationsById[locationId] = {
          id: locationId,
          isFavorite: false,
          status: "registered",
          point: {
            used: 0,
            saved: 0,
            extra: 0,
            lastSavedAt: null,
            lastTransactedAt: null,
          },
          credit: {
            deposit: 0,
            withdraw: 0,
            balance: 0,
            lastTransactedAt: null,
          },
          registeredAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          salesDate,
        };
        yield call(API.updateCustomer, {
          ...locationCustomers[0],
          locations: Object.values(locationsById),
        });
      }
    } else {
      const customer = yield select(reducers.getUser);
      yield call(API.createCustomer, {
        ...(yield _buildBaseCustomer(customer)),
        id: generateId("cst"),
        storeId: storeId,
        locationId: locationId,
        status: "registered",
        registeredAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        salesDate,
      });
    }
    const subscription = yield call(
      API.subscribeSubscriptionPlan,
      customerId,
      planId,
      locationId
    );
    const updatedCustomer = yield call(_getMarketCustomer, customerId);
    yield put(actions.receiveUser(updatedCustomer.customer));
    yield put(actions.setSubscriptionEditing(subscription));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchSubscribeSubscriptionPlan() {
  yield takeEvery(
    actions.SUBSCRIBE_SUBSCRIPTION_PLAN,
    subscribeSubscriptionPlan
  );
}

export function* stopSubscription({ subscriptionId }) {
  try {
    const subscription = yield call(API.stopSubscription, subscriptionId);
    let _customer = yield select(reducers.getUser);
    const { customer } = yield call(_getMarketCustomer, _customer.id);
    yield put(actions.receiveUser(customer));
    yield put(actions.setSubscriptionEditing(subscription));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchStopSubscription() {
  yield takeEvery(actions.STOP_SUBSCRIPTION, stopSubscription);
}

export function* restoreSubscription({ subscriptionId }) {
  try {
    const subscription = yield call(API.restoreSubscription, subscriptionId);
    let _customer = yield select(reducers.getUser);
    const { customer } = yield call(_getMarketCustomer, _customer.id);
    yield put(actions.receiveUser(customer));
    yield put(actions.setSubscriptionEditing(subscription));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchRestoreSubscription() {
  yield takeEvery(actions.RESTORE_SUBSCRIPTION, restoreSubscription);
}

export function* cancelSubscription({ subscriptionId }) {
  try {
    const subscription = yield call(API.cancelSubscription, subscriptionId);
    let _customer = yield select(reducers.getUser);
    const { customer } = yield call(_getMarketCustomer, _customer.id);
    yield put(actions.receiveUser(customer));
    yield put(actions.setSubscriptionEditing(subscription));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchCancelSubscription() {
  yield takeEvery(actions.CANCEL_SUBSCRIPTION, cancelSubscription);
}

export function* getSubscriptionEvents({ customerId }) {
  try {
    let eventsData = {};
    let data = [];
    do {
      eventsData = yield call(
        API.getSubscriptionEvents,
        customerId,
        20,
        eventsData.nextToken
      );
      data = [...data, ...eventsData.items];
    } while (eventsData.nextToken);
    yield put(actions.receiveSubscriptionEvents(data));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchGetSubscriptionEvents() {
  yield takeEvery(actions.GET_SUBSCRIPTION_EVENTS, getSubscriptionEvents);
}

export function* getSubscriptionPlansByIds({ ids }) {
  try {
    const plans = yield all(ids.map((id) => call(API.getSubscriptionPlan, id)));
    yield put(actions.receiveSubscriptionPlans(plans));
    yield put(actions.setSubscriptionStatusSucess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSubscriptionStatusFailure(error));
  }
}

export function* watchGetSubscriptionPlansByIds() {
  yield takeEvery(
    actions.GET_SUBSCRIPTION_PLANS_BY_IDS,
    getSubscriptionPlansByIds
  );
}

export function* createOrUpdateTerminal({ terminal }) {
  try {
    let _terminal = _.cloneDeep(terminal);
    let currentTerminal = yield call(API.getTerminal, terminal.id);
    if (!currentTerminal) {
      _terminal = yield call(API.createTerminal, _terminal);
    } else {
      _terminal = yield call(API.updateTerminal, {
        ..._terminal,
        updatedAt: new Date().toISOString(),
      });
    }
    yield put(actions.receiveContractTerminal(_terminal));
    yield put(actions.receiveTerminals([_terminal]));
    yield put(actions.setTerminalStatusSuccesss());
  } catch (error) {
    console.log(error);
    yield put(actions.setTerminalStatusFailure(error));
  }
}

export function* watchCreateOrUpdateTerminal() {
  yield takeEvery(actions.CREATE_OR_UPDATE_TERMINAL, createOrUpdateTerminal);
}

export function* deleteTerminal({ terminalId }) {
  try {
    yield call(API.deleteTerminal, terminalId);
    yield put(actions.receiveDeletedTerminal(terminalId));
    yield put(actions.receiveDeletedContractTerminalId(terminalId));
    yield put(actions.setTerminalStatusSuccesss());
  } catch (error) {
    yield put(actions.setTerminalStatusFailure(error));
  }
}

export function* watchDeleteTerminal() {
  yield takeEvery(actions.DELETE_TERMINAL, deleteTerminal);
}

export function* getTerminals() {
  try {
    const terminals = yield call(_getAllTerminals, {});
    yield put(actions.receiveTerminals(terminals));
    yield put(actions.setTerminalStatusSuccesss());
  } catch (error) {
    yield put(actions.setTerminalStatusFailure(error));
  }
}

export function* watchGetTerminals() {
  yield takeEvery(actions.GET_TERMINALS, getTerminals);
}

export function* getExpectedRecipientNumber({
  storeId,
  locationId,
  locationIds,
  tagId,
  includes,
  filter,
}) {
  try {
    let _filter = [
      ...(locationId
        ? [
            {
              key: "locationId",
              value: locationId || null,
            },
          ]
        : []),
      {
        key: "status",
        value: "registered",
      },
      ...(tagId ? [{ key: "tagId", value: tagId }] : []),
      ...(!_.isEmpty(filter)
        ? [
            {
              key: "registeredAt",
              operation: filter.operation,
              value: filter.value,
            },
          ]
        : []),
    ];
    const count = yield call(
      API.getCustomerCount,
      storeId,
      locationId,
      "registered",
      _filter,
      includes,
      locationIds
    );
    yield put(actions.setExpectedRecipientNumber(count));
    yield put(actions.setGetCampaignSuccess());
  } catch (error) {
    yield put(actions.setGetCampaignFailure(error));
  }
}

export function* watchGetRecipientCount() {
  yield takeEvery(
    actions.GET_EXPECTED_RECIPIENT_NUMBER,
    getExpectedRecipientNumber
  );
}

export function* getRegisteredCustomerCount({ storeId, locationId }) {
  try {
    const count = yield call(
      API.getCustomerCount,
      storeId,
      locationId,
      "registered",
      [
        ...(locationId
          ? [
              {
                key: "locationId",
                value: locationId,
              },
            ]
          : []),
        {
          key: "status",
          value: "registered",
        },
      ]
    );
    yield put(actions.receiveCustomers([], count, false));
    yield put(actions.setGetRegisteredCustomerCountSuccess());
  } catch (error) {
    yield put(actions.setGetRegisteredCustomerCountFailure(error));
  }
}

export function* watchGetRegisteredCustomerCount() {
  yield takeEvery(
    actions.GET_REGISTERED_CUSTOMER_COUNT,
    getRegisteredCustomerCount
  );
}

export function* updateReceiptConfig({ config }) {
  try {
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    if (isMultiLocation) {
      const locationId = yield select(reducers.getCurrentLocationId);
      let location = yield call(API.getStoreLocation, locationId);
      yield call(API.updateLocation, {
        ...location,
        receipt: config,
      });
    } else {
      let store = yield call(API.getStore, storeId);
      yield call(API.updateStore, {
        ...store,
        receipt: config,
      });
    }
    let updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateReceiptConfig", {
      config,
    });
  }
}

export function* watchUpdateReceiptConfig() {
  yield takeEvery(actions.UPDATE_RECEIPT_CONFIG, updateReceiptConfig);
}

export function* updateViewConfig({ config }) {
  try {
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    if (isMultiLocation) {
      const locationId = yield select(reducers.getCurrentLocationId);
      let location = yield call(API.getStoreLocation, locationId);
      yield call(API.updateLocation, {
        ...location,
        view: config,
      });
    } else {
      let store = yield call(API.getStore, storeId);
      yield call(API.updateStore, {
        ...store,
        view: config,
      });
    }
    let updatedStore = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateViewConfig", {
      config,
    });
  }
}

export function* watchUpdateViewConfig() {
  yield takeEvery(actions.UPDATE_VIEW_CONFIG, updateViewConfig);
}

export function* findEmployeeByEmail({ email }) {
  try {
    yield put(actions.receiveSearchedMgmtStores([]));
    const employee = yield call(API.getEmployeeByEmail, email);
    if (employee) {
      if (employee.employeeStoreId) {
        const store = yield call(API.getStore, employee.employeeStoreId);
        yield put(actions.receiveSearchedMgmtStores([{ ...employee, store }]));
      } else {
        const organizations = yield call(
          _getAllOrganizationsByEmployee,
          employee.id
        );
        let searched = [];
        for (let org of organizations) {
          const store = yield call(API.getStore, org.storeId);
          if (org.locationId) {
            const location = yield call(API.getStoreLocation, org.locationId);
            searched = [
              ...searched,
              {
                ...employee,
                store: { ...store, location: location },
              },
            ];
          } else {
            searched = [...searched, store];
          }
        }
        yield put(actions.receiveSearchedMgmtStores(searched));
      }
    }
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
    Analytics.sendErrorReport(error, "findEmployeeByEmail", email);
  }
}

export function* watchFindEmployeeByEmail() {
  yield takeEvery(actions.FIND_EMPLOYEE_BY_EMAIL, findEmployeeByEmail);
}

export function* getMgmtContract({ employee }) {
  try {
    let data = {
      store: employee.store,
      operator: {},
      contract: {},
      terminals: [],
      rentals: [],
    };

    // Owner of store
    if (employee.employeeStoreId) {
      const storeContract = yield call(API.getStoreContract, employee.store.id);
      data.contract = storeContract || {};
      if (data.contract.businessOperatorId) {
        const [operator, terminals, rentals] = yield all([
          API.getBusinessOperator(storeContract.businessOperatorId),
          _getAllTerminals({
            storeId: employee.store.id,
          }),
          _getAllRentalContracts({ storeId: employee.store.id }),
        ]);
        data.operator = operator || {};
        data.terminals = terminals;
        data.rentals = rentals;
      }
      if (employee.store.policy?.multiLocation) {
        const locations = yield call(_getLocations, employee.employeeStoreId);
        yield put(actions.receiveMgmtLocations(locations));
      }
    } else {
      const locationContract = yield call(
        API.getLocationContract,
        employee.store.location.id
      );
      data.contract = locationContract || {};
      if (data.contract.businessOperatorId) {
        const [operator, terminals, rentals] = yield all([
          API.getBusinessOperator(locationContract.businessOperatorId),
          _getAllTerminals({
            locationId: employee.store.location.id,
          }),
          _getAllRentalContracts({ locationId: employee.store.location.id }),
        ]);
        data.operator = operator || {};
        data.terminals = terminals;
        data.rentals = rentals;
      }
    }

    if (data.operator.businessNumber) {
      let _terminals = yield call(_getAllTerminals, {
        businessNumber: data.operator.businessNumber,
      });
      data.terminals = Object.values(
        [...data.terminals, ..._terminals].reduce((obj, t) => {
          obj[t.id] = t;
          return obj;
        }, {})
      );
    }

    console.log("RECEIVED MGMT CONTRACT", data);
    yield put(actions.receiveMgmtContract(data));
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
    Analytics.sendErrorReport(error, "getMgmtContract", employee);
  }
}

export function* watchGetMgmtContract() {
  yield takeEvery(actions.GET_MGMT_CONTRACT, getMgmtContract);
}

export function* clearAllContract() {
  try {
    yield put(actions.clearOperator());
    yield put(actions.clearContract());
    yield put(actions.clearMgmtLocations());
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
  }
}

export function* watchClearAllContract() {
  yield takeEvery(actions.CLEAR_ALL_CONTRACT, clearAllContract);
}

export function* createOrUpdateContract({
  contract,
  operator,
  employee,
  isFranchise,
}) {
  try {
    // TODO: businessNumber 바뀌었을때
    let updatedContract = null;
    let updatedOperator = _.cloneDeep(operator);
    if (!isFranchise) {
      if (!operator.id) {
        updatedOperator = yield call(API.createBusinessOperator, {
          id: hashMd5(operator.businessNumber),
          businessNumber: operator.businessNumber,
          name: operator.name,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      } else {
        let originalOperator = yield call(API.getBusinessOperator, operator.id);
        if (originalOperator.businessNumber !== operator.businessNumber) {
          let terminals = yield call(_getAllTerminals, {
            businessOperatorId: originalOperator.id,
          });
          let rentals = yield call(_getAllRentalContracts, {
            businessOperatorId: originalOperator.id,
          });

          updatedOperator = yield call(
            _updateBusinessNumberForOperation,
            operator,
            terminals,
            rentals
          );
        } else {
          updatedOperator = yield call(API.updateBusinessOperator, {
            ...operator,
            updatedAt: new Date().toISOString(),
          });
        }
      }
    }
    contract = { ...contract, businessOperatorId: updatedOperator.id };
    if (employee.employeeStoreId) {
      const storeContract = yield call(API.getStoreContract, employee.store.id);
      if (!storeContract) {
        updatedContract = yield call(API.createStoreContract, {
          ...contract,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      } else {
        updatedContract = yield call(API.updateStoreContract, {
          ...contract,
          updatedAt: new Date().toISOString(),
        });
      }
    } else {
      const locationContract = yield call(
        API.getLocationContract,
        employee.store.location.id
      );
      if (!locationContract) {
        updatedContract = yield call(API.createLocationContract, contract);
      } else {
        updatedContract = yield call(API.updateLocationContract, contract);
      }
    }
    if (updatedContract) {
      yield put(actions.receiveUpdatedContract(updatedContract));
    }
    yield put(actions.receiveOperator(updatedOperator));
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
  }
}

export function* watchCreateOrUpdateContract() {
  yield takeEvery(actions.CREATE_OR_UPDATE_CONTRACT, createOrUpdateContract);
}

export function* createOrUpdateRentalContract({ rentalContract }) {
  try {
    let updatedContract = _.cloneDeep(rentalContract);
    if (!rentalContract.id) {
      updatedContract = yield call(API.createRentalContract, {
        ...rentalContract,
        id: generateId("rc"),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    } else {
      updatedContract = yield call(API.updateRentalContract, {
        ...rentalContract,
        updatedAt: new Date().toISOString(),
      });
    }
    yield put(actions.receiveRentals([updatedContract]));
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
  }
}

export function* watchCreateOrUpdateRentalContract() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_RENTAL_CONTRACT,
    createOrUpdateRentalContract
  );
}

export function* findStoresByOperatorId({ businessOperatorId }) {
  try {
    let stores = [];
    const storeContracts = yield call(
      API.getStoreContractsByBusinessOperator,
      businessOperatorId,
      null,
      300
    );
    for (let c of storeContracts.items) {
      const store = yield call(API.getStore, c.id);
      stores = [...stores, { employeeStoreId: store.id, store: store }];
    }
    const locationContracts = yield call(
      API.getLocationContractsByBusinessOperator,
      businessOperatorId,
      null,
      300
    );
    for (let c of locationContracts.items) {
      const location = yield call(API.getStoreLocation, c.id);
      const store = yield call(API.getStore, location.storeId);
      stores = [
        ...stores,
        { employeeStoreId: null, store: { ...store, location: location } },
      ];
    }
    yield put(actions.receiveSearchedStoresByOperatorId(stores));
    yield put(actions.setMgmtStatusSuccess());
  } catch (error) {
    yield put(actions.setMgmtStatusFailure(error));
  }
}

export function* watchFindStoresByOperatorId() {
  yield takeEvery(actions.FIND_STORES_BY_OPERATOR_ID, findStoresByOperatorId);
}

export function* createOrUpdateLabelTemplate({ template }) {
  try {
    if (template.id) {
      const updatedTemplate = yield call(API.updateLabelTemplate, {
        ...template,
        updatedAt: new Date().toISOString(),
      });
      yield put(actions.receiveLabelTemplate(updatedTemplate));
    } else {
      const createdTemplate = yield call(API.createLabelTemplate, {
        ...template,
        id: generateId("lbt"),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
      yield put(actions.receiveLabelTemplate(createdTemplate));
    }
    yield put(actions.setTemplateStatusSuccess());
  } catch (error) {
    yield put(actions.setTemplateStatusFailure(error));
  }
}

export function* watchCreateOrUpdateLabelTemplate() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_LABEL_TEMPLATE,
    createOrUpdateLabelTemplate
  );
}

export function* deleteLabelTemplate({ id }) {
  try {
    yield call(API.deleteLabelTemplate, id);
    yield put(actions.receiveDeletedLabelTemplate(id));
    yield put(actions.setTemplateStatusSuccess());
  } catch (error) {
    yield put(actions.setTemplateStatusFailure(error));
  }
}

export function* watchDeleteLabelTemplate() {
  yield takeEvery(actions.DELETE_LABEL_TEMPLATE, deleteLabelTemplate);
}

export function* getLabelTemplates({ storeId }) {
  try {
    const locationId = yield select(reducers.getCurrentLocationId);
    let data = yield call(API.getLabelTemplatesByStore, storeId);
    let templates = data.items;
    templates = templates.filter(
      (template) => template.locationId === locationId || !template.locationId
    );
    yield put(actions.receiveLabelTemplates(templates));
    yield put(actions.setTemplateStatusSuccess());
  } catch (error) {
    yield put(actions.setTemplateStatusFailure(error));
  }
}

export function* watchGetLabelTemplates() {
  yield takeEvery(actions.GET_LABEL_TEMPLATES, getLabelTemplates);
}

export function* updateSettings({ settings }) {
  try {
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    if (isMultiLocation && Authority.level() !== "store") {
      const locationId = yield select(reducers.getCurrentLocationId);
      const location = yield call(API.getStoreLocation, locationId);
      yield call(API.updateLocation, {
        ..._.cloneDeep(location),
        settings: _.cloneDeep(settings),
      });
      const updatedStore = yield call(_getStoreWithLocations, storeId);
      yield put(actions.initStore(updatedStore));
    } else {
      const store = yield call(API.getStore, storeId);
      const updatedStore = yield call(_updateStore, {
        ..._.cloneDeep(store),
        settings: _.cloneDeep(settings),
      });
      yield put(actions.initStore(updatedStore));
    }
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateSettings", { settings });
  }
}

export function* watchUpdateSettings() {
  yield takeEvery(actions.UPDATE_SETTINGS, updateSettings);
}

export function* convertPosMode({ newMode }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const store = yield call(API.getStore, storeId);
    const updatedStore = yield call(_updateStore, {
      ..._.cloneDeep(store),
      ...(newMode === "retail" && store.market?.status === "active"
        ? { market: { ...store.market, status: "inactive" } }
        : {}),
      settings: {
        ...store.settings,
        mode: newMode,
      },
    });
    yield put(actions.initStore(updatedStore));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "convertPosMode", { newMode });
  }
}

export function* watchConvertPosMode() {
  yield takeEvery(actions.CONVERT_POS_MODE, convertPosMode);
}

export function* createInventoryTransferHistory({ history }) {
  try {
    let _history = _.cloneDeep(history);
    const storeId = yield select(reducers.getStoreId);
    const employee = yield select(reducers.getEmployee);
    _history = {
      ..._history,
      storeId,
      employeeId: _history.employeeId || employee.id,
    };
    const origin = yield select(reducers.getRequestProgressEventOrigin);
    API.applyInventoryTransferHistory(_history, origin.name, origin.id);
  } catch (error) {
    yield put(actions.setInventoryStatusFailure(error));
  }
}

export function* watchCreateInventoryTransferHistory() {
  yield takeEvery(
    actions.CREATE_INVENTORY_TRANSFER_HISTORY,
    createInventoryTransferHistory
  );
}

export function* sharePurchaseOrderPdf({ purchaseOrder }) {
  try {
    const skus = yield select(reducers.getSkusById);
    const prodsById = yield select(reducers.getProductsById);

    let header = PurchaseOrderHeader;
    let info = PurchaseOrderInfo;
    let items = PurchaseOrderItems;
    let costInfo = PurchaseOrderCostInfo;
    let _title = "발주서";

    let _html = _.cloneDeep(PurchaseOrderSample);

    let _infoString = _.cloneDeep(info);
    if (_infoString.includes("{{createdAt}}")) {
      _infoString = _infoString.replace(
        "{{createdAt}}",
        purchaseOrder.createdAt
      );
    }
    if (_infoString.includes("{{supplier}}")) {
      _infoString = _infoString.replace("{{supplier}}", purchaseOrder.supplier);
    }
    if (_infoString.includes("{{location}}")) {
      _infoString = _infoString.replace("{{location}}", purchaseOrder.location);
    }
    if (_infoString.includes("{{expectDate}}")) {
      _infoString = _infoString.replace(
        "{{expectDate}}",
        purchaseOrder.expectDate
      );
    }
    if (_infoString.includes("{{receivedAt}}")) {
      _infoString = _infoString.replace(
        "{{receivedAt}}",
        purchaseOrder.receivedAt
      );
    }
    if (_infoString.includes("{{employee}}")) {
      _infoString = _infoString.replace("{{employee}}", purchaseOrder.employee);
    }
    if (_infoString.includes("{{status}}")) {
      _infoString = _infoString.replace("{{status}}", purchaseOrder.status);
    }
    if (_infoString.includes("{{totalCost}}")) {
      _infoString = _infoString.replace(
        "{{totalCost}}",
        numberToCurrency(purchaseOrder.totalCost)
      );
    }

    let _costInfoString = _.cloneDeep(costInfo);
    if (_costInfoString.includes("{{supplyCost}}")) {
      _costInfoString = _costInfoString.replace(
        "{{supplyCost}}",
        numberToCurrency(purchaseOrder.supplyCost)
      );
    }
    if (_costInfoString.includes("{{vat}}")) {
      _costInfoString = _costInfoString.replace(
        "{{vat}}",
        numberToCurrency(purchaseOrder.vat)
      );
    }
    if (_costInfoString.includes("{{totalCost}}")) {
      _costInfoString = _costInfoString.replace(
        "{{totalCost}}",
        numberToCurrency(purchaseOrder.totalCost)
      );
    }

    // NOTE
    let _noteString = _.cloneDeep(NoteSample);
    if (_noteString.includes("{{note}}")) {
      _noteString = _noteString.replace("{{note}}", purchaseOrder.note || "");
    }

    // TITLE
    let _titleString = _.cloneDeep(TitleSample);
    _titleString = _titleString.replace("{{title}}", _title);

    _html = _html.replace("{{info}}", _infoString);
    _html = _html.replace("{{costInfo}}", _costInfoString);
    _html = _html.replace("{{header}}", header);
    _html = _html.replace("{{noteSample}}", _noteString);
    _html = _html.replace("{{titleSample}}", _titleString);

    let _skusString = "";
    for (let item of purchaseOrder.inventories || []) {
      let statementItem = _.cloneDeep(items);
      const sku = skus[item.skuId];
      const product = prodsById[sku.parentId];
      if (statementItem.includes("{{prodName}}")) {
        statementItem = statementItem.replace(
          "{{prodName}}",
          product?.name || ""
        );
      }
      if (statementItem.includes("{{skuName}}")) {
        statementItem = statementItem.replace("{{skuName}}", sku?.name || "");
      }
      if (statementItem.includes("{{unit}}")) {
        statementItem = statementItem.replace("{{unit}}", sku?.unit || "");
      }
      if (statementItem.includes("{{model}}")) {
        statementItem = statementItem.replace("{{model}}", sku?.model || "");
      }
      if (statementItem.includes("{{cost}}")) {
        statementItem = statementItem.replace(
          "{{cost}}",
          numberToCurrency(item?.cost || "")
        );
      }
      if (statementItem.includes("{{quantity}}")) {
        statementItem = statementItem.replace(
          "{{quantity}}",
          item?.quantity || ""
        );
      }
      if (statementItem.includes("{{waitingQuantity}}")) {
        statementItem = statementItem.replace(
          "{{waitingQuantity}}",
          item?.waitingQuantity || ""
        );
      }
      if (statementItem.includes("{{receivedQuantity}}")) {
        statementItem = statementItem.replace(
          "{{receivedQuantity}}",
          item?.receivedQuantity || ""
        );
      }
      if (statementItem.includes("{{totalCost}}")) {
        statementItem = statementItem.replace(
          "{{totalCost}}",
          numberToCurrency(item?.receivedQuantity * item?.cost)
        );
      }

      _skusString += statementItem;
    }
    _html = _html.replace("{{skus}}", _skusString);
    const options = {
      filename: "발주서",
      pagebreak: {
        avoid: "tr",
        mode: "css",
      },
      margin: [5, 0, 7, 0],
    };
    yield html2pdf()
      .set(options)
      .from(_html)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text(
            i + " / " + totalPages,
            pdf.internal.pageSize.getWidth() / 2.1,
            pdf.internal.pageSize.getHeight() - 2
          );
        }
      })
      .save();
    yield put(actions.setSharePdfSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSharePdfFailure(error));
    Analytics.sendErrorReport(error, "sharePurchaseOrderPdf", {
      purchaseOrder,
    });
  }
}

export function* watchSharePurchaseOrderPdf() {
  yield takeEvery(actions.SHARE_PURCHASE_ORDER_PDF, sharePurchaseOrderPdf);
}

export function* shareHistoryPdf({ history, _type = "bulk" }) {
  try {
    const skus = yield select(reducers.getSkusById);
    const prodsById = yield select(reducers.getProductsById);

    let header = null;
    let info = null;
    let items = null;
    let _title = "";
    if (_type === "transfer") {
      header = TransferHeader;
      info = TransferInfo;
      items = TransferItems;
      _title = "지점이동 내역서";
    } else if (_type === "count") {
      header = CountHeader;
      info = CountInfo;
      items = CountItems;
      _title = "재고실사 내역서";
    } else if (_type === "bulk") {
      header = BulkActionHeader;
      info = BulkActionInfo;
      items = BulkActionItems;
      _title = "재고조정 내역서";
    }

    let _html = _.cloneDeep(BasicSample);

    let _infoString = _.cloneDeep(info);
    if (_infoString.includes("{{createdAt}}")) {
      _infoString = _infoString.replace("{{createdAt}}", history.createdAt);
    }
    if (_infoString.includes("{{location}}")) {
      _infoString = _infoString.replace("{{location}}", history.location || "");
    }
    if (_infoString.includes("{{employee}}")) {
      _infoString = _infoString.replace("{{employee}}", history.employee || "");
    }
    if (_infoString.includes("{{fromLocation}}")) {
      _infoString = _infoString.replace(
        "{{fromLocation}}",
        history.fromLocation || ""
      );
    }
    if (_infoString.includes("{{toLocation}}")) {
      _infoString = _infoString.replace(
        "{{toLocation}}",
        history.toLocation || ""
      );
    }
    if (_infoString.includes("{{state}}")) {
      _infoString = _infoString.replace("{{state}}", history.state || "");
    }
    if (_infoString.includes("{{totalCost}}")) {
      _infoString = _infoString.replace(
        "{{totalCost}}",
        history.totalCost || ""
      );
    }
    // NOTE
    let _noteString = _.cloneDeep(NoteSample);
    if (_noteString.includes("{{note}}")) {
      _noteString = _noteString.replace("{{note}}", history.note || "");
    }

    // TITLE
    let _titleString = _.cloneDeep(TitleSample);
    _titleString = _titleString.replace("{{title}}", _title);

    _html = _html.replace("{{info}}", _infoString);
    _html = _html.replace("{{header}}", header);
    _html = _html.replace("{{noteSample}}", _noteString);
    _html = _html.replace("{{titleSample}}", _titleString);

    let _skusString = "";
    for (let item of history.origin.items || []) {
      let statementItem = _.cloneDeep(items);
      const sku = skus[item.skuId];
      const product = prodsById[sku.parentId];
      if (statementItem.includes("{{prodName}}")) {
        statementItem = statementItem.replace(
          "{{prodName}}",
          product?.name || ""
        );
      }
      if (statementItem.includes("{{skuName}}")) {
        statementItem = statementItem.replace("{{skuName}}", sku?.name || "");
      }
      if (statementItem.includes("{{unit}}")) {
        statementItem = statementItem.replace("{{unit}}", sku?.unit || "");
      }
      if (statementItem.includes("{{model}}")) {
        statementItem = statementItem.replace("{{model}}", sku?.model || "");
      }
      if (statementItem.includes("{{quantity}}")) {
        statementItem = statementItem.replace("{{quantity}}", item.quantity);
      }
      if (statementItem.includes("{{reason}}")) {
        statementItem = statementItem.replace(
          "{{reason}}",
          GetInventoryTransactionReason(item.reason)
        );
      }
      if (statementItem.includes("{{cost}}")) {
        statementItem = statementItem.replace(
          "{{cost}}",
          numberToCurrency(item.cost)
        );
      }
      if (statementItem.includes("{{receivedQuantity}}")) {
        statementItem = statementItem.replace(
          "{{receivedQuantity}}",
          item.receivedQuantity || ""
        );
      }
      if (statementItem.includes("{{diff}}")) {
        statementItem = statementItem.replace(
          "{{diff}}",
          item.receivedQuantity - item.quantity
        );
      }
      if (statementItem.includes("{{countDiff}}")) {
        statementItem = statementItem.replace(
          "{{countDiff}}",
          item.currQuantity - item.prevQuantity
        );
      }
      if (statementItem.includes("{{costDiff}}")) {
        statementItem = statementItem.replace(
          "{{costDiff}}",
          numberToCurrency(item.cost)
        );
      }
      if (statementItem.includes("{{prevQuantity}}")) {
        statementItem = statementItem.replace(
          "{{prevQuantity}}",
          item.prevQuantity || ""
        );
      }
      if (statementItem.includes("{{currQuantity}}")) {
        statementItem = statementItem.replace(
          "{{currQuantity}}",
          item.currQuantity || ""
        );
      }
      if (statementItem.includes("{{totalCost}}")) {
        statementItem = statementItem.replace(
          "{{totalCost}}",
          numberToCurrency(item.totalCost)
        );
      }
      _skusString += statementItem;
    }
    _html = _html.replace("{{skus}}", _skusString);
    const options = {
      filename: _title,
      pagebreak: {
        avoid: "tr",
        mode: "css",
      },
      margin: [5, 0, 7, 0],
    };
    yield html2pdf()
      .set(options)
      .from(_html)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(0);
          pdf.text(
            i + " / " + totalPages,
            pdf.internal.pageSize.getWidth() / 2.1,
            pdf.internal.pageSize.getHeight() - 2
          );
        }
      })
      .save();

    yield put(actions.setSharePdfSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setSharePdfFailure(error));
    Analytics.sendErrorReport(error, "shareHistoryPdf", {
      history,
    });
  }
}

export function* watchShareHistoryPdf() {
  yield takeEvery(actions.SHARE_HISTORY_PDF, shareHistoryPdf);
}

export function* shareGroupHistoryPdf({ history, _type }) {
  try {
    const currentLocationId = yield select(reducers.getCurrentLocationId);
    const storeId = yield select(reducers.getStoreId);
    let histories = [];
    let filters = [
      {
        key: "groupId",
        value: history.origin.groupId,
        operation: "eq",
      },
    ];
    if (Authority.level() === "location") {
      filters.push({
        key: "locationId",
        value: currentLocationId,
        operation: "eq",
      });
    }
    let page = 1;
    let _count = 0;
    do {
      const { items, count } = yield call(
        API.getInventoryHistories,
        storeId,
        page,
        300,
        "single",
        filters
      );
      histories = [...histories, ...items];
      _count = count;
      page++;
    } while (_count > histories.length);

    let _history = [];
    _history = {
      ...history,
      origin: {
        ...history.origin,
        items: histories || [],
        histories: histories || [],
      },
    };

    yield put(actions.shareHistoryPdf(_history, _type));
  } catch (error) {
    console.log(error);
    Analytics.sendErrorReport(error, "shareGroupHistoryPdf", {
      history,
      _type,
    });
  }
}

export function* watchShareGroupHistoryPdf() {
  yield takeEvery(actions.SHARE_GROUP_HISTORY_PDF, shareGroupHistoryPdf);
}

export function* updateInventoryHistory({
  historyId,
  quantity,
  cost,
  expiryDate,
}) {
  try {
    const updatedHistory = yield call(
      API.updateInventoryHistory,
      historyId,
      quantity,
      cost,
      expiryDate
    );
    yield put(actions.receiveInventoryHistory(updatedHistory));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "updateInventoryHistory", {
      historyId,
      quantity,
      cost,
      expiryDate,
    });
  }
}

export function* watchUpdateInventoryHistory() {
  yield takeEvery(actions.UPDATE_INVENTORY_HISTORY, updateInventoryHistory);
}

export function* refreshInventoryHistory({ page, size, historyType, filters }) {
  try {
    let histories = [];
    let _page = 1;
    let _count = 0;
    const storeId = yield select(reducers.getStoreId);
    do {
      const { items, count } = yield call(
        API.getInventoryHistories,
        storeId,
        _page,
        size,
        historyType,
        filters
      );
      histories = [...histories, ...items];
      _count = count;
      _page += 1;
    } while (_page <= page);
    yield put(actions.receiveInventoryHistories(histories, _count, true));
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "refreshInventoryHistory", {
      page,
      size,
      historyType,
      filters,
    });
  }
}

export function* watchRefreshInventoryHistory() {
  yield takeEvery(actions.REFRESH_INVENTORY_HISTORY, refreshInventoryHistory);
}

export function* downloadInventoryHistories({ historyType, filters }) {
  try {
    let headers = [];
    let data = [];
    let histories = [];
    let _page = 1;
    let _count = 0;
    let fileName = getHistoryFileNameByType(historyType);

    const locationsById = yield select(reducers.getStoreLocationsById);
    const skusById = yield select(reducers.getSkusById);
    const productsById = yield select(reducers.getProductsById);
    const storeId = yield select(reducers.getStoreId);

    do {
      const { items, count } = yield call(
        API.getInventoryHistories,
        storeId,
        _page,
        100,
        historyType === "group" ? "group" : "single",
        filters
      );
      histories = [...histories, ...items];
      _count = count;
      _page++;
    } while (_count > histories.length);

    let legacyHistories = [];
    const filtersByKey = filters.reduce((obj, filter) => {
      obj[filter.key] = filter;
      return obj;
    }, {});
    const start = filtersByKey["createdAt"].value[0];
    const isLegacy = isLegacyInventoryHistories(start, historyType);
    if (isLegacy) {
      legacyHistories = yield call(_getLegacyInventoryHistories, filtersByKey);
    }
    if (legacyHistories.length) {
      histories = [
        ...histories,
        ...legacyHistories.map((his) => ({ ...his, isLegacy: true })),
      ];
    }
    if (historyType === "group") {
      headers = TABLE_GROUP_HEADERS.map((header) => header.label);
      data = histories.map((history) => [
        moment(history.createdAt).format("YYYY/MM/DD HH:mm"),
        locationsById[history.locationId]?.name,
        GetInventoryTransactionReason(history.reason),
        history.groupHistoryCount,
        history.totalCost,
        history.note,
      ]);
    } else if (historyType === "single") {
      headers = TABLE_HEADERS.map((header) => header.label);
      data = histories
        .map((history) => {
          let skuId = history.skuId;
          // NOTE: for legacy
          if (!skuId && history.isLegacy) {
            let mixedIds = history.id.split("sku_");
            skuId = "sku_" + mixedIds[1];
          }
          let sku = skusById[skuId] || {};
          const prod = productsById[sku?.parentId] || {};
          return [
            moment(history.createdAt).format("YYYY/MM/DD HH:mm"),
            locationsById[history.locationId]?.name,
            prod.name ?? "삭제된 제품",
            sku.name ?? "삭제된 제품",
            sku.model,
            GetInventoryTransactionReason(history.reason),
            Number(history.quantity),
            history.totalCost,
            history.note,
          ];
        })
        .filter((his) => his);
    } else if (historyType === "asset") {
      headers = ASSET_TABLE_HEADER.map((column) => column.label);
      data = histories
        .map((history) => [
          getAssetHistoryReason(history),
          moment(history.createdAt).format(Constants.MOMENT.DATETIME_FORMAT),
          history.totalCost || 0,
          getOriginReason(history.reason),
        ])
        .filter((his) => his);
    }
    downloadCSV([headers, ...data], fileName);
    yield put(actions.setInventoryStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setInventoryStatusFailure(error));
    Analytics.sendErrorReport(error, "downloadInventoryHistories", {
      historyType,
      filters,
    });
  }
}

export function* watchDownloadInventoryHistories() {
  yield takeEvery(
    actions.DOWNLOAD_INVENTORY_HISTORIES,
    downloadInventoryHistories
  );
}

function* _createCouponTrasnactions(
  coupon,
  transactions,
  locationId,
  settings
) {
  const locations = yield select(reducers.getStoreLocations);
  let expiredDate = new Date();
  if (coupon.validDateType === "publish") {
    expiredDate.setDate(new Date().getDate() + Number(coupon.validValue));
  } else {
    expiredDate = new Date(coupon.validValue);
  }
  expiredDate.setHours(23, 59, 59, 999);
  let _transactions = transactions.map((tran) => ({
    id: generateId("cpt"),
    couponTransactionsId: coupon.id,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    state: settings?.state || "generated",
    barcode: tran,
    expiredDate: expiredDate.toISOString(),
    generateLocationId: locationId || null,
    generatedAt: new Date().toISOString(),
    storeId: coupon.storeId,
    locationId: coupon.locationId,
    customerId: settings.customerId || null,
    providerCustomerId: settings.providerCustomerId || null,
    couponTransactionCustomerId: settings.couponTransactionCustomerId || null,
    name: coupon.name,
    code: coupon.code || null,
    type: coupon.type || null,
    method: coupon.method || null,
    conditionType: coupon.conditionType,
    conditionAmount: coupon.conditionAmount,
    conditionItems: coupon.conditionItems,
    conditionTimes: coupon.conditionTimes,
    discountMethod: coupon.discountMethod,
    discountValue: coupon.discountValue,
    campaignId: settings?.campaignId,
    publishableLocationIds:
      coupon.publishableLocationIds || locations.map((loc) => loc.id),
    applyScope: coupon.applyScope,
    applyItemId: coupon.applyItemId,
    startedAt: coupon.startedAt,
    endedAt: coupon.endedAt,
  }));
  let updatedTransactions = [];
  const CHUNK_SIZE = 3000;
  for (let i = 0; i < _transactions.length; i += CHUNK_SIZE) {
    let chunk = _transactions.slice(i, i + CHUNK_SIZE);
    const _updatedTransactions = yield call(
      API.createBarcodeCouponTransactions,
      chunk
    );
    updatedTransactions = [...updatedTransactions, ..._updatedTransactions];
  }
  return updatedTransactions;
}

export function* createCouponTransactions({ coupon, transactions }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    const updatedCouponTransactions = _createCouponTrasnactions(
      coupon,
      transactions,
      organization.locationId
    );
    const updatedCoupon = yield call(API.updateCoupon, {
      ...coupon,
      generateCount:
        (coupon.publishCount || 0) + updatedCouponTransactions.length,
    });
    yield put(actions.receiveCoupon(updatedCoupon));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "createCouponTransactions", {
      coupon,
      transactions,
    });
  }
}

export function* watchCreateCouponTransactions() {
  yield takeEvery(actions.CREATE_COUPON_TRANSACTIONS, createCouponTransactions);
}

export function* downloadCsvForTransactions({ coupon }) {
  try {
    if (coupon.publishCount > 1000) {
      yield delay(200);
      yield put(actions.setCouponStatusFailure(new Error("TooManyData")));
      return;
    } else {
      let transactions = [];
      transactions = yield call(API.getBarcodeCouponTransactions, coupon.id);
      let headers = [
        "쿠폰 이름",
        "사용 지점",
        "사용일시",
        "바코드",
        "고객 이름",
        "고객 전화번호",
        "사용 여부",
        "할인된 금액",
      ];
      let csvData = [];
      for (let transaction of transactions || []) {
        let row = [
          transaction.couponName,
          transaction.locationName,
          transaction.usedAt
            ? moment(transaction.usedAt).format("YYYY/MM/DD HH:mm")
            : "",
          transaction.barcode,
        ];
        row.push(
          ...[
            maskingName(transaction.customerName),
            maskingPhoneNumber(transaction.phone),
          ]
        );
        row.push(transaction.state === "used" ? "사용" : "미사용");
        row.push(
          transaction.amount ? `${numberToCurrency(transaction.amount)}` : ""
        );
        csvData.push(row);
      }
      downloadCSV([headers, ...csvData], "사용 데이터");
    }
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "downloadCsvForTransactions", {
      coupon,
    });
  }
}

export function* watchDownloadCsvForTransactions() {
  yield takeEvery(
    actions.DOWNLOAD_CSV_FOR_TRANSACTIONS,
    downloadCsvForTransactions
  );
}

function* _getBarcodeCoupon(couponId) {
  let coupon = yield call(API.getCouponByApiKey, couponId);
  if (coupon.storeId) {
    const store = yield call(API.getStoreByApiKey, coupon.storeId);
    let locations = [];
    let data = {};
    do {
      data = yield call(
        API.getStoreLocationsByApiKey,
        store.id,
        data.nextToken,
        200
      );
      locations = [...locations, ...data.items];
    } while (data.nextToken);
    yield put(
      actions.initStore({
        ...store,
        locations: {
          items: locations,
        },
      })
    );
  }
  yield put(actions.receiveCoupon(coupon));
  if (coupon.referId) {
    const product = yield call(API.getItemByApiKey, coupon.referId);
    yield put(
      actions.receiveItem({
        ...product,
        type: "product",
      })
    );
  }
  return coupon;
}

export function* getBarcodeCoupon({ couponId, locationId }) {
  try {
    yield _getBarcodeCoupon(couponId, locationId);
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getBarcodeCoupon", {
      couponId,
      locationId,
    });
  }
}

export function* watchGetBarcodeCoupon() {
  yield takeEvery(actions.GET_BARCODE_COUPON, getBarcodeCoupon);
}

export function* getBarcodeCouponTransaction({ transactionId }) {
  try {
    const transaction = yield call(
      API.getCouponTransactionByApiKey,
      transactionId
    );
    yield put(actions.receiveBarcodeCouponTransaction(transaction));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getCouponTransaction", {
      transactionId,
    });
  }
}

export function* watchGetBarcodeCouponTransaction() {
  yield takeEvery(
    actions.GET_BARCODE_COUPON_TRANSACTION,
    getBarcodeCouponTransaction
  );
}

export function* getBarcodeCoupons({ storeId, override }) {
  try {
    const organization = yield select(reducers.getCurrentOrganization);
    const coupons = yield call(API.getCouponsWithStats, storeId, [
      { key: "method", value: "barcode", operation: "eq" },
    ]);
    const filteredCoupons = coupons.filter((coupon) =>
      isCurrentLocationAvailableCoupon(coupon, organization.locationId)
    );
    yield put(actions.receiveBarcodeCoupons(filteredCoupons, override));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getBarcodeCoupons", {
      storeId,
    });
  }
}

export function* watchGetBarcodeCoupons() {
  yield takeEvery(actions.GET_BARCODE_COUPONS, getBarcodeCoupons);
}

export function* getBarcodeCouponTransactionByPhone({
  storeId,
  locationId,
  couponId,
  phone,
}) {
  try {
    const barcodeCouponsById = yield select(reducers.getBarcodeCouponsById);
    const originalCoupon = barcodeCouponsById[couponId];
    const globalCustomerId = hashMd5(phone);
    const customers = yield call(
      API.getCustomers,
      globalCustomerId,
      null,
      null,
      null,
      null,
      1,
      1
    );
    let customer = {};
    const storeCustomers = (customers || []).filter(
      (c) => c.storeId === storeId
    );

    let couponTransaction = null;
    let _customer = null;

    if (
      storeCustomers.filter((c) => c.status === "registered").length > 0 ||
      storeCustomers.filter(
        (c) =>
          c.status === "anonymous" && c.locationId === originalCoupon.locationId
      ).length > 0
    ) {
      if (
        storeCustomers.filter((c) => c.status === "registered").length === 0 &&
        storeCustomers.filter(
          (c) =>
            c.status === "anonymous" &&
            c.locationId === originalCoupon.locationId
        ).length > 0
      ) {
        const _anonymousCustomer = storeCustomers.filter(
          (c) =>
            c.status === "anonymous" &&
            c.locationId === originalCoupon.locationId
        )[0];
        _customer = yield call(API.updateCustomer, {
          ..._anonymousCustomer,
          customerId: globalCustomerId,
          status: "registered",
          registeredAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      }
      const data = yield call(
        API.getCouponTransactionsByStoreCustomerByApi,
        couponId,
        null,
        { customerId: { eq: globalCustomerId } },
        500
      );
      const transactions = data.items || [];
      const publishedTransactions =
        transactions.filter((item) => item.state === "published") || [];
      const usedTransactions =
        transactions.filter((item) => item.state === "used") || [];
      const refundedTransactions =
        transactions.filter((item) => item.state === "refunded") || [];
      if (publishedTransactions.length > 0) {
        couponTransaction = _.sortBy(publishedTransactions, "expiredDate")[0];
      } else if (refundedTransactions.length > 0) {
        couponTransaction = _.sortBy(refundedTransactions, "expiredDate")[0];
      } else if (
        usedTransactions.length > 0 &&
        !originalCoupon.duplicatePublish
      ) {
        couponTransaction = _.sortBy(usedTransactions, "expiredDate")[0];
      } else {
        let issuedBarcodes = [];
        let tempBarcode = null;
        do {
          tempBarcode = generateBarcode();
          issuedBarcodes = yield call(
            API.getCouponTransactionsByStoreBarcode,
            storeId,
            tempBarcode
          );
        } while (issuedBarcodes.length === 0);
        let createdTransactions = [];
        const registeredCustomer =
          storeCustomers.filter((c) => c.status === "registered")[0] ||
          _customer;
        createdTransactions = yield _createCouponTrasnactions(
          originalCoupon,
          [tempBarcode],
          null,
          {
            state: "published",
            customerId: globalCustomerId,
            couponTransactionCustomerId: registeredCustomer.id,
          }
        );
        couponTransaction = createdTransactions[0];
      }
    } else {
      _customer = yield call(API.createCustomer, {
        ...(yield _buildBaseCustomer({ ...customer, phone })),
        status: "registered",
        id: generateId("cst"),
        registeredAt: new Date().toISOString(),
        ...(originalCoupon.locationId
          ? { locationId: originalCoupon.locationId }
          : {}),
        storeId,
      });
      let issuedBarcodes = [];
      let tempBarcode = null;
      do {
        tempBarcode = generateBarcode();
        issuedBarcodes = yield call(
          API.getCouponTransactionsByStoreBarcode,
          storeId,
          tempBarcode
        );
      } while (issuedBarcodes.length === 0);
      let createdTransactions = [];
      createdTransactions = yield _createCouponTrasnactions(
        originalCoupon,
        [tempBarcode],
        null,
        {
          state: "published",
          customerId: globalCustomerId,
          couponTransactionCustomerId: _customer.id,
        }
      );
      couponTransaction = createdTransactions[0];
    }

    yield put(actions.receiveBarcodeCouponTransaction(couponTransaction));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getBarcodeCoupons", {
      storeId,
    });
  }
}

export function* watchGetBarcodeCouponTransactionByPhone() {
  yield takeEvery(
    actions.GET_BARCODE_COUPOON_TRANSACTION_BY_PHONE,
    getBarcodeCouponTransactionByPhone
  );
}

export function* issueBarcodeCouponForProviderCustomer({
  customer,
  phone,
  couponId,
}) {
  try {
    const barcodeCouponsById = yield select(reducers.getBarcodeCouponsById);
    const originalCoupon = barcodeCouponsById[couponId];
    const storeId = yield select(reducers.getStoreId);
    const storeProvider = yield select(reducers.getStoreProvider);
    const storeCustomerData = yield call(
      API.getLocationCustomersByOriginId,
      customer.customerId,
      {
        eq: storeId,
      },
      null,
      null,
      {
        _delta: {
          ne: "DELETE",
        },
        status: {
          eq: "registered",
        },
      }
    );
    let couponTransaction = null;
    const storeCustomer = storeCustomerData?.items[0];
    if (!_.isEmpty(storeCustomer)) {
      const data = yield call(
        API.getCouponTransactionsByStoreCustomerByApi,
        couponId,
        null,
        { customerId: { eq: storeCustomer.id } },
        500
      );
      const transactions = data.items || [];
      const publishedTransactions =
        transactions.filter((item) => item.state === "published") || [];
      const usedTransactions =
        transactions.filter((item) => item.state === "used") || [];
      const refundedTransactions =
        transactions.filter((item) => item.state === "refunded") || [];
      if (publishedTransactions.length > 0) {
        couponTransaction = _.sortBy(publishedTransactions, "expiredDate")[0];
      } else if (refundedTransactions.length > 0) {
        couponTransaction = _.sortBy(refundedTransactions, "expiredDate")[0];
      } else if (
        usedTransactions.length > 0 &&
        !originalCoupon.duplicatePublish
      ) {
        couponTransaction = _.sortBy(usedTransactions, "expiredDate")[0];
      } else {
        let issuedBarcodes = [];
        let tempBarcode = null;
        do {
          tempBarcode = generateBarcode();
          issuedBarcodes = yield call(
            API.getCouponTransactionsByStoreBarcode,
            storeId,
            tempBarcode
          );
        } while (issuedBarcodes.length === 0);
        let createdTransactions = [];
        createdTransactions = yield _createCouponTrasnactions(
          originalCoupon,
          [tempBarcode],
          null,
          {
            state: "published",
            customerId: customer.customerId,
            couponTransactionCustomerId: storeCustomer.id,
            providerCustomerId:
              storeCustomer.identType === "user" && storeCustomer.ident
                ? customer.ident.split("|")[2]
                : null,
          }
        );
        couponTransaction = createdTransactions[0];
      }
    } else {
      let createdCustomer = yield call(API.createCustomer, {
        ...(yield _buildBaseOutsourcedCustomer(
          { ...customer, phone },
          storeProvider
        )),
        status: "registered",
        id: generateId("cst"),
        registeredAt: new Date().toISOString(),
        ...(originalCoupon.locationId
          ? { locationId: originalCoupon.locationId }
          : { locationId: yield select(reducers.getCurrentLocationId) }),
        storeId,
      });
      let issuedBarcodes = [];
      let tempBarcode = null;
      do {
        tempBarcode = generateBarcode();
        issuedBarcodes = yield call(
          API.getCouponTransactionsByStoreBarcode,
          storeId,
          tempBarcode
        );
      } while (issuedBarcodes.length === 0);
      let createdTransactions = [];
      createdTransactions = yield _createCouponTrasnactions(
        originalCoupon,
        [tempBarcode],
        null,
        {
          state: "published",
          customerId: createdCustomer.customerId,
          couponTransactionCustomerId: createdCustomer.id,
        }
      );
      couponTransaction = createdTransactions[0];
    }

    yield put(actions.receiveBarcodeCouponTransaction(couponTransaction));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "issueBarcodeCouponForProviderCustomer", {
      phone,
    });
  }
}

export function* watchIssueBarcodeCouponForProviderCustomer() {
  yield takeEvery(
    actions.ISSUE_BARCODE_COUPON_FOR_PROVIDER_CUSTOMER,
    issueBarcodeCouponForProviderCustomer
  );
}

export function* issueBarcodeCouponWithStoreCustomerId({
  storeCustomerId,
  couponId,
  locationId,
  campaignId,
}) {
  try {
    const barcodeCouponsById = yield select(reducers.getBarcodeCouponsById);
    let originalCoupon = null;
    if (!_.isEmpty(barcodeCouponsById)) {
      originalCoupon = barcodeCouponsById[couponId];
    } else {
      originalCoupon = yield _getBarcodeCoupon(couponId);
    }
    const storeCustomer = yield call(
      API.getLocationCustomerWithStoreCustomer,
      storeCustomerId
    );
    let couponTransaction = null;
    if (!_.isEmpty(storeCustomer)) {
      const data = yield call(
        API.getCouponTransactionsByStoreCustomerByApi,
        couponId,
        null,
        { customerId: { eq: storeCustomer.customerId } },
        500
      );
      const transactions = data.items || [];
      const publishedTransactions =
        transactions.filter((item) => item.state === "published") || [];
      const usedTransactions =
        transactions.filter((item) => item.state === "used") || [];
      const refundedTransactions =
        transactions.filter((item) => item.state === "refunded") || [];
      if (publishedTransactions.length > 0) {
        couponTransaction = _.sortBy(publishedTransactions, "expiredDate")[0];
      } else if (refundedTransactions.length > 0) {
        couponTransaction = _.sortBy(refundedTransactions, "expiredDate")[0];
      } else if (
        usedTransactions.length > 0 &&
        !originalCoupon.duplicatePublish
      ) {
        couponTransaction = _.sortBy(usedTransactions, "expiredDate")[0];
      } else {
        let issuedBarcodes = [];
        let tempBarcode = null;
        do {
          tempBarcode = generateBarcode();
          issuedBarcodes = yield call(
            API.getCouponTransactionsByStoreBarcode,
            storeCustomer.storeId,
            tempBarcode
          );
        } while (issuedBarcodes.length === 0);
        let createdTransactions = [];
        createdTransactions = yield _createCouponTrasnactions(
          originalCoupon,
          [tempBarcode],
          null,
          {
            state: "published",
            customerId: storeCustomer.customerId,
            couponTransactionCustomerId: storeCustomer.id,
            providerCustomerId:
              storeCustomer.identType === "user" && storeCustomer.ident
                ? storeCustomer.ident.split("|")[2]
                : null,
            campaignId: campaignId,
          }
        );
        couponTransaction = createdTransactions[0];
      }
    }
    yield put(actions.receiveBarcodeCouponTransaction(couponTransaction));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "issueBarcodeCouponWithStoreCustomerId", {
      storeCustomerId,
      couponId,
      locationId,
    });
  }
}

export function* watchIssueBarcodeCouponWithStoreCustomerId() {
  yield takeEvery(
    actions.ISSUE_BARCODE_COUPON_WITH_STORE_CUSTOMER_ID,
    issueBarcodeCouponWithStoreCustomerId
  );
}

export function* dropoutAccount({}) {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield call(API.deleteUser, user.attributes.sub);
    Auth.signOut();
    yield put(actions.setDropoutSuccess());
  } catch (error) {
    console.log("dropoutAccount error: ", error);
    yield put(actions.setDropoutFailure(error));
    Analytics.sendErrorReport(error, "dropoutAccount", {});
  }
}

export function* watchDropoutAccount() {
  yield takeEvery(actions.DROPOUT_ACCOUNT, dropoutAccount);
}

export function* syncExternalLoyalty({ storeId }) {
  try {
    const loyalty = yield call(API.getExternalLoyaltyConfig, storeId, "cafe24");
    let store = yield call(API.getStore, storeId);
    yield call(API.updateStore, {
      ...store,
      policy: {
        ...(store.policy || {}),
        provider: {
          ...(store.policy?.provider || {}),
        },
      },
      loyalty,
    });
    const data = yield call(API.getProviderCustomerGroups, storeId);
    const updatedGroups = data.items || [];
    const config = yield call(API.getProviderConfig, storeId);
    let existingTiersByTierId = _.cloneDeep(
      config.autoTier?.tiers || []
    ).reduce((obj, t) => {
      obj[t.tierId] = t;
      return obj;
    }, {});
    for (let g of updatedGroups) {
      if (existingTiersByTierId[g.id]) {
        existingTiersByTierId[g.id] = {
          ...existingTiersByTierId[g.id],
          name: g.name,
        };
      } else {
        existingTiersByTierId[g.id] = {
          tierId: g.id,
          name: g.name,
          coupons: [],
          minAmount: 0,
          maxAmount: null,
        };
      }
    }
    const updatedIds = updatedGroups.map((g) => g.id);
    for (let tier of Object.values(existingTiersByTierId)) {
      if (!updatedIds.includes(tier.tierId)) {
        delete existingTiersByTierId[tier.tierId];
      }
    }
    const updatedConfig = yield call(API.updateProviderConfig, {
      ...config,
      autoTier: {
        ...(config?.autoTier || {}),
        active: config?.autoTier?.active || false,
        interval: config?.autoTier?.interval || "month",
        intervalValue: config?.autoTier?.intervalValue || 1,
        orderPeriod: config?.autoTier?.orderPeriod || 12,
        tiers: Object.values(existingTiersByTierId),
      },
      updatedAt: new Date().toISOString(),
    });
    yield call(_syncDiscountsByTier, storeId, updatedGroups);
    yield put(actions.receiveProviderCustomerGroups(updatedGroups));
    yield put(actions.receiveProviderConfig(updatedConfig));
    store = yield call(_getStoreWithLocations, storeId);
    yield put(actions.initStore(store));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.log("sync External Loyalty: ", error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "syncExternalLoyalty", { storeId });
  }
}

export function* watchSyncExternalLoyalty() {
  yield takeEvery(actions.SYNC_EXTERNAL_LOYALTY, syncExternalLoyalty);
}

export function* getProviderCoupons({ storeId }) {
  try {
    const coupons = yield call(API.getProviderCoupons, storeId);
    yield put(actions.receiveProviderCoupons(coupons));
    yield put(actions.setCouponStatusSuccess());
  } catch (error) {
    console.log("get provider coupons: ", error);
    yield put(actions.setCouponStatusFailure(error));
    Analytics.sendErrorReport(error, "getProviderCoupons", { storeId });
  }
}

export function* watchGetProviderCoupons() {
  yield takeEvery(actions.GET_PROVIDER_COUPONS, getProviderCoupons);
}

export function* updateProviderConfig({ updater }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const config = yield call(API.getProviderConfig, storeId);
    const updatedConfig = yield call(API.updateProviderConfig, {
      ...config,
      ...updater,
      updatedAt: new Date().toISOString(),
    });
    const isActivated =
      updater.autoTier && updater.autoTier.active && !config.autoTier?.active;
    const isOrderPeriodUpdated =
      updater.autoTier &&
      updater.autoTier.active &&
      config.autoTier?.active &&
      updater.autoTier.orderPeriod > (config.autoTier?.orderPeriod || 0);
    const needSyncOrders = isActivated || isOrderPeriodUpdated;
    if (needSyncOrders) {
      const launchDate = updater.autoTier.launchDate;
      const startDate = new Date(
        new Date(launchDate).setMonth(
          new Date(launchDate).getMonth() - updater.autoTier.orderPeriod
        )
      );
      startDate.setHours(0, 0, 0, 0);
      API.syncProviderOrders(storeId, startDate.toISOString());
    }
    yield put(actions.receiveProviderConfig(updatedConfig));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "updateProviderConfig", { updater });
  }
}

export function* watchUpdateProviderConfig() {
  yield takeEvery(actions.UPDATE_PROVIDER_CONFIG, updateProviderConfig);
}

export function* getProviderConfig({ storeId }) {
  try {
    const config = yield call(API.getProviderConfig, storeId);
    yield put(actions.receiveProviderConfig(config || {}));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getProviderConfig", { storeId });
  }
}

export function* watchGetProviderConfig() {
  yield takeEvery(actions.GET_PROVIDER_CONFIG, getProviderConfig);
}

export function* updateStoreProvider({ provider }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    if (provider) {
      let store = yield call(API.getStore, storeId);
      const updatedStore = yield call(_updateStore, {
        ..._.cloneDeep(store),
        policy: {
          ...(store.policy || {}),
          provider: provider,
        },
      });
      yield put(actions.initStore(updatedStore));
    }
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateStoreProvider", { storeId });
  }
}

export function* watchUpdateStoreProvider() {
  yield takeEvery(actions.UPDATE_STORE_PROVIDER, updateStoreProvider);
}

export function* getProviderCustomerGroups({ storeId }) {
  try {
    const data = yield call(API.getProviderCustomerGroups, storeId);
    yield put(actions.receiveProviderCustomerGroups(data?.items));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getProviderCustomerGroups", { storeId });
  }
}

export function* watchGetProviderCustomerGroups() {
  yield takeEvery(
    actions.GET_PROVIDER_CUSTOMER_GROUPS,
    getProviderCustomerGroups
  );
}

export function* getTierUpdateHistories({ storeId }) {
  try {
    let data = [];
    let histories = [];
    do {
      data = yield call(
        API.getTierUpdateHistories,
        storeId,
        null,
        data.nextToken,
        100
      );
      histories = [...histories, ...data.items];
    } while (data.nextToken);
    yield put(actions.receiveTierUpdateHistories(histories));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getTierUpdateHistories", { storeId });
  }
}

export function* watchGetTierUpdateHistories() {
  yield takeEvery(actions.GET_TIER_UPDATE_HISTORIES, getTierUpdateHistories);
}

export function* linkProviderProducts({ options, excludeKeys, pairs }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    if (options) {
      delete options.method;
      if (options.start) {
        options.start = new Date(options.start).toISOString();
      }
    }
    const employee = yield select(reducers.getEmployee);
    API.linkProviderProducts(storeId, options, pairs, excludeKeys, employee.id);
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
  }
}

export function* watchLinkProviderProducts() {
  yield takeEvery(actions.LINK_PROVIDER_PRODUCTS, linkProviderProducts);
}

export function* unlinkProviderProducts({ skuIds }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    yield call(API.unlinkProviderProducts, storeId, skuIds);
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "unlinkProviderProducts", {
      storeId,
      skuIds,
    });
  }
}

export function* watchUnlinkProviderProducts() {
  yield takeEvery(actions.UNLINK_PROVIDER_PRODUCTS, unlinkProviderProducts);
}

export function* getProviderProducts({ search, searchType, isLoadMore }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    let data = [];
    const size = 100;
    const providerProducts = yield select(reducers.getProductsFromProvider);
    let since = null;
    if (isLoadMore) {
      since = providerProducts[providerProducts.length].referId;
    }
    let filters = [
      {
        key: "linked",
        operation: "eq",
        value: false,
      },
      {
        key: "available",
        operation: "eq",
        value: true,
      },
    ];
    data = yield call(
      API.getProviderProducts,
      storeId,
      size,
      since,
      search,
      searchType,
      filters
    );
    yield put(
      actions.receiveProviderProducts(...(providerProducts || []), data.items)
    );
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getProviderProducts", { storeId });
  }
}

export function* watchGetProviderProducts() {
  yield takeEvery(actions.GET_PROVIDER_PRODUCTS, getProviderProducts);
}

export function* syncProviderInventories({ search, searchType, isLoadMore }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    const employee = yield select(reducers.getEmployee);
    API.syncProviderInventories(storeId, employee.id);
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
  }
}

export function* watchSyncProviderInventories() {
  yield takeEvery(actions.SYNC_PROVIDER_INVENTORIES, syncProviderInventories);
}

export function* getInventorySyncHistories({ filters }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    const size = 20;
    let histories = [];
    let data = {};
    let page = 1;
    do {
      data = yield call(
        API.getInventorySyncHistories,
        storeId,
        page++,
        size,
        filters
      );
      histories = [...histories, ...data.items];
    } while (data.count > histories.length);
    yield put(actions.receiveInventorySyncHistories(histories));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getInventorySyncHistories", { storeId });
  }
}

export function* watchGetInventorySyncHistories() {
  yield takeEvery(
    actions.GET_INVENTORY_SYNC_HISTORIES,
    getInventorySyncHistories
  );
}

export function* getProviderProductsLinkHistories({ start, end }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    const size = 20;
    let histories = [];
    let data = {};
    do {
      data = yield call(
        API.getProviderProductsLinkHistories,
        storeId,
        { between: [start, end] },
        null,
        size,
        data.nextToken
      );
      histories = [...histories, ...data.items];
    } while (data.nextToken);
    yield put(actions.receiveLinkHistories(histories));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getProviderProductsLinkHistories", {
      storeId,
    });
  }
}

export function* watchGetProviderProductsLinkHistories() {
  yield takeEvery(
    actions.GET_PROVIDER_PRODUCTS_LINK_HISTORIES,
    getProviderProductsLinkHistories
  );
}

export function* syncProviderProducts({ skuIds, excludeKeys }) {
  const storeId = yield select(reducers.getStoreId);
  try {
    yield call(API.syncProviderProducts, storeId, skuIds, excludeKeys);
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "syncProviderProducts", {
      storeId,
      excludeKeys,
    });
  }
}

export function* watchSyncProviderProducts() {
  yield takeEvery(actions.SYNC_PROVIDER_PRODUCTS, syncProviderProducts);
}

export function* getWaiting({ waitingId }) {
  try {
    const waiting = yield call(API.getWaitingDelta, waitingId);
    if (waiting.storeId && waiting.locationId) {
      const store = yield call(API.getStoreByApiKey, waiting.storeId);
      const location = yield call(API.getLocationByApiKey, waiting.locationId);
      const info = yield call(
        API.getWaitingInfo,
        waiting.storeId,
        waiting.locationId,
        location.state === "opened" ? location.sales?.startedAt : null,
        waitingId
      );
      yield put(
        actions.initStore({
          ...store,
          locations: {
            items: [
              store.policy?.multiLocation && waiting.locationId ? location : {},
            ],
          },
        })
      );
      yield put(actions.receiveWaitingInfo(info));
      yield put(actions.receiveWaiting(waiting));
      yield put(actions.setWaitingStatusSuccess());
    }
  } catch (error) {
    yield put(actions.setWaitingStatusFailure(error));
    Analytics.sendErrorReport(error, "getWaitingDelta", { waitingId });
  }
}

export function* watchGetWaiting() {
  yield takeEvery(actions.GET_WAITING, getWaiting);
}

export function* getWaitingInfo({ storeId, locationId, waitingId }) {
  try {
    if (storeId && locationId) {
      const location = yield call(API.getLocationByApiKey, locationId);
      const info = yield call(
        API.getWaitingInfo,
        storeId,
        locationId,
        (location.state === "opened" && location.sales?.startedAt) ?? null,
        waitingId
      );
      yield put(actions.receiveWaitingInfo(info));
      yield put(actions.setWaitingStatusSuccess());
    } else {
      yield delay(100);
      yield put(actions.setWaitingStatusFailure("no storeId"));
    }
  } catch (error) {
    yield put(actions.setWaitingStatusFailure(error));
    Analytics.sendErrorReport(error, "getWaitingInfo", { storeId, locationId });
  }
}

export function* watchGetWaitingInfo() {
  yield takeEvery(actions.GET_WAITING_INFO, getWaitingInfo);
}

export function* cancelWaiting({ waiting }) {
  try {
    if (waiting.id) {
      yield call(API.cancelWaiting, {
        ...waiting,
        updatedAt: new Date().toISOString(),
      });
      yield put(actions.clearWaiting());
      yield put(actions.setWaitingStatusSuccess());
    }
  } catch (error) {
    yield put(actions.setWaitingStatusFailure(error));
    Analytics.sendErrorReport(error, "cancelWaiting", { waiting });
  }
}

export function* watchCancelWaiting() {
  yield takeEvery(actions.CANCEL_WAITING, cancelWaiting);
}

export function* updateCreditConfig({ config }) {
  try {
    const isMultiLocation = yield select(reducers.isMultiLocation);
    const storeId = yield select(reducers.getStoreId);
    if (isMultiLocation && Authority.level() !== "store") {
      const locationId = yield select(reducers.getCurrentLocationId);
      const location = yield call(API.getStoreLocation, locationId);
      yield call(API.updateLocation, {
        ..._.cloneDeep(location),
        credit: _.cloneDeep(config),
      });
      const updatedStore = yield call(_getStoreWithLocations, storeId);
      yield put(actions.initStore(updatedStore));
    } else {
      const store = yield call(API.getStore, storeId);
      const updatedStore = yield call(_updateStore, {
        ..._.cloneDeep(store),
        credit: _.cloneDeep(config),
      });
      yield put(actions.initStore(updatedStore));
    }
    yield put(actions.setStoreSuccess());
  } catch (error) {
    console.error(error);
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "updateSettings", { config });
  }
}

export function* watchUpdateCreditConfig() {
  yield takeEvery(actions.UPDATE_CREDIT_CONFIG, updateCreditConfig);
}

export function* getCreditStatus({}) {
  const storeId = yield select(reducers.getStoreId);
  const hasMulti = yield select(reducers.isMultiLocation);
  const locationId = yield select(reducers.getCurrentLocationId);
  const hasLocationId =
    hasMulti && Authority.level() !== "store" ? locationId : null;
  try {
    const _receivedCreditStatus = yield call(
      API.getCreditStatus,
      storeId,
      hasLocationId
    );
    yield put(actions.receiveCreditStatus(_receivedCreditStatus.balance));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "getCreditStatus", {
      storeId,
      locationId,
    });
  }
}

export function* watchGetCreditStatus() {
  yield takeEvery(actions.GET_CREDIT_STATUS, getCreditStatus);
}

export function* getCreditHistories({ page, size, filters, override }) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const hasMulti = yield select(reducers.isMultiLocation);
    const locationId = yield select(reducers.getCurrentLocationId);
    const hasLocationId =
      hasMulti && Authority.level() !== "store" ? locationId : null;
    let totalItems = [];
    let totalCount = 0;

    let { items, count } = yield call(
      API.getCreditTransactionHistories,
      storeId,
      page,
      size,
      hasLocationId,
      filters
    );

    totalItems = items;
    totalCount = count;
    yield put(actions.receiveCreditHistories(totalItems, totalCount, override));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "getCreditHistories", {
      page,
      filters,
      override,
    });
  }
}

export function* watchGetCreditHistories() {
  yield takeEvery(actions.GET_CREDIT_HISTORIES, getCreditHistories);
}

export function* chargeCredit({ amount, quantity }) {
  const storeId = yield select(reducers.getStoreId);
  const hasMulti = yield select(reducers.isMultiLocation);
  const locationId = yield select(reducers.getCurrentLocationId);
  const hasLocationId =
    hasMulti && Authority.level() !== "store" ? locationId : null;
  const currentCredit = yield select(reducers.getStoreCreditStatus);
  try {
    const history = yield call(
      API.chargeCredit,
      storeId,
      amount,
      quantity,
      hasLocationId
    );
    yield put(actions.receiveCreditStatus(Math.ceil(currentCredit + quantity)));
    yield put(actions.setStoreSuccess());
  } catch (error) {
    yield put(actions.setStoreFailure(error));
    Analytics.sendErrorReport(error, "chargeCredit", {
      storeId,
      amount,
      locationId,
    });
  }
}

export function* watchChargeCredit() {
  yield takeEvery(actions.CHARGE_CREDIT, chargeCredit);
}

export function* getRequestProgressEvents({ originId }) {
  try {
    console.log("getRequestProgressEvents", originId);
    const date = new Date();
    date.setMinutes(date.getMinutes() - 60);
    let data = {};
    let items = [];
    do {
      data = yield call(
        API.getRequestProgressEventsByOrigin,
        originId,
        { gt: date.toISOString() },
        { origin: { eq: "dashboard" }, status: { eq: "ongoing" } },
        data.nextToken
      );
      items = [...items, ...data.items];
    } while (data.nextToken);
    if (items.length > 0) {
      yield put(actions.receiveRequestProgressEvents(items));
    }
  } catch (error) {
    Analytics.sendErrorReport(error, "getRequestProgressEvents", {
      originId,
    });
  }
}

export function* watchGetRequestProgressEvents() {
  yield takeEvery(
    actions.GET_REQUEST_PROGRESS_EVENTS,
    getRequestProgressEvents
  );
}

export function* receiveRequestProgressEvent({ event }) {
  try {
    switch (event.func) {
      case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_TRANSFER_HISTORY:
      case Constants.ASYNC_PROGRESS_FUNC.CANCEL_INVENTORY_TRANSFER_HISTORY:
      case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_COUNT_HISTORY:
        if (event.errorMessage) {
          yield put(
            actions.setInventoryStatusFailure(Error(event.errorMessage))
          );
        } else if (event.progress === 0) {
          yield put(actions.setInventoryStatusSuccess());
        }
        break;
      case Constants.ASYNC_PROGRESS_FUNC.CANCEL_PURCHASE_ORDER:
      case Constants.ASYNC_PROGRESS_FUNC.APPLY_PURCHASE_ORDER:
        if (event.errorMessage) {
          yield put(
            actions.setPurchaseOrderStatusFailure(Error(event.errorMessage))
          );
        } else if (event.progress === 0) {
          yield put(actions.setPurchaseOrderStatusSuccess());
        }
        break;
      case Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_ADJUSTMENTS:
        if (event.errorMessage) {
          yield put(actions.setUpdateItemsFailure(Error(event.errorMessage)));
        } else if (event.progress === 0) {
          yield put(actions.setUpdateItemsSuccess());
        }
        break;
      case Constants.ASYNC_PROGRESS_FUNC.SYNC_PRODUCTS:
      case Constants.ASYNC_PROGRESS_FUNC.SYNC_INVENTORIES:
        if (event.errorMessage) {
          yield put(
            actions.setProviderStatusFailure(Error(event.errorMessage))
          );
        } else if (event.progress === 0) {
          yield put(actions.setProviderStatusSuccess());
        }
        break;
      default:
        break;
    }
  } catch (error) {
    Analytics.sendErrorReport(error, "receiveRequestProgressEvent", {
      event,
    });
  }
}

export function* watchReceiveRequestProgressEvent() {
  yield takeEvery(
    actions.RECEIVE_REQUEST_PROGRESS_EVENT,
    receiveRequestProgressEvent
  );
}

export function* getDraftOnoffConfig({ storeId }) {
  try {
    const config = yield call(API.getOnoffConfig, storeId, "draft");
    yield put(actions.receiveDraftOnoffConfig(config));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getDraftOnoffConfig", {
      storeId,
    });
  }
}

export function* watchGetDraftOnoffConfig() {
  yield takeEvery(actions.GET_DRAFT_ONOFF_CONFIG, getDraftOnoffConfig);
}

export function* getLiveOnoffConfig({ storeId }) {
  try {
    const config = yield call(API.getOnoffConfig, storeId, "live");
    yield put(actions.receiveLiveOnoffConfig(config));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getLiveOnoffConfig", {
      storeId,
    });
  }
}

export function* watchGetLiveOnoffConfig() {
  yield takeEvery(actions.GET_LIVE_ONOFF_CONFIG, getLiveOnoffConfig);
}

export function* createOrUpdateOnoffConfig({ config }) {
  try {
    let updatedConfig = null;
    const _config = _.cloneDeep(config);
    const storeId = yield select(reducers.getStoreId);
    if (_config.stage === "live") {
      const liveConfig = yield call(API.getOnoffConfig, storeId, "live");
      if (_.isEmpty(liveConfig)) {
        updatedConfig = yield call(API.createOnoffConfig, {
          ..._config,
          storeId: storeId,
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        });
      } else {
        // 다음에 새로운 로고 업로드시 기존 로고 지워주기
        updatedConfig = yield call(API.updateOnoffConfig, {
          ..._config,
          updatedAt: new Date().toISOString(),
        });
      }
      yield call(API.deleteOnoffConfig, storeId, "draft");
      yield put(actions.clearDraftOnoffConfig());
      yield put(actions.receiveLiveOnoffConfig(updatedConfig));
    } else {
      const draftConfig = yield call(API.getOnoffConfig, storeId, "draft");
      if (_.isEmpty(draftConfig)) {
        updatedConfig = yield call(API.createOnoffConfig, {
          ..._config,
          storeId: storeId,
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        });
      } else {
        updatedConfig = yield call(API.updateOnoffConfig, {
          ..._config,
          updatedAt: new Date().toISOString(),
        });
      }
      yield put(actions.receiveDraftOnoffConfig(updatedConfig));
    }
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "createOrUpdateOnoffConfig", {
      config,
    });
  }
}

export function* watchCreateOrUpdateOnoffConfig() {
  yield takeEvery(
    actions.CREATE_OR_UPDATE_ONOFF_CONFIG,
    createOrUpdateOnoffConfig
  );
}

export function* deleteDraftOnoffConfig({}) {
  try {
    const storeId = yield select(reducers.getStoreId);
    const updatedConfig = yield call(API.deleteOnoffConfig, storeId, "draft");
    yield put(actions.clearDraftOnoffConfig());
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "deleteDraftOnoffConfig", {});
  }
}

export function* watchDeleteDraftOnoffConfig() {
  yield takeEvery(actions.DELETE_DRAFT_ONOFF_CONFIG, deleteDraftOnoffConfig);
}

export function* getCherryPickers({ storeId }) {
  try {
    const customers = yield call(API.getCherryPickers, storeId);
    yield put(
      actions.receivePickers((customers || []).length > 0 ? customers : [])
    );
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getCherryPickers", { storeId });
  }
}

export function* watchGetCherryPickers() {
  yield takeEvery(actions.GET_CHERRY_PICKERS, getCherryPickers);
}

export function* getBlackListCustomers({ storeId }) {
  try {
    const customers = yield call(API.getBlackList, storeId);
    const customersById = (customers || []).reduce((obj, c) => {
      obj[c.id] = c;
      return obj;
    }, {});
    yield put(actions.receiveBlackListCustomers(customersById));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getBlackListCustomers", { storeId });
  }
}

export function* watchGetBlackListCustomers() {
  yield takeEvery(actions.GET_BLACKLIST_CUSTOMERS, getBlackListCustomers);
}

export function* getCustomerTierHistories({ storeId, customerId, start, end }) {
  try {
    let histories = yield call(API.getCustomerTierHistories, storeId, [
      {
        key: "customerId",
        value: customerId,
        operation: "eq",
      },
      {
        key: "createdAt",
        value: [start, end],
        operation: "between",
      },
    ]);
    histories = _.reverse(_.sortBy(histories || [], "createdAt"));
    yield put(actions.receiveCustomerTierHistories(histories || []));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "getCustomerTierHistories", { storeId });
  }
}

export function* watchGetCustomerTierHistories() {
  yield takeEvery(
    actions.GET_CUSTOMER_TIER_HISTORIES,
    getCustomerTierHistories
  );
}

export function* updateCustomerTier({ storeId, customerId, tierId }) {
  try {
    let data = yield call(API.updateCustomerTier, storeId, customerId, tierId);
    yield put(actions.receiveRetrievedCustomer(data));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "updateCustomerTier", { storeId });
  }
}

export function* watchUpdateCustomerTier() {
  yield takeEvery(actions.UPDATE_CUSTOMER_TIER, updateCustomerTier);
}

export function* updateCustomerBlackList({ storeId, customerId, active }) {
  try {
    let data = yield call(
      API.updateCustomerBlackList,
      storeId,
      customerId,
      active
    );
    // yield put(actions.receiveCustomerTierHistories(histories || []));
    yield put(actions.setProviderStatusSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.setProviderStatusFailure(error));
    Analytics.sendErrorReport(error, "updateCustomerBlackList", {
      storeId,
      customerId,
    });
  }
}

export function* watchUpdateCustomerBlackList() {
  yield takeEvery(actions.UPDATE_CUSTOMER_BLACKLIST, updateCustomerBlackList);
}

export default function* root() {
  yield all([
    fork(watchGenerateStore),
    fork(watchGetStore),
    fork(watchUpdateStore),
    fork(watchUpdateMirrorConfig),
    fork(watchUpdateStoreLoyalty),
    fork(watchActiveReviewCampaign),
    fork(watchInactiveReviewCampaign),
    fork(watchActiveKioskMode),
    fork(watchInactiveKioskMode),
    fork(watchGetBillingHistories),
    fork(watchSendBarcodeCouponCampaign),
    fork(watchGetCampaigns),
    fork(watchGetCampaign),
    fork(watchGetCampaignWithReport),
    fork(watchCreateCampaign),
    fork(watchUpdateCampaign),
    fork(watchLanuchCampaign),
    fork(watchCancelCampaign),
    fork(watchDeleteCampaign),
    fork(watchGetItems),
    fork(watchGetLoyaltyReport1),
    fork(watchGetLoyaltySummary),
    fork(watchGetSalesReport),
    fork(watchGetItemsReport),
    fork(watchGetSalesSummary),
    fork(watchGetSalesHistories),
    fork(watchGetSettlements),
    fork(watchCreateOrUpdatePurchaseOrder),
    fork(watchDeletePurchaseOrder),
    fork(watchGetPurchaseOrders),
    fork(watchGetEmployeeByPurchaseOrderId),
    fork(watchGetPurchaseOrderById),
    fork(watchUpdateProducts),
    fork(watchUpdateStoreMenuPlan),
    fork(watchCreateOrUpdateProduct),
    fork(watchDeleteProduct),
    fork(watchCreateOrUpdateCategory),
    fork(watchDeleteCategory),
    fork(watchCreateOrUpdateModifier),
    fork(watchDeleteModifier),
    fork(watchGetOrder),
    fork(watchGetOrderWithTransactions),
    fork(watchGetOrderHistories),
    fork(watchGetInventoryHistoriesByStore),
    fork(watchGetInventoryHistoriesByLocation),
    fork(watchGetAllOrderHistories),
    fork(watchGetShippingOrders),
    fork(watchGetCustomers),
    fork(watchRetrieveCustomer),
    fork(watchGetProviderCustomer),
    fork(watchGetCustomer),
    fork(watchUpdateOrder),
    fork(watchUpdateOrders),
    fork(watchCreateOrUpdateCustomerGroup),
    fork(watchDeleteCustomerGroup),
    fork(watchCreateCustomer),
    fork(watchGetCustomerGorups),
    fork(watchDeleteCustomer),
    fork(watchUpdateCustomer),
    fork(watchGetPointTransactions),
    fork(watchUpdateEmployee),
    fork(watchUpdateOrganizationEmployee),
    fork(watchDeleteOrganizationEmployee),
    fork(watchGetOrdersByStore),
    fork(watchGetBillingCost),
    fork(watchGetNotices),
    fork(watchGetNoticeCampaigns),
    fork(watchGetUser),
    fork(watchUpdateUser),
    fork(watchSignUpMarket),
    fork(watchSignInMarket),
    fork(watchSetStoreFavorite),
    fork(watchLoadStore),
    fork(watchAddItemToCart),
    fork(watchAddItemsToCart),
    fork(watchDuplicateOrderToCart),
    fork(watchRemoveItemFromCart),
    fork(watchDeleteCart),
    fork(watchGetCart),
    fork(watchRecievePaidOrder),
    fork(watchGetOrdersByCustomer),
    fork(watchPayOrder),
    fork(watchRegisterOrUpdateAccount),
    fork(watchSendReceiptLink),
    fork(watchCancelOrder),
    fork(watchResetMarketPassword),
    fork(watchGetCoupon),
    fork(watchGetCoupons),
    fork(watchGetCouponsForPublish),
    fork(watchCreateCoupon),
    fork(watchUpdateCoupon),
    fork(watchDeleteCoupon),
    fork(watchDeleteCoupons),
    fork(watchSetOrderType),
    fork(watchGetReviewCampaigns),
    fork(watchCreateOrUpdateReviewCampaign),
    fork(watchDeleteReviewCampaign),
    fork(watchLaunchReviewCampaign),
    fork(watchGetReviewCampaignResults),
    fork(watchDownloadReviewCampaign),
    fork(watchLanunchMultiLocation),
    fork(watchGetOrderReport),
    fork(watchChannelOrderReport),
    fork(watchGetReviewReport),
    fork(watchGetPointReport),
    fork(watchGetInventoryItemReport),
    fork(watchGetInventoryDateReport),
    fork(watchGetInventoryTurnoverReport),
    fork(watchCreateOrUpdateMenuPlan),
    fork(watchDeleteMenuPlan),
    fork(watchCreateOrUpdateOrderPlan),
    fork(watchDeleteOrderPlan),
    fork(watchCreateOrUpdateLocation),
    fork(watchInviteEmployee),
    fork(watchGetOrganizations),
    fork(watchSetCurrentOrganization),
    fork(watchConfirmInviteEmployee),
    fork(watchCreateOrUpdateNotice),
    fork(watchCreateOrUpdateNoticeCampaign),
    fork(watchDeleteNotice),
    fork(watchDeleteNoticeCampaign),
    fork(watchGetArchivedNotices),
    fork(watchAdjustInventories),
    fork(watchUpdateSkuLocations),
    fork(watchCreateSkuInventory),
    fork(watchUpdateSkuInventory),
    fork(watchDeleteSkuInventory),
    fork(watchUpdateSyncMenuBoard),
    fork(watchCreateOrUpdateCustomerTag),
    fork(watchUpdateCustomerTags),
    fork(watchDeleteCustomerTag),
    fork(watchCreateOrUpdateStoreAtrributes),
    fork(watchGetCustomerTags),
    fork(watchUploadItemTemplate),
    fork(watchSyncItemTemplate),
    fork(watchReceiveStoreStatus),
    fork(watchUpdateActiveChangedProducts),
    fork(watchGetOrderByApiKey),
    fork(watchUpdateOrderFromReceipt),
    fork(watchGetCorporateCustomers),
    fork(watchGetSubscriptionPlans),
    fork(watchGetSubscriptionPlan),
    fork(watchCreateSubscriptionPlan),
    fork(watchUpdateSubscriptionPlan),
    fork(watchDeleteSubscriptionPlan),
    fork(watchCreateCustomerPayment),
    fork(watchDeleteCustomerPayment),
    fork(watchSubscribeSubscriptionPlan),
    fork(watchStopSubscription),
    fork(watchRestoreSubscription),
    fork(watchCancelSubscription),
    fork(watchGetSubscriptionEvents),
    fork(watchGetSubscriptionPlansByIds),
    fork(watchGetSubscriptionReport),
    fork(watchCreateOrUpdateTerminal),
    fork(watchDeleteTerminal),
    fork(watchGetTerminals),
    fork(watchGetCustomerGroupsWithCount),
    fork(watchGetCustomersByTag),
    fork(watchGetCustomersStats),
    fork(watchGetRecipientCount),
    fork(watchGetRegisteredCustomerCount),
    fork(watchGetCustomerTagsCount),
    fork(watchPayFailedBillings),
    fork(watchUpdateLocation),
    fork(watchUpdateReceiptConfig),
    fork(watchUpdateViewConfig),
    fork(watchGetDeliveryReport),
    fork(watchFindEmployeeByEmail),
    fork(watchGetMgmtContract),
    fork(watchClearAllContract),
    fork(watchCreateOrUpdateContract),
    fork(watchCreateOrUpdateRentalContract),
    fork(watchFindStoresByOperatorId),
    fork(watchCreateOrUpdateLabelTemplate),
    fork(watchDeleteLabelTemplate),
    fork(watchGetLabelTemplates),
    fork(watchUpdateSettings),
    fork(watchConvertPosMode),
    fork(watchGetSuppliers),
    fork(watchCreateOrUpdateSupplier),
    fork(watchDeleteSupplier),
    fork(watchCreateInventoryTransferHistory),
    fork(watchGetInventoryHistoriesByGroupId),
    fork(watchGetInventoryTransferHistories),
    fork(watchGetSkuInventoryHistories),
    fork(watchGetInventoryHistorySummary),
    fork(watchCompleteInventoryTransferHistory),
    fork(watchDeleteInventoryTransferHistory),
    fork(watchGetCountHistories),
    fork(watchShareHistoryPdf),
    fork(watchSharePurchaseOrderPdf),
    fork(watchUpdateInventoryHistory),
    fork(watchRefreshInventoryHistory),
    fork(watchDownloadInventoryHistories),
    fork(watchShareGroupHistoryPdf),
    fork(watchSyncInventoryTemplate),
    fork(watchUploadInventoryTemplate),
    fork(watchGenerateInventoryTemplate),
    fork(watchGetInventoryAssetReport),
    fork(watchGetProduct),
    fork(watchCreateCouponTransactions),
    fork(watchDownloadCsvForTransactions),
    fork(watchGetBarcodeCoupon),
    fork(watchGetBarcodeCouponTransaction),
    fork(watchGetBarcodeCoupons),
    fork(watchGetBarcodeCouponTransactionByPhone),
    fork(watchIssueBarcodeCouponForProviderCustomer),
    fork(watchIssueBarcodeCouponWithStoreCustomerId),
    fork(watchDropoutAccount),
    fork(watchSyncExternalLoyalty),
    fork(watchGetProviderCoupons),
    fork(watchUpdateProviderConfig),
    fork(watchGetProviderConfig),
    fork(watchGetWaiting),
    fork(watchGetWaitingInfo),
    fork(watchCancelWaiting),
    fork(watchUpdateCreditConfig),
    fork(watchGetCreditStatus),
    fork(watchGetCreditHistories),
    fork(watchChargeCredit),
    fork(watchGetRequestProgressEvents),
    fork(watchReceiveRequestProgressEvent),
    fork(watchGetProviderOrders),
    fork(watchGetProviderCustomerGroups),
    fork(watchGetTierUpdateHistories),
    fork(watchLinkProviderProducts),
    fork(watchGetProviderProducts),
    fork(watchUpdateStoreProvider),
    fork(watchSyncProviderInventories),
    fork(watchGetInventorySyncHistories),
    fork(watchGetProviderProductsLinkHistories),
    fork(watchUnlinkProviderProducts),
    fork(watchSyncProviderProducts),
    fork(watchGetDraftOnoffConfig),
    fork(watchGetLiveOnoffConfig),
    fork(watchCreateOrUpdateOnoffConfig),
    fork(watchDeleteDraftOnoffConfig),
    fork(watchRetrieveCustomerWithPoint),
    fork(watchRetrieveCustomerWithOrder),
    fork(watchRetrieveCustomerWithCredit),
    fork(watchRetrieveCustomerWithCoupon),
    fork(watchGetCherryPickers),
    fork(watchGetBlackListCustomers),
    fork(watchGetCustomerTierHistories),
    fork(watchUpdateCustomerTier),
    fork(watchUpdateCustomerBlackList),
  ]);
}
