import { combineReducers } from "redux";
import { cloneDeep } from "lodash";

import * as actions from "../actions";

const initialState = {
  campaignStatus: {
    isFetching: false,
    error: null,
  },
  campaigns: {},
  expectedRecipientNumber: 0,
};

function campaignStatus(state = initialState.campaignStatus, action) {
  switch (action.type) {
    case actions.CREATE_CAMPAIGN:
    case actions.UPDATE_CAMPAIGN:
    case actions.CANCEL_CAMPAIGN:
    case actions.DELETE_CAMPAIGN:
    case actions.LAUNCH_CAMPAIGN:
    case actions.SEND_BARCODE_COUPON_CAMPAIGN:
    case actions.GET_CAMPAIGNS:
    case actions.GET_CAMPAIGN:
    case actions.GET_CAMPAIGN_WITH_REPORT:
    case actions.GET_EXPECTED_RECIPIENT_NUMBER:
      return {
        isFetching: true,
        error: null,
      };
    case actions.CREATE_OR_UPDATE_CAMPAIGN_SUCCESS:
    case actions.CANCEL_CAMPAIGN_SUCCESS:
    case actions.LAUNCH_CAMPAIGN_SUCCESS:
    case actions.GET_CAMPAIGNS_SUCCESS:
    case actions.GET_CAMPAIGN_SUCCESS:
    case actions.DELETE_CAMPAIGN_SUCCESS:
      return initialState.campaignStatus;
    case actions.LAUNCH_CAMPAIGN_FAILURE:
    case actions.CREATE_OR_UPDATE_CAMPAIGN_FAILURE:
    case actions.DELETE_CAMPAIGN_FAILURE:
    case actions.CANCEL_CAMPAIGN_FAILURE:
    case actions.GET_CAMPAIGNS_FAILURE:
    case actions.GET_CAMPAIGN_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function campaigns(state = initialState.campaigns, action) {
  switch (action.type) {
    case actions.RECEIVE_CAMPAIGN:
      return {
        ...cloneDeep(state),
        [action.campaign.id]: cloneDeep(action.campaign),
      };
    case actions.RECEIVE_CAMPAIGNS:
      return action.campaigns.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
    case actions.RECEIVE_DELETED_CAMPAIGN:
      const { [action.campaignId]: _, ...campaigns } = state;
      return campaigns;
    case actions.SIGN_OUT:
      return initialState.campaigns;
    default:
      return state;
  }
}

function expectedRecipientNumber(state = initialState.expectedRecipientNumber, action) {
  switch (action.type) {
    case actions.SET_EXPECTED_RECIPIENT_NUMBER:
      return action.count;
    default:
      return state;
  }
}

export default combineReducers({
  campaigns,
  campaignStatus,
  expectedRecipientNumber,
});
