import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  config: {},
  status: {
    isFetching: false,
    error: null,
  },
  groups: [],
  histories: [],
  linkHistories: [],
  inventoryHistories: [],
  providerProducts: [],
  onoffDraftConfig: null,
  onoffLiveConfig: null,
  cherryPickers: [],
  blackList: {},
  customerTierHistories: [],
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.UPDATE_PROVIDER_CONFIG:
    case actions.GET_PROVIDER_CONFIG:
    case actions.GET_PROVIDER_CUSTOMER_GROUPS:
    case actions.GET_TIER_UPDATE_HISTORIES:
    case actions.GET_PROVIDER_PRODUCTS:
    case actions.LINK_PROVIDER_PRODUCTS:
    case actions.SYNC_PROVIDER_INVENTORIES:
    case actions.GET_INVENTORY_SYNC_HISTORIES:
    case actions.UNLINK_PROVIDER_PRODUCTS:
    case actions.GET_PROVIDER_PRODUCTS_LINK_HISTORIES:
    case actions.SYNC_PROVIDER_PRODUCTS:
    case actions.GET_DRAFT_ONOFF_CONFIG:
    case actions.GET_LIVE_ONOFF_CONFIG:
    case actions.CREATE_OR_UPDATE_ONOFF_CONFIG:
    case actions.DELETE_DRAFT_ONOFF_CONFIG:
    case actions.GET_CHERRY_PICKERS:
    case actions.GET_BLACKLIST_CUSTOMERS:
    case actions.GET_CUSTOMER_TIER_HISTORIES:
    case actions.UPDATE_CUSTOMER_TIER:
    case actions.UPDATE_CUSTOMER_BLACKLIST:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_PROVIDER_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_PROVIDER_STATUS_FAILURE:
      return {
        ...initialState.status,
        error: action.error,
      };
    default:
      return state;
  }
}

function config(state = initialState.config, action) {
  switch (action.type) {
    case actions.RECEIVE_PROVIDER_CONFIG:
      return action.config;
    default:
      return state;
  }
}

function groups(state = initialState.groups, action) {
  switch (action.type) {
    case actions.RECEIVE_PROVIDER_CUSTOMER_GROUPS:
      return action.groups;
    case actions.SIGN_OUT:
    case actions.CLEAR_PROVIDER_CUSTOMER_GROUPS:
      return initialState.groups;
    default:
      return state;
  }
}

function histories(state = initialState.histories, action) {
  switch (action.type) {
    case actions.RECEIVE_TIER_UPDATE_HISTORIES:
      return action.histories;
    case actions.SIGN_OUT:
    case actions.CLEAR_TIER_UPDATE_HISTORIES:
      return initialState.histories;
    default:
      return state;
  }
}

function linkHistories(state = initialState.linkHistories, action) {
  switch (action.type) {
    case actions.RECEIVE_LINK_HISTORIES:
      return action.histories;
    case actions.SIGN_OUT:
    case actions.CLEAR_LINK_HISTORIES:
      return initialState.linkHistories;
    default:
      return state;
  }
}

function inventoryHistories(state = initialState.inventoryHistories, action) {
  switch (action.type) {
    case actions.RECEIVE_INVENTORY_SYNC_HISTORIES:
      return action.histories;
    case actions.SIGN_OUT:
    case actions.CLEAR_INVENTORY_SYNC_HISTORIES:
      return initialState.inventoryHistories;
    default:
      return state;
  }
}

function providerProducts(state = initialState.providerProducts, action) {
  switch (action.type) {
    case actions.RECEIVE_PROVIDER_PRODUCTS:
      return action.items;
    case actions.SIGN_OUT:
    case actions.CLEAR_PROVIDER_PRODUCTS:
      return initialState.providerProducts;
    default:
      return state;
  }
}

function onoffDraftConfig(state = initialState.onoffDraftConfig, action) {
  switch (action.type) {
    case actions.RECEIVE_DRAFT_ONOFF_CONFIG:
      return action.config;
    case actions.CLEAR_DRAFT_ONOFF_CONFIG:
    case actions.SIGN_OUT:
      return initialState.onoffDraftConfig;
    default:
      return state;
  }
}

function onoffLiveConfig(state = initialState.onoffLiveConfig, action) {
  switch (action.type) {
    case actions.RECEIVE_LIVE_ONOFF_CONFIG:
      return action.config;
    default:
      return state;
  }
}

function cherryPickers(state = initialState.cherryPickers, action) {
  switch (action.type) {
    case actions.RECEIVE_PICKERS:
      return action.customers;
    case actions.CLEAR_PICKERS:
    case actions.SIGN_OUT:
      return initialState.cherryPickers;
    default:
      return state;
  }
}

function blackList(state = initialState.blackList, action) {
  switch (action.type) {
    case actions.RECEIVE_BLACKLIST_CUSTOMERS:
      return action.customers;
    case actions.SIGN_OUT:
    case actions.CLEAR_BLACKLIST_CUSTOMERS:
      return initialState.blackList;
    default:
      return state;
  }
}

function customerTierHistories(
  state = initialState.customerTierHistories,
  action
) {
  switch (action.type) {
    case actions.RECEIVE_CUSTOMER_TIER_HISTORIES:
      return action.histories;
    case actions.SIGN_OUT:
    case actions.CLEAR_CUSTOMER_TIER_HISTORIES:
      return initialState.histories;
    default:
      return state;
  }
}

export const getLinkOriginLabel = (origin) => {
  switch (origin) {
    case "cafe24":
      return "카페24";

    default:
      return "";
  }
};

export const getTierHistoryReasonLabel = (reason) => {
  switch (reason) {
    case "auto":
      return "자동조정";
    case "force":
      return "수동조정";
    default:
      return "";
  }
};

export default combineReducers({
  config,
  status,
  groups,
  histories,
  linkHistories,
  inventoryHistories,
  providerProducts,
  onoffDraftConfig,
  onoffLiveConfig,
  cherryPickers,
  blackList,
  customerTierHistories,
});
