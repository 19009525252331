import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  waiting: {},
  info: {
    waitings: null,
    minutes: null,
  },
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.GET_WAITING:
    case actions.CANCEL_WAITING:
    case actions.GET_WAITING_INFO:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_WAITING_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_WAITING_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

const waiting = (state = initialState.waiting, action) => {
  switch (action.type) {
    case actions.RECEIVE_WAITING:
      return action.waiting;
    case actions.CLEAR_WAITING:
      return initialState.waiting;
    default:
      return state;
  }
};

const info = (state = initialState.info, action) => {
  switch (action.type) {
    case actions.RECEIVE_WAITING_INFO:
      return {
        waitings: action.info?.waitings,
        minutes: action.info?.minutes,
      };
    case actions.CLEAR_WAITING:
      return initialState.info;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  waiting,
  info,
});
