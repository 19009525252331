import { combineReducers } from "redux";
import _ from "lodash";

import * as actions from "../actions";

export const getSellableQuantity = (inventories) => {
  return _.sumBy(inventories, "quantity") || 0;
};

export const getDisuseQuantity = (inventories) => {
  return _.sumBy(inventories, "unavailableQuantity") || 0;
};

export const getHistoryFileNameByType = (type) => {
  switch (type) {
    case "group":
      return "inventory_group_history";
    case "asset":
      return "자산 리포트";
    case "single":
    default:
      return "inventory_history";
  }
};

export const getAssetHistoryReason = (history) => {
  switch (history.reason) {
    case "sales":
    case "loss":
    case "remove":
      return "출고";
    case "newStock":
    case "returns":
      return "입고";
    case "transfer":
      if (history.quantity > 0) {
        return "입고";
      } else {
        return "출고";
      }
    case "force":
      if (history.totalCost > 0) {
        return "입고";
      } else if (history.totalCost < 0) {
        return "출고";
      } else {
        return "-";
      }
    default:
      return "";
  }
};

export const getOriginReason = (reason) => {
  switch (reason) {
    case "sales":
      return "판매";
    case "remove":
      return "임의차감";
    case "loss":
      return "손실";
    case "newStock":
      return "입고";
    case "returns":
      return "반품입고";
    case "transfer":
      return "지점이동";
    case "force":
      return "임의조정";
    default:
      return "";
  }
};

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  histories: {
    byId: {},
    count: 0,
    currentItemCount: 0,
  },
  transferHistories: {
    byId: {},
    count: 0,
  },
  countHistories: {
    byId: {},
    count: 0,
  },
  summary: {
    bySkuId: {},
  },
};

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.GET_INVENTORY_HISTORIES_BY_STORE:
    case actions.GET_INVENTORY_HISTORIES_BY_LOCATION:
    case actions.CREATE_INVENTORY_TRANSFER_HISTORY:
    case actions.UPDATE_INVENTORY_TRANSFER_HISTORY:
    case actions.DELETE_INVENTORY_TRANSFER_HISTORY:
    case actions.COMPLETE_INVENTORY_TRANSFER_HISTORY:
    case actions.GET_INVENTORY_TRANSFER_HISTORIES:
    case actions.GET_INVENTORY_COUNT_HISTORIES:
    case actions.GET_INVENTORY_HISTORY_SUMMARY:
    case actions.GET_SKU_INVENTORY_HISTORIES:
    case actions.UPDATE_INVENTORY_HISTORY:
    case actions.REFRESH_INVENTORY_HISTORY:
    case actions.GET_INVENTORY_HISTORIES_BY_GROUP_ID:
    case actions.DOWNLOAD_INVENTORY_HISTORIES:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_INVENTORY_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_INVENTORY_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}

const summary = (state = initialState.summary, action) => {
  switch (action.type) {
    case actions.RECEIVE_INVENTORY_HISTORY_SUMMARY:
      return {
        ...state,
        bySkuId: action.list.reduce((obj, his) => {
          obj[his.skuId] = his;
          return obj;
        }, {}),
      };
    case actions.CLEAR_INVENTORY_HISTORY_SUMMARY:
      return initialState.summary;

    default:
      return state;
  }
};

const histories = (state = initialState.histories, action) => {
  let _histories = _.cloneDeep(state.byId);
  switch (action.type) {
    case actions.RECEIVE_INVENTORY_HISTORIES:
      return {
        ...state,
        byId: action.list.reduce(
          (obj, his) => {
            if (his.isLegacy) {
              obj[his.id + his.createdAt] = his;
            } else {
              obj[his.id] = his;
            }
            return obj;
          },
          action.override ? {} : _histories
        ),
        count: action.count,
      };
    case actions.RECEIVE_SKU_INVENTORY_HISTORIES:
      return {
        ...state,
        byId: action.list.reduce((obj, his) => {
          obj[his.id] = his;
          return obj;
        }, {}),
        currentItemCount: action.prevQuantity,
      };
    case actions.RECEIVE_INVENTORY_HISTORY:
      _histories[action.history.id] = {
        ...action.history,
        histories: [
          ...(_histories[action.history.id]?.histories || []),
          ...(action.history.histories || []),
        ],
      };
      return {
        ...state,
        byId: _histories,
        currentItemCount: action.count,
      };
    case actions.CLEAR_INVENTORY_HISTORIES:
      return initialState.histories;
    case actions.SIGN_OUT:
      return initialState.histories;
    default:
      return state;
  }
};

const transfer = (state = initialState.transferHistories, action) => {
  let _transfer = _.cloneDeep(state.byId);
  switch (action.type) {
    case actions.RECEIVE_INVENTORY_TRANSFER_HISTORIES:
      return {
        byId: action.list.reduce(
          (obj, his) => {
            obj[his.id] = his;
            return obj;
          },
          action.override ? {} : _transfer
        ),
        count: action.count,
      };
    case actions.RECEIVE_INVENTORY_TRANSFER_HISTORY:
      _transfer[action.history.id] = action.history;
      return {
        ...state,
        byId: _transfer,
      };
    case actions.RECEIVE_DELETED_TRANSFER_HISTORY:
      _transfer = Object.values(_transfer || {})
        .filter((t) => t.id !== action.history.id)
        .reduce((obj, _t) => {
          obj[_t.id] = _t;
          return obj;
        }, {});
      return {
        ...state,
        byId: _transfer,
      };
    default:
      return state;
  }
};

const count = (state = initialState.countHistories, action) => {
  let _count = _.cloneDeep(state.byId);
  switch (action.type) {
    case actions.RECEIVE_COUNT_HISTORIES:
      return {
        byId: action.list.reduce(
          (obj, his) => {
            obj[his.id] = his;
            return obj;
          },
          action.override ? {} : _count
        ),
        count: action.count,
      };
    default:
      return state;
  }
};

export const getSkuQuantityByLocationId = (sku, locationId) => {
  let quantity = 0;
  for (let inv of sku.inventories || []) {
    if (inv.locationId === locationId) {
      quantity += inv.quantity;
    }
  }
  return quantity;
};

export const inventoryEditableLocations = (
  locations,
  organization,
  hasAccess
) => {
  return (locations || []).filter(
    (loc) => loc.id === organization.locationId || hasAccess
  );
};

export default combineReducers({
  status,
  histories,
  transfer,
  count,
  summary,
});
