import { combineReducers } from "redux";
import _ from "lodash";
import * as actions from "../actions";


const initialState = {
  settlements: [],
  status: {
    isFetching: false,
    error: null,
  },
};

function settlements(state = initialState.settlements, action) {
  switch (action.type) {
    case actions.RECEIVE_SETTLEMENTS:
      return action.settlements;
    default:
      return state;
  }
}

function status(state = initialState.status, action) {
    switch (action.type) {
      case actions.GET_SETTLEMENTS:
        return {
          isFetching: true,
          error: null,
        };
      case actions.SET_SETTLEMENTS_STATUS_SUCCESS:
        return initialState.status;
      case actions.SET_SETTLEMENTS_STATUS_FAILURE:
        return {
          isFetching: false,
          error: action.error,
        };
      default:
        return state;
    }
  }

export default combineReducers({
  settlements,
  status,
});
