import { combineReducers } from "redux";
import * as actions from "../actions";

const initialState = {
  location: {},
  plan: {},
  employee: {},
  notice: {},
};

function location(state = initialState.location, action) {
  switch (action.type) {
    case actions.SET_EDITING_LOCATION:
      return action.location;
    case actions.SIGN_OUT:
      return initialState.location;
    default:
      return state;
  }
}

function plan(state = initialState.plan, action) {
  switch (action.type) {
    case actions.SET_EDITING_PLAN:
      return action.plan;
    case actions.SIGN_OUT:
      return initialState.plan;
    default:
      return state;
  }
}

function employee(state = initialState.employee, action) {
  switch (action.type) {
    case actions.SET_EDITING_EMPLOYEE:
      return action.employee;
    case actions.SIGN_OUT:
      return initialState.employee;
    default:
      return state;
  }
}

function notice(state = initialState.notice, action) {
  switch (action.type) {
    case actions.SET_EDITING_NOTICE:
      return action.notice;
    case actions.SIGN_OUT:
      return initialState.notice;
    default:
      return state;
  }
}

export default combineReducers({
  location,
  plan,
  employee,
  notice,
});
