import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";

const initialState = {
  orderStatus: {
    isFetching: false,
    error: null,
  },
  orders: {
    byId: {},
    nextToken: null,
  },
  historiesStatus: {
    isFetching: false,
    error: null,
  },
  histories: {
    items: [],
    nextToken: null,
  },
  providerOrders: {
    items: {},
    count: 0,
  },
};

export const getOrderItemName = (item) => {
  const orderItemName = item.description
    ? `(${item.description})${item.name} x${item.quantity}`
    : `${item.name} x${item.quantity}`;
  return orderItemName;
};

export const getOrderNumber = (
  id,
  orderNumber,
  orderNumberPrefix,
  orderOriginId = null
) => {
  if (orderNumber) {
    return orderNumberPrefix
      ? `${orderNumberPrefix}-${orderNumber}`
      : orderNumber;
  } else {
    if (orderOriginId) {
      return orderOriginId;
    } else {
      return (id || "").slice(id?.length - 10);
    }
  }
};

export const getTypeLabel = (order) => {
  switch (!_.isEmpty(order.retailOption)) {
    case true:
      switch (order.retailOption) {
        case "instant":
          return "즉시출고";
        case "ready":
          return "출고대기";
        case "shipping":
          return "배송주문";

        default:
          return "";
      }
    case false:
      switch (order.diningOption) {
        case "forhere":
          return "매장주문";
        case "takeout":
          return "포장주문";
        case "delivery":
          return "배달주문";
        default:
          return "";
      }
    default:
      return "";
  }
};

export const getRetailStateLabel = (state) => {
  switch (state) {
    case "unconfirmed":
      return "미확인";
    case "confirmed":
      return "배송대기";
    case "shipped":
      return "배송완료";

    default:
      return "미확인";
  }
};

const orders = (state = initialState.orders, action) => {
  switch (action.type) {
    case actions.RECEIVE_ORDERS:
      return {
        ...state,
        byId: action.orders.items.reduce((obj, order) => {
          obj[order.id] = order;
          return obj;
        }, {}),
        nextToken: action.orders.nextToken,
      };
    case actions.RECEIVE_ORDER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.order.id]: action.order,
        },
      };
    case actions.SIGNOUT_MARKET:
      return initialState.orders;
    default:
      return state;
  }
};

function orderStatus(state = initialState.orderStatus, action) {
  switch (action.type) {
    case actions.GET_ORDER:
    case actions.GET_ORDER_WITH_TRANSACTIONS:
    case actions.GET_ORDERS_BY_STORE:
    case actions.GET_ORDERS_BY_CUSTOMER:
    case actions.GET_SHIPPING_ORDERS:
    case actions.UPDATE_ORDER:
    case actions.UPDATE_ORDERS:
    case actions.GET_ORDER_HISTORIES:
    case actions.GET_PROVIDER_ORDERS:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_ORDER_SUCCESS:
    case actions.GET_ORDERS_SUCCESS:
    case actions.UPDATE_ORDER_SUCCESS:
    case actions.GET_ORDER_HISTORIES_SUCCESS:
      return initialState.orderStatus;
    case actions.GET_ORDER_FAILURE:
    case actions.GET_ORDERS_FAILURE:
    case actions.UPDATE_ORDER_FAILURE:
    case actions.GET_ORDER_HISTORIES_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function historiesStatus(state = initialState.historiesStatus, action) {
  switch (action.type) {
    case actions.GET_ORDER_HISTORIES:
    case actions.GET_ALL_ORDER_HISTORIES:
      return {
        isFetching: true,
        error: null,
      };
    case actions.GET_ORDER_HISTORIES_SUCCESS:
      return initialState.historiesStatus;
    case actions.GET_ORDER_HISTORIES_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
function histories(state = initialState.histories, action) {
  switch (action.type) {
    case actions.RECEIVE_ORDER:
      const _items = state.items.map((history) => {
        if (history.orderHistoryOrderId === action.order.id) {
          history.order = {
            ...history.order,
            //NOTE: sagas의 updateOrder order.customre를 delete하고 있기에 기존 order에 note만을 업데이트합니다.
            note: action.order.note,
          };
        }
        return history;
      });
      return {
        ...state,
        items: _items,
      };
    case actions.RECEIVE_ORDER_HISTORIES:
      let items = (action.histories.items || []).map((hist) => {
        if (hist.order && hist.order.customer) {
          hist.order.customer = {
            ...hist.order.customer,
            ...hist.order.customer.customer,
            id: hist.order.customer.id,
          };
          delete hist.order.customer.customer;
        }
        return hist;
      });
      return {
        ...state,
        nextToken: action.histories.nextToken,
        items: items,
      };
    default:
      return state;
  }
}

const providerOrders = (state = initialState.providerOrders, action) => {
  switch (action.type) {
    case actions.CLEAR_PROVIDER_ORDERS:
      return { items: {}, count: 0 };
    case actions.RECEIVE_PROVIDER_ORDERS:
      let _orders = (action.orders?.items || []).reduce((obj, order) => {
        obj[order.id] = order;
        return obj;
      }, state.items);
      return { items: _orders, count: action.orders.count };
    default:
      return state;
  }
};

export const getOrderOrigin = (origin) => {
  switch (origin) {
    case "cafe24":
      return "카페24";
    case "market":
      return "어라운드마켓";
    case "menuorder":
      return "테이블오더";
    case "coupang":
      return "쿠팡이츠";
    case "baemin":
      return "배달의민족";
    case "yogiyo":
      return "요기요";
    case "kiosk":
      return "키오스크";
    default:
    case "pos":
      return "매장";
  }
};

export const getPaymentInfo = (_order) => {
  const payment = {};
  let totalReceiving = 0;
  let cashReceiving = 0;
  let cardReceiving = 0;
  let creditReceiving = 0;
  let extraReceiving = 0;
  let barcodeReceiving = 0;
  let refundReceiving = 0;
  let refundingAmount = 0;
  let discountAmount = 0;
  let taxAmount = 0;

  if (_order.items) {
    let taxObj = _order.items
      .filter((item) => item.type === "tax")
      .map((item) => item);
    if (taxObj.length > 0) {
      for (let tax of taxObj) {
        taxAmount += tax.amount;
      }
      payment.taxAmount = taxAmount;
    } else {
      payment.taxAmount = 0;
    }

    let discountItems = _order.items
      .filter(
        (item) =>
          item.type === "discount" ||
          item.type === "coupon" ||
          item.type === "reward"
      )
      .map((item) => item);
    for (let item of discountItems) {
      discountAmount += item.amount;
    }
    payment.discountAmount = discountAmount;
    payment.shippingAmount = _.sumBy(
      _order.items.filter((i) => i.type === "shipping"),
      "amount"
    );
  }

  if (_order.charges) {
    for (let charge of _order.charges) {
      if (charge.method === "cash") {
        cashReceiving += charge.amount;
      }
      if (charge.method === "card") {
        cardReceiving += charge.amount;
      }
      if (charge.method === "credit") {
        creditReceiving += charge.amount;
      }
      if (charge.method === "extra") {
        extraReceiving += charge.amount;
      }
      if (charge.method === "barcode") {
        barcodeReceiving += charge.amount;
      }
      if (charge.method === "refund" && charge.amount > 0) {
        refundReceiving += charge.amount;
      }
      if (
        charge.method === "refund" &&
        charge.amount === 0 &&
        charge.taxRefundAmount > 0 &&
        (charge.refunds || []).length < 1
      ) {
        refundingAmount += charge.taxRefundAmount || 0;
      }
    }
    payment.unpaid =
      _order.amount -
      (cashReceiving +
        cardReceiving +
        creditReceiving +
        extraReceiving +
        barcodeReceiving +
        refundReceiving);
    payment.cashReceiving = cashReceiving + _order.amountChanged;
    payment.cardReceiving = cardReceiving;
    payment.barcodeReceiving = barcodeReceiving;
    payment.creditReceiving = creditReceiving;
    payment.extraReceiving = extraReceiving;
    payment.refundReceiving = refundReceiving;
    payment.refundingAmount = refundingAmount;

    totalReceiving =
      payment.cashReceiving +
      payment.cardReceiving +
      payment.creditReceiving +
      payment.extraReceiving +
      payment.barcodeReceiving;
    payment.totalReceiving = totalReceiving;
  } else {
    payment.unpaid =
      _order.amount -
      (cashReceiving +
        cardReceiving +
        creditReceiving +
        extraReceiving +
        barcodeReceiving +
        refundReceiving);
    payment.cashReceiving = 0;
    payment.cardReceiving = 0;
    payment.creditReceiving = 0;
    payment.extraReceiving = 0;
    payment.barcodeReceiving = 0;
    payment.totalReceiving = 0;
    payment.refundReceiving = 0;
  }
  return payment;
};

export const getTaxRefundChargesByType = (orderCharges, isReturned) => {
  const _afterRefundCharges = [];
  const _immediateTaxRefundCharges = [];
  const returnObj = {};
  if (orderCharges?.length > 0) {
    orderCharges.forEach((charge) => {
      if (
        charge.method === "refund" &&
        (isReturned
          ? (charge.refunds || []).length > 0
          : (charge.refunds || []).length < 1)
      ) {
        if (charge.amount === 0 && charge.taxRefundAmount > 0) {
          _afterRefundCharges.push(charge);
        } else if (charge.amount > 0) {
          _immediateTaxRefundCharges.push(charge);
        }
      }
    });
  }
  if (_afterRefundCharges.length) {
    returnObj.afterRefund = isReturned
      ? _afterRefundCharges[_afterRefundCharges.length - 1]
      : _afterRefundCharges[0];
  }
  if (_immediateTaxRefundCharges.length) {
    returnObj.immediateTaxRefund = isReturned
      ? _immediateTaxRefundCharges[_immediateTaxRefundCharges.length - 1]
      : _immediateTaxRefundCharges[0];
  }
  return returnObj;
};

export const getMethodName = (charge, isRefund = false) => {
  if (
    isRefund &&
    (charge?.refunds || []).filter((refund) => refund.origin === "manual")
      .length
  ) {
    return "임의환불";
  } else if (charge.origin === "manual") {
    return charge.name;
  } else {
    switch (charge.method) {
      case "cash":
        return "현금";
      case "extra":
        return "기타수단";
      case "credit":
        return "선불금";
      case "barcode":
        return `간편결제(${charge.name})`;
      case "card":
        return `카드(${charge.ident ? charge.ident.substr(0, 4) : ""})`;
    }
  }
};

export const getImmediateTaxRefundAmount = (order) => {
  const orderCharges = order.charges || [];
  const isReturned = order.state === "returned";
  let immediateTaxRefundAmount = null;
  const taxRefundChargesByType = getTaxRefundChargesByType(
    orderCharges,
    isReturned
  );
  if (!_.isEmpty(taxRefundChargesByType.immediateTaxRefund)) {
    // NOTE: 주문건이 paid되기 이전에 환급이 취소된 케이스 제외
    const isReturnedBeforePaid =
      isReturned &&
      (taxRefundChargesByType.immediateTaxRefund.refunds || []).filter(
        (refund) => {
          return (
            new Date(refund.createdAt).getTime() <
            new Date(order.paidAt).getTime()
          );
        }
      ).length > 0;
    if (isReturnedBeforePaid) {
      return 0;
    } else {
      immediateTaxRefundAmount =
        taxRefundChargesByType.immediateTaxRefund.amount;
    }
  }
  return immediateTaxRefundAmount;
};

export const getAfterTaxRefundAmount = (order) => {
  const orderCharges = order.charges || [];
  const isReturned = order.state === "returned";
  let afterTaxRefundAmount = null;
  if (isReturned) {
    return afterTaxRefundAmount;
  }
  const taxRefundChargesByType = getTaxRefundChargesByType(
    orderCharges,
    isReturned
  );
  if (!_.isEmpty(taxRefundChargesByType.afterRefund)) {
    afterTaxRefundAmount = taxRefundChargesByType.afterRefund.taxRefundAmount;
  }
  return afterTaxRefundAmount;
};

export default combineReducers({
  orderStatus,
  orders,
  histories,
  historiesStatus,
  providerOrders,
});
