import React, { useEffect, useRef } from "react";
import md5, { base64 } from "js-md5";
import _, { isArray, isNil, isString } from "lodash";
import reactStringReplace from "react-string-replace";
import moment from "moment";

import awsConfig from "../aws-exports";
import { batch } from "react-redux";
import Constants, { DaysOfWeek } from "./Constants";

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  // return v4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
};

export const getMarketCognitoId = (phone) => {
  return hashMd5(phone) + "@thegrowing.co";
};

export const getBucketUrl = (path) => {
  return `https://s3-${awsConfig.aws_user_files_s3_bucket_region}.amazonaws.com/${awsConfig.aws_user_files_s3_bucket}/${path}`;
};

export const toNumberString = function (string) {
  return string ? string.replace(/[^0-9]+/g, "") : string;
};

export const addressToStr = (address) => {
  // NOTE: address = {main: "", detail: ""}
  return (address?.main || "") + (address?.detail || "")
    ? (address?.main || "") + (address?.detail || "")
    : null;
};

export const getTaxAmount = (amount, taxRate) => {
  return taxRate > 0 ? Math.ceil(amount - amount / (1 + taxRate / 100)) : 0;
};

export const removeString = function (string) {
  return string ? string.replace(/[^0-9]+/gi, "") : null;
};

export function removeEmoji(str) {
  const emoji =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return str.replace(emoji, "");
}

export const isValidPhone = (str) => {
  return str && str.length >= 7 && /^\d+$/.test(str);
};

export const getDayName = (dateString) => {
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  var d = new Date(dateString);
  return days[d.getDay()];
};

export const phoneNumberFormat = (text) => {
  // NOTE: https://cublip.tistory.com/326
  if (text) {
    return text
      .replace(/[^0-9]/g, "")
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
        "$1-$2-$3"
      )
      .replace("--", "-");
  }
  return text;
};

export const replaceString = (str, match, url) => {
  let count = 0;
  return reactStringReplace(str, match, (match, i) => {
    if (count === 0) {
      count++;
      return (
        <a
          className={"blue-color"}
          key={match + i}
          target={"_black"}
          href={url}
        >
          {match}
        </a>
      );
    } else {
      return match;
    }
  });
};

export const convertDateToString = (d) => {
  return (
    d.getFullYear().toString() +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    d.getDate().toString().padStart(2, "0") +
    d.getHours().toString().padStart(2, "0") +
    d.getMinutes().toString().padStart(2, "0") +
    d.getSeconds().toString().padStart(2, "0") +
    d.getMilliseconds().toString().padStart(3, "0")
  );
};

export const strToHtml = (str) =>
  str.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      {str.split("\n").length !== index ? <br /> : ""}
    </span>
  ));

export const toLocaleString = (str) => {
  if (str) {
    return str.toLocaleString();
  }
  return str;
};

export const splitFilename = (str) => {
  return str.split(/\.(?=[^\.]+$)/);
};

export const getFilenameFromPath = (str) => {
  return str.replace(/^.*[\\\/]/, "");
};

export const removeWhitespace = (str) => {
  if (str) {
    return str.replace(/\s/g, "");
  }
  return str;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const replaceCommas = (string) => {
  if (string.includes("-")) {
    return string.replace(/-/g, "");
  } else {
    return string.replace(/,/g, " ");
  }
};

export const downloadCSV = (rows, fileName = null) => {
  const universalBOM = "\uFEFF";
  let csvContent = universalBOM;

  rows.forEach(function (rowArray) {
    let row = rowArray
      // TODO: r값에 #이 들어갈 경우 뒤에 값이 삭제됨
      .map((r) => (!isNil(r) && isString(r) ? `"${r.replace("#", "")}"` : r))
      .join(",");
    csvContent += row + "\r\n";
  });
  const csvBlob = new Blob([csvContent], { type: "text/csv" });
  const blobUri = URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.setAttribute("href", blobUri);
  link.setAttribute("download", `${fileName || "report"}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const numberWithCommas = function (value) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value;
};

export const getExtention = (filename) => {
  return filename.split(".").pop();
};

export const replaceAt = function (string, index, replacement) {
  if (string) {
    return (
      string.substr(0, index) +
      replacement +
      string.substr(index + replacement.length)
    );
  }
  return string;
};

export const birthFormat = function (string) {
  return (
    string.substr(0, 4) + "/" + string.substr(4, 2) + "-" + string.substr(6, 2)
  );
};

export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const isValidDecimal = (decimalValue) => {
  // 2 places
  var rx = /^\d+(?:\.\d{1,2})?$/;
  if (rx.test(decimalValue)) {
    return true;
  } else {
    return false;
  }
};

export const hashMd5 = (s) => {
  let hash = md5.create();
  hash.update(s);
  return hash.hex();
};

export const numberValidator = function (string) {
  if (isString(string)) {
    if (string.includes(!/^[0-9]+$/)) {
      return string.substr(0, string.length - 1);
    }
  }
  return string;
};

// export const attachPoint = function(string) {
//   if (string) {
//     return string + " " + "p";
//   }
//   return string;
// };

export const limit4Characters = function (string) {
  if (string) {
    if (string.length > 4) return string.substr(0, string.length - 1);
  }
  return string;
};

export const removeCharacters = function (string, includesSpace = false) {
  if (string) {
    if (includesSpace) {
      return string.replace(/[^0-9 \.]+/g, "");
    } else {
      return string.replace(/[^0-9\.]+/g, "");
    }
  }
  return string;
};

export const generateId = (prefix, values = []) => {
  let hash = md5.create();
  if (isArray(values) && values.length > 0) {
    for (let v of values) {
      hash.update(v);
    }
  } else {
    // hash.update(new Date().toISOString());
    hash.update(new Date().getTime().toString());
    hash.update(Math.random().toString(36).substring(7));
  }
  let ident = btoa(hash.hex()).substr(0, 24);
  if (prefix) {
    return `${prefix}_${ident}`;
  }
  return ident;
};

export const isValidEmail = function (string) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
};

export const generateRandomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const numberToCurrency = (amount, currency = "KRW") => {
  return new Intl.NumberFormat("ko-KR", {
    style: "currency",
    currency: currency || "KRW",
  }).format(amount);
};

export const generateColor = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgba(" + r + "," + g + "," + b + ", 1)";
};

export const cardNumberFormat = function (string) {
  if (string) {
    return (
      string.slice(0, 4) +
      "-" +
      string.slice(4, 8) +
      "-" +
      string.slice(8, 12) +
      "-" +
      string.slice(12, 16)
    );
  }
  return string;
};

export const countKoString = function (string) {
  let charLength = 0;
  let ch1 = "";
  if (string) {
    for (let i = 0; i < string.length; i++) {
      ch1 = string.charAt(i);

      if (escape(ch1).length > 4) {
        charLength += 2;
      } else {
        charLength += 1;
      }
    }
  }
  return charLength;
};

export const msToTime = (s) => {
  // Pad to 2 or 3 digits, default is 2
  const pad = (n, z) => {
    z = z || 2;
    return ("00" + n).slice(-z);
  };

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
};

export const removeEmptySpace = function (string) {
  if (string) {
    string.replace(/\s/g, "");
  }
  return string;
};

export const alphanumericValidator = function (string) {
  if (string) {
    return string.replace(
      /[^A-Za-z0-9_\`\~\!\@\#\$\%\^\&\*\(\)\-\=\+\\\{\}\[\]\'\"\;\:\<\,\>\.\?\/\s]/gi,
      ""
    );
  }
  return string;
};

export const isEmptyString = (str) => {
  return str && 0 === str.length;
};

export const getDates = (a, b) => {
  var dates = [],
    currentDate = a,
    addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= b) {
    dates.push(moment(currentDate).format("MM/DD"));
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const isValidPassword = (text) => {
  let valid = true;
  if (text) {
    if (!/^[A-Za-z\d!@#$%^&*+-_]{8,}$/.test(text)) {
      valid = false;
    }
    return valid;
  }
};

export const maskingPhoneNumber = (str) => {
  if (!str) {
    return str;
  }
  str = str.replace(
    /(\+?\d+)(\d{4})(\d{4})/g,
    function (match, start, middle, end) {
      return start + "*".repeat(middle.length) + end;
    }
  );
  str = str.replace(
    /(\+?\d+)(\d{3})(\d{4})/g,
    function (match, start, middle, end) {
      return start + "*".repeat(middle.length) + end;
    }
  );
  return str;
};

export const maskingName = (strName) => {
  if (!strName) {
    return strName;
  } else if (strName.length > 2) {
    var originName = strName.split("");
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = "*";
    });
    var joinName = originName.join();
    return joinName.replace(/,/g, "");
  } else {
    var pattern = /.$/; // 정규식
    return strName.replace(pattern, "*");
  }
};

export const maskingBarcode = (strName) => {
  if (!strName) {
    return strName;
  } else if (strName.length > 2) {
    let originBarcode = strName.split("");
    originBarcode.forEach(function (name, i) {
      if (
        i === 0 ||
        i === 1 ||
        i === originBarcode.length - 1 ||
        i === originBarcode.length - 2
      )
        return;
      originBarcode[i] = "*";
    });
    let joined = originBarcode.join("");
    return joined;
  } else {
    let originBarcode = strName.split("");
    originBarcode[originBarcode.length - 1] = "*";
    return originBarcode.join("");
  }
};

export const periodChangeButtonPress = (
  type,
  selectedPeriod,
  onResetPeriod,
  onChangePeriod
) => {
  let start = new Date();
  let end = new Date();

  if (type === selectedPeriod) {
    onResetPeriod();
    return;
  }

  if (type === "today") {
    start = new Date(new Date().setHours(0, 0, 0, 0));
    end = new Date(new Date().setHours(23, 59, 59, 999));
  } else if (type === "yesterday") {
    start = moment().subtract(1, "day").toDate();
    end = moment().subtract(1, "day").toDate();
  } else if (type === "lastWeek") {
    start = moment().subtract(1, "week").startOf("isoWeek").toDate();
    end = moment().startOf("week").toDate();
  } else if (type === "thisWeek") {
    start = moment().startOf("isoWeek").toDate();
    end = moment().toDate();
  } else if (type === "lastMonth") {
    start = moment().subtract(1, "month").startOf("month").toDate();
    end = moment().subtract(1, "month").endOf("month").toDate();
  } else if (type === "thisMonth") {
    start = moment().startOf("month").toDate();
    let diff = moment().endOf("month").diff(moment());
    end = diff === 0 ? moment().endOf("month").toDate() : moment().toDate();
  }
  onChangePeriod(type, start, end);
};

export const base64ToFile = (dataurl, fileName) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const diffSet = (a, b) => {
  // NOTE: A - B
  return (a || []).filter((x) => !(b || []).includes(x));
};

export const getPhoneDevicePlatform = () => {
  const currentUser = window.navigator.userAgent;
  if (currentUser.indexOf("iPhone") > -1) {
    return "ios";
  } else if (currentUser.indexOf("Android") > -1) {
    return "android";
  } else {
    return null;
  }
};

export const isMobile = () => {
  let isCheck = false;

  if (getPhoneDevicePlatform()) {
    isCheck = true;
  }

  return isCheck;
};

export const connectAppStore = (platform) => {
  if (platform === "android") {
    window.location.href = Constants.PLAYSTORE_URL;
  } else {
    window.location.href = Constants.APPSTORE_URL;
  }
};

export const convertMilliToPoint = (mm) => {
  return Math.ceil(mm * 2.8346456693 * 100) / 100;
};

export const convertMilliToPixel = (mm) => {
  return mm * 3.7795275591;
};

export const convertPointToMilli = (pt) => {
  return pt * 0.3527777778;
};

export const convertPixelToPoint = (px) => {
  return px * 0.75;
};

export const convertPointToPixel = (pt) => {
  return pt * 1.3333333333333333;
};

export const moveElementInArray = (arr, elm, n) => {
  let shiftedArray = [...arr];
  const prevIndex = arr.indexOf(elm);
  if (prevIndex === -1) {
    return arr;
  }
  const len = arr.length;
  const newIndex = (prevIndex + (n % len) + len) % len;
  shiftedArray.splice(newIndex, 0, shiftedArray.splice(prevIndex, 1)[0]);

  return shiftedArray;
};

export const usePrevious = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const isLegacyInventoryHistories = (startDate, historyType) => {
  return (
    moment(Constants.STOCK_HISTORY_UPDATE_DATE).isAfter(moment(startDate)) &&
    historyType === "single"
  );
};

const generateCheckSum = (barcode) => {
  const barcodeChar = barcode.split("");
  const odds = barcodeChar.filter((char, index) => index % 2 !== 0);
  const evens = barcodeChar.filter((char, index) => index % 2 === 0);
  const oddSum = odds.reduce((sum, odd) => {
    sum += parseInt(odd, 10) * 3;
    return sum;
  }, 0);
  const evenSum = evens.reduce((sum, odd) => {
    sum += parseInt(odd, 10);
    return sum;
  }, 0);
  // NOTE: EAN-13의 체크섬을 계산하기 위한 공식이다.
  return (10 - ((oddSum + evenSum) % 10)) % 10;
};

export const generateBarcode = (prefix = null) => {
  const random = Math.random() + 1;
  const barcode = random * Date.now();
  let _barcode = barcode.toString();
  if (prefix) {
    _barcode = prefix + _barcode.slice(0, 12 - prefix.length);
  } else {
    _barcode = _barcode.slice(0, 12);
  }
  const checkSum = generateCheckSum(_barcode);
  return _barcode + checkSum.toString();
};

export const refreshBrowser = () => {
  window.location.reload();
};

export const compareDateString = (a, b) => {
  return new Date(a).getTime() > new Date(b).getTime() ? -1 : 1;
};

export const isDateEqual = (a, b) => {
  return new Date(a).getTime() === new Date(b).getTime();
};

export function isAvailableQuantity(value) {
  const isAvailableQuantity = value < Constants.STOCK_QUANTITY_MAX_VALUE;
  return isAvailableQuantity;
}

export function isAvailableAmount(value) {
  const isAvailableAmount = value < Constants.PRICE_MAX_VALUE;
  return isAvailableAmount;
}

export function getAvailableAmount(number) {
  if (isAvailableAmount(number)) {
    return number;
  } else {
    return Number(
      number
        .toString()
        .slice(0, Constants.PRICE_MAX_VALUE.toString().length - 1)
    );
  }
}

export const getCreditCost = (type) => {
  switch (type) {
    case "sms":
      return 2;
    case "lms":
      return 6;
    case "mms":
      return 12;
    case "normal":
      return 6;
    case "wide":
      return 7;
    case "text":
      return 4;
    case "alimtalk":
      return 2;
    default:
      return 2;
  }
};

export const isValidDate = (date) => {
  return !isNaN(date.getTime());
};

export const getDownloadFileDate = () => {
  const date = new Date();
  return `${date.getFullYear()}${
    date.getMonth() + 1
  }${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
};

export const downloadExcel = async (workbook, fileName) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName + ".xlsx";
  anchor.click();
  window.URL.revokeObjectURL(url);
};

export const addWheelEvent = () => {
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
};

export const removeWheelEvent = () => {
  document.removeEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
};
